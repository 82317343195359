import React from "react";
import { overviewCardStyle } from "./style";
import { useNavigate } from "react-router-dom";

const OverviewCard = ({
  numericFigure,
  title,
  icon,
  infoIcon,
  showMoreInfo = true,
  cardBackColor,
  infoIconBackColor,
  fontColor = "white",
  numericColor = "white",
  titleColor = "white",
  navigatePath,
  filter,
}) => {
  const navigate = useNavigate();
  return (
    <div className="col-md-3">
      <div
        className="card mb-3"
        style={{ ...overviewCardStyle.card, backgroundColor: cardBackColor }}
      >
        <div className="row p-2">
          <div className="card-body col-9 pt-0">
            <div style={overviewCardStyle.cardBody}>
              <h3 style={{ ...overviewCardStyle.numeric, color: numericColor }}>
                {numericFigure}
              </h3>
              <div style={{ ...overviewCardStyle.title, color: titleColor }}>
                {title}
              </div>
            </div>
          </div>
          <div className="col-3 pt-1">{icon}</div>
        </div>
        {showMoreInfo && (
          <div
            style={{
              ...overviewCardStyle.moreInfo,
              backgroundColor: infoIconBackColor,
              color: fontColor,
            }}
            onClick={() =>
              navigate(navigatePath, {
                state: { ...filter, dashboardCheck: true },
              })
            }
          >
            <div>More Info {infoIcon}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewCard;
