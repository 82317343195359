import React from "react";
import IconButton from "@mui/material/IconButton";
import { iconStyles } from "./style";

const ReusableIcon = (props) => {
  const {
    icon,
    iconColor,
    style,
    iconBackgroundColor,
    onClick,
    className,
    toolTip,
    iconText,
    btnLoading = false,
  } = props;
  const iconStyle = {
    ...iconStyles.iconButton,
    color: iconColor || iconStyles.iconButton.color, // Use provided color or default color
    backgroundColor:
      iconBackgroundColor || iconStyles.iconButton.backgroundColor,
    padding: 0,
    ...style,
  };

  return (
    <IconButton
      style={iconStyle}
      onClick={onClick}
      className={className}
      title={toolTip}
      disabled={btnLoading}
    >
      {icon} {iconText && <span style={iconStyles.span}>{iconText}</span>}
    </IconButton>
  );
};

export default ReusableIcon;
