import {
  SEARCH_SUBMIT,
  SEARCH_COMPLETE,
  UPDATE_FILTERED_DATA,
  UPDATE_GLOBAL_SEARCH_DATA,
} from "./actionTypes";

const initialState = {
  searchSubmitted: false,
  filteredData: [],
  globalSearchFilteredData: [],
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SUBMIT:
      return {
        ...state,
        searchSubmitted: true,
      };
    case SEARCH_COMPLETE:
      return {
        ...state,
        searchSubmitted: false,
      };
    case UPDATE_FILTERED_DATA:
      return {
        ...state,
        filteredData: action.payload,
      };
    case UPDATE_GLOBAL_SEARCH_DATA:
      return {
        ...state,
        globalSearchFilteredData: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;
