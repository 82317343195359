import React from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { utrStyle } from "../style";
import { utrRemovedTableColumns } from "../../utils/DepositUtils";

const UtrRemovedList = (props) => {
  const { formattedRemovedDepositData, loading } = props;
  return (
    <CustomTable
      data={formattedRemovedDepositData}
      columns={utrRemovedTableColumns}
      rows={25} //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
      style={utrStyle.table}
      loading={loading}
    />
  );
};

export default UtrRemovedList;
