import { color } from "../../../themes/Colors";

export const withdrawStyle = {
    container: { borderRadius: "1px", position: "relative" },
    searchBtn: {
        width: "100%",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: "none",
        color: color._ffffff,
        fontWeight: 400,
        height: "29px"
    },
    table: { minWidth: "100rem" },
    btnLabel: {
        color: "transparent",
    },
};