// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import DisabledAccountsContainer from '../../../containers/AccountsContainer/DisabledAccountsContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const DisabledAccounts = () => {

  // Returning DisabledAccounts Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.disabledAccountListing} />
      <Layout heading={layoutHeading.disabledAccounts}>
        <DisabledAccountsContainer />
      </Layout>
    </>
  );
};

// Exporting DisabledAccounts Function.
export default DisabledAccounts;
