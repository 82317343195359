import React from 'react';
import { addAccountStyle } from './style';
import AddAccountForm from './helper/AddAccountForm';

const AddAccountContainer = () => {
    return (
        <>
            <div className='row'>
                <div className='col-md-12 mb-4'>
                    <div className='card card-primary p-2' style={addAccountStyle.container}>
                        <AddAccountForm />
                    </div>
                </div>
            </div>
        </>

    );
};

export default AddAccountContainer;