import { useCallback, useEffect, useRef, useState } from "react";
import { dateError, formError } from "../constants/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { getSiteUserStats } from "./../redux/user/action";
import { getFormattedStats } from "../containers/StatisticsContainer/Utils/StatsUtils";
import { useNavigate } from "react-router-dom";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";
import { getAuthData, getValidateValue } from "../utils/Common";
import { toast } from "react-toastify";

export const useStatsHook = () => {
  const { siteUserStats } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [resetDisable, setResetDisable] = useState(true);
  const [formInputChange, setFormInputChange] = useState(false);
  const [formErrors, setFormErrors] = useState({
    startDate: "",
    endDate: "",
  });
  const [searchInput, setSearchInput] = useState({
    startDate: "",
    endDate: new Date().toISOString().split("T")[0],
  });

  const isMounted = useRef(true);
  const { token } = getAuthData();

  const fetchSiteUserStats = useCallback(
    async (obj = null) => {
      setLoading(true);
      await dispatch(
        getSiteUserStats(token, obj, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
      setLoading(false);
    },
    [dispatch]
  );

  useEffect(() => {
    if (isMounted.current) {
      fetchSiteUserStats(getSiteDates());
    }
    return () => {
      isMounted.current = false;
    };
  }, [fetchSiteUserStats]);

  const getSiteDates = () => {
    let todayDate = new Date();
    let endDate = todayDate.toISOString().split("T")[0];
    const sevenDaysBack = new Date(todayDate);
    sevenDaysBack.setDate(todayDate.getDate() - 7);
    const startDate = sevenDaysBack.toISOString().split("T")[0];
    return { startDate, endDate };
  };

  useEffect(() => {
    setSearchInput(getSiteDates());
  }, []);

  const renderError = (field) => {
    return formErrors[field];
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (getValidateValue(formErrors, dateError)) {
      setBtnDisable(true);
      await fetchSiteUserStats({ searchInput });
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
      toast.error(formError.error);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSearchInput(getSiteDates());
    setFormErrors({});
    fetchSiteUserStats(getSiteDates());
    setBtnDisable(true);
    setResetDisable(true);
  };

  const validateDates = (name, value) => {
    let error = {};
    let today = new Date();
    const startDate =
      name === "startDate"
        ? new Date(value)
        : searchInput.startDate
        ? new Date(searchInput.startDate)
        : "";
    const endDate =
      name === "endDate"
        ? new Date(value)
        : searchInput.endDate
        ? new Date(searchInput.endDate)
        : "";
    if (startDate && endDate && startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        name === "startDate"
          ? (error.startDate = dateError.startDate)
          : (error.endDate = dateError.endDate);
      }
    }
    if (endDate !== "" && endDate > today) {
      error.endDate = dateError.endCannotGreat;
    }
    if (startDate !== "" && startDate > today) {
      error.startDate = dateError.startCannotGreat;
    }
    return error;
  };

  const handleSearchFormInputChange = (e) => {
    const { name, value } = e.target;
    setBtnDisable(false);
    setResetDisable(false);
    const dateError = validateDates(name, value);
    setSearchInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormInputChange(true);
    setFormErrors(dateError);
  };

  const formattedSiteUserStatsData =
    siteUserStats?.length && getFormattedStats(siteUserStats);

  return {
    formInputChange,
    handleSearchFormInputChange,
    searchInput,
    renderError,
    formErrors,
    loading,
    formattedSiteUserStatsData,
    siteUserStats,
    handleReset,
    handleSearch,
    btnDisable,
    resetDisable,
  };
};

export default useStatsHook;
