// Importing Dependencies.
import React from "react";
import { btnStyle } from './style';

// Declaring Button Function.
const Button = ({
  onClick,
  title,
  disabled,
  type,
  style,
  className,
  btnIcon,
  isLoading,
  status,
  loaderStyle,
  dataBsToggle,
  dataBsTarget,
  dataBsDismiss,
  tooltip
}) => {
  // Create an object to hold the conditional attributes for modal
  const conditionalAttributes = {};
  if (dataBsToggle) {
    conditionalAttributes['data-bs-toggle'] = dataBsToggle;
  }
  if (dataBsTarget) {
    conditionalAttributes['data-bs-target'] = dataBsTarget;
  }
  if (dataBsDismiss) {
    conditionalAttributes['data-bs-dismiss'] = dataBsDismiss;
  }

  // Returning Button UI.
  return (
    <button
      className={className}
      style={{ ...style, ...btnStyle.btn }}
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      title={tooltip}
      {...conditionalAttributes} // Spread the conditional attributes
    >
      {btnIcon && <span>{btnIcon}</span>}
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm"
          role={status}
          aria-hidden="true"
          style={loaderStyle}
        ></span>
      )}
      {title}
    </button>
  );
};

// Exporting Button UI.
export default Button;
