import React from 'react';
import CustomLabel from '../../../../components/atoms/CustomLabel';
import CustomSelectBox from '../../../../components/atoms/CustomSelectBox';
import { roleOptions, usersContants } from '../constants';
import Button from '../../../../components/atoms/Button';
import { usersStyle } from '../style';
import { classes } from '../../../../constants/CommonConstants';

const SearchForm = (props) => {
    const { onHandleSelect, handleReset } = props;
    return (
        <form>
            <div className="row">
                <div className="col-md-3">
                    <CustomLabel label={usersContants.role} />
                    <CustomSelectBox
                        options={roleOptions}
                        defaultValue=""
                        className={classes.selectBox}
                        onChange={(value) => onHandleSelect("role", value.label, "inActiveUserSearch")}
                    />
                </div>
                <div className="col-md-3 d-flex align-items-end mt-2 mt-md-0">
                    <Button
                        title={usersContants.search}
                        style={usersStyle.searchBtn}
                        className="py-1 btn-hover"
                    />
                </div>
                <div className="col-md-3 d-flex align-items-end mt-2 mt-md-0">
                    <Button
                        title={usersContants.reset}
                        style={usersStyle.searchBtn}
                        className="py-1 btn-hover"
                        onClick={handleReset}
                    />
                </div>
            </div>
        </form>
    )
}

export default SearchForm