export const accountStatementConstants = {
    siteUsers: "Site Users:",
    type: "Type:",
    search: "Search",
    reset: "Reset",
}

export const siteUserOptions = [
    { value: "1", label: "Select Site User" },
    { value: "2", label: "Demolalit(laser247.com)" },
    { value: "3", label: "Demolalit(play247.win)" },
    { value: "3", label: "Demolalit(11xplay.com)" },
];

export const typeOptions = [
    { value: "", label: "Select type" },
    { value: "2", label: "Settlement" },
    { value: "3", label: "Deposit/Withdraw" },
];