import React from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { accountsStyle } from "../../AccountListContainer/style";
import { useSelector } from "react-redux";
import { disabledAccountsTableColumns } from "../../utils/AccountUtils";
import BlankRecord from "../../../../components/organisms/CustomTable/BlankRecord";

const DisabledAccountsList = (props) => {
  const { disabledAccountsData, loading } = props;
  const { filteredDisabledData } = useSelector((state) => state.bankAccount);

  return (
    <>
      {filteredDisabledData === 0 ? <BlankRecord data={[]} columns={disabledAccountsTableColumns}
        style={accountsStyle.table} /> : <CustomTable
        data={
          filteredDisabledData && filteredDisabledData.length > 0
            ? filteredDisabledData
            : disabledAccountsData
        }
        columns={disabledAccountsTableColumns}
        rows={25}  //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
        style={accountsStyle.table}
        loading={loading}
      />}
    </>


  );
};

export default DisabledAccountsList;
