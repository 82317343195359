import React from 'react'
import { loginImage } from '../../../assets/images'
import { logoStyle } from './style'
import { URLs } from '../../../routes/URLs'
import { getAuthData } from '../../../utils/Common';

const Logo = () => {
    const { userName } = getAuthData();

    return (
        <div className="logo-container">
            <div className="row text-center">
                <div className="col-12 mb-1" style={logoStyle.logoContainer}>
                    <img className="img-fluid w-auto" src={loginImage} alt="Logo" style={logoStyle.img} />
                </div>
                <div className="col-12">
                    <p className="mb-0 mx-auto" href={URLs.dashboard} style={logoStyle.color}>{userName}</p>
                </div>
            </div>
        </div>
    )
}

export default Logo