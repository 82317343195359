import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { depositConstants } from "../constants";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { depositStyle } from "../style";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";
import ReusableIcon from "../../../../components/atoms/ReusableIcon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { color } from "../../../../themes/Colors";
import Swal from "sweetalert2";

const DepositForm = (props) => {
  const {
    bankAccounts,
    depositDetails,
    onHandleInputChange,
    handleDepositInputChange,
    handleDepositSubmit,
    formErrors,
    renderError,
    btnLoading,
    fileInputRef,
    setDepositAccountId,
    CREATEPERMISSION,
  } = props;
  const accountOptions = bankAccounts?.map((account) => ({
    value: account?.id,
    label: account?.account_name,
  }));

  const openProof = (item) => {
    const file = item.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        Swal.fire({
          imageUrl: reader.result,
          imageHeight: 500,
          imageAlt: "Preview",
          showCloseButton: true,
          customClass: {
            confirmButton: "proof-class",
            closeButton: "proof-close-btn",
          },
        });
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <form onSubmit={handleDepositSubmit}>
      <div className="row m-0 p-2">
        <div className="col-md-3 p-1">
          <CustomLabel label={depositConstants.utrNumber} />
          <CustomInput
            className={`${classes.inputClass} ${
              formErrors["utrNumber"] && classes.invalid
            }`}
            type={inputTypes.text}
            value={depositDetails?.utrNumber}
            onChange={(e) => handleDepositInputChange("utrNumber", e)}
            error={renderError("utrNumber")}
          />
        </div>
        <div className="col-md-3 p-1">
          <CustomLabel label={depositConstants.account} />
          <SearchBox
            options={accountOptions}
            placeholder={depositConstants.selectAccount}
            onChange={(value) => {
              onHandleInputChange("account", value[0]?.text);
              setDepositAccountId(value[0]?.id);
            }}
            className={`${formErrors["account"] && classes.invalid}`}
            error={renderError("account")}
            // formSubmitState={formSubmitState}
            value={depositDetails?.account}
          />
        </div>
        <div className="col-md-3 p-1">
          <CustomLabel label={depositConstants.amount} />
          <CustomInput
            className={`${classes.inputClass} ${
              formErrors["amount"] && classes.invalid
            }`}
            type={inputTypes.text}
            value={depositDetails?.amount}
            onChange={(e) => handleDepositInputChange("amount", e)}
            error={renderError("amount")}
          />
        </div>
        <div className="col-md-3 p-1">
          <CustomLabel label={depositConstants.note} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.text}
            value={depositDetails?.note}
            onChange={(e) => handleDepositInputChange("note", e)}
          />
        </div>
        <div
          className={`${
            fileInputRef?.current?.value?.length ? "col-md-4" : "col-md-3"
          } p-1 d-flex justify-center`}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div
            className={`${
              fileInputRef?.current?.value?.length ? "col-md-9" : "col-md-12"
            }`}
          >
            <CustomLabel label={depositConstants.uploadImg} />
            <CustomInput
              className={`${classes.inputClass} ${
                formErrors.qrCode ? classes.invalid : ""
              }`}
              type={inputTypes.file}
              // onChange={(e) => handleImageOnChange(e)}
              onChange={(e) => handleDepositInputChange("qrCode", e)}
              accept={inputTypes.acceptType}
              error={renderError("qrCode")}
              id="fileInput"
              inputRef={fileInputRef}
            />
          </div>
          {fileInputRef?.current?.value?.length ? (
            <div className="mt-4" style={{ paddingLeft: "10px" }}>
              <ReusableIcon
                icon={<VisibilityIcon sx={{ fontSize: 14 }} />}
                style={{
                  color: color._ffffff,
                  backgroundColor: color._0089ca,
                  borderRadius: "15px",
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                }}
                onClick={() => openProof(fileInputRef)}
                iconText={"Preview"}
                toolTip={"Preview"}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-3 p-1" style={{ marginLeft: "6px" }}>
          <CustomLabel
            label={depositConstants.submit}
            additionalStyles={depositStyle.btnLabel}
          />

          <Button
            title={depositConstants.submit}
            style={
              CREATEPERMISSION
                ? depositStyle.searchBtn
                : depositStyle.searchDisabledBtn
            }
            className="py-1 btn-hover"
            isLoading={btnLoading}
            disabled={!CREATEPERMISSION}
          />
        </div>
      </div>
    </form>
  );
};

export default DepositForm;
