import React from "react";
import SearchForm from "./helper/SearchForm";
import useGlobalSearchHook from "../../hooks/global-search-hook.js";
import GlobalSearchCards from "../../components/organisms/GlobaISearchCards";
import Modal from "../../components/molecules/Modal/Modal.js";
import { globalSearchConstants } from "./constants.js";
import CustomTable from "../../components/organisms/CustomTable/CustomTable.js";
import { globalSearchTableColumns } from "./utils/GlobalSearchUtils.js";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import AddClientModal from "./utils/AddClientModal.js";
import DepositModal from "./utils/DepositModal.js";
const GlobalSearchContainer = () => {
  const {
    globalCardsData,
    showModal,
    handleCloseModal,
    formattedGlobalData,
    loading,
    onHandleInputChange,
    formErrors,
    credentials,
    renderError,
    handleAddClient,
    domains,
    domainValue,
    handleDomainChange,
    inputRef,
    handleClick,
    handleUserName,
    handleReset,
    searchUsername,
    modalCheck,
    onHandleDepositInputChange,
    depositInput,
    handleDeposit,
    handleWithdraw,
    onClickRefresh,
    loaderKey,
    individualLoader,
    btnLoading,
    btnDisable,
    resetBtnDisable,
  } = useGlobalSearchHook();
  const { globalSearchFilteredData } = useSelector((state) => state.filter);
  const { globalSiteObjDetails, globalSiteUserDetails } = useSelector(
    (state) => state.globalSearch
  );
  const { bankAccounts } = useSelector((state) => state.bankAccount);
  const accountOptions = bankAccounts?.map((account) => ({
    value: account.id,
    label: account.account_name,
    minAmount: account.min_amount,
    maxAmount: account.max_amount,
  }));
  return (
    <>
      <div className="row mb-2">
        <>
          {globalCardsData.length ? (
            globalCardsData.map((card, index) => (
              <div
                className="col-12 col-md-3 mb-1 p-2"
                key={card.labelText + index}
              >
                <GlobalSearchCards
                  imageSrc={card.imageSrc}
                  alt={card.alt}
                  labelText={card.labelText}
                  balanceInfo={card.balanceInfo}
                  cardButtons={card.cardButtons}
                  cardStyle={card.cardStyle}
                  altKey={index + card.labelText}
                  onClickRefresh={onClickRefresh}
                  loaderKey={loaderKey}
                  individualLoader={individualLoader}
                />
              </div>
            ))
          ) : (
            <ClipLoader
              cssOverride={{
                display: "block",
                margin: "0 auto",
              }}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          <Modal
            show={showModal}
            handleClose={handleCloseModal}
            isLoading={btnLoading}
            btnDisable={btnDisable}
            modalTitle={
              modalCheck === "AddClient"
                ? `${globalSearchConstants.addClientToModal} ${globalSiteObjDetails?.site}`
                : modalCheck === "Deposit"
                ? `Deposit to ${globalSiteUserDetails.username} Account`
                : `Withdraw to ${globalSiteUserDetails.username} Account`
            }
            modalFooterPrimaryTitle={globalSearchConstants.close}
            modalFooterSecondaryTitle={
              modalCheck === "AddClient"
                ? globalSearchConstants.add
                : globalSearchConstants.update
            }
            handleSave={
              modalCheck === "AddClient"
                ? handleAddClient
                : modalCheck === "Deposit"
                ? handleDeposit
                : handleWithdraw
            }
          >
            {modalCheck === "AddClient" ? (
              <AddClientModal
                formErrors={formErrors}
                credentials={credentials}
                onHandleInputChange={onHandleInputChange}
                renderError={renderError}
              />
            ) : (
              <DepositModal
                formErrors={formErrors}
                onHandleDepositInputChange={onHandleDepositInputChange}
                renderError={renderError}
                depositInput={depositInput}
                accountOptions={accountOptions}
              />
            )}
          </Modal>
        </>
      </div>
      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body pb-0">
              <SearchForm
                domains={domains}
                domainValue={domainValue}
                handleDomainChange={handleDomainChange}
                inputRef={inputRef}
                handleClick={handleClick}
                handleUserName={handleUserName}
                handleReset={handleReset}
                searchUsername={searchUsername}
                resetBtnDisable={resetBtnDisable}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2">
          <div className="card table-responsive pb-2">
            <CustomTable
              data={
                globalSearchFilteredData && globalSearchFilteredData.length > 0
                  ? globalSearchFilteredData
                  : !Array.isArray(globalSearchFilteredData)
                  ? []
                  : formattedGlobalData
              }
              columns={globalSearchTableColumns}
              rows={25} //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalSearchContainer;
