export const addAccountConstant = {
    accountType: "Account Type:",
    onlyForWithdrawal: "Purpose:",
    addAccount: "Add Account",
    editAccount: "Edit Account",
    enterBank: "Enter Bank Name",
    bankName: "Bank Name:",
    enterName: "Enter Name",
    name: "Name:",
    accountNumber: "Account number:",
    enterAccountNumber: "Enter Account number",
    ifsc: "IFSC Code:",
    enterIfsc: "Enter IFSC Code",
    minimumAmount: "Minimum amount:",
    enterMinimum: "Enter Minimum amount",
    maximumAmount: "Maximum amount",
    enterMaximum: "Enter Minimum amount",
    maximumDeposit: "Maximum Deposit amount",
    qrCode: "QR Code:",
    noFile: "No file chosen",
    status: "Status:",
    preview: "Preview:",
    add: "Add",
    update: "Update",
}

export const accountTypeOptions = [
    { value: "", label: "--Select Account type--" },
    { value: "0", label: "Bank Account" },
    { value: "1", label: "Gpay" },
    { value: "2", label: "Paytm" },
    { value: "3", label: "phonepe" },
    { value: "4", label: "UPI" },
    { value: "5", label: "IMPS" },
];

export const withdrawlOptions = [
    { value: "", label: "--Select Purpose--" },
    { value: 0, label: "Withdrawal" },
    { value: 1, label: "Deposit" },
];

export const depositOptions = [
    { value: "", label: "--Select Amount Range--" },
    { value: 0, label: "Below 5000" },
    { value: 1, label: "Above 5000" },
    { value: 2, label: "Both" },
]
export const statusOptions = [
    { value: "", label: "--Select Status--" },
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
    { value: 2, label: "Disabled" },
]