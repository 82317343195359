import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../utils/Common";
import {
  getBankAccountStatement,
  getBankAccountStatementById,
} from "../redux/bankAccount/action";
import { getGlobalThirdParty } from "../redux/globalSearch/action";
import { getFormattedData } from "../containers/AccountStatementContainer/utils/AccountStatementUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";

export const useAccountStatementHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bankAccountStatements } = useSelector((state) => state.bankAccount);
  const { globalThirdParties } = useSelector((state) => state.globalSearch);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [siteUser, setSiteUser] = useState({ value: null, label: null });
  const [type, setType] = useState({ value: null, label: null });
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [resetBtnDisabled, setResetBtnDisabled] = useState(true);
  const { token } = getAuthData();
  const isMountedAccountStatements = useRef(true);
  const isMountedThirdParties = useRef(true);
  const location = useLocation();
  const typeRefStatus = useRef(null);

  const fetchGlobalThirdParties = useCallback(async () => {
    setLoading(true);
    await dispatch(
      getGlobalThirdParty(() => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch, navigate]);

  const fetchBankAccountStatement = useCallback(async () => {
    setSearchBtnDisabled(true);
    setLoading(true);
    await dispatch(
      getBankAccountStatement(
        token,
        () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        },
        location?.state ? { clientId: location.state.clientId } : null
      )
    );
    setLoading(false);
  }, [dispatch, token, navigate, location.state]);

  useEffect(() => {
    if (!location.state) {
      isMountedAccountStatements.current = true;
    }
    return () => {
      isMountedAccountStatements.current = false;
    };
  }, [location]);

  useEffect(() => {
    if (isMountedAccountStatements.current) {
      fetchBankAccountStatement();
    }
    if (isMountedThirdParties.current) {
      fetchGlobalThirdParties();
    }
    return () => {
      isMountedAccountStatements.current = false;
      isMountedThirdParties.current = false;
    };
  }, [fetchGlobalThirdParties, fetchBankAccountStatement]);

  const domains = globalThirdParties?.map((party) => ({
    value: party.site,
    label: party.site,
    id: party.site_id,
  }));

  const handleSiteUser = (e) => {
    setSearchBtnDisabled(false);
    setResetBtnDisabled(false);
    setSiteUser(e[0]?.text);
    setType(e[0]?.text);
    setFilters({ ...filters, site: e[0]?.text });
  };

  const handleType = (e) => {
    setSearchBtnDisabled(false);
    setResetBtnDisabled(false);
    setType(e);
    setFilters({ ...filters, type: e });
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (typeRefStatus.current) {
      typeRefStatus.current.value = "Select type";
    }
    setType("");
    setSiteUser({ value: null, label: null });
    setFilters({ site: "", type: "" });
    setSearchBtnDisabled(true);
    setResetBtnDisabled(true);
    fetchBankAccountStatement();
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchBtnDisabled(true);
    setLoading(true);

    if (location.state) {
      dispatch(
        getBankAccountStatement(
          token,
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          },
          location?.state
            ? {
                clientId: location.state.clientId,
                type: filters.type.label.toLowerCase(),
              }
            : null
        )
      );
      setLoading(false);
      return;
    }
    await dispatch(
      getBankAccountStatementById(
        { site: filters.site, type: filters.type?.label?.toLowerCase() },
        token,
        () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        }
      )
    );
    setLoading(false);
  };

  const formattedAccountStatementData =
    bankAccountStatements &&
    bankAccountStatements?.length &&
    getFormattedData(bankAccountStatements, location.state);

  return {
    loading,
    formattedAccountStatementData,
    filters,
    handleSiteUser,
    handleType,
    siteUser,
    type,
    handleSearch,
    domains,
    handleReset,
    location,
    searchBtnDisabled,
    resetBtnDisabled,
    typeRefStatus,
  };
};

export default useAccountStatementHook;
