import React from 'react';
import DepositCashSettlementForm from './helper/DepositCashSettlementForm';
import { depositStyle } from './style';
import useDepositHook from '../../../hooks/deposit-hook';

const DepositCashSettlementContainer = () => {
    const {
        formErrors,
        renderError,
        onHandleInputChange,
        handleDepositInputChange,
        depositCashSettlement,
        handleAddDepositCashSettlement,
        bankAccounts
    } = useDepositHook(false, true, false);
    return (
        <>
            <div className='row'>
                <div className='col-md-9'>
                    <div className='card card-primary p-2' style={depositStyle.container}>
                        <DepositCashSettlementForm
                            formErrors={formErrors}
                            renderError={renderError}
                            onHandleInputChange={onHandleInputChange}
                            handleDepositInputChange={handleDepositInputChange}
                            depositCashSettlement={depositCashSettlement}
                            handleAddDepositCashSettlement={handleAddDepositCashSettlement}
                            bankAccounts={bankAccounts}
                        />
                    </div>
                </div>
            </div>
        </>

    );
};

export default DepositCashSettlementContainer;