// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import WithdrawHistoryContainer from '../../../containers/WithdrawContainer/WithdrawHistoryContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const WithdrawHistory = () => {

  // Returning WithdrawHistory Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.withdrawHistory} />
      <Layout heading={layoutHeading.withdrawHistory}>
        <WithdrawHistoryContainer />
      </Layout>
    </>
  );
};

// Exporting WithdrawHistory Function.
export default WithdrawHistory;
