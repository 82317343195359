import React from "react";
import SearchForm from "./helper/SearchForm";
import { utrStyle } from "./style";
import UtrRemovedList from "./helper/UtrRemovedList";
import useDepositHook from "../../../hooks/deposit-hook";

const UtrRemovedListContainer = () => {
  const {
    formattedRemovedDepositData,
    handleUtrRemovedInput,
    depositFilters,
    handleRefresh,
    handleUtrRemoveSearch,
    loading,
    refreshBtnDisable,
    searchDisabled
  } = useDepositHook(false, false, true, false);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card pt-2 pb-3 px-4" style={utrStyle.container}>
            <SearchForm
              handleUtrRemovedInput={handleUtrRemovedInput}
              depositFilters={depositFilters}
              handleRefresh={handleRefresh}
              handleUtrRemoveSearch={handleUtrRemoveSearch}
              refreshBtnDisable={refreshBtnDisable}
              searchDisabled={searchDisabled}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2 mt-2">
          <div className="card table-responsive">
            <UtrRemovedList
              formattedRemovedDepositData={formattedRemovedDepositData}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UtrRemovedListContainer;
