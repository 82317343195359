// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import UtrPendingListContainer from '../../../containers/DepositContainer/UtrPendingListContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const UTRPendingList = () => {

  // Returning UTRPendigList Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.utrPendingList} />
      <Layout heading={layoutHeading.utrPendingList}>
        <UtrPendingListContainer />
      </Layout>
    </>
  );
};

// Exporting UTRPendingList Function.
export default UTRPendingList;
