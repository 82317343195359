// Importing Dependencies.
import React from "react";
import SearchForm from "./helper/SearchForm";
import Statistics from "./helper/Statistics";
import useStatsHook from "../../hooks/stats-hook";

// Defining StatisticsContainer Function.
const StatisticsContainer = () => {
  const {
    formErrors,
    renderError,
    formInputChange,
    searchInput,
    handleSearchFormInputChange,
    formattedSiteUserStatsData,
    siteUserStats,
    loading,
    handleSearch,
    handleReset,
    btnDisable,
    resetDisable
  } = useStatsHook();
  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body pb-2">
              <SearchForm
                formErrors={formErrors}
                renderError={renderError}
                formInputChange={formInputChange}
                searchInput={searchInput}
                handleSearchFormInputChange={handleSearchFormInputChange}
                handleSearch={handleSearch}
                handleReset={handleReset}
                btnDisable={btnDisable}
                resetDisable={resetDisable}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2">
          <div className="card table-responsive">
            <Statistics
              formattedSiteUserStatsData={formattedSiteUserStatsData}
              siteUserStats={siteUserStats}
              loading={loading} />
          </div>
        </div>
      </div>
    </>
  );
};

// Exporting StatisticsContainer Function.
export default StatisticsContainer;
