import React from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { accountsStyle } from "../style";
import { useSelector } from "react-redux";
import { accountsTableColumns } from "../../utils/AccountUtils";
import BlankRecord from "../../../../components/organisms/CustomTable/BlankRecord";

const AccountList = (props) => {
  const { formattedBankAccountsData, loading } =
    props;
  const { filteredActiveData } = useSelector((state) => state.bankAccount);
  return (
    <>
      {filteredActiveData === 0 ? <BlankRecord data={[]} columns={accountsTableColumns}
        style={accountsStyle.table} />
        : <CustomTable
          data={
            filteredActiveData && filteredActiveData.length > 0
              ? filteredActiveData
              : formattedBankAccountsData
          }
          columns={accountsTableColumns}
          rows={25} //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
          style={accountsStyle.table}
          loading={loading}
        />}
    </>
  );
};


export default AccountList;
