// Defining colors for using in whole app.
export const color = {
    _000: "#000",
    _6c757d: "#6c757d",
    _e9ecef: "#e9ecef",
    _ffffff: "#ffffff",
    _0e8d84: "#0e8d84",
    _027970: "#027970",
    _68758e: "#68758e",
    _ff9900: "#ff9900",
    _ffc107: "#ffc107",
    _17a2b8: "#17a2b8",
    _dc3545: "#dc3545",
    _28a745: "#28a745",
    _6d6d6d: "#6d6d6d",
    _f8f9fa: "#f8f9fa",
    _66748e: "#66748e",
    _f44336: "#F44336",
    _1a3da6: "#1a3da6",
    _5f7fdb: "#5f7fdb",
    _0cddff: "#0cddff",
    _ffa724: "#ffa724",
    _167abf: "#167abf",
    _0089ca: "#0089ca",
    _67748E: "#67748E",
    _green: "green",
    _red: "red",
    _lightSkyBlue: "lightskyblue",
    _lightGreen: "lightgreen",
    _lightYellow: "lightyellow",
    _darkSlateGrey: "darkslategrey",
    _darkBlue: "darkblue",
    _indianRed: "indianred",

}