import { exch99Image } from "../../../assets/images";
import {
  formatDateTime,
  formatNumber,
  sortByDateDescending,
} from "../../../utils/Common";
import { globalSearchConstants } from "../constants";
import { globalSearchStyle } from "../style";

export const globalSearchTableColumns = [
  { field: "clientId", header: "id", width: "5%" },
  { field: "username", header: "name", width: "8%" },
  { field: "site", header: "site", width: "7%" },
  { field: "pl", header: "pl", width: "5%", fontWeight: "bold" },
  { field: "fix", header: "expose", width: "15%", fontWeight: "bold" },
  { field: "current", header: "balance", width: "15%", fontWeight: "bold" },
  { field: "updated", header: "last updated", width: "25%" },
  { field: "action", header: "action", width: "20%" },
];

export const getFormattedData = (arr, renderActions) => {
  return sortByDateDescending(
    arr.map((client) => ({
      ...client,
      updated: client?.updated ? formatDateTime(client?.updated) : "",
    })),
    "updated"
  ).map((client, index) => ({
    ...client,
    no: index + 1,
    current: formatNumber(client?.limit?.current),
    fix: formatNumber(client?.limit?.fix),
    pl: formatNumber(client?.pl),
    action: renderActions(client),
  }));
};

export const generateGlobalCardsData = (globalThirdParties, cardButtons) => {
  // if (!globalThirdParties?.length) return []; //will use later
  return (
    globalThirdParties.length &&
    globalThirdParties.map((user) => ({
      ...user,
      cardStyle: globalSearchStyle.card1,
      imageSrc: exch99Image,
      alt: globalSearchConstants.exchImg,
      labelText: `${user.username}(${user.site})`,
      balanceInfo: [
        { label: globalSearchConstants.balance, value: user?.current },
        { label: globalSearchConstants.pl, value: "97281.75" },
        { label: globalSearchConstants.clientWallet, value: "2718.25" },
      ],
      cardButtons: cardButtons({
        domainName: `${user.username}(${user.site})`,
        site: user?.site,
      }),
      btnIconStyle: globalSearchStyle.btnIcon,
    }))
  );
};
