import React from "react";
import { Link, useLocation } from "react-router-dom";
import { listStyle } from "./style";
import { ComponentNames } from "../../../constants/ComponentNames";
import { URLs } from './../../../routes/URLs';

const BreadCrumb = () => {
    const location = useLocation();
    // Extract the pathname from the current location
    const currentPath = location.pathname;
    // Split the current path into segments
    const pathSegments = currentPath
        .split("/")
        .filter((segment) => segment !== "");

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb float-sm-end" style={listStyle.list}>
                <li className="breadcrumb-item" key="home">
                    <Link to={URLs.dashboard} style={listStyle.list}>{ComponentNames.Home}</Link>
                </li>
                {/* Generate breadcrumbs dynamically */}
                {pathSegments.map((segment, index) => (
                    <li style={listStyle.listItem}
                        key={segment} // Using segment as the key
                        className={`breadcrumb-item ${index === pathSegments.length - 1 ? "active" : ""
                            }`}
                        aria-current={index === pathSegments.length - 1 ? "page" : null}
                    >
                        {index === pathSegments.length - 1 ? (
                            // If it's the last segment, just display the segment name
                            segment
                        ) : (
                            //  Generate a new link to the segment
                            <Link to={`/${segment}`} style={listStyle.list}>
                                {segment}
                            </Link>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default BreadCrumb;
