import { toast } from "react-toastify";
import Badge from "../../../components/atoms/Badge";
import { classes } from "../../../constants/CommonConstants";
import { formatDateTime, sortByDateDescending } from "../../../utils/Common";
import { usersContants } from "../ActiveUsersContainer/constants";
import { usersStyle } from "../ActiveUsersContainer/style";

export const usersTableColumns = [
    { field: "id", header: "id", width: "5%" },
    { field: "username", header: "username", width: "10%" },
    { field: "name", header: "name", width: "10%" },
    { field: "parent", header: "parent", width: "5%" },
    { field: "totalClients", header: "total clients", width: "10%" },
    { field: "todaysClients", header: `today's clients`, width: "10%" },
    { field: "created", header: "created on", width: "20%" },
    { field: "role", header: "role", width: "10%" },
    { field: "active", header: "user lock", width: "10%" },
    { field: "action", header: "action", width: "10%" },
];

export const getUserStatus = (status) => {
    switch (status) {
        case 0:
            return (
                <Badge
                    title={usersContants.inActive}
                    className={classes.badge}
                    style={{ ...usersStyle.badge, ...usersStyle.badgeRed }}
                />
            );
        case '0':
            return (
                <Badge
                    title={usersContants.inActive}
                    className={classes.badge}
                    style={{ ...usersStyle.badge, ...usersStyle.badgeRed }}
                />
            );
        case 1:
            return (
                <Badge
                    title={usersContants.active}
                    className={classes.badge}
                    style={{ ...usersStyle.badge, ...usersStyle.badgeGreen }}
                />

            );
        case "1":
            return (
                <Badge
                    title={usersContants.active}
                    className={classes.badge}
                    style={{ ...usersStyle.badge, ...usersStyle.badgeGreen }}
                />

            );
        default:
            return null;
    }
}

//As we were not getting any index or serial no. from backend
export const generateformattedUsersList = (users, renderActions) => {
    // if (!users?.length) return [];
    return users?.length && sortByDateDescending(
        users.map((user) => ({
            ...user,
            created: formatDateTime(user?.created),
        })),
        "created"
    ).map((user, index) => ({
        ...user,
        id: index + 1,
        active: getUserStatus(user?.active),
        action: renderActions(user),
    }));
};

export const handleCopy = (password) => {
    navigator.clipboard
        .writeText(password)
        .then(() => {
            toast.success(usersContants.PasswordCopied);
            console.log("Copied to clipboard:", password);
        })
        .catch((error) => {
            console.error("Unable to copy:", error);
        });
};