import React from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { utrStyle } from "../style";
import { utrPendingTableColumns } from "../../utils/DepositUtils";

const UtrPendingList = (props) => {
  const { formattedPendingDepositData, loading } = props;
  return (
    <CustomTable
      data={formattedPendingDepositData?.length && formattedPendingDepositData}
      columns={utrPendingTableColumns}
      rows={25} //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
      style={utrStyle.table}
      loading={loading}
    />
  );
};

export default UtrPendingList;
