import React from "react";
import SearchForm from "./helper/SearchForm";
import { depositStyle } from "./style";
import DepositHistoryList from "./helper/DepositHistoryList";
import useDepositHook from "../../../hooks/deposit-hook";
import { useSelector } from "react-redux";
import { getPermission, getRole } from "../../../utils/Common";
import { usePermissions } from "../../../permissionhook";

const DepositHistoryContainer = () => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();

  const UPDATEPERMISSION = getPermission(
    permissions,
    "DEPOSITLIST",
    "UPDATEPERMISSION",
    getRole(role)
  );
  const {
    formErrors,
    renderError,
    formChanged,
    searchInput,
    handleSearchFormInputChange,
    formattedDepositHistoryData,
    loading,
    handleDepositHistorySelectChange,
    historyFilters,
    handleDepositHistoryInput,
    handleRefresh,
    handleDepositHistoryRefresh,
    formSubmitState,
    selectStatusRef,
    selectTypeRef,
    selectedType,
    refreshBtnDisable,
  } = useDepositHook(false, false, false, true, UPDATEPERMISSION);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card p-2" style={depositStyle.container}>
            <SearchForm
              formErrors={formErrors}
              renderError={renderError}
              formChanged={formChanged}
              searchInput={searchInput}
              handleSearchFormInputChange={handleSearchFormInputChange}
              handleDepositHistorySelectChange={
                handleDepositHistorySelectChange
              }
              historyFilters={historyFilters}
              handleDepositHistoryInput={handleDepositHistoryInput}
              handleRefresh={handleRefresh}
              handleDepositHistoryRefresh={handleDepositHistoryRefresh}
              // formSubmitState={formSubmitState}
              selectStatusRef={selectStatusRef}
              selectTypeRef={selectTypeRef}
              selectedType={selectedType}
              refreshBtnDisable={refreshBtnDisable}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2 mt-2">
          <div className="card table-responsive">
            <DepositHistoryList
              formattedDepositHistoryData={formattedDepositHistoryData}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositHistoryContainer;
