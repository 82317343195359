import { color } from "../../themes/Colors";

export const dashboardStyle = {
    btnGreen: {
        width: "80px",
        backgroundColor: color._0e8d84
    },
    btnMustard: {
        width: "80px",
        backgroundColor: color._ffc107,
        border: "none"
    },
    icon: {
        borderRadius: "10px",
        padding: "13px"
    },
    btnIcon: {
        padding: 0
    }
};
