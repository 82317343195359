export const globalSearchConstants = {
  title: "Global Search",
  domain: "Domain:",
  username: "Username:",
  usernamePlaceholder: "Enter Username",
  search: "Search",
  addClient: "Add Client",
  exchImg: "99exch Image",
  demolalit: "Demolalit(99exch)",
  addClientToModal: "Add client to",
  close: "Close",
  cancel: "Cancel",
  add: "Add",
  update: "Update",
  yes: "Yes",
  save: "Save",
  password: "Password",
  labelInfo: "(Must be contains alphanumeric and more than 5 letters)",
  referral: "Referral Code (Optional)",
  modal: "modal",
  modalId: "exampleModal",
  balance: "balance",
  pl: "pl",
  clientWallet: "client wallet",
  deposit: "Deposit",
  withdraw: "Withdraw",
  acStatement: "Account Statement",
  selectDomain: "Select Domain",
  reset: "Reset",
  utrNumber: "UTR Number",
};

export const globalSearchTableClasses = {
  badge: "badge rounded-pill global_badge_hover",
};
