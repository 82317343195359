import { toast } from "react-toastify";
import { endPoints } from "../../services/EndPoints";
import { _get } from "../../services/HttpMethods";
import { logoutErrors } from "../../constants/ErrorMessage";
import { LOGOUT_SUCCESS } from "../auth/actionType";
import { removeAuthData } from "../../utils/Common";
import {
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DATA_SUCCESS,
} from "./actionTypes";

export const getDashboardData = (
  token = null,
  obj = null,
  showToast = false,
  logoutFunc = () => {}
) => {
  return async (dispatch) => {
    try {
      const response = await _get(
        endPoints.dashboard,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      }
      if (response.status === 200) {
        dispatch({
          type: GET_DASHBOARD_DATA_SUCCESS,
          payload: response.data || [],
        });
      } else if (response.status === 404) {
        dispatch({
          type: GET_DASHBOARD_DATA_SUCCESS,
          payload: [],
        });
        if (showToast) {
          toast.info(response.msg);
        }
      } else {
        throw new Error(response.msg);
      }
    } catch (error) {
      if (error.message === "Unauthorized") {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_DASHBOARD_DATA_FAIL,
          payload: error.message,
        });
      }
    }
  };
};
