// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import UtrRemovedListContainer from './../../../containers/DepositContainer/UtrRemovedListContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const UTRRemovedList = () => {

  // Returning UTRRemovedList Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.utrRemovedList} />
      <Layout heading={layoutHeading.utrRemovedList}>
        <UtrRemovedListContainer />
      </Layout>
    </>
  );
};

// Exporting UTRPendigList Function.
export default UTRRemovedList;
