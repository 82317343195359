// Importing Dependencies.
import React from "react";
import SearchForm from "./helper/SearchForm";
import AccountStatementList from "./helper/AccountStatementList";
import useAccountStatementHook from "../../hooks/account-statement-hook";

// Defining AccountStatementContainer Function.
const AccountStatementContainer = () => {
  const {
    siteUser,
    type,
    handleSiteUser,
    handleType,
    handleSearch,
    domains,
    handleReset,
    formattedAccountStatementData,
    loading,
    location,
    filters,
    searchBtnDisabled,
    resetBtnDisabled,
    typeRefStatus,
  } = useAccountStatementHook();

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body pb-2">
              <SearchForm
                siteUser={siteUser}
                type={type}
                handleSiteUser={handleSiteUser}
                handleType={handleType}
                handleSearch={handleSearch}
                domains={domains}
                handleReset={handleReset}
                location={location}
                filters={filters}
                searchBtnDisabled={searchBtnDisabled}
                resetBtnDisabled={resetBtnDisabled}
                typeRefStatus={typeRefStatus}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2">
          <div className="card table-responsive">
            <AccountStatementList
              formattedAccountStatementData={formattedAccountStatementData}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// Exporting Dashboard Container Function.
export default AccountStatementContainer;
