import React from 'react';
import { cardStyle } from './style';

const CustomImageCard = ({ imageSrc, altText, labelText, imgStyle }) => {
    return (
        <div className="text-center">
            <img className="img-fluid mb-2 mt-3" src={imageSrc} alt={altText} style={cardStyle.img} />
            <h2 style={cardStyle.heading} className='mb-2'>{labelText}</h2>
        </div>
    );
}

export default CustomImageCard;
