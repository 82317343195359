// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';
import EditUserContainer from '../../../containers/ExtraUsersContainer/EditUsersContainer/EditUserContainer';

// Declaring Funtion
const EditUsers = () => {

  // Returning AddUsers Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.editUsers} />
      <Layout heading={layoutHeading.users}>
        <EditUserContainer/>
      </Layout>
    </>
  );
};

// Exporting AddUsers Function.
export default EditUsers;
