import { color } from "../../../themes/Colors";
import { fontFamily } from "../../../themes/font";

export const clientStyle = {
    container: { borderRadius: "1px", position: "relative" },
    searchBtn: {
        width: "100%",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: "none",
        color: color._ffffff,
        fontWeight: 400,
    },
    table: { width: "auto" },
    badge: {
        marginRight: "5px",
        padding: "6px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 400,
        cursor: "pointer",
        borderRadius: "2px",
    },
    badgeAction: {
        marginRight: "5px",
        padding: "7px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 400,
        cursor: "pointer",
        borderRadius: "3px"
    },
    badgeActionGreen: {
        backgroundColor: color._0e8d84,
    },
    badgeRed: {
        backgroundColor: color._f44336,
        fontWeight: 600,
    },
    badgeGreen: {
        backgroundColor: color._0e8d84
    },
    badgeYellow: {
        backgroundColor: color._ffc107,
        color: color._000,
    },
};