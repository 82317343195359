import { color } from "../../../themes/Colors";

export const depositStyle = {
  container: { borderRadius: "1px", position: "relative" },
  searchBtn: {
    width: "100%",
    backgroundColor: color._0e8d84,
    borderRadius: "2px",
    textTransform: "capitalize",
    border: "none",
    color: color._ffffff,
    fontWeight: 400,
  },
  searchDisabledBtn: {
    width: "100%",
    backgroundColor: color._0e8d84,
    borderRadius: "2px",
    textTransform: "capitalize",
    border: "none",
    color: color._ffffff,
    fontWeight: 400,
    cursor: "not-allowed",
  },
  table: { minWidth: "auto" },
  iconApproved: {
    color: color._ffffff,
    backgroundColor: color._28a745,
    borderRadius: "15px",
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingTop: "1px",
    paddingBottom: "1px",
    marginRight: "5px",
    // textTransform: "capitalize",
  },
  iconRejected: {
    color: color._ffffff,
    backgroundColor: color._f44336,
    borderRadius: "2px",
    padding: "2px",
  },
  _iconProof: {
    color: color._ffffff,
    backgroundColor: color._0089ca,
    borderRadius: "15px",
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingTop: "1px",
    paddingBottom: "1px",
    marginLeft: "5px",
  },
  iconProof: {
    color: color._ffffff,
    backgroundColor: color._0089ca,
    borderRadius: "15px",
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingTop: "1px",
    paddingBottom: "1px",
    marginRight: "5px",
  },
  btnLabel: {
    color: "transparent",
  },
  badge: {
    marginRight: "5px",
    padding: "6px",
    fontSize: "8px",
    textTransform: "uppercase",
    fontWeight: 400,
    cursor: "pointer",
    borderRadius: "2px",
  },
  Disabledbadge: {
    marginRight: "5px",
    padding: "6px",
    fontSize: "8px",
    textTransform: "uppercase",
    fontWeight: 400,
    cursor: "not-allowed",
    borderRadius: "2px",
  },
  badgeRed: {
    backgroundColor: color._f44336,
    padding: "5px",
    // textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "10px",
    borderRadius: "10px",
    marginRight: "0px",
  },
  badgeBlue: {
    backgroundColor: color._0089ca,
    padding: "5px",
    // textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "10px",
    borderRadius: "10px",
    marginLeft: "5px",
  },
  badgeStatusRed: {
    backgroundColor: color._f44336,
    padding: "3px",
    // textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "12px",
    borderRadius: "3px",
  },
  badgeGreen: {
    backgroundColor: color._28a745,
    padding: "5px",
    // textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "10px",
    borderRadius: "10px",
  },
  badgeStatusGreen: {
    backgroundColor: color._28a745,
    padding: "3px",
    // textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "12px",
    borderRadius: "3px",
  },
  badgeYellow: {
    backgroundColor: color._ffc107,
    color: color._000,
    fontWeight: 600,
    fontSize: "12px",
    padding: "3px",
    borderRadius: "3px",
  },
  iconCopy: {
    color: color._ffffff,
    backgroundColor: color._0e8d84,
    borderRadius: "50px",
    width: "20px",
    height: "20px",
    marginLeft: "3px",
  },
  accountDetailsText: { marginBottom: "2px" },
};
