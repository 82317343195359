import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import clientReducer from "./client/reducer";
import userReducer from "./user/reducer";
import bankAccountReducer from "./bankAccount/reducer";
import depositReducer from "./deposit/reducer";
import withdrawalReducer from "./withdrawal/reducer";
import filterReducer from "./filter/reducer";
import globalSearchReducer from "./globalSearch/reducer";
import dashboardReducer from "./dashboard/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  client: clientReducer,
  user: userReducer,
  bankAccount: bankAccountReducer,
  deposit: depositReducer,
  withdrawal: withdrawalReducer,
  filter: filterReducer,
  globalSearch: globalSearchReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
