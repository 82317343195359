import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomInput from "../../../../components/atoms/CustomInput";
import { classes, inputTypes } from "../../../../constants/CommonConstants";
import { utrConstants } from "../constants";
import Button from "../../../../components/atoms/Button";
import { utrStyle } from "../style";

const SearchForm = (props) => {
  const {
    handleUtrRemovedInput,
    depositFilters,
    handleUtrRemoveSearch,
    refreshBtnDisable,
    searchDisabled,
    handleRefresh,
  } = props;
  return (
    <form onSubmit={handleUtrRemoveSearch}>
      <div className="row">
        <div className="col-md-3">
          <CustomLabel label={utrConstants.utrNumber} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.numeric}
            onChange={handleUtrRemovedInput}
            name="utrNumber"
            value={depositFilters.utrNumber || ""}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel
            label={utrConstants.search}
            additionalStyles={utrStyle.btnLabel}
          />
          <Button
            title={utrConstants.search}
            style={utrStyle.searchBtn}
            disabled={searchDisabled}
            className="btn-hover"
          />
        </div>
        <div className="col-md-3">
          <CustomLabel
            label={utrConstants.reset}
            additionalStyles={utrStyle.btnLabel}
          />
          <Button
            title={utrConstants.reset}
            style={utrStyle.searchBtn}
            className="btn-hover"
            onClick={handleRefresh}
            disabled={refreshBtnDisable}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
