import { color } from "../../../themes/Colors";

export const overviewCardStyle = {
  card: {
    border: "none",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
  },
  numeric: {
    color: color._ffffff,
    fontSize: "2rem",
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
  title: {
    color: color._ffffff,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    whiteSpace: "nowrap", // Ensure the text is on a single line
  },
  moreInfo: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: "white",
  },
};
