import React from 'react';
import { addAccountStyle } from '../AddAccountContainer/style';
import EditAccountForm from './helper/EditAccountForm';

const EditAccountContainer = () => {
    return (
        <>
            <div className='row'>
                <div className='col-md-12 mb-4'>
                    <div className='card card-primary p-2' style={addAccountStyle}>
                        <EditAccountForm />
                    </div>

                </div>
            </div>
        </>

    );
};

export default EditAccountContainer;