import React, { useEffect } from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import {
  loginOptions,
  roleOptions,
  statusOptions,
  usersConstants,
} from "../../AddUsersContainer/constants";
import CardHeader from "../../../../components/molecules/CardHeader";
import CustomInput from "../../../../components/atoms/CustomInput/CustomInput";
import {
  inputTypes,
  classes,
  buttonTypes,
} from "../../../../constants/CommonConstants";
import { addUserStyle } from "../../AddUsersContainer/style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import useUsersHook from "../../../../hooks/users-hook";

const EditUserForm = () => {
  const {
    formErrors,
    renderError,
    onHandleInputChange,
    btnLoading,
    getEditUserData,
    editUser,
    onHandleSelect,
    handleEditUser,
  } = useUsersHook(false);

  useEffect(() => {
    getEditUserData();
  }, []);

  return (
    <form onSubmit={handleEditUser}>
      <CardHeader cardHeaderTitle={usersConstants.editUser} />
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <CustomLabel
                label={usersConstants.name}
                additionalStyles={addUserStyle.label}
              />
              <CustomInput
                type={inputTypes.text}
                className={`${classes.inputClass} ${formErrors["name"] && classes.invalid
                  }`}
                placeholder={usersConstants.enterName}
                value={editUser?.name}
                onChange={(e) => onHandleInputChange("name", e, "editUser")}
                error={renderError("name")}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={usersConstants.username}
                additionalStyles={addUserStyle.label}
              />
              <CustomInput
                type={inputTypes.text}
                className={`${classes.inputClass} ${formErrors["username"] && classes.invalid
                  }`}
                placeholder={usersConstants.enterUsername}
                value={editUser?.username}
                onChange={(e) => onHandleInputChange("username", e, "editUser")}
                error={renderError("username")}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={usersConstants.password}
                additionalStyles={addUserStyle.label}
              />
              <CustomInput
                type={inputTypes.password}
                className={`${classes.inputClass} ${formErrors["password"] && classes.invalid
                  }`}
                placeholder={usersConstants.enterPassword}
                value={editUser?.password}
                onChange={(e) => onHandleInputChange("password", e, "editUser")}
                error={renderError("password")}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={usersConstants.role}
                additionalStyles={addUserStyle.label}
              />
              <CustomSelectBox
                options={roleOptions}
                className={`${classes.selectBox} ${formErrors["role"] && classes.invalid
                  }`}
                value={editUser.role}
                onChange={(value) =>
                  onHandleSelect("role", value.label, "editUser")
                }
                error={renderError("role")}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={usersConstants.noOfLogins}
                additionalStyles={addUserStyle.label}
              />
              <CustomSelectBox
                options={loginOptions}
                value={editUser.no_of_logins}
                className={classes.selectBox}
                onChange={(value) =>
                  onHandleSelect("no_of_logins", value.value, "editUser")
                }
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={usersConstants.status}
                additionalStyles={addUserStyle.label}
              />
              <CustomSelectBox
                options={statusOptions}
                value={editUser.status}
                className={`${classes.selectBox} ${formErrors["status"] && classes.invalid
                  }`}
                onChange={(value) =>
                  onHandleSelect("status", value.value, "editUser")
                }
                error={renderError("status")}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <CardFooter
        title={usersConstants.update}
        type={buttonTypes.submit}
        className={classes.btn}
        isLoading={btnLoading}
        loaderStyle={addUserStyle.loading}
      />
    </form>
  );
};

export default EditUserForm;
