// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import AccountListContainer from '../../../containers/AccountsContainer/AccountListContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const AccountList = () => {

  // Returning AccountList Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.accountListing} />
      <Layout heading={layoutHeading.accounts}>
        <AccountListContainer />
      </Layout>
    </>
  );
};

// Exporting AccountList Function.
export default AccountList;
