import { toast } from "react-toastify";
import { endPoints } from "../../services/EndPoints";
import {
  _crossPlatformGet,
  _crossXp_put,
  _postCrossPlatToken,
} from "../../services/HttpMethods";
import {
  GET_WITHDRAWAL_SUCCESS,
  GET_WITHDRAWAL_FAIL,
  UPDATE_WITHDRAWAL_STATUS_FAIL,
  GET_WITHDRAW_HISTORY,
  GET_BANK_ACCOUNT_WITHDRAW_HISTORY_FAIL,
} from "./actionType";
import { responseCode } from "../../constants/response-code";
import { withdrawlMessage } from "../../constants/SuccessMessage";
import { logoutErrors } from "../../constants/ErrorMessage";
import { LOGOUT_SUCCESS } from "../auth/actionType";
import { removeAuthData } from "../../utils/Common";
import { constants } from "../../constants/CommonConstants";

export const getWithdrawal = (token, obj, showToast = true, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _crossPlatformGet(
        endPoints.withdrawals,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      }
      if (response.status === 200) {
        dispatch({
          type: GET_WITHDRAWAL_SUCCESS,
          payload: response.data,
        });
      } else if (response.status === 404) {
        dispatch({
          type: GET_WITHDRAWAL_SUCCESS,
          payload: [], // Set deposits to an empty array on 404
        });
        if (showToast) {
          toast.info(response.msg); // Show toast only if showToast is true
        }
      } else {
        throw new Error(response.msg);
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_WITHDRAWAL_FAIL,
          payload: error.message,
        });
      }
    }
  };
};

export const getBankAccountWithdrawHistory = (
  token,
  obj,
  showToast = true,
  logoutFunc
) => {
  return async (dispatch) => {
    try {
      const response = await _crossPlatformGet(
        endPoints.withdrawalHistory,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      }
      if (response.status === 200) {
        dispatch({
          type: GET_WITHDRAW_HISTORY,
          payload: response.data,
        });
      } else if (response.status === 404) {
        dispatch({
          type: GET_WITHDRAW_HISTORY,
          payload: [], // Set deposits to an empty array on 404
        });
        if (showToast) {
          toast.info(response.msg); // Show toast only if showToast is true
        }
      } else {
        throw new Error(response.msg);
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_BANK_ACCOUNT_WITHDRAW_HISTORY_FAIL,
          payload: error.message,
        });
      }
    }
  };
};

export const manualWithdrawal = (
  obj,
  onSuccess,
  token,
  logoutFunc,
  btnDisableFunc = null
) => {
  return async (dispatch) => {
    try {
      const response = await _postCrossPlatToken(
        endPoints.withdrawals,
        obj,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { status, msg } = response.data;
      if (status !== responseCode.OK) {
        throw new Error(msg);
      }
      toast.success(withdrawlMessage.successManualWithdrawalAdded);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        if (btnDisableFunc) {
          btnDisableFunc();
        }
        toast.error(error.message);
      }
    }
  };
};

export const updateWithdrawalStatus = (payload, token, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _crossXp_put(
        endPoints.withdrawalUpdateAction,
        payload,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.data.status !== responseCode.OK) {
        throw new Error(response.data.msg);
      }
      dispatch(getWithdrawal(token));
      toast.success(response.data.msg);
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        dispatch({
          type: UPDATE_WITHDRAWAL_STATUS_FAIL,
          payload: error.message,
        });
        toast.error(error.message);
      }
    }
  };
};
