import React from "react";
import CustomLabel from "../../../components/atoms/CustomLabel";
import CustomSelectBox from "../../../components/atoms/CustomSelectBox";
import Button from "../../../components/atoms/Button";
import { accountStatementStyle } from "../style";
import { classes } from "../../../constants/CommonConstants";
import { accountStatementConstants, typeOptions } from "../constant";
import SearchBox from "../../../components/atoms/SearchableSelectBox/SearchBox";

const SearchForm = (props) => {
  const {
    type,
    handleSiteUser,
    handleType,
    handleSearch,
    domains,
    handleReset,
    location,
    filters,
    searchBtnDisabled,
    resetBtnDisabled,
    typeRefStatus,
  } = props;
  return (
    <form>
      <div className="row">
        {!location?.state && (
          <div className="col-md-3">
            <CustomLabel label={accountStatementConstants.siteUsers} />
            <SearchBox
              options={domains}
              placeholder={"Select Site User"}
              value={filters.site}
              onChange={handleSiteUser}
            />
          </div>
        )}
        <div className="col-md-3">
          <CustomLabel label={accountStatementConstants.type} />
          <CustomSelectBox
            options={typeOptions}
            className={classes.selectBox}
            onChange={handleType}
            property={"type"}
            ref={typeRefStatus}
          />
        </div>
        <div className="col-md-3 d-flex align-items-end mt-2">
          <Button
            title={accountStatementConstants.search}
            style={accountStatementStyle.searchBtn}
            className="pb-1 btn-hover"
            onClick={handleSearch}
            disabled={searchBtnDisabled}
          />
        </div>
        {!location.state && (
          <div className="col-md-3 d-flex align-items-end mt-2">
            <Button
              title={accountStatementConstants.reset}
              style={accountStatementStyle.searchBtn}
              className="pb-1 btn-hover"
              onClick={handleReset}
              disabled={resetBtnDisabled}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchForm;
