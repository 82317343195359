// Importing dependencies.
import React from "react";
import Layout from "../../layout/Layout";
import AccountStatementContainer from "./../../containers/AccountStatementContainer";
import { tabTitles } from "../../constants/CommonConstants";
import PageHelmet from "../../components/atoms/PageHelmet";
import { useLocation } from "react-router-dom";

// Declaring Funtion
const AccountStatement = () => {
  const location = useLocation();
  console.log(location.state, "location.state");
  // Returning AccountStatement Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.accountStatement} />
      <Layout
        heading={
          location && location?.state
            ? `ACCOUNT STATEMENT (${location?.state?.username}-${location?.state?.site})`
            : `ACCOUNT STATEMENT`
        }
      >
        <AccountStatementContainer />
      </Layout>
    </>
  );
};

// Exporting AccountList Function.
export default AccountStatement;
