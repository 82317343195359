import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBankAccountWithdrawHistory,
  getWithdrawal,
  manualWithdrawal,
  updateWithdrawalStatus,
} from "./../redux/withdrawal/action";
import { getAuthData, getValidateValue } from "../utils/Common";
import { withdrawConstants } from "../containers/WithdrawContainer/WithdrawListContainer/constants";
import { withdrawStyle } from "../containers/WithdrawContainer/WithdrawListContainer/style";
import { dateError, formError, utrFormError } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { getClient } from "../redux/client/action";
import { getBankAccount } from "../redux/bankAccount/action";
import { useLocation, useNavigate } from "react-router-dom";
import { URLs } from "../routes/URLs";
import {
  formatWithdrawalsHistoryListData,
  formatWithdrawalsListData,
} from "../containers/WithdrawContainer/utils/WithdrawalUtils";
import { classes, utrRegexComp } from "../constants/CommonConstants";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";
import { GET_WITHDRAW_LIST_DATA } from "../redux/withdrawal/actionType";
import Badge from "../components/atoms/Badge";
import { depositStyle } from "../containers/DepositContainer/DepositListContainer/style";

export const useWithdrawHook = (
  isWithdrawals = true,
  isClients = false,
  isAccounts = false,
  isWithdrawalHistory = false,
  UPDATEPERMISSION = true
) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { withdrawals, withdrawHistory } = useSelector(
    (state) => state.withdrawal
  );
  const { bankAccounts } = useSelector((state) => state.bankAccount);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [resetDisabled, setResetDisabled] = useState(
    location.state ? false : true
  );
  const [withdrawalCashSettlement, setWithdrawalCashSettlement] = useState({
    account: null,
    amount: "",
  });
  const [withdrawManual, setWithdrawManual] = useState({
    client: null,
    account: null,
    utr_number: "",
    amount: "",
  });
  const [formErrors, setFormErrors] = useState({
    client: null,
    account: null,
    utr_number: "",
    amount: "",
    startDate: "",
    endDate: "",
  });
  const [searchInput, setSearchInput] = useState({
    startDate: "",
    endDate: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [withdrawUserName, setWithdrawUserName] = useState();
  const [withdrawAccountName, setWithdrawAccountName] = useState();
  const [historyFilters, setHistoryFilters] = useState({
    site: "",
    ...(location?.state && { clientId: location?.state?.clientId }),
  });
  const [withdrawListFilters, setWithdrawListFilters] = useState({});
  const [withdrawApproveInput, setWithdrawApproveInput] = useState({
    account: "",
    utrNumber: "",
  });
  const [withdrawRejectInput, setWithdrawRejectInput] = useState({
    file: "",
    reason: {},
    otherReason: "",
  });
  const [modalFormErrors, setModalFormErrors] = useState({
    utrNumber: "",
    reason: "",
    otherReason: "",
  });
  const [accountId, setAccountId] = useState();
  const [btnDisable, setBtnDisable] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalCheck, setModalCheck] = useState("Approve");
  const { token } = getAuthData();
  const isMountedClients = useRef(true);
  const isMountedAccounts = useRef(true);
  const isMountedWithdrawals = useRef(true);
  const isMountedWithdrawalsHistory = useRef(true);
  const uploadRejectFile = useRef(null);
  const selectBoxRef = useRef(null);
  const statusRef = useRef(null);
  const typeRef = useRef(null);
  const userNameRef = useRef(null);
  const utrRef = useRef(null);
  const endDateRef = useRef(null);
  const startDateRef = useRef(null);

  const fetchWithdrawals = useCallback(
    async (obj = {}) => {
      setLoading(true);
      await dispatch(
        getWithdrawal(token, obj, true, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
      setLoading(false);
    },
    [dispatch, token]
  );

  const fetchClients = useCallback(async () => {
    setLoading(true);
    await dispatch(
      getClient(token, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch, token]);

  const fetchBankAccounts = useCallback(async () => {
    setLoading(true);
    await dispatch(
      getBankAccount(token, {}, true, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch, token]);

  useEffect(() => {
    if (isMountedClients.current) {
      isClients && fetchClients();
    }
    if (isMountedAccounts.current) {
      isAccounts && fetchBankAccounts();
    }
    if (isMountedWithdrawals.current) {
      if (location.state?.dashboardCheck) {
        let newFilter = location.state;
        delete newFilter["dashboardCheck"];
        setWithdrawListFilters({ ...withdrawListFilters, ...newFilter });
        isWithdrawals && fetchWithdrawals(newFilter);

        if (Object.values(newFilter).length && statusRef.current) {
          statusRef.current.value = newFilter.status; // Reset the select box value
        }
      } else {
        setWithdrawListFilters({
          ...withdrawListFilters,
          status: 0,
          type: "client",
        });
        isWithdrawals && fetchWithdrawals({ status: 0, type: "client" });
        if (statusRef.current) {
          statusRef.current.value = "0";
        }
        if (typeRef.current) {
          typeRef.current.value = "client";
        }
      }
    }

    return () => {
      isMountedClients.current = false;
      isMountedAccounts.current = false;
      isMountedWithdrawals.current = false;
    };
  }, [
    fetchClients,
    fetchBankAccounts,
    fetchWithdrawals,
    isClients,
    isAccounts,
    isWithdrawals,
  ]);

  // Function to display errors
  const renderError = (field) => {
    return formErrors[field];
  };

  const renderModalError = (field) => {
    return modalFormErrors[field];
  };

  const getWithdrawalHistory = async (obj = null) => {
    setBtnDisabled(true);
    setLoading(true);
    await dispatch(
      getBankAccountWithdrawHistory(token, obj, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  };

  const handleInputChange = (e, prop) => {
    setBtnDisabled(false);
    setResetDisabled(false);
    if (e.target.value.length === 0) {
      getWithdrawalHistory({});
    }
    let obj = { ...historyFilters };
    obj[prop] = e.target.value;
    setHistoryFilters(obj);
  };

  const validateDates = (name, value, filters) => {
    let error = {};
    let today = new Date();
    const startDate =
      name === "startDate"
        ? new Date(value)
        : filters.startDate
        ? new Date(filters.startDate)
        : "";
    const endDate =
      name === "endDate"
        ? new Date(value)
        : filters.endDate
        ? new Date(filters.endDate)
        : "";
    if (startDate && endDate && startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        name === "startDate"
          ? (error.startDate = dateError.startDate)
          : (error.endDate = dateError.endDate);
      }
    }
    if (endDate !== "" && endDate > today) {
      error.endDate = dateError.endCannotGreat;
    }
    if (startDate !== "" && startDate > today) {
      error.startDate = dateError.startCannotGreat;
    }
    return error;
  };

  const handleSelectChange = (e, prop) => {
    setBtnDisabled(false);
    setResetDisabled(false);
    try {
      let obj = { ...historyFilters };
      obj[prop] =
        prop === "startDate" || prop === "endDate"
          ? e.target.value
          : prop === "site"
          ? e
          : e.value;
      setHistoryFilters(obj); //setting the filters value
      if (prop === "startDate" || prop === "endDate") {
        let dateError;
        dateError = validateDates(prop, e.target.value, historyFilters);
        setFormErrors(dateError);
        if (
          (dateError.startDate && dateError.startDate !== "") ||
          (dateError.endDate && dateError.endDate !== "")
        ) {
          return;
        }
      }
      getWithdrawalHistory(obj);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    try {
      if (
        (formErrors.startDate && formErrors.startDate !== "") ||
        (formErrors.endDate && formErrors.endDate !== "")
      ) {
        throw new Error("Date Is Invalid");
      }
      getWithdrawalHistory(historyFilters);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (isMountedWithdrawalsHistory.current) {
      isWithdrawalHistory &&
        getWithdrawalHistory(
          location?.state ? { clientId: location?.state?.clientId } : {}
        );
    }
    return () => {
      isMountedWithdrawalsHistory.current = false;
    };
  }, [isWithdrawalHistory]);

  const handleRefresh = (e) => {
    e.preventDefault();
    setBtnDisable(true);
    setResetDisabled(true);
    if (statusRef.current) {
      statusRef.current.value = "";
    }
    if (typeRef.current) {
      typeRef.current.value = "";
    }
    setWithdrawListFilters({});
    fetchWithdrawals();
  };

  const handleSearchFormInputChange = (e) => {
    const { name, value } = e.target;
    const dateError = validateDates(name, value, searchInput);

    setSearchInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormChanged(true);
    setFormErrors(dateError);
  };

  const validateForm = (
    obj,
    clientCheck = true,
    utrCheck = true,
    accountCheck = true
  ) => {
    const errors = {};
    if (accountCheck && obj.account === null) {
      errors.account = utrFormError.account;
    }
    if (clientCheck && obj.client === null) {
      errors.client = utrFormError.client;
    }
    if (utrCheck && obj.utr_number.length === 0) {
      errors.utr_number = utrFormError.utrNumber;
    }
    if (obj.amount.length === 0) {
      errors.amount = utrFormError.amount;
    }
    if (isNaN(obj.amount)) {
      errors.max_amount = utrFormError.validAmount;
    }
    // Set formErrors state with the errors
    setFormErrors(errors);
    return getValidateValue(errors, utrFormError);
  };

  const handleAddWithdrawalCashSettlement = async (e) => {
    e.preventDefault();
    // Validate form first
    if (
      getValidateValue(formErrors, utrFormError) &&
      validateForm(withdrawalCashSettlement, false, false, false)
    ) {
      setLoading(true);
      //Getting depositCashSettlement values will use them
      setLoading(false);
    } else {
      toast.error(formError.error);
    }
  };

  const handleAddWithdrawalManual = async (e) => {
    e.preventDefault();
    // Validate form first
    if (
      getValidateValue(formErrors, utrFormError) &&
      validateForm(withdrawManual, true, true, false)
    ) {
      setBtnLoading(true);
      let obj = {
        ...withdrawManual,
        utrNumber: withdrawManual.utr_number,
        clientId: withdrawManual.client,
        type: "manual",
        site: "100Panel",
        username: withdrawUserName,
        account: withdrawAccountName,
      };
      delete obj["client"];
      delete obj["utr_number"];
      await dispatch(
        manualWithdrawal(
          obj,
          () => navigate(URLs.withdrawalList),
          token,
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          }
        )
      );
      setBtnLoading(false);
    } else {
      toast.error(formError.error);
    }
  };

  const onHandleSelect = (property, value) => {
    setWithdrawManual({
      ...withdrawManual,
      [property]: property === "client" ? value.id : value,
    });
    setWithdrawalCashSettlement({
      ...withdrawalCashSettlement,
      [property]: value,
    });

    if (property === "client") {
      if (value) {
        setFormErrors({
          ...formErrors,
          client: "",
        });
      }
    }
  };

  const onHandleInputChange = (property, e) => {
    setWithdrawManual({
      ...withdrawManual,
      [property]: e.target.value,
    });
    setWithdrawalCashSettlement({
      ...withdrawalCashSettlement,
      [property]: e.target.value,
    });
    if (property === "utr_number") {
      const utrRegex = utrRegexComp;
      const utr = e.target.value;
      if (!utr.length || utrRegex.test(utr)) {
        setFormErrors({ ...formErrors, utr_number: "" });
      } else {
        setFormErrors({
          ...formErrors,
          utr_number: utrFormError.invalidUtr,
        });
      }
    } else if (property === "amount") {
      const amountRegex = /^\d+(\.\d{1,2})?$/;
      const amount = e.target.value;
      if (!amount.length || amountRegex.test(amount)) {
        setFormErrors({ ...formErrors, amount: "" });
      } else {
        setFormErrors({
          ...formErrors,
          amount: utrFormError.validAmount,
        });
      }
    }
  };

  const handleApproveBtn = async (item) => {
    fetchBankAccounts();
    await dispatch({
      type: GET_WITHDRAW_LIST_DATA,
      payload: item,
    });
    setTransactionId(item?.id);
    setModalCheck("Approve");
    setShowModal(true);
  };

  const handleRejectButton = async (item) => {
    await dispatch({
      type: GET_WITHDRAW_LIST_DATA,
      payload: item,
    });
    setTransactionId(item?.id);
    setModalCheck("Reject");
    setShowModal(true);
  };

  const renderActions = (item) => {
    if (item.status === 0) {
      return (
        <>
          {/* Earlier icon was used so they replaced to icon, so commenting it */}
          <Badge
            title={withdrawConstants.approve}
            className={classes.badge}
            style={{
              ...(UPDATEPERMISSION
                ? withdrawStyle.badge
                : depositStyle.Disabledbadge),
              ...withdrawStyle.badgeGreen,
            }}
            onClick={UPDATEPERMISSION ? () => handleApproveBtn(item) : null}
          />
          <Badge
            title={withdrawConstants.reject}
            className={classes.badge}
            style={{
              ...(UPDATEPERMISSION
                ? withdrawStyle.badge
                : depositStyle.Disabledbadge),
              ...withdrawStyle.badgeRed,
            }}
            onClick={UPDATEPERMISSION ? () => handleRejectButton(item) : null}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const handleFilterReset = (e) => {
    e.preventDefault();
    setHistoryFilters({
      site: "",
    });
    if (statusRef.current) {
      statusRef.current.value = "";
    }
    if (typeRef.current) {
      typeRef.current.value = "";
    }
    if (userNameRef.current) {
      userNameRef.current.value = "";
    }
    if (utrRef.current) {
      utrRef.current.value = "";
    }
    if (endDateRef.current) {
      endDateRef.current.value = "";
    }
    if (startDateRef.current) {
      startDateRef.current.value = "";
    }
    setResetDisabled(true);
    getWithdrawalHistory();
    setBtnDisable(true);
    setFormErrors({
      client: null,
      account: null,
      utr_number: "",
      amount: "",
      startDate: "",
      endDate: "",
    });
  };

  const formattedWithdrawalsData = formatWithdrawalsListData(
    withdrawals,
    renderActions
  );

  const formattedWithdrawalsHistoryData =
    formatWithdrawalsHistoryListData(withdrawHistory);

  const handleWithdrawFilterChange = async (e, prop) => {
    setBtnDisabled(false);
    setResetDisabled(false);
    let obj = { ...withdrawListFilters };
    obj[prop] = e.value;
    setWithdrawListFilters(obj);
    fetchWithdrawals(obj);
  };

  const handleWithdrawInput = async (e) => {
    setBtnDisabled(false);
    setResetDisabled(false);
    let obj = { ...withdrawListFilters };
    obj["username"] = e.target.value;
    setWithdrawListFilters(obj);
    if (e.target.value.length === 0) {
      await dispatch(
        getWithdrawal(token, obj, false, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    }
  };

  const handleSearchWithdrawList = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    await dispatch(
      getWithdrawal(token, withdrawListFilters, true, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
  };

  const validateModalForm = (obj, utrCheck = true, reasonCheck = true) => {
    const errors = {};
    if (utrCheck && obj.utrNumber.length === 0) {
      errors.utrNumber = utrFormError.utrNumber;
    }
    if (reasonCheck && (!obj.reason.value || obj.reason?.value === "")) {
      errors.reason = utrFormError.reason;
    }
    if (
      reasonCheck &&
      obj.reason?.value === "Other" &&
      obj.otherReason === ""
    ) {
      errors.otherReason = utrFormError.reason;
    }
    // Set formErrors state with the errors
    setModalFormErrors(errors);
    return getValidateValue(errors, utrFormError);
  };

  const handleApproveWithdrawal = async (e) => {
    e.preventDefault();
    if (
      getValidateValue(modalFormErrors, utrFormError) &&
      validateModalForm(withdrawApproveInput, true, false)
    ) {
      setBtnLoading(true);
      await dispatch(
        updateWithdrawalStatus(
          {
            id: transactionId,
            status: 1,
            utrNumber: withdrawApproveInput.utrNumber,
          },

          token,
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          }
        )
      );
      setBtnLoading(false);
      setWithdrawApproveInput({
        account: "",
        utrNumber: "",
      });
      handleCloseModal();
    } else {
      setBtnDisable(true);
      toast.error(formError.error);
    }
  };

  const handleRejectWithdrawal = async (e) => {
    e.preventDefault();
    if (
      getValidateValue(modalFormErrors, utrFormError) &&
      validateModalForm(withdrawRejectInput, false, true)
    ) {
      const fileInput = document.getElementById("uploadRejectFile");
      const file = fileInput.files[0];
      // Get the selected file
      try {
        if (file) {
          const reader = new FileReader();
          reader.onload = async () => {
            const base64String_ = reader.result.split(",")[1];
            setBtnLoading(true);
            await dispatch(
              updateWithdrawalStatus(
                {
                  id: transactionId,
                  status: 2,
                  qrCode: {
                    name: file.name,
                    contentType: file.type,
                    data: base64String_,
                  },
                  reason:
                    withdrawRejectInput.reason?.value === "Other"
                      ? withdrawRejectInput.otherReason
                      : withdrawRejectInput.reason?.value,
                },
                token,
                () => {
                  dispatch({
                    type: LOGOUT_SUCCESS,
                    payload: [],
                  });
                  navigate("/");
                }
              )
            );
            setBtnLoading(false);
          };
          reader.readAsDataURL(file);
        } else {
          setBtnLoading(true);
          await dispatch(
            updateWithdrawalStatus(
              {
                id: transactionId,
                status: 2,
                reason:
                  withdrawRejectInput.reason?.value === "Other"
                    ? withdrawRejectInput.otherReason
                    : withdrawRejectInput.reason?.value,
              },
              token,
              () => {
                dispatch({
                  type: LOGOUT_SUCCESS,
                  payload: [],
                });
                navigate("/");
              }
            )
          );
          setBtnLoading(false);
        }
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
      handleCloseModal();
      setWithdrawRejectInput({
        file: "",
        reason: {},
        otherReason: "",
      });
      if (uploadRejectFile.current) {
        uploadRejectFile.current.value = "";
      }
      if (selectBoxRef.current) {
        selectBoxRef.current.value = "";
      }
    } else {
      setBtnDisable(true);
      toast.error(formError.error);
    }
  };

  const handleCloseModal = () => {
    setModalFormErrors({
      utrNumber: "",
      reason: "",
      otherReason: "",
    });
    setWithdrawApproveInput({
      account: "",
      utrNumber: "",
    });
    setWithdrawRejectInput({
      file: "",
      reason: {},
      otherReason: "",
    });

    if (uploadRejectFile.current) {
      uploadRejectFile.current.value = "";
    }
    if (selectBoxRef.current) {
      selectBoxRef.current.value = "";
    }
    setBtnDisable(false);
    setShowModal(false);
  };

  const onHandleWithdrawModalChange = (property, e) => {
    setBtnDisable(false);
    setWithdrawApproveInput({
      ...withdrawApproveInput,
      [property]: property === "account" ? e.text : e.target.value,
    });
    if (property === "account") {
      setAccountId(e.id);
    }
    if (property === "utrNumber") {
      const utrRegex = utrRegexComp;
      const utr = e.target.value;
      if (!utr.length || utrRegex.test(utr)) {
        setModalFormErrors({ ...modalFormErrors, utrNumber: "" });
      } else {
        setModalFormErrors({
          ...modalFormErrors,
          utrNumber: utrFormError.invalidUtr,
        });
      }
    }
  };

  const onHandleWithRejectChange = (property, e) => {
    setBtnDisable(false);
    setWithdrawRejectInput({
      ...withdrawRejectInput,
      [property]: property === "reason" ? e : e.target.value,
    });
    if (property === "reason") {
      if (e.value !== "" || e.label === "Select Reason")
        setModalFormErrors({ ...modalFormErrors, reason: "" });
    } else if (property === "otherReason") {
      if (e.target.value)
        setModalFormErrors({ ...modalFormErrors, otherReason: "" });
    }
  };

  return {
    loading,
    formattedWithdrawalsData,
    formSubmitted,
    formChanged,
    formErrors,
    withdrawalCashSettlement,
    renderError,
    handleAddWithdrawalCashSettlement,
    onHandleInputChange,
    onHandleSelect,
    withdrawManual,
    handleAddWithdrawalManual,
    searchInput,
    handleSearchFormInputChange,
    setWithdrawUserName,
    setWithdrawAccountName,
    formattedWithdrawalsHistoryData,
    handleSelectChange,
    handleInputChange,
    handleFilterReset,
    handleSearch,
    historyFilters,
    handleRefresh,
    handleWithdrawFilterChange,
    withdrawListFilters,
    handleWithdrawInput,
    handleSearchWithdrawList,
    btnLoading,
    bankAccounts,
    showModal,
    handleCloseModal,
    handleApproveWithdrawal,
    onHandleWithdrawModalChange,
    withdrawApproveInput,
    btnDisable,
    modalCheck,
    withdrawRejectInput,
    onHandleWithRejectChange,
    handleRejectWithdrawal,
    modalFormErrors,
    renderModalError,
    uploadRejectFile,
    selectBoxRef,
    statusRef,
    typeRef,
    userNameRef,
    utrRef,
    endDateRef,
    startDateRef,
    btnDisabled,
    resetDisabled,
    withdrawals,
  };
};

export default useWithdrawHook;
