import React from 'react';

const PageNotFound = () => {
  return (
    <div className='not-found-container'>
      <div className='not-found-content'>
        <h1 className='not-found-title'>404 - Not Found</h1>
        <p className='not-found-title'>The page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default PageNotFound;