// Importing dependencies.
import React from 'react';
import Layout from '../../layout/Layout';
import ChangePasswordContainer from '../../containers/ChangePasswordContainer';
import { layoutHeading, tabTitles } from '../../constants/CommonConstants';
import PageHelmet from '../../components/atoms/PageHelmet';

// Declaring Funtion
const ChangePassword = () => {

  // Returning ChangePassword Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.changePassword} />
      <Layout heading={layoutHeading.changePassword}>
        <ChangePasswordContainer />
      </Layout>
    </>
  );
};

// Exporting ChangePassword Function.
export default ChangePassword;
