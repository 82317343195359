import React from 'react'
import Heading from '../../atoms/Heading'
import BreadCrumb from '../../atoms/BreadCrumb/BreadCrumb'

const PageHeader = (props) => {
    const { heading } = props;
    return (
        <div className="row mb-2 px-2 mt-1">
            <div className="col-sm-6 mt-3">
                <Heading heading={heading} />
            </div>
            <div className="col-sm-6 mt-3">
                <BreadCrumb />
            </div>
        </div>
    )
}

export default PageHeader