// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import DepositListContainer from './../../../containers/DepositContainer/DepositListContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const DepositList = () => {

  // Returning DepositList Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.depositListing} />
      <Layout heading={layoutHeading.deposit}>
        <DepositListContainer />
      </Layout>
    </>
  );
};

// Exporting DepositList Function.
export default DepositList;
