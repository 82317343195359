import { useState } from "react";
import { clientsFormError, formError } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAuthData } from "../utils/Common";
import { changePasswordAction } from "../redux/auth/action";
import { passwordRegexComp } from "../constants/CommonConstants";

export const useChangePasswordHook = () => {
  const dispatch = useDispatch();
  const [changePassword, setChangePassword] = useState({
    oldPassword: "",
    password: "",
    confirm_password: "",
  });
  const [formErrors, setFormErrors] = useState({
    oldPassword: "",
    password: "",
    confirm_password: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const getValidateValue = (formObj) => {
    console.log(formObj, "formObj");
    let errorsArr = Object.values(clientsFormError);
    for (let value of Object.values(formObj)) {
      if (errorsArr.includes(value)) {
        return false;
      }
    }
    return true;
  };

  const validateForm = () => {
    let validateErrors = {};
    if (changePassword.oldPassword?.length === 0) {
      validateErrors["oldPassword"] = clientsFormError.oldPassword;
    }
    if (changePassword.password?.length === 0) {
      validateErrors["password"] = clientsFormError.password;
    }
    if (changePassword.password !== changePassword.confirm_password) {
      validateErrors["confirm_password"] = clientsFormError.confirmPassword;
    }
    setFormErrors(validateErrors);
    // Return true if there are no errors
    return getValidateValue(validateErrors);
  };

  const renderError = (field) => {
    return formErrors[field];
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setBtnLoading(true);
      try {
        let obj = {
          id: localStorage.getItem("userId"),
          username: localStorage.getItem("userName"),
          oldPassword: changePassword.oldPassword,
          newPassword: changePassword.password,
          confirmNewPassword: changePassword.confirm_password,
        };
        const { token } = getAuthData();
        await dispatch(changePasswordAction(obj, token));
        setChangePassword({
          oldPassword: "",
          password: "",
          confirm_password: "",
        });
      } catch (err) {
        toast.error(err.message);
      } finally {
        setBtnLoading(false);
      }
    } else {
      toast.error(formError.error);
    }
  };

  const onHandleInputChange = (property, e) => {
    setChangePassword({
      ...changePassword,
      [property]: e.target.value,
    });

    if (property === "oldPassword") {
      setFormErrors({ ...formErrors, oldPassword: "" });
    }

    if (property === "password") {
      const password = e.target.value;
      if (!password?.length || passwordRegexComp.test(password)) {
        setFormErrors({ ...formErrors, password: "" });
      } else {
        setFormErrors({
          ...formErrors,
          password: clientsFormError.passwordCondition,
        });
      }
    }

    if (property === "confirm_password") {
      const confirm_password = e.target.value;
      if (
        !confirm_password?.length ||
        changePassword.password === confirm_password
      ) {
        setFormErrors({ ...formErrors, confirm_password: "" });
      } else if (changePassword.password !== confirm_password) {
        setFormErrors({
          ...formErrors,
          confirm_password: clientsFormError.confirmPassword,
        });
      }
    }
  };

  return {
    btnLoading,
    changePassword,
    formErrors,
    handleUpdatePassword,
    renderError,
    onHandleInputChange,
  };
};

export default useChangePasswordHook;
