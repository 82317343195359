import React from "react";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import SearchIcon from "@mui/icons-material/Search";
import Person2Icon from "@mui/icons-material/Person2";
import SavingsIcon from "@mui/icons-material/Savings";
import AddCardIcon from "@mui/icons-material/AddCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import GroupsIcon from "@mui/icons-material/Groups";
import MovingIcon from "@mui/icons-material/Moving";
import KeyIcon from "@mui/icons-material/Key";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

export const SideBarIcons = {
  dashboardIcon: <ViewCompactIcon />,
  menuIcon: <MenuOutlinedIcon />,
  searchIcon: <SearchIcon />,
  clientsIcon: <Person2Icon />,
  accountsIcon: <SavingsIcon />,
  depositIcon: <AddCardIcon />,
  withdrawIcon: <CreditScoreIcon />,
  usersIcon: <GroupsIcon />,
  statementIcon: <MovingIcon />,
  statsIcon: <QueryStatsIcon />,
  changepasswordIcon: <KeyIcon />,
  submenuIcon: <PanoramaFishEyeIcon sx={{ fontSize: 20 }} />,
  downArrow: <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />,
  leftArrow: <KeyboardArrowRightIcon sx={{ fontSize: 20 }} />,
};

export const LogOutIcon = {
  logoutIcon: <LogoutIcon />,
};

export const DashboardIcons = {
  personAddIcon: <PersonAddIcon sx={{ fontSize: 33 }} />,
  paymentIcon: <PaymentsIcon sx={{ fontSize: 33 }} />,
  bankAccountIcon: <AccountBalanceIcon sx={{ fontSize: 33 }} />,
  searchIcon: <SearchIcon sx={{ fontSize: 33 }} />,
  moreInfoIcon: <ArrowCircleRightIcon sx={{ fontSize: 22 }} />,
};

export const TableIcons = {
  refreshIcon: <RefreshIcon />,
  downloadIcon: <DownloadIcon />,
  editIcon: <BorderColorIcon sx={{ fontSize: 12 }} />,
  copyIcon: <FileCopyIcon sx={{ fontSize: 9 }} />,
  deleteIcon: <DeleteIcon sx={{ fontSize: 12 }} />,
  doneIcon: <DoneIcon sx={{ fontSize: 14 }} />,
  clearIcon: <ClearIcon sx={{ fontSize: 14 }} />,
  visibleIcon: <VisibilityIcon sx={{ fontSize: 14 }} />,
};

export const GlobalSearchIcons = {
  cachedIcon: <CachedIcon sx={{ fontSize: 15 }} />,
  personAddIcon: <PersonAddAltIcon sx={{ fontSize: 17 }} />,
  searchIcon: <SearchIcon sx={{ fontSize: 23 }} />,
};

export const PasswordIcons = {
  visibleIcon: <VisibilityIcon sx={{ fontSize: 15 }} />,
  visiblityOffIcon: <VisibilityOffIcon sx={{ fontSize: 15 }} />,
};

export const ModalIcon = {
  closeIcon: <CloseIcon sx={{ color: "#ffffff", marginTop: "-16px" }} />,
};
