export const endPoints = {
  login: "login",
  logout: "logout",
  clients: "clients",
  user: "user",
  bankAccount: "bank-account",
  bankAccountUpdateStatus: "bank-account/update-status",
  bankAccountDepositHistory: "bank-account/deposits-history",
  bankAccountWithdrawalHistory: "bank-account/withdrawals-history",
  deposits: "deposits/deposit-transaction",
  withdrawals: "withdrawals/withdrawal-transaction",
  thirdParties: "third-parties",
  changePassword: "user/changepassword",
  userEdit: "user/update",
  resetPassword: "user/resetpass",
  accountStatement: "account-statement",
  siteUserStats: "site-user-stats",
  despoitUpdateAction: "deposits/deposit-transaction/updateaction",
  withdrawalUpdateAction: "withdrawals/withdrawal-transaction/updateaction",
  withdrawalHistory: "withdrawals/withdrawal-transaction/history",
  depositHistory: "deposits/deposit-transaction/history",
  userlogin: "xp-user-login",
  dashboard: "/dashboard",
};
