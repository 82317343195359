// Importing dependencies.
import React from "react";
import GlobalSearchContainer from "../../containers/GlobalSearchContainer";
import Layout from "../../layout/Layout";
import { layoutHeading, tabTitles } from "../../constants/CommonConstants";
import PageHelmet from './../../components/atoms/PageHelmet';

// Declaring Funtion
const GlobalSearch = () => {
  // Returning GlobalSearch Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.globalSearch} />
      <Layout heading={layoutHeading.globalSearch}>
        <GlobalSearchContainer />
      </Layout>
    </>
  );
};

// Exporting GlobalSearch Function.
export default GlobalSearch;
