import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomInput from "../../../../components/atoms/CustomInput";
import { classes, inputTypes } from "../../../../constants/CommonConstants";
import { addAccountConstant } from "../../../AccountsContainer/AddAccountContainer/constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import { reasonOptions } from "../constants";

const RejectWithModal = ({
  modalFormErrors,
  withdrawRejectInput,
  renderError,
  onHandleWithRejectChange,
  uploadRejectFile,
  selectBoxRef,
  showProof = true,
  modalSelectOptions = reasonOptions,
}) => {
  return (
    <div className="row">
      {showProof && (
        <div className="col-12 mb-3">
          <CustomLabel label={"Proof:"} />
          <CustomInput
            type={inputTypes.file}
            className={`${classes.inputClass}`}
            placeholder={addAccountConstant.noFile}
            onChange={(e) => onHandleWithRejectChange("file", e)}
            accept={inputTypes.acceptType}
            id="uploadRejectFile"
            inputRef={uploadRejectFile}
          />
        </div>
      )}
      <div className="col-12 mb-3">
        <CustomLabel label={"Reason:"} />
        <CustomSelectBox
          options={modalSelectOptions}
          className={`${classes.selectBox} ${
            modalFormErrors["reason"] && classes.invalid
          }`}
          value={withdrawRejectInput.reason.value}
          onChange={(e) => onHandleWithRejectChange("reason", e)}
          error={renderError("reason")}
          property="reason"
          ref={selectBoxRef}
        />
      </div>

      {withdrawRejectInput.reason.value === "Other" && (
        <div className="col-12 mb-3">
          <CustomInput
            type={inputTypes.text}
            placeholder={"Type your reason here ..."}
            value={withdrawRejectInput.otherReason}
            className={`${classes.inputClass} ${
              modalFormErrors["otherReason"] && classes.invalid
            }`}
            onChange={(e) => onHandleWithRejectChange("otherReason", e)}
            error={renderError("otherReason")}
          />
        </div>
      )}
    </div>
  );
};

export default RejectWithModal;
