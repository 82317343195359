export const usersContants = {
    role: "Role:",
    search: "Search",
    reset: "Reset",
    edit: "Edit",
    rp: "RP",
    loading: "Loading...",
    ResetPassword: "Reset Password",
    copyPassword: "Copy Password",
    newPassword: "New Password : ",
    active: "Active",
    inActive: "Inactive",
    PasswordCopied: "Password has been Copied!"
}

export const roleOptions = [
    { value: "", label: "Select role" },
    { value: "0", label: "Deposit Manager" },
    { value: "1", label: "Withdraw Manager" },
];
export const usersConstants = {
}