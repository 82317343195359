import { color } from "../../../themes/Colors";
import { fontFamily } from "../../../themes/font";

export const utrStyle = {
    badge: {
        marginRight: "5px",
        padding: "3px",
        fontSize: "12px",
        textTransform: "uppercase",
        fontWeight: 800,
        cursor: "pointer",
        backgroundColor: color._ffc107,
        color: color._000,
        fontFamily: fontFamily.fontFamily,
        borderRadius: "3px"
    },
    table: { minWidth: "50rem" },
    icon: {
        color: color._ffffff,
        backgroundColor: color._dc3545,
        borderRadius: "2px",
        width: '20px',
        height: '20px',
        marginLeft: "20px"
    },
}