import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import { addClientConstant, masterOptions } from "../constants";
import CardHeader from "../../../../components/molecules/CardHeader";
import CustomInput from "../../../../components/atoms/CustomInput";
import {
  inputTypes,
  classes,
  buttonTypes,
} from "../../../../constants/CommonConstants";
import { addClientStyle } from "../style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import useClientsHook from "../../../../hooks/clients-hook";
import Modal from './../../../../components/molecules/Modal';

const AddClientForm = () => {
  const {
    handleAddClient,
    credentials,
    onHandleSelect,
    onHandleInputChange,
    formErrors,
    renderError,
    btnLoading,
    domains,
    showModal,
    btnModalLoading,
    handleCloseModal,
    handleCopyModal
  } = useClientsHook(false);
  return (
    <>
      <form onSubmit={handleAddClient}>
        <CardHeader cardHeaderTitle={addClientConstant.addClients} />
        <div className="card-body">
          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <CustomLabel
                  label={addClientConstant.master}
                  additionalStyles={addClientStyle.label}
                />
                <CustomSelectBox
                  options={domains}
                  className={classes.selectBox}
                  onChange={(value) => onHandleSelect("site", value.label)}
                />
              </div>
              <div className="col-md-6">
                <CustomLabel
                  label={addClientConstant.referralCode}
                  additionalStyles={addClientStyle.label}
                />
                <CustomInput
                  type={inputTypes.numeric}
                  placeholder={addClientConstant.enterReferralCode}
                  value={credentials?.referralCode}
                  className={classes.inputClass}
                  onChange={(e) => onHandleInputChange("referralCode", e)}
                />
              </div>
              <div className="col-md-6 mt-2">
                <CustomLabel
                  label={addClientConstant.username}
                  additionalStyles={addClientStyle.label}
                />
                <CustomInput
                  type={inputTypes.text}
                  placeholder={addClientConstant.enterUsername}
                  value={credentials?.username}
                  className={`${classes.inputClass} ${formErrors["username"] && classes.invalid
                    }`}
                  onChange={(e) => onHandleInputChange("username", e)}
                  error={renderError("username")}
                />
              </div>
              <div className="col-md-6 mt-2">
                <CustomLabel
                  label={addClientConstant.password}
                  additionalStyles={addClientStyle.label}
                />
                <CustomInput
                  type={inputTypes.password}
                  placeholder={addClientConstant.enterPassword}
                  value={credentials?.password}
                  className={`${classes.inputClass} ${formErrors["password"] && classes.invalid
                    }`}
                  // onChange={(e) => onHandleInputChange("password", e)}
                  error={renderError("password")}
                  passwordToggleIconStyle={addClientStyle.eyeIcon}
                  disabled={true}
                  showIcon={false}
                />
              </div>
            </div>
          </div>
        </div>
        <CardFooter
          title={addClientConstant.add}
          type={buttonTypes.submit}
          className={`${classes.btn} btn-hover`}
          isLoading={btnLoading}
          loaderStyle={addClientStyle.loading}
        />
      </form>
      <Modal
        show={showModal}
        handleClose={handleCloseModal}
        isLoading={btnModalLoading}
        // btnDisable={btnDisable}
        modalFooterPrimaryTitle={addClientConstant.close}
        modalFooterSecondaryTitle={
          addClientConstant.copyText
        }
        handleSave={handleCopyModal}
      >

        <div className="row">
          <div className="col-12">
            <h2>{addClientConstant.clickToCopy}</h2>
            <p>
              {addClientConstant.username}&nbsp;{credentials?.username}

            </p>
            <p className="mb-0">
              {addClientConstant.password}&nbsp;{credentials?.password}

            </p>
          </div>
        </div>

      </Modal>
    </>

  );
};

export default AddClientForm;
