import React from "react";
import CardHeader from "../../../../components/molecules/CardHeader";
import { depositConstants } from "../constants";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomInput from "../../../../components/atoms/CustomInput/CustomInput";
import { classes, inputTypes } from "../../../../constants/CommonConstants";
import { depositStyle } from "../style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";
import { useSelector } from "react-redux";
import { getPermission, getRole } from "../../../../utils/Common";
import { usePermissions } from "../../../../permissionhook";
const DepositCashSettlementForm = (props) => {
  const {
    formErrors,
    renderError,
    onHandleInputChange,
    handleDepositInputChange,
    depositCashSettlement,
    handleAddDepositCashSettlement,
    bankAccounts,
  } = props;
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();

  const CREATEPERMISSION = getPermission(
    permissions,
    "DEPOSITCASHSETTLEMENT",
    "WRITEPERMISSION",
    getRole(role)
  );
  const accountOptions = bankAccounts?.map((account) => ({
    value: account.id,
    label: account.account_name,
  }));

  return (
    <form onSubmit={handleAddDepositCashSettlement}>
      <CardHeader cardHeaderTitle={depositConstants.cashSettlement} />
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <CustomLabel
                label={depositConstants.account}
                additionalStyles={depositStyle.label}
              />
              <SearchBox
                options={accountOptions}
                className={`${formErrors["account"] && classes.invalid}`}
                onChange={(value) =>
                  onHandleInputChange(
                    "account",
                    value[0]?.text,
                    null,
                    "cashForm"
                  )
                }
                error={renderError("account")}
                placeholder={depositConstants.selectAccount}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={depositConstants.amount}
                additionalStyles={depositStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                className={`${classes.inputClass} ${
                  formErrors["amount"] && classes.invalid
                }`}
                placeholder={depositConstants.enterAmount}
                value={depositCashSettlement?.amount}
                onChange={(e) =>
                  handleDepositInputChange("amount", e, "cashForm")
                }
                error={renderError("amount")}
              />
            </div>
          </div>
        </div>
      </div>
      <CardFooter
        title={depositConstants.add}
        className={`${classes.btn} btn-hover`}
        CREATEPERMISSION={CREATEPERMISSION}
      />
    </form>
  );
};

export default DepositCashSettlementForm;
