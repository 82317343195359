export const utrConstants = {
    utrEntry: "UTR Entry",
    utrNumber: "UTR Number:",
    enterUtrNumber: "Enter UTR Number",
    account: "Account:",
    amount: "Amount:",
    enterAmount: "Enter Amount",
    add: "Add",
    selectAccount: "Select account",
    user: "User",
    note: "Note",
    selectUser: "Select user",
    uploadImg: "Upload Image"
}
