import React from 'react'
import { labelStyle } from './style'

const CustomLabel = (props) => {
    const { label, additionalStyles } = props
    return (
        <label style={{ ...labelStyle, ...additionalStyles }}>{label}</label>
    )
}

export default CustomLabel