import { color } from "../../../themes/Colors";

export const depositStyle = {
    container: { borderRadius: "1px", position: "relative" },
    searchBtn: {
        width: "100%",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: "none",
        color: color._ffffff,
        fontWeight: 400,
    },
    table: { minWidth: "auto" },
    badge: {
        marginRight: "5px",
        padding: "7px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 600,
        cursor: "pointer"
    },
    badgeRed: {
        backgroundColor: color._f44336
    },
    badgeGreen: {
        backgroundColor: color._0e8d84
    },
    btnLabel: {
        color: "transparent"
    },
}