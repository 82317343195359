import { useCallback, useEffect, useRef, useState } from "react";
import {
  btnConstants,
  dashboardConstants,
} from "../containers/DashboardContainer/constant";
import ReusableIcon from "../components/atoms/ReusableIcon";
import { DashboardIcons } from "../assets/icons/Icons";
import { dashboardStyle } from "../containers/DashboardContainer/style";
import { color } from "../themes/Colors";
import { URLs } from "./../routes/URLs";
import { useNavigate } from "react-router-dom";
import { getDashboardData } from "../redux/dashboard/action";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../utils/Common";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";

// Defining Dashboard Hook.
export const useDashboardHook = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const isMobile = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = getAuthData();
  const dashboardData = useSelector((state) => state.dashboard);
  const isMountedDashboard = useRef(true);

  useEffect(() => {
    const handleResize = () => {
      // Determine viewport width
      isMobile.current = window.innerWidth <= 768;
      setIsSidebarOpen(!isMobile.current);
    };

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Resizing once to set initial state
    handleResize();

    // Cleaning event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleOutsideClick = (event) => {
    if (isMobile.current && !event.target.closest(".sidebar")) {
      setIsSidebarOpen(false);
    }
  };

  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const fetchDashboardData = useCallback(async () => {
    setLoadingDashboard(true);
    await dispatch(
      getDashboardData(token, null, null, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoadingDashboard(false);
  }, [dispatch, token]);

  useEffect(() => {
    if (isMountedDashboard.current) {
      fetchDashboardData();
    }
    return () => {
      isMountedDashboard.current = false;
    };
  }, []);

  useEffect(() => {
    isMobile.current &&
      document.addEventListener("mousedown", handleOutsideClick);

    return () =>
      isMobile.current &&
      document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const handleDashboardCardClick = (cardTitle) => {
    // Define redirection logic based on cardTitle
    switch (cardTitle) {
      case dashboardConstants.newClient:
        navigate(URLs.clientsAdd);
        break;
      case dashboardConstants.depositList:
        navigate(URLs.depositsList);
        break;
      case dashboardConstants.utrEntry:
        navigate(URLs.depositsUtrEntryAdd);
        break;
      case dashboardConstants.withdraw:
        navigate(URLs.withdrawalList);
        break;
      case dashboardConstants.addAccount:
        navigate(URLs.accountsAdd);
        break;
      case dashboardConstants.accountList:
        navigate(URLs.accountsList);
        break;
      case dashboardConstants.globalSearch:
        navigate(URLs.globalSearch);
        break;
      default:
        break;
    }
  };

  const totalClients = dashboardData?.dashboard?.totalClients ? dashboardData?.dashboard?.totalClients : 0;

  const dashboardCardGroups = [
    {
      colCount: 3,
      cardData: [
        {
          cardTitle: dashboardConstants.newClient + totalClients,
          icon: (
            <ReusableIcon
              icon={DashboardIcons.personAddIcon}
              iconColor={color._ffffff}
              style={dashboardStyle.icon}
              iconBackgroundColor={color._ffc107}
            />
          ),
          btnClassName: `${btnConstants.btnClass} btn-hover`,
          btnTitle: btnConstants.btnTitle,
          btnStyle: dashboardStyle.btnGreen,
          name: "CLIENTS",
          onClick: () => handleDashboardCardClick(dashboardConstants.newClient),
        },
      ],
    },
    {
      colCount: 4,
      cardData: [
        {
          cardTitle: dashboardConstants.depositList,
          icon: (
            <ReusableIcon
              icon={DashboardIcons.paymentIcon}
              iconColor={color._ffffff}
              style={dashboardStyle.icon}
              iconBackgroundColor={color._17a2b8}
            />
          ),
          btnClassName: `${btnConstants.btnClass} warning-hover`,
          btnTitle: btnConstants.btnTitle,
          btnStyle: dashboardStyle.btnMustard,
          name: "DEPOSITLIST",
          onClick: () =>
            handleDashboardCardClick(dashboardConstants.depositList),
        },
        {
          cardTitle: dashboardConstants.utrEntry,
          icon: (
            <ReusableIcon
              icon={DashboardIcons.bankAccountIcon}
              iconColor={color._ffffff}
              style={dashboardStyle.icon}
              iconBackgroundColor={color._dc3545}
            />
          ),
          btnClassName: `${btnConstants.btnClass} btn-hover`,
          btnTitle: btnConstants.btnTitle,
          btnStyle: dashboardStyle.btnGreen,
          name: "DEPOSITUTRFORM",
          onClick: () => handleDashboardCardClick(dashboardConstants.utrEntry),
        },
        {
          cardTitle: dashboardConstants.withdraw,
          icon: (
            <ReusableIcon
              icon={DashboardIcons.paymentIcon}
              iconColor={color._ffffff}
              style={dashboardStyle.icon}
              iconBackgroundColor={color._28a745}
            />
          ),
          btnClassName: `${btnConstants.btnClass} warning-hover`,
          btnTitle: btnConstants.btnTitle,
          btnStyle: dashboardStyle.btnMustard,
          name: "WITHDRAWLIST",
          onClick: () => handleDashboardCardClick(dashboardConstants.withdraw),
        },
        {
          cardTitle: dashboardConstants.addAccount,
          icon: (
            <ReusableIcon
              icon={DashboardIcons.bankAccountIcon}
              iconColor={color._ffffff}
              style={dashboardStyle.icon}
              iconBackgroundColor={color._dc3545}
            />
          ),
          btnClassName: `${btnConstants.btnClass} btn-hover`,
          btnTitle: btnConstants.btnTitle,
          btnStyle: dashboardStyle.btnGreen,
          name: "ADDACCOUNTS",
          onClick: () =>
            handleDashboardCardClick(dashboardConstants.addAccount),
        },
      ],
    },
    {
      colCount: 4,
      cardData: [
        {
          cardTitle: dashboardConstants.accountList,
          icon: (
            <ReusableIcon
              icon={DashboardIcons.bankAccountIcon}
              iconColor={color._ffffff}
              style={dashboardStyle.icon}
              iconBackgroundColor={color._dc3545}
            />
          ),
          btnClassName: `${btnConstants.btnClass} warning-hover`,
          btnTitle: btnConstants.btnTitle,
          btnStyle: dashboardStyle.btnMustard,
          name: "LISTACCOUNTS",
          onClick: () =>
            handleDashboardCardClick(dashboardConstants.accountList),
        },
        {
          cardTitle: dashboardConstants.globalSearch,
          icon: (
            <ReusableIcon
              icon={DashboardIcons.searchIcon}
              iconColor={color._ffffff}
              style={dashboardStyle.icon}
              iconBackgroundColor={color._ffc107}
            />
          ),
          btnClassName: `${btnConstants.btnClass} btn-hover`,
          btnTitle: btnConstants.btnTitle,
          btnStyle: dashboardStyle.btnGreen,
          name: "GLOBALSEARCH",
          onClick: () =>
            handleDashboardCardClick(dashboardConstants.globalSearch),
        },
      ],
    },
  ];

  // Returning Functions and Variables.
  return {
    isSidebarOpen,
    toggleSidebar,
    dashboardCardGroups,
    dashboardData,
    loadingDashboard
  };
};

// Exporting Dashboard Hook.
export default useDashboardHook;
