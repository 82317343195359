// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import WithdrawListContainer from '../../../containers/WithdrawContainer/WithdrawListContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const WithrawList = () => {

  // Returning WithrawList Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.withdrawListing} />
      <Layout heading={layoutHeading.withdraw}>
        <WithdrawListContainer />
      </Layout>
    </>
  );
};

// Exporting WithrawList Function.
export default WithrawList;
