import React from "react";
import CustomCards from "../../molecules/CustomCards";
import { getPermission, getRole } from "../../../utils/Common";
import { usePermissions } from "../../../permissionhook";
import { useSelector } from "react-redux";

const CardGroup = ({ colCount, cardData }) => {
  const permissions = usePermissions();
  const role = useSelector((state) => state.auth.role);
  const renderCards = () => {
    return (
      cardData?.length > 0 &&
      cardData
        ?.filter((i) =>
          getRole(role)
            ? true
            : getPermission(permissions, i?.name, "READPERMISSOIN")
        )
        ?.map((data, index) => (
          <div
            key={index + data.cardTitle}
            className={`col-md-${12 / colCount}`}
          >
            <CustomCards
              icon={data.icon}
              cardTitle={data.cardTitle}
              btnClassName={data.btnClassName}
              btnTitle={data.btnTitle}
              btnStyle={data.btnStyle}
              onClick={data.onClick}
            />
          </div>
        ))
    );
  };
  return <div className="row">{renderCards()}</div>;
};

export default CardGroup;
