import React from 'react'
import { Helmet } from 'react-helmet';

const PageHelmet = ({ tabTitle }) => {
    return (
        //Using Page Helmet for the dynamic browser page tab title
        <Helmet>
            <title>{tabTitle}</title>
        </Helmet>
    )
}

export default PageHelmet