// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import WithdrawManualContainer from './../../../containers/WithdrawContainer/WithdrawManualContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const Manual = () => {

  // Returning Manual Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.manualWithdraw} />
      <Layout heading={layoutHeading.manualWithdraw}>
        <WithdrawManualContainer />
      </Layout>
    </>
  );
};

// Exporting Manual Function.
export default Manual;
