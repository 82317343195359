// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import DepositHistoryContainer from '../../../containers/DepositContainer/DepositHistoryContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const DepositHistory = () => {

  // Returning DepositHistory Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.depositHistory} />
      <Layout heading={layoutHeading.depositHistory}>
        <DepositHistoryContainer />
      </Layout>
    </>
  );
};

// Exporting AddAccount Function.
export default DepositHistory;
