import React from "react";
import CustomLabel from "../../../components/atoms/CustomLabel";
import { globalSearchConstants } from "../constants";
import { globalSearchStyle } from "../style";
import CustomInput from "../../../components/atoms/CustomInput";
import { classes, inputTypes } from "../../../constants/CommonConstants";

const AddClientModal = ({
  formErrors,
  credentials,
  onHandleInputChange,
  renderError,
}) => {
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <CustomLabel
          label={globalSearchConstants.username}
          additionalStyles={globalSearchStyle.modalLabel}
        />
        <span style={globalSearchStyle.labelInfo}>
          {globalSearchConstants.labelInfo}
        </span>
        <CustomInput
          placeholder={globalSearchConstants.username}
          className={`${classes.inputClass} ${
            formErrors["username"] && classes.invalid
          }`}
          value={credentials?.username}
          type={inputTypes.text}
          onChange={(e) => onHandleInputChange("username", e)}
          error={renderError("username")}
        />
      </div>
      <div className="col-12 mb-3">
        <CustomLabel
          label={globalSearchConstants.password}
          additionalStyles={globalSearchStyle.modalLabel}
        />
        :
        <CustomInput
          placeholder={globalSearchConstants.password}
          type={inputTypes.password}
          value={credentials?.password}
          className={`${classes.inputClass} ${
            formErrors["password"] && classes.invalid
          }`}
          onChange={(e) => onHandleInputChange("password", e)}
          error={renderError("password")}
        />
      </div>
      <div className="col-12 mb-3">
        <CustomLabel
          label={globalSearchConstants.referral}
          additionalStyles={globalSearchStyle.modalLabel}
        />
        :
        <CustomInput
          placeholder={globalSearchConstants.referral}
          className={classes.inputClassNoRound}
        />
      </div>
    </div>
  );
};

export default AddClientModal;
