import React from 'react'
import CustomTable from '../../../../components/organisms/CustomTable/CustomTable'
import { withdrawStyle } from '../style'
import { withdrawListTableColumns } from '../../utils/WithdrawalUtils'

const WithdrawList = (props) => {
    const { formattedWithdrawalsData, loading } = props;
    return (
        <CustomTable
            data={formattedWithdrawalsData}
            columns={withdrawListTableColumns}
            rows={25}  //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
            style={withdrawStyle.table}
            loading={loading}
        />
    )
}

export default WithdrawList