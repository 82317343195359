export const inputTypes = {
  text: "text",
  numeric: "numeric",
  password: "password",
  file: "file",
  date: "date",
  acceptType: "image/*",
  button: "button",
};

export const buttonTypes = {
  submit: "submit",
  reset: "reset",
  button: "button",
};

export const layoutHeading = {
  dashboard: "Welcome",
  globalSearch: "Global Search",
  clients: "clients",
  accounts: "Accounts",
  account: "Account",
  disabledAccounts: "Disabled Accounts",
  cashSettlement: "Cash Settlement",
  depositHistory: "Deposit History",
  deposit: "Deposit",
  utrEntry: "UTR Entry",
  utrPendingList: "UTR Pending List",
  utrRemovedList: "UTR Removed List",
  withdrawHistory: "Withdraw History",
  withdraw: "Withdraw",
  manualWithdraw: "Manual Withdraw",
  users: "User",
  accountStatement: "Account Statement (DEMOLALIT-99EXCH.COM)",
  stats: "Stats",
  changePassword: "Change Password",
};

export const classes = {
  inputClass: "form-control",
  selectBox: "form-select form-select-sm",
  btn: "btn",
  badge: "badge",
  badgeRounded: "badge rounded-pill",
  btnDefault: "btn btn-default",
  invalid: "is-invalid",
  btnClose: "btn-close",
  btnSecondary: "btn btn-secondary",
  btnDanger: "btn btn-danger",
  inputClassNoRound: "form-control rounded-0",
  shadowNone: "shadow-none",
};

export const tabTitles = {
  login: "Login",
  dashboard: "Dashboard",
  globalSearch: "Global Search",
  addClients: "Add Clients",
  clientsListing: "Client Listing",
  addAccount: "Add Account",
  editAccount: "Edit Account",
  accountListing: "Account Listing",
  disabledAccountListing: "Disabled Account Listing",
  depositListing: "Deposit Listing",
  depositHistory: "Deposit History",
  utrEntry: "UTR Entry",
  utrPendingList: "UTR Pending List",
  utrRemovedList: "Removed UTR List",
  cashSettlement: "Cash Settlement",
  withdrawListing: "Withdraw Listing",
  withdrawHistory: "Withdraw History",
  manualWithdraw: "Manual Withdraw",
  addUsers: "Add Users",
  editUsers: "Edit Users",
  userListing: "User Listing",
  accountStatement: "Account Statement",
  statistics: "Site User Stats",
  changePassword: "Change Password",
};

export const sweetAlertIconType = {
  success: "success",
};

export const constants = {
  Unauthorized: "Unauthorized",
  status_200: 200,
  status_201: 201,
};

export const isValidDateRange = (startDate, endDate) => {
  let today = new Date();
  if (startDate === "" || endDate === "") return false;
  let startMilliseconds = startDate && startDate.getTime();
  let endMilliseconds = endDate && endDate.getTime();
  let todayMilliseconds = today.getTime();

  if (endMilliseconds && startMilliseconds > endMilliseconds) {
    return "Start date should not be greater than end date"; // Start date should not be greater than end date
  }
  if (startMilliseconds && startMilliseconds > todayMilliseconds) {
    return "Start date should not be greater than today's date"; // Start date should not be greater than today's date
  }
  if (endMilliseconds && endMilliseconds > todayMilliseconds) {
    return "End date should not be greater than today's date"; // End date should not be greater than today's date
  }
  if (startMilliseconds && endMilliseconds < startMilliseconds) {
    return "End date should not be less than start date"; // End date should not be less than start date
  }

  return false;
};

export const utrRegexComp = /^\d{8,20}$/;

export const passwordRegexComp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
