import {
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_FAIL,
  ADD_DEPOSIT_SUCCESS,
  ADD_DEPOSIT_FAIL,
  UPDATE_DEPOSIT_STATUS_SUCCESS,
  UPDATE_DEPOSIT_STATUS_FAIL,
  GET_DEPOSIT_HISTORY_SUCCESS,
  GET_DEPOSIT_HISTORY_FAIL,
  UPDATE_FILTERED_DEPOSIT_DATA,
  GET_DEPOSIT_LIST_ITEM_DATA,
} from "./actionType";

const initialState = {
  depositAdded: false,
  errorMessage: null,
  deposits: [],
  depositHistory: [],
  depositUpdated: [],
  depositListItemData: {},
};

const depositReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEPOSIT_SUCCESS:
      return {
        ...state,
        depositAdded: true,
        errorMessage: null,
      };
    case ADD_DEPOSIT_FAIL:
      return {
        ...state,
        depositAdded: false,
        errorMessage: action.payload,
      };
    case GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        deposits: action.payload,
        errorMessage: null,
        depositAdded: false,
      };
    case GET_DEPOSIT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GET_DEPOSIT_LIST_ITEM_DATA:
      return {
        ...state,
        depositListItemData: action.payload,
        errorMessage: action.payload,
      };
    case UPDATE_DEPOSIT_STATUS_SUCCESS:
      // Map through deposits to update the status of the deposit with the matching ID
      // const updatedDeposits = state.depositHistory.map((deposit) => {
      const updatedDeposits = state.deposits.map((deposit) => {
        if (deposit.id === action.payload.id) {
          return {
            ...deposit,
            status: action.payload.status,
          };
        }
        return deposit;
      });
      const updatedDepositHistory = state.depositHistory.map((deposit) => {
        if (deposit.id === action.payload.id) {
          return {
            ...deposit,
            status: action.payload.status,
          };
        }
        return deposit;
      });
      return {
        ...state,
        depositHistory: updatedDepositHistory,
        depositUpdated: true,
        deposits: updatedDeposits,
        errorMessage: null,
      };
    case UPDATE_DEPOSIT_STATUS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GET_DEPOSIT_HISTORY_SUCCESS:
      return {
        ...state,
        depositHistory: action.payload,
        errorMessage: null,
        depositAdded: false,
      };
    case GET_DEPOSIT_HISTORY_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default depositReducer;
