import { color } from "../themes/Colors";

export const container = {
    col1: {
        backgroundColor: color._f8f9fa,
    },
    col2: { width: 'calc(100% - 250px)' },
    navBar: {
        backgroundColor: color._027970,
    },
    spanIcon: {
        cursor: "pointer"
    },
};
