import React from 'react';
import SearchForm from './helper/SearchForm';
import { accountsStyle } from './style';
import DisabledAccountsList from './helper/DisabledAccountsList';
import useAccountsHook from '../../../hooks/accounts-hook';

const DisabledAccountsContainer = () => {
    const {
        bankAccounts,
        // handleSearchForDisable,
        searchAccountFormValues,
        // onHandleSearchInputChangeDisable,
        handleReset,
        disabledAccountsData,
        loading,
        handleAccountSearchableSelect,
        accountFilters,
        handleSearchAccounts,
        handleAccountInputSearch,
        handleAccountFilterChange,
        resetStatusRef
    } = useAccountsHook();
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card p-2' style={accountsStyle.container}>
                        <SearchForm
                            bankAccounts={bankAccounts}
                            // handleSearchForDisable={handleSearchForDisable}
                            // searchAccountFormValues={searchAccountFormValues}
                            // onHandleSearchInputChangeDisable={onHandleSearchInputChangeDisable}
                            handleReset={handleReset}
                            handleAccountSearchableSelect={handleAccountSearchableSelect}
                            accountFilters={accountFilters}
                            handleSearchAccounts={handleSearchAccounts}
                            handleAccountInputSearch={handleAccountInputSearch}
                            handleAccountFilterChange={handleAccountFilterChange}
                            resetStatusRef={resetStatusRef}

                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-2 mt-2">
                    <div className="card table-responsive">
                        <DisabledAccountsList disabledAccountsData={disabledAccountsData} loading={loading} />
                    </div>
                </div>
            </div>
        </>

    );
};

export default DisabledAccountsContainer;