import { responseCode } from "../../constants/response-code";
import { _post, _put, _postNew } from "../../services/HttpMethods";
import { endPoints } from "../../services/EndPoints";
import { removeAuthData, setAuthData } from "../../utils/Common";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "./actionType";
import { unexpectedError } from "../../constants/ErrorMessage";
import { toast } from "react-toastify";

export const login = (credentials) => {
  return async (dispatch) => {
    try {
      const response = await _postNew(
        endPoints.userlogin,
        credentials,
        null,
        true
      );
      const { status, data, msg } = response.data;
      if (status !== 200) {
        throw new Error(msg);
      }
      const authData = {
        token: data.token,
        userId: data.id,
        userName: data.username,
        permission: data.permission,
        role: data.role,
      };
      setAuthData(authData);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: authData,
      });
      toast.success(msg);
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload: error,
      });
      toast.error(error.message);
    }
  };
};

export const logout = (token, success) => {
  return async (dispatch) => {
    try {
      const response = await _post(
        endPoints.logout,
        null,
        token,
        false,
        success
      );
      if (!response) {
        return;
      }
      const { data } = response;
      if (data.status !== responseCode.OK) {
        throw new Error(data.msg);
      }
      if (response.status === responseCode._403) {
        removeAuthData();
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: response.data,
        });
        toast.error(data?.Message || data?.message);
        success();
      }
      removeAuthData();
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: data,
      });
      toast.success(data.msg);
      if (success) {
        success();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const changePasswordAction = (obj, token = null) => {
  return async () => {
    try {
      const response = await _put(endPoints.changePassword, obj, token);
      const { status, msg } = response.data;
      if (status === responseCode.OK) {
        toast.success(msg);
      } else {
        toast.error(msg);
      }
    } catch (error) {
      toast.error(unexpectedError.unexpextedServer);
    }
  };
};
