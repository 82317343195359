// Exporting URL's in an object.
export const URLs = {
    login: "/",
    forgot_password: "/forgot-password",
    dashboard: "/dashboard",
    globalSearch: "/global-search",
    clientsAdd: "/clients/add",
    clientsList: "/clients",
    accountsAdd: "/accounts/add",
    accountsList: "/accounts",
    accountsDisabled: "/accounts/disabled",
    depositsList: "/deposits",
    depositsHistory: "/deposits/history",
    depositsUtrEntryAdd: "/deposits/utr-entry",
    depositsUtrPendingList: "/deposits/utr-entry/list",
    depositsUtrRemovedList: "/deposits/utr-entry/removed-list",
    depositsCashSettlement: "/deposits/cash-settlement",
    withdrawalList: "/withdrawals",
    withdrawalHistory: "/withdrawals/history",
    withdrawalManual: "/manual/widthdraw",
    withdrawalCashSettlement: "/withdrawals/cash-settlement",
    usersAdd: "/users/add",
    users: "/users",
    usersInactive: "/users/inactive",
    accountStatement: "/account-statement",
    statistics: "/site-user-stats",
    changePassword: "/change-password",
};
