import {
  GET_WITHDRAWAL_SUCCESS,
  GET_WITHDRAWAL_FAIL,
  UPDATE_WITHDRAWAL_STATUS_SUCCESS,
  UPDATE_WITHDRAWAL_STATUS_FAIL,
  GET_WITHDRAW_HISTORY,
  GET_BANK_ACCOUNT_WITHDRAW_HISTORY_FAIL,
  GET_WITHDRAW_LIST_DATA,
} from "./actionType";

const initialState = {
  errorMessage: null,
  withdrawals: [],
  withdrawHistory: [],
  withdrawListData: {},
};

const withdrawalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        withdrawals: action.payload,
        errorMessage: null,
      };
    case GET_WITHDRAWAL_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case UPDATE_WITHDRAWAL_STATUS_SUCCESS:
      // Map through withdrawals to update the status of the withdrawal with the matching ID
      const updatedWithdrawals = state.withdrawals.map((withdrawal) => {
        if (withdrawal.transaction_id === action.payload.id) {
          return {
            ...withdrawal,
            status: action.payload.status,
          };
        }
        return withdrawal;
      });
      return {
        ...state,
        withdrawals: updatedWithdrawals,
        errorMessage: null,
      };
    case UPDATE_WITHDRAWAL_STATUS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GET_WITHDRAW_LIST_DATA:
      return {
        ...state,
        withdrawListData: action.payload,
      };
    case GET_WITHDRAW_HISTORY:
      return {
        ...state,
        withdrawHistory: action.payload,
        errorMessage: null,
      };
    case GET_BANK_ACCOUNT_WITHDRAW_HISTORY_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default withdrawalReducer;
