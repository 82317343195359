// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import WithdrawCashSettlementContainer from '../../../containers/WithdrawContainer/WithdrawCashSettlementContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const WithdrawCashSettlement = () => {

  // Returning CashSettlement Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.cashSettlement} />
      <Layout heading={layoutHeading.cashSettlement}>
        <WithdrawCashSettlementContainer />
      </Layout>
    </>
  );
};

// Exporting CashSettlement Function.
export default WithdrawCashSettlement;
