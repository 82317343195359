import React from 'react'
import SearchForm from './helper/SearchForm'
import { depositStyle } from './style'
import AccountWithdrawalHistoryList from './helper/AccountWithdrawalHistoryList'
import { useAccountsHook } from './../../../hooks/accounts-hook';

const AccountWithdrawalHistoryContainer = () => {
    const {
        formattedAccountWithdrawalHistoryData,
        loading,
        openAccountWithdrawalHistory,
        handleAccountWithdrawalFilterSelect,
        accountWithdrawalFilters,
        handleAccountWithdrawalReset,
        accountWithdrawalFilteredData,
        statusRef,
        typeRef,
        handleAccountWithdrawalFilterInput,
        handleAccountWithdrawalSearch
    } = useAccountsHook(false);
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card p-2' style={depositStyle.container}>
                        <SearchForm
                            handleAccountWithdrawalFilterSelect={handleAccountWithdrawalFilterSelect}
                            accountWithdrawalFilters={accountWithdrawalFilters}
                            handleAccountWithdrawalReset={handleAccountWithdrawalReset}
                            statusRef={statusRef}
                            typeRef={typeRef}
                            handleAccountWithdrawalFilterInput={handleAccountWithdrawalFilterInput}
                            handleAccountWithdrawalSearch={handleAccountWithdrawalSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-2 mt-2">
                    <div className="card table-responsive">
                        <AccountWithdrawalHistoryList
                            formattedAccountWithdrawalHistoryData={formattedAccountWithdrawalHistoryData}
                            loading={loading}
                            openAccountWithdrawalHistory={openAccountWithdrawalHistory}
                            accountWithdrawalFilteredData={accountWithdrawalFilteredData}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountWithdrawalHistoryContainer