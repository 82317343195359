import React from "react";
import { Navigate } from "react-router-dom";
import { URLs } from "./URLs";
import { getAuthData } from "../utils/Common";

const PrivateRoute = ({ children, isAuthenticated, ...rest }) => {
  const { token } = getAuthData();
  return token && isAuthenticated ? (
    children
  ) : (
    <Navigate to={URLs.login} replace />
  );
};

export default PrivateRoute;
