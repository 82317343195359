import { TableIcons } from "../../../assets/icons/Icons";
import Badge from "../../../components/atoms/Badge";
import { classes } from "../../../constants/CommonConstants";
import {
  CopyToClipboardIcon,
  formatDateTime,
  formatNumber,
  getStatus,
  sortByDateDescending,
  statusFilter,
} from "../../../utils/Common";
import { depositConstants } from "../AccountDepositHistoryContainer/constants";
import { depositStyle } from "../AccountDepositHistoryContainer/style";
import {
  accountsConstants,
  statusOptions,
} from "../AccountListContainer/constants";
import { accountsStyle } from "../AccountListContainer/style";

export const accountsTableColumns = [
  { field: "no", header: "no", width: "1%" },
  { field: "id", header: "id", width: "5%" },
  { field: "username", header: "user", width: "5%" },
  { field: "account_name", header: "name", width: "10%" },
  { field: "account_type", header: "a/c type", width: "6%" },
  { field: "account_number", header: "a/c no", width: "7%" },
  { field: "ifsc_code", header: "ifsc code", width: "5%" },
  { field: "bank_name", header: "bank name", width: "5%" },
  { field: "min_amount", header: "min amount", width: "7%" },
  { field: "max_amount", header: "max amount", width: "7%" },
  { field: "max_deposit_amount", header: "max-deposit", width: "5%" },
  { field: "for", header: "for", width: "4%" },
  { field: "status", header: "status", width: "5%" },
  { field: "created", header: "created-on", width: "6%" },
  { field: "action", header: "action", width: "22%" },
];

export const disabledAccountsTableColumns = [
  { field: "no", header: "no", width: "1%" },
  { field: "id", header: "id", width: "3%" },
  { field: "username", header: "user", width: "5%" },
  { field: "account_name", header: "name", width: "10%" },
  { field: "account_type", header: "a/c type", width: "6%" },
  { field: "account_number", header: "a/c no", width: "9%" },
  { field: "ifsc_code", header: "ifsc code", width: "5%" },
  { field: "bank_name", header: "bank name", width: "5%" },
  { field: "min_amount", header: "min amount", width: "7%" },
  { field: "max_amount", header: "max-amount", width: "7%" },
  { field: "max_deposit_amount", header: "max-deposit", width: "5%" },
  { field: "for", header: "for", width: "4%" },
  { field: "status", header: "status", width: "5%" },
  { field: "created", header: "created-on", width: "6%" },
  { field: "action", header: "action", width: "22%" },
];

export const accountDepositHistoryTableColumns = [
  { field: "account_id", header: "id", width: "5%" },
  { field: "username", header: "username", width: "15%" },
  { field: "amount", header: "amount", width: "15%" },
  { field: "type", header: "type", width: "10%" },
  { field: "utrNumber", header: "utr number", width: "25%" },
  { field: "status", header: "status", width: "15%" },
  { field: "created", header: "date", width: "15%" },
];

export const accountDepositHistoryTableData = [
  {
    account_id: "1",
    logged_in_user: { username: "john_doe" },
    amount: "100",
    type: "Client",
    utrNumber: "UTR12345678",
    status: 0,
    created: "2023-07-01",
  },
  {
    account_id: "2",
    logged_in_user: { username: "jane_smith" },
    amount: "200",
    type: "Manual",
    utrNumber: "UTR87654321",
    status: 1,
    created: "2023-07-02",
  },
  {
    account_id: "3",
    logged_in_user: { username: "alice_johnson" },
    amount: "150",
    type: "UTR Entry",
    utrNumber: "UTR11223344",
    status: 2,
    created: "2023-07-03",
  },
  {
    account_id: "4",
    logged_in_user: { username: "bob_brown" },
    amount: "300",
    type: "Manual",
    utrNumber: "UTR44332211",
    status: 1,
    created: "2023-07-04",
  },
  {
    account_id: "5",
    logged_in_user: { username: "charlie_davis" },
    amount: "250",
    type: "Client",
    utrNumber: "UTR99887766",
    status: 0,
    created: "2023-07-05",
  },
];

export const getAccountStatus = (status) => {
  switch (status) {
    case 0:
      return (
        <Badge
          title={depositConstants.pending}
          className={classes.badge}
          style={{ ...depositStyle.badge, ...depositStyle.badgeYellow }}
        />
      );
    case 1:
      return (
        <Badge
          title={depositConstants.approved}
          className={classes.badge}
          style={{ ...depositStyle.badge, ...depositStyle.badgeGreen }}
        />
      );
    case 2:
      return (
        <Badge
          title={depositConstants.reject}
          className={classes.badge}
          style={{ ...depositStyle.badge, ...depositStyle.badgeRed }}
        />
      );
    default:
      return null;
  }
};

export const formatHistoryData = (
  historyData,
  statusConstants,
  badgeStyles
) => {
  return (
    historyData?.length &&
    sortByDateDescending(
      historyData.map((historyItem, index) => ({
        ...historyItem,
        account_id: historyItem?.account_id,
        amount: historyItem?.amount ? formatNumber(historyItem?.amount) : "",
        utrNumber: historyItem?.utrNumber,
        created: historyItem?.created
          ? formatDateTime(historyItem?.created)
          : "",
        status: getAccountStatus(historyItem?.status),
        no: index + 1,
        username: historyItem?.logged_in_user?.username,
      })),
      "created"
    )
  );
};
export const formatWithdrawalHistoryData = (
  historyData,
  statusConstants,
  badgeStyles
) => {
  return (
    historyData?.length &&
    sortByDateDescending(
      historyData.map((historyItem, index) => ({
        ...historyItem,
        account_id: historyItem?.account_id,
        amount: historyItem?.amount ? formatNumber(historyItem?.amount) : "",
        utrNumber: historyItem?.utr_number,
        created: historyItem?.created
          ? formatDateTime(historyItem?.created)
          : "",
        status: getAccountStatus(historyItem?.status),
        no: index + 1,
        username: historyItem?.added_by?.username,
      })),
      "created"
    )
  );
};

export const formatActiveAccountsData = (bankAccounts, renderActions) => {
  return bankAccounts
    ?.filter(statusFilter([0, 1]))
    .map((bankAccount, index) => ({
      ...bankAccount,
      account_name: (
        <>
          <span style={accountsStyle.span}>
            {bankAccount.account_name.toUpperCase()}
          </span>
          <CopyToClipboardIcon
            value={bankAccount.account_name}
            icon={TableIcons.copyIcon}
            style={accountsStyle.iconCopy}
          />
        </>
      ),
      bank_name: bankAccount.bank_name.toUpperCase(),
      account_type: bankAccount.account_type.toUpperCase(),
      account_number: (
        <>
          <span style={accountsStyle.span}>
            {bankAccount.account_number.toUpperCase()}
          </span>
          <CopyToClipboardIcon
            value={bankAccount.account_number}
            icon={TableIcons.copyIcon}
            style={accountsStyle.iconCopy}
          />
        </>
      ),
      ifsc_code: (
        <>
          <span style={accountsStyle.span}>
            {bankAccount.ifsc_code.toUpperCase()}
          </span>
          <CopyToClipboardIcon
            value={bankAccount.ifsc_code}
            icon={TableIcons.copyIcon}
            style={accountsStyle.iconCopy}
          />
        </>
      ),
      min_amount: formatNumber(bankAccount?.min_amount),
      max_amount: formatNumber(bankAccount?.max_amount),
      created: bankAccount?.created ? formatDateTime(bankAccount?.created) : "",
      status: getStatus(
        bankAccount?.status,
        { pending: "Inactive", approved: "Active" },
        accountsConstants,
        classes,
        accountsStyle
      ),
      action: renderActions(bankAccount),
      no: index + 1,
      username: bankAccount?.added_by?.username,
      for: bankAccount?.added_by?.for,
    }));
};

export const formatDisabledAccountsData = (
  bankAccounts,
  renderDisableActions
) => {
  return bankAccounts?.filter(statusFilter([2])).map((bankAccount, index) => ({
    ...bankAccount,
    account_name: (
      <>
        <span style={accountsStyle.span}>{bankAccount.account_name}</span>
        <CopyToClipboardIcon
          value={bankAccount.account_name}
          icon={TableIcons.copyIcon}
          style={accountsStyle.iconCopy}
        />
      </>
    ),
    account_number: (
      <>
        <span style={accountsStyle.span}>{bankAccount.account_number}</span>
        <CopyToClipboardIcon
          value={bankAccount.account_number}
          icon={TableIcons.copyIcon}
          style={accountsStyle.iconCopy}
        />
      </>
    ),
    ifsc_code: (
      <>
        <span style={accountsStyle.span}>{bankAccount.ifsc_code}</span>
        <CopyToClipboardIcon
          value={bankAccount.ifsc_code}
          icon={TableIcons.copyIcon}
          style={accountsStyle.iconCopy}
        />
      </>
    ),
    created: bankAccount?.created ? formatDateTime(bankAccount?.created) : "",
    status: getStatus(
      bankAccount?.status,
      { rejected: "Disabled" },
      accountsConstants,
      classes,
      accountsStyle
    ),
    action: renderDisableActions(bankAccount),
    no: index + 1,
    username: bankAccount?.added_by?.username,
    for: bankAccount?.added_by?.for,
  }));
};

export const statusLabelToValueMap = statusOptions.reduce((acc, option) => {
  acc[option.label] = option.value;
  return acc;
}, {});
