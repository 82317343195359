import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import {
    accountsConstants,
    statusOptions,
} from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes, buttonTypes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { accountsStyle } from "../style";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";

const SearchForm = (props) => {
    const {
        bankAccounts,
        handleSearchForDisable,
        searchAccountFormValues,
        onHandleSearchInputChangeDisable,
        handleReset,
        handleAccountSearchableSelect,
        accountFilters,
        handleSearchAccounts,
        handleAccountInputSearch,
        handleAccountFilterChange,
        resetStatusRef
    } = props;
    //To reduce duplicate usernames
    const userOptions = bankAccounts?.reduce((acc, user) => {
        const username = user?.added_by?.username;
        if (username && !acc.some(option => option.label === username)) {
            acc.push({
                value: user.id,
                label: username,
            });
        }
        return acc;
    }, []);

    return (
        <form onSubmit={handleSearchAccounts}>
            <div className="row m-0 pt-2">
                <div className="col-md-2">
                    <CustomLabel label={accountsConstants.user} />
                    <SearchBox
                        options={userOptions}
                        placeholder={accountsConstants.selectUser}
                        onChange={(value) => handleAccountSearchableSelect(value[0]?.text, "username")}
                        value={accountFilters.username || ''}
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={accountsConstants.acName} />
                    <CustomInput
                        className={classes.inputClass}
                        type={inputTypes.text}
                        placeholder={accountsConstants.enterName}

                        value={accountFilters.accountName || ""}
                        onChange={handleAccountInputSearch}
                        name="accountName"
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={accountsConstants.acNumber} />
                    <CustomInput
                        className={classes.inputClass}
                        type={inputTypes.text}
                        placeholder={accountsConstants.enterNumber}
                        value={accountFilters.accountNumber || ""}
                        onChange={handleAccountInputSearch}
                        name="accountNumber"
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={accountsConstants.status} />
                    <CustomSelectBox
                        options={statusOptions}
                        className={classes.selectBox}
                        onChange={(event) => handleAccountFilterChange(event, "status")}
                        ref={resetStatusRef}

                    />
                </div>
                <div className="col-md-2 d-flex align-items-center mt-md-4 mt-2">
                    <Button
                        title={accountsConstants.search}
                        style={accountsStyle.searchBtn}
                        className="pb-1 pt-1 btn-hover"
                    />
                </div>
                <div className="col-md-2 d-flex align-items-center mt-md-4 mt-2">
                    <Button
                        title={accountsConstants.reset}
                        style={accountsStyle.searchBtn}
                        className="pb-1 pt-1 btn-hover"
                        type={buttonTypes.reset}
                        onClick={handleReset}
                    />
                </div>
            </div>
        </form>
    );
};

export default SearchForm;
