import { color } from "../../../themes/Colors";

export const usersStyle = {
    searchBtn: {
        width: "100%",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: 'none',
        color: color._ffffff,
        fontWeight: 400,
    },
    table: { width: "auto" },
    icon: {
        color: color._ffffff,
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        marginRight: "5px",
        padding: "5px",
    },
    btnAction: {
        color: color._ffffff,
        backgroundColor: color._0e8d84,
        borderRadius: "10px",
        fontSize: "10px",
        padding: "3px",
        marginRight: "5px",
        fontWeight: 400,
    },
    badge: {
        marginRight: "5px",
        padding: "6px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 400,
        cursor: "pointer",
        borderRadius: "2px",
    },
    badgeAction: {
        color: color._ffffff,
        backgroundColor: color._0e8d84,
        borderRadius: "10px",
        padding: "5px",
        fontSize: "10px"
    },
    badgeRed: {
        backgroundColor: color._f44336,
        padding: "3px",
        // textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "12px",
        borderRadius: "3px"
    },
    badgeGreen: {
        backgroundColor: color._28a745,
        padding: "3px",
        // textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "12px",
        borderRadius: "3px"
    },
};
