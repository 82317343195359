import React from "react";
import { balanceInfoContainer } from "./style";

const BalanceInfoCard = ({ label, value }) => {
    return (
        <div style={balanceInfoContainer.container} className="global-card-btn">
            <p className="mb-0" style={balanceInfoContainer.label}>
                {label}
            </p>
            <p className="mb-0" style={balanceInfoContainer.value}>
                {value}
            </p>
        </div>
    );
};

export default BalanceInfoCard;
