import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { depositConstants, statusOptions, typeOptions } from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { depositStyle } from "../style";

const SearchForm = (props) => {
    const {
        handleAccountDepositFilterSelect,
        handleAccountDepositReset,
        statusRef,
        typeRef,
        accountDepositFilters,
        handleAccountDepositSearch,
        handleAccountDepositFilterInput
    } = props;
    return (
        <form onSubmit={handleAccountDepositSearch}>
            <div className="row m-0 pt-2">
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.selectStatus} />
                    <CustomSelectBox
                        options={statusOptions}
                        className={classes.selectBox}
                        onChange={(selectedOption) =>
                            handleAccountDepositFilterSelect("status", selectedOption.value)
                        }
                        ref={statusRef}
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.type} />
                    <CustomSelectBox
                        options={typeOptions}
                        className={classes.selectBox}
                        property="type"
                        onChange={(selectedOption) =>
                            handleAccountDepositFilterSelect("type", selectedOption.label)
                        }
                        ref={typeRef}
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.username} />
                    <CustomInput
                        className={classes.inputClass}
                        type={inputTypes.text}
                        value={accountDepositFilters?.username}
                        name="username"
                        onChange={(e) => handleAccountDepositFilterInput(e, 'username')}
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.utrNumber} />
                    <CustomInput
                        className={classes.inputClass}
                        type={inputTypes.text}
                        value={accountDepositFilters?.utrNumber}
                        onChange={(e) => handleAccountDepositFilterInput(e, 'utrNumber')}

                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel
                        label={depositConstants.search}
                        additionalStyles={depositStyle.btnLabel}
                    />
                    <Button
                        title={depositConstants.search}
                        style={depositStyle.searchBtn}
                        className="pb-1 pt-1"
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel
                        label={depositConstants.search}
                        additionalStyles={depositStyle.btnLabel}
                    />
                    <Button
                        title={depositConstants.reset}
                        style={depositStyle.searchBtn}
                        className="pb-1 pt-1"
                        onClick={handleAccountDepositReset}
                    />
                </div>
            </div>
        </form>
    );
};

export default SearchForm;
