import React from 'react'
import CustomTable from '../../../../components/organisms/CustomTable/CustomTable'
import { usersStyle } from '../style'
import { usersTableColumns } from '../../utils/UserUtils'
import { useSelector } from "react-redux";

const ActiveUsersList = (props) => {
    const { filteredData } = useSelector((state) => state.filter);
    const { formattedUsersData, loading } = props;
    return (
        <CustomTable
            data={filteredData && filteredData.length ? filteredData : formattedUsersData}
            columns={usersTableColumns}
            rows={25}  //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
            style={usersStyle.table}
            loading={loading}
        />
    )
}

export default ActiveUsersList