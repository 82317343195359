import React from "react";
import { withdrawStyle } from "./style";
import SearchForm from "./helper/SearchForm";
import WithdrawHistoryList from "./helper/WithdrawHistoryList";
import useWithdrawHook from "../../../hooks/withdraw-hook";

const WithdrawHistoryContainer = (props) => {
  const {
    formErrors,
    renderError,
    handleInputChange,
    handleSelectChange,
    handleFilterReset,
    handleSearch,
    historyFilters,
    formattedWithdrawalsHistoryData,
    loading,
    statusRef,
    typeRef,
    userNameRef,
    utrRef,
    endDateRef,
    startDateRef,
    btnDisabled,
    resetDisabled
  } = useWithdrawHook(false, false, false, true);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card p-2" style={withdrawStyle.container}>
            <SearchForm
              formErrors={formErrors}
              renderError={renderError}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              handleFilterReset={handleFilterReset}
              handleSearch={handleSearch}
              historyFilters={historyFilters}
              statusRef={statusRef}
              typeRef={typeRef}
              userNameRef={userNameRef}
              utrRef={utrRef}
              endDateRef={endDateRef}
              startDateRef={startDateRef}
              btnDisabled={btnDisabled}
              resetDisabled={resetDisabled}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2 mt-2">
          <div className="card table-responsive">
            <WithdrawHistoryList
              formattedWithdrawalsHistoryData={formattedWithdrawalsHistoryData}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawHistoryContainer;
