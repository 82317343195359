// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';
import EditAccountContainer from '../../../containers/AccountsContainer/EditAccountContainer';

// Declaring Funtion
const EditAccount = () => {

    return (
        <>
            <PageHelmet tabTitle={tabTitles.editAccount} />
            <Layout heading={layoutHeading.account}>
                <EditAccountContainer />
            </Layout>
        </>
    );
};

export default EditAccount;
