import { toast } from "react-toastify";
import { endPoints } from "../../services/EndPoints";
import { _get, _post, _put } from "../../services/HttpMethods";
import {
  GET_RESET_USER_DATA,
  GET_USER_FAIL,
  GET_USER_STATS_FAIL,
  GET_USER_STATS_SUCCESS,
  GET_USER_SUCCESS,
} from "./actionType";
import { userMessage } from "../../constants/SuccessMessage";
import { getFormattedUserData, removeAuthData } from "../../utils/Common";
import { logoutErrors } from "../../constants/ErrorMessage";
import { LOGOUT_SUCCESS } from "../auth/actionType";
import { constants } from "../../constants/CommonConstants";

export const getUser = (token, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _get(
        endPoints.user,
        null,
        token,
        null,
        logoutFunc
      );
      if (!response) {
        return;
      }
      if (response.status !== 200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_USER_SUCCESS,
        payload: getFormattedUserData(response.data),
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_USER_FAIL,
          payload: error.message,
        });
      }
    }
  };
};

export const addUser = (obj, onSuccess, token = null, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _post(
        endPoints.user,
        obj,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      }
      if (response.data.status !== 200) {
        throw new Error(response.data.msg);
      }
      toast.success(userMessage.successUserAdded);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else toast.error(error.message);
    }
  };
};

export const editUserAction = (obj, onSuccess, token = null, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _put(
        endPoints.userEdit,
        obj,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { status, msg } = response.data;
      if (status !== constants.status_200) {
        throw new Error(msg);
      }
      toast.success(msg);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
      }
    }
  };
};

export const resetPasswordAction = (
  obj,
  // onSuccess,
  token = null,
  logoutFunc
) => {
  return async (dispatch) => {
    try {
      const response = await _put(
        endPoints.resetPassword,
        obj,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { status, msg, data } = response.data;
      if (status !== constants.status_200) {
        throw new Error(msg);
      }
      await dispatch({
        type: GET_RESET_USER_DATA,
        payload: { ...data, username: obj.username },
      });
      // if (onSuccess) {
      //   onSuccess();
      // }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
      }
    }
  };
};

export const getSiteUserStats = (token, obj, logoutFunc = null) => {
  return async (dispatch) => {
    try {
      const response = await _get(
        endPoints.siteUserStats,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== constants.status_200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_USER_STATS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_USER_STATS_FAIL,
          payload: error.message,
        });
      }
    }
  };
};
