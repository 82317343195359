export const depositConstants = {
    utrNumber: "UTR Number:",
    username: "Username:",
    selectStatus: "Select Status",
    status: "Status:",
    pending: "Pending",
    approved: "Approved",
    reject: "Rejected",
    type: "Type:",
    search: "Search",
    reset: "Reset"
}

export const statusOptions = [
    { value: "", label: "Select status" },
    { value: 0, label: "Pending" },
    { value: 1, label: "Approved" },
    { value: 2, label: "Reject" },
];

export const typeOptions = [
    { value: "", label: "Select type" },
    { value: 1, label: "Client" },
    { value: 2, label: "Manual" },
    { value: 3, label: "UTR Entry" },
];

