import { color } from "../../../themes/Colors";

export const globalSearchCardStyle = {
    btnRefresh: {
        border: `1px solid ${color._fff}}`,
        backgroundColor: color._ffffff,
        color: color._000,
        width: "11%",
        borderRadius: 0,
        padding: "5px",
        height: "auto"
    },
    iconColor: {
        color: color._ffffff
    },
    parentDiv:{
        position: "relative",
    },
    childDiv:{
        position: "absolute",
        zIndex: "10",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(225, 225, 225, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}