import Badge from "../../../components/atoms/Badge";
import { classes } from "../../../constants/CommonConstants";
import {
  CopyToClipboardIcon,
  formatDateTime,
  sortByDateDescending,
} from "../../../utils/Common";
import { depositConstants } from "../DepositCashSettlementContainer/constants";
import { depositStyle } from "../DepositListContainer/style";
import { TableIcons } from "../../../assets/icons/Icons";

export const depositListTableColumns = [
  { field: "no", header: "no", width: "3%" },
  { field: "id", header: "id", width: "7%" },
  { field: "site", header: "site", width: "7%" },
  { field: "username", header: "username", width: "10%" },
  { field: "account", header: "account", width: "7%" },
  { field: "amount", header: "amount", width: "10%" },
  { field: "utrNumber", header: "utr number", width: "10%" },
  { field: "type", header: "type", width: "8%" },
  { field: "status", header: "status", width: "7%" },
  { field: "action", header: "action", width: "12%" },
  { field: "date", header: "date", width: "6%" },
  { field: "approvedAt", header: "approved at", width: "8%" },
  { field: "rejectedAt", header: "rejected at", width: "8%" },
];

export const depositHistoryTableColumns = [
  { field: "no", header: "no", width: "3%" },
  { field: "id", header: "id", width: "7%" },
  { field: "site", header: "site", width: "7%" },
  { field: "username", header: "username", width: "5%" },
  { field: "master", header: "master", width: "5%" },
  { field: "addedBy", header: "added by", width: "7%" },
  { field: "amount", header: "amount", width: "10%" },
  { field: "type", header: "type", width: "8%" },
  { field: "utrNumber", header: "utr number", width: "10%" },
  { field: "status", header: "status", width: "7%" },
  { field: "action", header: "action", width: "9%" },
  { field: "date", header: "date", width: "6%" },
  { field: "approvedAt", header: "approved at", width: "8%" },
  { field: "rejectedAt", header: "rejected at", width: "8%" },
];

export const utrPendingTableColumns = [
  { field: "no", header: "no", width: "3%" },
  { field: "id", header: "id", width: "10%" },
  { field: "master", header: "master", width: "10%" },
  { field: "addedBy", header: "added by", width: "10%" },
  { field: "account", header: "account", width: "10%" },
  { field: "utrNumber", header: "utr number", width: "7%" },
  { field: "amount", header: "amount", width: "10%" },
  { field: "status", header: "status", width: "10%" },
  { field: "date", header: "date", width: "15%" },
  { field: "action", header: "action", width: "15%" },
];

export const utrRemovedTableColumns = [
  { field: "no", header: "no", width: "5%" },
  { field: "id", header: "id", width: "10%" },
  { field: "removedBy", header: "removed by", width: "15%" },
  { field: "master", header: "master", width: "15%" },
  { field: "utrNumber", header: "utr number", width: "15%" },
  { field: "amount", header: "amount", width: "15%" },
  { field: "rejectedAt", header: "date", width: "25%" },
];

export const getDepositStatus = (status) => {
  switch (status) {
    case 0:
      return (
        <Badge
          title={depositConstants.pending}
          className={classes.badge}
          style={{ ...depositStyle.badge, ...depositStyle.badgeYellow }}
        />
      );
    case 1:
      return (
        <Badge
          title={depositConstants.approved}
          className={classes.badge}
          style={{ ...depositStyle.badge, ...depositStyle.badgeStatusGreen }}
        />
      );
    case 2:
      return (
        <Badge
          title={depositConstants.rejected}
          className={classes.badge}
          style={{ ...depositStyle.badge, ...depositStyle.badgeStatusRed }}
        />
      );
    default:
      return null;
  }
};

//As we were not getting any index or serial no. from backend
export const formatDepositListData = (data, dateField, renderActions) => {
  return (
    data?.length &&
    sortByDateDescending(
      data?.map((val) => ({
        ...val,
        created: val?.created ? formatDateTime(val.created.toString()) : null,
      })),
      "created"
    )?.map((item, index) => {
      return {
        ...item,
        no: index + 1,
        [dateField]: item.created,
        status: getDepositStatus(item?.status),
        action: renderActions(item),
        type: item?.type,
        username: (
          <>
            <p style={{ marginBottom: 0 }}>
              {item.client?.username}
              <CopyToClipboardIcon
                value={item?.client?.username}
                icon={TableIcons.copyIcon}
                style={depositStyle.iconCopy}
              />
            </p>
          </>
        ),
        utrNumber: (
          <>
            <p style={{ marginBottom: 0 }}>
              {item.utrNumber}
              <CopyToClipboardIcon
                value={item?.utrNumber}
                icon={TableIcons.copyIcon}
                style={depositStyle.iconCopy}
              />
            </p>
          </>
        ),
        amount: (
          <>
            <p style={{ marginBottom: 0 }}>
              {item?.amount}
              <CopyToClipboardIcon
                value={item?.amount}
                icon={TableIcons.copyIcon}
                style={depositStyle.iconCopy}
              />
            </p>
          </>
        ),
        account:
          item?.bankAccount && Object?.keys(item?.bankAccount)?.length > 0 ? (
            <>
              <p style={depositStyle.accountDetailsText}>
                A/C Name: {item?.bankAccount?.account_name}
                <CopyToClipboardIcon
                  value={`A/C Name: ${item?.bankAccount?.account_name}\nA/C No: ${item?.bankAccount?.account_number}\nIFSC Code: ${item?.bankAccount?.ifsc_code}`}
                  icon={TableIcons.copyIcon}
                  style={depositStyle.iconCopy}
                />
              </p>

              <p style={depositStyle.accountDetailsText}>
                A/C No: {item?.bankAccount?.account_number}
              </p>
              <p style={depositStyle.accountDetailsText}>
                IFSC Code: {item?.bankAccount?.ifsc_code}
              </p>
            </>
          ) : (
            ""
          ),
        approvedAt: item?.approvedAt
          ? formatDateTime(item?.approvedAt?.toString())
          : "-",
        rejectedAt: item?.rejectedAt
          ? formatDateTime(item?.rejectedAt.toString())
          : "-",
        imageUrl: item?.imageUrl,
        addedBy: item?.addedBy?.username ? item?.addedBy?.username : "",
        master: item?.client.master || "-",
      };
    })
  );
};
