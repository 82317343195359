import {
  GET_BANK_ACCOUNT_SUCCESS,
  GET_BANK_ACCOUNT_FAIL,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAIL,
  GET_BANK_ACCOUNT_BY_ID_SUCCESS,
  GET_BANK_ACCOUNT_BY_ID_FAIL,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_FAIL,
  UPDATE_BANK_ACCOUNT_STATUS_SUCCESS,
  UPDATE_BANK_ACCOUNT_STATUS_FAIL,
  GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_SUCCESS,
  GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_FAIL,
  GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_SUCCESS,
  GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_FAIL,
  UPDATE_ACTIVE_ACCOUNTS_DATA,
  UPDATE_DISABLED_FILTERED_DATA,
  SET_SEARCH_CRITERIA,
  GET_BANK_ACCOUNT_STATEMENT_SUCCESS,
  GET_BANK_ACCOUNT_STATEMENT_FAIL,
  GET_BANK_ACCOUNT_STATEMENT_BY_ID,
} from "./actionType";

const initialState = {
  accountAdded: false,
  errorMessage: null,
  bankAccounts: [],
  bankAccountById: {},
  bankAccountDepositHistoryById: [{}],
  bankAccountWithdrawalHistoryById: [{}],
  filteredDisabledData: [],
  filteredActiveData: [],
  criteria: {},
  bankAccountStatements: [],
};

const bankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountAdded: true,
        errorMessage: null,
      };
    case ADD_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        accountAdded: false,
        errorMessage: action.payload,
      };
    case GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankAccounts: action.payload,
        errorMessage: null,
      };
    case GET_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GET_BANK_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        bankAccountById: action.payload,
        errorMessage: null,
      };
    case GET_BANK_ACCOUNT_BY_ID_FAIL:
      return {
        ...state,
        bankAccountById: null,
        errorMessage: action.payload,
      };
    case GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        bankAccountDepositHistoryById: action.payload,
        errorMessage: null,
      };
    case GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_FAIL:
      return {
        ...state,
        bankAccountDepositHistoryById: null,
        errorMessage: action.payload,
      };
    case GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        bankAccountWithdrawalHistoryById: action.payload,
        errorMessage: null,
      };
    case GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_FAIL:
      return {
        ...state,
        bankAccountWithdrawalHistoryById: null,
        errorMessage: action.payload,
      };
    case UPDATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankAccounts: state.bankAccounts.map((account) =>
          account.id === action.payload.id ? action.payload : account
        ),
        errorMessage: null,
      };
    case UPDATE_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case UPDATE_BANK_ACCOUNT_STATUS_SUCCESS:
      // Map through bankAccounts to update the status of the account with the matching ID
      const updatedBankAccounts = state.bankAccounts.map((account) => {
        if (account.id === action.payload.id) {
          return {
            ...account,
            status: action.payload.status,
          };
        }
        return account;
      });
      return {
        ...state,
        bankAccounts: updatedBankAccounts,
        errorMessage: null,
      };
    case UPDATE_BANK_ACCOUNT_STATUS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case UPDATE_ACTIVE_ACCOUNTS_DATA:
      return {
        ...state,
        filteredActiveData: action.payload,
      };
    case UPDATE_DISABLED_FILTERED_DATA:
      return {
        ...state,
        filteredDisabledData: action.payload,
      };
    case SET_SEARCH_CRITERIA:
      return {
        ...state,
        criteria: action.payload,
      };
    case GET_BANK_ACCOUNT_STATEMENT_SUCCESS:
      return {
        ...state,
        bankAccountStatements: action.payload,
        errorMessage: null,
      };
    case GET_BANK_ACCOUNT_STATEMENT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GET_BANK_ACCOUNT_STATEMENT_BY_ID:
      return {
        ...state,
        bankAccountStatements: action.payload,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default bankAccountReducer;
