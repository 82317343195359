import React from "react";
import CardHeader from "../../../../components/molecules/CardHeader";
import { withdrawConstants } from "../constants";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomInput from "../../../../components/atoms/CustomInput/CustomInput";
import { classes, inputTypes } from "../../../../constants/CommonConstants";
import { withdrawStyle } from "../style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";

const WithdrawCashSettlementForm = (props) => {
  const {
    withdrawalCashSettlement,
    formErrors,
    renderError,
    handleAddWithdrawalCashSettlement,
    onHandleInputChange,
    bankAccounts,
    onHandleSelect,
    CREATEPERMISSION = true,
  } = props;
  const accountOptions = bankAccounts?.map((account) => ({
    value: account.id,
    label: account.account_name,
  }));
  return (
    <form onSubmit={handleAddWithdrawalCashSettlement}>
      <CardHeader cardHeaderTitle={withdrawConstants.cashSettlement} />
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <CustomLabel
                label={withdrawConstants.account}
                additionalStyles={withdrawStyle.label}
              />
              <SearchBox
                options={accountOptions}
                className={`${formErrors["account"] && classes.invalid}`}
                onChange={(value) => onHandleSelect("account", value[0]?.text)}
                error={renderError("account")}
                placeholder={withdrawConstants.selectAccount}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={withdrawConstants.amount}
                additionalStyles={withdrawStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                className={`${classes.inputClass} ${
                  formErrors["amount"] && classes.invalid
                }`}
                placeholder={withdrawConstants.enterAmount}
                value={withdrawalCashSettlement?.amount}
                onChange={(e) => onHandleInputChange("amount", e)}
                error={renderError("amount")}
              />
            </div>
          </div>
        </div>
      </div>
      <CardFooter
        title={withdrawConstants.add}
        className={`${classes.btn} btn-hover`}
        CREATEPERMISSION={CREATEPERMISSION}
      />
    </form>
  );
};

export default WithdrawCashSettlementForm;
