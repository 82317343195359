import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  getUser,
  editUserAction,
  resetPasswordAction,
} from "../redux/user/action";
import {
  formatDateTime,
  getValidateValue,
  sortByDateDescending,
  getAuthData,
  statusUserFilter,
} from "../utils/Common";
import Badge from "../components/atoms/Badge";
import { formError, userFormError } from "../constants/ErrorMessage";
import { loginOptions } from "../containers/ExtraUsersContainer/AddUsersContainer/constants";
import { searchSubmit, updateFilteredData } from "../redux/filter/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { URLs } from "../routes/URLs";
import { usersStyle } from "../containers/ExtraUsersContainer/ActiveUsersContainer/style";
import { usersContants } from "../containers/ExtraUsersContainer/ActiveUsersContainer/constants";
import Button from "./../components/atoms/Button/Button";
import { classes, passwordRegexComp } from "../constants/CommonConstants";
import {
  generateformattedUsersList,
  handleCopy,
} from "../containers/ExtraUsersContainer/utils/UserUtils";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";
import Modal from "../components/molecules/Modal";

export const useUsersHook = (isAddUser = true) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const { users, resetPassword } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [addNewUser, setAddNewUser] = useState({
    name: "",
    username: "",
    password: "",
    role: null,
    no_of_logins: loginOptions[0].value,
    status: null,
  });

  const selectRef = useRef(null);
  const [editUser, setEditUser] = useState({
    name: "",
    username: "",
    password: "",
    role: null,
    no_of_logins: loginOptions[0].value,
    status: null,
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    username: "",
    password: "",
    role: null,
    status: null,
  });
  const [activeUsers, setActiveUsers] = useState([]);
  const [inActiveUsers, setInActiveUsers] = useState([]);

  const getEditUserData = () => {
    const data = JSON.parse(localStorage.getItem("userData"));
    setEditUser({
      name: data.name,
      username: data.username,
      password: data.password,
      role: data.role === "Deposit Manager" ? "1" : "2",
      no_of_logins: parseInt(data.logins),
      status: data.active === 1 ? 1 : 0,
    });
  };

  const getFormattedData = (arr) => {
    return sortByDateDescending(
      arr.map((client) => ({
        ...client,
        created: formatDateTime(client?.created),
        active: client?.active ? (
          <Badge
            title={usersContants.active}
            className={`${classes.badge} btn-hover`}
            style={{ ...usersStyle.badge, ...usersStyle.badgeGreen }}
          />
        ) : (
          <Badge
            title={usersContants.inactive}
            className={classes.badge}
            style={{ ...usersStyle.badge, ...usersStyle.badgeRed }}
          />
        ),
        action: renderActions(client),
      })),
      "created"
    ).map((client, index) => ({
      ...client,
      no: index + 1,
    }));
  };

  const validateForm = (formData) => {
    const errors = {};
    if (formData.name.length === 0) {
      errors.name = userFormError.name;
    }
    if (formData.username.length === 0) {
      errors.username = userFormError.username;
    }
    if (formData.password.length === 0) {
      errors.password = userFormError.password;
    }
    if (formData.role === null) {
      errors.role = userFormError.role;
    }
    if (formData.status === null) {
      errors.status = userFormError.status;
    }
    setFormErrors(errors);
    return getValidateValue(errors, userFormError);
  };

  const renderError = (field) => {
    return formErrors[field];
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const { token } = getAuthData();
    await dispatch(
      getUser(token, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (isMounted.current) {
      isAddUser && fetchUsers();
    }
    return () => {
      isMounted.current = false;
    };
  }, [fetchUsers, isAddUser]);

  useEffect(() => {
    setActiveUsers(users.filter(statusUserFilter([1])));
    setInActiveUsers(users.filter(statusUserFilter([0])));
  }, [users]);

  const handleAddUser = async (e) => {
    e.preventDefault();
    if (
      getValidateValue(formErrors, userFormError) &&
      validateForm(addNewUser)
    ) {
      setBtnLoading(true);
      try {
        let obj = {
          ...addNewUser,
          logins: addNewUser.no_of_logins,
          active: addNewUser.status,
          parentId: localStorage.getItem("userId"),
          role: addNewUser.role === "Deposit Manager" ? "1" : "2",
        };
        delete obj["no_of_logins"];
        delete obj["status"];
        const { token } = getAuthData();
        await dispatch(
          addUser(
            obj,
            () => navigate(URLs.users),
            token,
            () => {
              dispatch({
                type: LOGOUT_SUCCESS,
                payload: [],
              });
              navigate("/");
            }
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setBtnLoading(false);
      }
    } else {
      toast.error(formError.error);
    }
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    if (validateForm(editUser)) {
      setBtnLoading(true);
      try {
        const data = JSON.parse(localStorage.getItem("userData"));
        let obj = {
          ...editUser,
          logins: editUser.no_of_logins,
          active: editUser.status,
          id: data.created,
        };
        delete obj["no_of_logins"];
        delete obj["status"];
        delete obj["role"];
        delete obj["password"];
        const { token } = getAuthData();
        await dispatch(
          editUserAction(
            obj,
            () => navigate(URLs.users),
            token,
            () => {
              dispatch({
                type: LOGOUT_SUCCESS,
                payload: [],
              });
              navigate("/");
            }
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setBtnLoading(false);
      }
    } else {
      toast.error(formError.error);
    }
  };

  const onHandleInputChange = (property, e, check = "addNewUser") => {
    if (check === "editUser") {
      setEditUser({
        ...editUser,
        [property]: e.target.value,
      });
    } else {
      setAddNewUser({
        ...addNewUser,
        [property]: e.target.value,
      });
    }

    if (property === "name") {
      const bankRegex = /^(?=.*[A-Za-z]{2})[A-Za-z]+(?:\s[A-Za-z]+)*$/;
      const name = e.target.value;
      if (!name.length || bankRegex.test(name)) {
        setFormErrors({ ...formErrors, name: "" });
      } else {
        setFormErrors({
          ...formErrors,
          name: userFormError.nameCondition,
        });
      }
    } else if (property === "username") {
      const bankRegex = /^[a-zA-Z]{2,}[0-9]*$/;
      const username = e.target.value;
      if (!username.length || bankRegex.test(username)) {
        setFormErrors({ ...formErrors, username: "" });
      } else {
        setFormErrors({
          ...formErrors,
          username: userFormError.usernameCondition,
        });
      }
    } else if (property === "password") {
      const password = e.target.value;
      if (!password.length || passwordRegexComp.test(password)) {
        setFormErrors({ ...formErrors, password: "" });
      } else {
        setFormErrors({
          ...formErrors,
          password: userFormError.passwordCondition,
        });
      }
    }
  };

  const onHandleSelect = (property, value, check = "addNewUser") => {
    if (check === "editUser") {
      setEditUser({
        ...editUser,
        [property]: value,
      });
    } else {
      setAddNewUser({
        ...addNewUser,
        [property]: value,
      });
    }

    setFormErrors({
      ...formErrors,
      [property]: "",
    });
    if (property === "role" && check === "activeUserSearch") {
      const filteredUsers = activeUsers.filter((user) => {
        console.log("value inside active", value);
        console.log("user inside active", user);
        return user.role === value;
      });
      dispatch(updateFilteredData(getFormattedData(filteredUsers)));
      dispatch(searchSubmit());
    } else if (property === "role" && check === "inActiveUserSearch") {
      const filteredUsers = inActiveUsers.filter((user) => {
        return user.role === value;
      });
      dispatch(
        updateFilteredData(
          generateformattedUsersList(filteredUsers, renderActions)
        )
      );
      dispatch(searchSubmit());
    }
  };

  const getUserData = (userList, id) => {
    for (let i = 0; i < userList.length; i++) {
      if (userList[i].created === id) {
        localStorage.setItem("userData", JSON.stringify(userList[i]));
      }
    }
  };

  const openEditUser = (item) => {
    localStorage.setItem(
      parseInt(item.active) === 1 ? "editActiveUserId" : "editInactiveUserId",
      item?.id
    );
    getUserData(activeUsers, item?.id);
    navigate(`${URLs.users}/edit`);
  };

  const CopyPasswordTrigger = async (item) => {
    try {
      setShowModal(true);
      setIsResetting(true);
      const { token } = getAuthData();
      let obj = {
        id: item?.id,
        username: item?.username,
      };
      await dispatch(
        resetPasswordAction(
          obj,
          // () => document.querySelector(".hidden-trigger").click(),
          token,
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          }
        )
      );
      setIsResetting(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderActions = (item) => (
    <>
      <Button
        style={usersStyle.btnAction}
        className={classes.btn}
        title={usersContants.btnEdit}
        onClick={() => openEditUser(item)}
      />
      <Button
        style={{ ...usersStyle.btnAction }}
        className={classes.btn}
        title={usersContants.rp}
        onClick={() => CopyPasswordTrigger(item)}
      />
      <Modal
        show={showModal}
        handleClose={handleCloseModal}
        modalFooterPrimaryTitle={usersContants.close}
        modalFooterSecondaryTitle={usersContants.copyPassword}
        handleSave={() => {
          handleCopy(resetPassword?.password);
          handleCloseModal();
        }}
      >
        <div className="row">
          <div className="col-12">
            <h2>{usersContants.clickToCopy}</h2>
            <p className="mb-2">
              {isResetting
                ? `${usersContants.resettingPass}`
                : `${usersContants.newPassword}${resetPassword?.password} (${resetPassword?.username})`}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );

  const formattedUsersData = generateformattedUsersList(
    activeUsers,
    renderActions
  );
  const formattedInactiveUsersData = generateformattedUsersList(
    inActiveUsers,
    renderActions
  );

  //Handle reset
  const handleReset = (e) => {
    e.preventDefault();
    if (selectRef.current) {
      selectRef.current.value = "Select role";
    }
    dispatch(updateFilteredData(getFormattedData(activeUsers)));
  };

  return {
    formattedUsersData,
    loading,
    addNewUser,
    formErrors,
    renderError,
    onHandleInputChange,
    handleAddUser,
    onHandleSelect,
    btnLoading,
    formattedInactiveUsersData,
    editUser,
    getEditUserData,
    handleEditUser,
    handleReset,
    selectRef,
  };
};

export default useUsersHook;
