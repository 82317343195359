import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import {
    accountTypeOptions,
    addAccountConstant,
    depositOptions,
    statusOptions,
    withdrawlOptions,
} from "../constants";
import CardHeader from "../../../../components/molecules/CardHeader";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import { addAccountStyle } from "../style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import { useAccountsHook } from "../../../../hooks/accounts-hook";

// 3 LINE INPUT FORM
const AddAccountForm = () => {
    const {
        onHandleSelect,
        onHandleInputChange,
        account,
        formErrors,
        renderError,
        handleAddAccount,
        buttonLoading,
    } = useAccountsHook(false);

    return (
        <form onSubmit={handleAddAccount}>
            <CardHeader cardHeaderTitle={addAccountConstant.addAccount} />
            <div className="card-body">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.accountType}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomSelectBox
                                options={accountTypeOptions}
                                className={`${classes.selectBox} ${formErrors["account_type"] && classes.invalid
                                    }`}
                                onChange={(value) =>
                                    onHandleSelect("account_type", value.label)
                                }
                                error={renderError("account_type")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.onlyForWithdrawal}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomSelectBox
                                options={withdrawlOptions}
                                onChange={(value) =>
                                    onHandleSelect("purpose", value?.label)
                                }
                                className={`${classes.selectBox} ${formErrors["purpose"] && classes.invalid
                                    }`}
                                error={renderError("purpose")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.bankName}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomInput
                                type={inputTypes.text}
                                className={`${classes.inputClass} ${formErrors["bank_name"] && classes.invalid
                                    }`}
                                placeholder={addAccountConstant.enterBank}
                                value={account.bank_name}
                                onChange={(e) => onHandleInputChange("bank_name", e)}
                                error={renderError("bank_name")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.name}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomInput
                                type={inputTypes.text}
                                className={`${classes.inputClass} ${formErrors["account_name"] && classes.invalid
                                    }`}
                                placeholder={addAccountConstant.enterName}
                                value={account.account_name}
                                onChange={(e) => onHandleInputChange("account_name", e)}
                                error={renderError("account_name")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.accountNumber}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomInput
                                type={inputTypes.numeric}
                                className={`${classes.inputClass} ${formErrors["account_number"] && classes.invalid
                                    }`}
                                placeholder={addAccountConstant.enterAccountNumber}
                                value={account.account_number}
                                onChange={(e) => onHandleInputChange("account_number", e)}
                                error={renderError("account_number")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.ifsc}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomInput
                                type={inputTypes.numeric}
                                className={`${classes.inputClass} ${formErrors["ifsc_code"] && classes.invalid
                                    }`}
                                placeholder={addAccountConstant.enterIfsc}
                                value={account.ifsc_code}
                                onChange={(e) => onHandleInputChange("ifsc_code", e)}
                                error={renderError("ifsc_code")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.minimumAmount}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomInput
                                type={inputTypes.numeric}
                                className={`${classes.inputClass} ${formErrors["min_amount"] && classes.invalid
                                    }`} placeholder={addAccountConstant.enterMinimum}
                                value={account.min_amount}
                                onChange={(e) => onHandleInputChange("min_amount", e)}
                                error={renderError("min_amount")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.maximumAmount}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomInput
                                type={inputTypes.numeric}
                                className={`${classes.inputClass} ${formErrors["max_amount"] && classes.invalid
                                    }`}
                                placeholder={addAccountConstant.enterMaximum}
                                value={account.max_amount}
                                onChange={(e) => onHandleInputChange("max_amount", e)}
                                error={renderError("max_amount")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.maximumDeposit}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomSelectBox
                                options={depositOptions}
                                className={`${classes.selectBox} ${formErrors["max_deposit_amount"] && classes.invalid
                                    }`}
                                onChange={(value) =>
                                    onHandleSelect("max_deposit_amount", value.label)
                                }
                                error={renderError("max_deposit_amount")}
                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <CustomLabel
                                label={addAccountConstant.qrCode}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomInput
                                type={inputTypes.file}
                                className={`${classes.selectBox} ${formErrors["qrCode"] && classes.invalid
                                    }`}
                                placeholder={addAccountConstant.noFile}
                                onChange={(e) => onHandleInputChange("qrCode", e)}
                                accept={inputTypes.acceptType}
                                // error={renderError("qr_code")}
                                id="fileAddInput"
                            />
                        </div>
                        <div className="col-md-4">
                            <CustomLabel
                                label={addAccountConstant.status}
                                additionalStyles={addAccountStyle.label}
                            />
                            <CustomSelectBox
                                options={statusOptions}
                                className={`${classes.selectBox} ${formErrors["status"] && classes.invalid
                                    }`}
                                defaultValue=""
                                onChange={(value) => onHandleSelect("status", value.value)}
                                error={renderError("status")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CardFooter
                title={addAccountConstant.add}
                className={`${classes.btn} btn-hover`}
                isLoading={buttonLoading}
                loaderStyle={addAccountStyle.loading}
            />
        </form>
    );
};

export default AddAccountForm;