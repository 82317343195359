export const accountsConstants = {
    user: "User:",
    acName: "A/C Name:",
    enterName: "Enter name:",
    acNumber: "A/C Number:",
    enterNumber: "Enter Number:",
    amountRange: "Max Amount Range:",
    status: "Status:",
    search: "Search",
    active: "Active",
    inactive: "Inactive",
    disable: "Disable",
    depositHistory: "Deposit history",
    withdrawHistory: "Withdraw history",
    selectUser: "Select User",
    reset: "Reset",
    edit: "Edit",
};

export const maxAmountOptions = [
    { value: "", label: "Select Range" },
    { value: 0, label: "Both" },
    { value: 1, label: "Below 5000" },
    { value: 2, label: "Above 5000" },
];

export const statusOptions = [
    { value: "", label: "Select status" },
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
    { value: 2, label: "Disabled" },
];



