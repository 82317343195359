import { color } from "../../../themes/Colors";

export const modalStyle = {
  modalFooterBtn: {
    backgroundColor: color._0e8d84,
    color: color._ffffff,
  },
  loading: {
    marginRight: "5px",
  },
  header: {
    backgroundColor: "#0e8d84",
    color: "#ffffff",
  },
  btnClose: {
    "--bs-btn-close-bg": "none",
  },
};
