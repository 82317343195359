import React from 'react'
import SearchForm from './helper/SearchForm'
import { depositStyle } from './style'
import AccountDespositHistoryList from './helper/AccountDepositHistoryList'
import useAccountsHook from '../../../hooks/accounts-hook'

const AccountDepositHistoryContainer = () => {
    const {
        formattedAccountDepositHistoryData,
        loading,
        openAccountDepositHistory,
        handleAccountDepositFilterSelect,
        accountDepositFilteredData,
        handleAccountDepositReset,
        statusRef,
        typeRef,
        accountDepositFilters,
        handleAccountDepositSearch,
        handleAccountDepositFilterInput
    } = useAccountsHook(false);

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='card p-2' style={depositStyle.container}>
                        <SearchForm
                            handleAccountDepositFilterSelect={handleAccountDepositFilterSelect}
                            handleAccountDepositReset={handleAccountDepositReset}
                            statusRef={statusRef}
                            typeRef={typeRef}
                            accountDepositFilters={accountDepositFilters}
                            handleAccountDepositSearch={handleAccountDepositSearch}
                            handleAccountDepositFilterInput={handleAccountDepositFilterInput}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-2 mt-2">
                    <div className="card table-responsive">
                        <AccountDespositHistoryList
                            formattedAccountDepositHistoryData={formattedAccountDepositHistoryData} //api data
                            loading={loading}
                            openAccountDepositHistory={openAccountDepositHistory}
                            accountDepositFilteredData={accountDepositFilteredData}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountDepositHistoryContainer