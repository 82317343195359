import React from "react";
import { paginationConstant } from "./constants";
import Button from "../../atoms/Button";

const Pagination = ({ currentPage, totalPages, onPageChange, setCurrentPage }) => {
    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination pagination-sm justify-content-start mb0-pt10">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <Button className="page-link bg-light" onClick={() => setCurrentPage(currentPage - 1)} title={paginationConstant.previous} />
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                        <Button className="page-link" onClick={() => onPageChange(i + 1)} title={i + 1} />
                    </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <Button className="page-link" onClick={() => setCurrentPage(currentPage + 1)} title={paginationConstant.next} />
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
