import React, { useState } from "react";
import { inputBox } from "./style";
import ReusableIcon from "../ReusableIcon";
import { PasswordIcons } from "../../../assets/icons/Icons";
import { classes, inputTypes } from "../../../constants/CommonConstants";

const CustomInput = (props) => {
  const {
    type,
    name,
    value,
    id,
    placeholder,
    ariaLabel,
    className,
    ariaDescribedBy,
    onChange,
    error,
    accept,
    dropdownOptions,
    useInputGroupClass = true,
    passwordToggleIconStyle,
    disabled,
    inputRef = null,
    showIcon = true,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputGroupClass = useInputGroupClass
    ? "input-group input-group-sm"
    : "";
  const handleDropdownItemClick = (selectedValue) => {
    const event = {
      target: {
        name: name,
        value: selectedValue,
      },
    };
    onChange(event);
  };

  return (
    <div className={`${inputGroupClass}`} style={inputBox.inputGroup}>
      <input
        type={
          type === inputTypes.password && showPassword ? inputTypes.text : type
        }
        className={className}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedBy}
        name={name}
        value={value}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        accept={accept}
        disabled={disabled}
        ref={inputRef}
      />
      {type === inputTypes.password &&
        showIcon &&
        !className.includes(classes.invalid) && (
          <span
            onClick={togglePasswordVisibility}
            style={{
              ...inputBox.passwordToggleIcon,
              ...passwordToggleIconStyle,
            }}
          >
            {showPassword ? (
              <ReusableIcon icon={PasswordIcons.visiblityOffIcon} />
            ) : (
              <ReusableIcon icon={PasswordIcons.visibleIcon} />
            )}
          </span>
        )}
      {error && (
        <div className="invalid-feedback fw-bolder" style={inputBox.errorStyle}>
          {error}
        </div>
      )}
      {dropdownOptions && dropdownOptions.length > 0 && (
        <ul
          className="dropdown-menu"
          style={{
            display: "block",
            position: "absolute",
            top: "100%",
            left: 0,
            width: "auto",
            zIndex: 1000,
          }}
        >
          {dropdownOptions.map((option, index) => (
            <li
              key={index}
              className="dropdown-item"
              onClick={() => handleDropdownItemClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomInput;
