import React from "react";
import UtrPendingList from "./helper/UtrPendingList";
import useDepositHook from "../../../hooks/deposit-hook";
import { useSelector } from "react-redux";
import { getPermission, getRole } from "../../../utils/Common";
import { usePermissions } from "../../../permissionhook";
const UtrPendingListContainer = (props) => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();

  const DELETEPERMISSION = getPermission(
    permissions,
    "DEPOSITPENDINGLIST",
    "DELETEPERMISSION",
    getRole(role)
  );

  const { formattedPendingDepositData, loading } = useDepositHook(
    false,
    false,
    true,
    false,
    true,
    DELETEPERMISSION
  );

  return (
    <>
      <div className="row">
        <div className="col-12 pb-2 mt-2">
          <div className="card table-responsive">
            <UtrPendingList
              formattedPendingDepositData={formattedPendingDepositData}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UtrPendingListContainer;
