import React from 'react';
import SearchForm from './helper/SearchForm';
import ActiveUsersList from './helper/ActiveUsersList';
import useUsersHook from '../../../hooks/users-hook';

const ActiveUsersContainer = () => {
    const { onHandleSelect, handleReset, formattedUsersData, loading,selectRef } = useUsersHook();
    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body pb-2">
                            <SearchForm
                                onHandleSelect={onHandleSelect}
                                handleReset={handleReset}
                                selectRef={selectRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-2">
                    <div className="card table-responsive">
                        <ActiveUsersList
                            formattedUsersData={formattedUsersData}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </>

    );
};

export default ActiveUsersContainer;