// Importing Dependencies.
import React from "react";
import ChangePasswordForm from "./helper/ChangePasswordForm";
import { changePasswordStyle } from "./style";

// Defining ChangePasswordContainer Function.
const ChangePasswordContainer = () => {
  return (
    <>
      <div className='row'>
        <div className='col-md-9'>
          <div className='card card-primary p-2' style={changePasswordStyle.container}>
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </>

  );
};

// Exporting Dashboard Container Function.
export default ChangePasswordContainer;
