import { DashboardIcons } from "../../assets/icons/Icons";
import ReusableIcon from "../../components/atoms/ReusableIcon";
import { color } from "../../themes/Colors";
import { dashboardStyle } from "./style";

export const dashboardConstants = {
  welcome: "Welcome",
  newClient: "New Clients : ",
  depositList: "Deposit list",
  utrEntry: "UTR Entry",
  withdraw: "Withdraw",
  addAccount: "Add Account",
  accountList: "Account list",
  globalSearch: "Global search",
  numericFigure: 0,
  totalFtdCount: "Total FTD : 0",
  icon: (
    <ReusableIcon
      icon={DashboardIcons.paymentIcon}
      iconColor={color._000}
      style={dashboardStyle.btnIcon}
    />
  ),
  infoIcon: (
    <ReusableIcon
      icon={DashboardIcons.moreInfoIcon}
      iconColor={color._ffffff}
      style={dashboardStyle.btnIcon}
    />
  ),
};

export const btnConstants = {
  btnTitle: "Click Here",
  btnClass: "btn btn-success btn-sm mt-1 w-auto",
};
