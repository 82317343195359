import { color } from "../../themes/Colors";

export const globalSearchStyle = {
    searchBtn: {
        width: "100%",
        height: "30px",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: 'none',
        color: color._ffffff,
        fontWeight: 400,
    },

    imgSize: {
        width: "104px",
        height: "40px",
    },

    card1: {
        backgroundColor: color._1a3da6,
        color: color._ffffff,
    },

    card2: {
        background: `linear-gradient(180deg, ${color._5f7fdb} 0, ${color._0cddff} 100%)`,
        color: color._ffffff,
    },

    card3: {
        backgroundColor: color._ffa724,
        color: color._ffffff,
    },

    card4: {
        backgroundColor: color._167abf,
        color: color._ffffff,
    },
    btn: {
        border: `1px solid ${color._ffffff}`,
        backgroundColor: color._027970,
        color: color._ffffff,
        width: "100%",
        borderRadius: "2px",
        textTransform: "uppercase",
        padding: "2px"
    },
    btnSearch: {
        border: `1px solid ${color._ffffff}`,
        backgroundColor: "transparent",
        color: color._ffffff,
        width: "100%",
        borderRadius: "2px",
        textTransform: "uppercase",
        padding: "2px",
    },
    icon: {
        marginTop: "-4px",
        marginRight: "2px"
    },
    modalLabel: {
        fontSize: "14px",
        fontWeight: 400,
        marginBottom: 0,
        color: color._000
    },
    labelInfo: {
        fontSize: "11px",
        fontWeight: 400,
        marginBottom: 0,
        color: color._6d6d6d
    },
    badge: {
        marginRight: "5px",
        padding: "8px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 400,
        cursor: "pointer",
        borderRadius: "2px",
    },
    badgeRed: {
        backgroundColor: color._f44336,
        fontWeight: 600,
    },
    badgeRedSecondary: {
        backgroundColor: color._red,
        fontWeight: 600,
    },
    badgeGreen: {
        backgroundColor: color._green,
        fontWeight: 600,
    },
    currentBal:{ fontWeight: 700 }
};

