import {
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DATA_SUCCESS,
} from "./actionTypes";

const initialState = {
  dashboard: {},
  errorMessage: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        errorMessage: null,
      };
    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        dashboard: {},
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
