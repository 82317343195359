export const accountsConstants = {
    user: "User:",
    acName: "A/C Name:",
    enterName: "Enter name:",
    acNumber: "A/C Number:",
    enterNumber: "Enter Number:",
    status: "Status:",
    search: "Search",
    reset: "Reset",
    selectUser: "Select User",
}
export const statusOptions = [
    { value: "", label: "Select status" },
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
    { value: 2, label: "Disabled" },
]


