// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import AddClientContainer from '../../../containers/ClientsContainer/AddClientContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const AddClient = () => {

  // Returning AddClient Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.addClients} />
      <Layout heading={layoutHeading.clients}>
        <AddClientContainer />
      </Layout>
    </>
  );
};

// Exporting AddClient Function.
export default AddClient;
