import { ADD_CLIENT_FAIL, ADD_CLIENT_SUCCESS, GET_CLIENT_FAIL, GET_CLIENT_SUCCESS } from "./actionType";

const initialState = {
    clientAdded: false,
    errorMessage: null,
    clients: []
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CLIENT_SUCCESS:
            return {
                ...state,
                clientAdded: true,
                errorMessage: null
            };
        case ADD_CLIENT_FAIL:
            return {
                ...state,
                clientAdded: false,
                errorMessage: action.payload
            };
        case GET_CLIENT_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                errorMessage: null
            };
        case GET_CLIENT_FAIL:
            return {
                ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }
};

export default clientReducer;
