import React from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { withdrawStyle } from "../style";
import { withDrawHistoryTableColumns } from "../../utils/WithdrawalUtils";
import BlankRecord from "../../../../components/organisms/CustomTable/BlankRecord";

const WithdrawHistoryList = (props) => {
  const { formattedWithdrawalsHistoryData, loading } = props;
  return (
    <>
      {formattedWithdrawalsHistoryData === 0 ||
        !formattedWithdrawalsHistoryData ? (
        <BlankRecord
          data={[]}
          columns={withDrawHistoryTableColumns}
          style={withdrawStyle.table}
        />
      ) : (
        <CustomTable
          data={
            formattedWithdrawalsHistoryData?.length &&
            formattedWithdrawalsHistoryData
          }
          columns={withDrawHistoryTableColumns}
          // rows={formattedWithdrawalsHistoryData.length}
          rows={25}  //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
          style={withdrawStyle.table}
          loading={loading}
        />
      )}
    </>
  );
};

export default WithdrawHistoryList;
