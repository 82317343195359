import { TableIcons } from "../../../assets/icons/Icons";
import Badge from "../../../components/atoms/Badge";
import { classes } from "../../../constants/CommonConstants";
import {
  CopyToClipboardIcon,
  formatDateTime,
  sortByDateDescending,
} from "../../../utils/Common";
import { withdrawConstants } from "../WithdrawListContainer/constants";
import { withdrawStyle } from "../WithdrawListContainer/style";

export const withdrawListTableColumns = [
  { field: "no", header: "no", width: "3%" },
  { field: "id", header: "id", width: "8%" },
  { field: "site", header: "site", width: "10%" },
  { field: "username", header: "username", width: "10%" },
  { field: "account", header: "account", width: "7%" },
  { field: "amount", header: "amount", width: "10%" },
  { field: "type", header: "type", width: "10%" },
  { field: "status", header: "status", width: "7%" },
  { field: "action", header: "action", width: "9%" },
  { field: "created", header: "date", width: "10%" },
  { field: "aproved_at", header: "approved at", width: "8%" },
  { field: "rejected_at", header: "rejected at", width: "8%" },
];

export const withDrawHistoryTableColumns = [
  { field: "no", header: "no", width: "3%" },
  { field: "id", header: "id", width: "7%" },
  { field: "site", header: "site", width: "7%" },
  { field: "username", header: "username", width: "5%" },
  { field: "master", header: "master", width: "5%" },
  { field: "account", header: "account", width: "7%" },
  { field: "amount", header: "amount", width: "10%" },
  { field: "type", header: "type", width: "8%" },
  { field: "utrNumber", header: "utr number", width: "10%" },
  { field: "status", header: "status", width: "7%" },
  { field: "action", header: "action", width: "9%" },
  { field: "date", header: "date", width: "6%" },
  { field: "aproved_at", header: "approved at", width: "8%" },
  { field: "rejected_at", header: "rejected at", width: "8%" },
];

export const getWithdrawalStatus = (status) => {
  switch (status) {
    case 0:
      return (
        <Badge
          title={withdrawConstants.pending}
          className={classes.badge}
          style={{ ...withdrawStyle.badge, ...withdrawStyle.badgeYellow }}
        />
      );
    case 1:
      return (
        <Badge
          title={withdrawConstants.approved}
          className={classes.badge}
          style={{ ...withdrawStyle.badge, ...withdrawStyle.badgeStatusGreen }}
        />
      );
    case 2:
      return (
        <Badge
          title={withdrawConstants.rejected}
          className={classes.badge}
          style={{ ...withdrawStyle.badge, ...withdrawStyle.badgeStatusRed }}
        />
      );
    default:
      return null;
  }
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getType = (type) => {
  if (type === "1" || type === "2" || type === "3")
    switch (type) {
      case "1":
        return "Manual";
      case "2":
        return "Client";
      case "3":
        return "UTR Pending";
      default:
        return null;
    }
  return capitalizeFirstLetter(type);
};

//As we were not getting any index or serial no. from backend
export const formatWithdrawalsListData = (data, renderActions) => {
  return (
    data?.length &&
    sortByDateDescending(
      data?.map((withdrawal) => ({
        ...withdrawal,
        created: formatDateTime(withdrawal?.created),
        status: getWithdrawalStatus(withdrawal?.status),
        action: renderActions(withdrawal),
        type: withdrawal?.type,
        username: (
          <>
            <p style={{ marginBottom: 0 }}>
              {withdrawal.client?.username}
              <CopyToClipboardIcon
                value={withdrawal?.client?.username}
                icon={TableIcons.copyIcon}
                style={withdrawStyle.iconCopy}
              />
            </p>
          </>
        ),
        amount: (
          <>
            <p style={{ marginBottom: 0 }}>
              {withdrawal.amount}
              <CopyToClipboardIcon
                value={withdrawal?.amount}
                icon={TableIcons.copyIcon}
                style={withdrawStyle.iconCopy}
              />
            </p>
          </>
        ),
        account:
          withdrawal?.clientBank &&
          Object?.keys(withdrawal?.clientBank)?.length > 0 ? (
            <>
              <p style={withdrawStyle.accountDetailsText}>
                A/C Name: {withdrawal?.clientBank?.accountName}
                <CopyToClipboardIcon
                  value={`Account Id: ${withdrawal?.accountId}\nUsername: ${withdrawal?.addedBy?.username}\nA/C Name: ${withdrawal?.clientBank?.accountName}\nA/C No: ${withdrawal?.clientBank?.accountNumber}\nIFSC Code: ${withdrawal?.clientBank?.ifscCode}\nAmount: ${withdrawal?.amount}`}
                  icon={TableIcons.copyIcon}
                  style={withdrawStyle.iconCopy}
                />
              </p>

              <p style={withdrawStyle.accountDetailsText}>
                A/C No: {withdrawal?.clientBank?.accountNumber}
              </p>
              <p style={withdrawStyle.accountDetailsText}>
                IFSC Code: {withdrawal?.clientBank?.ifscCode}
              </p>
            </>
          ) : (
            "-"
          ),
        aproved_at: withdrawal?.approvedAt
          ? formatDateTime(withdrawal?.approvedAt)
          : "-",
        rejected_at: withdrawal?.rejectedAt
          ? formatDateTime(withdrawal?.rejectedAt)
          : "-",
      })),
      "created"
    ).map((item, index) => ({
      ...item,
      no: index + 1,
    }))
  );
};

export const formatWithdrawalsHistoryListData = (data) => {
  return (
    data?.length &&
    sortByDateDescending(
      data?.map((withdrawal) => ({
        ...withdrawal,
        created: formatDateTime(withdrawal?.created),
        date: formatDateTime(withdrawal?.created),
        status: getWithdrawalStatus(withdrawal?.status),
        utrNumber: withdrawal.utr_number || "-",
        // action: renderActions(withdrawal),
        type: getType(withdrawal?.type),
        username: (
          <>
            <p style={{ marginBottom: 0 }}>
              {withdrawal.client?.username}
              <CopyToClipboardIcon
                value={withdrawal?.client?.username}
                icon={TableIcons.copyIcon}
                style={withdrawStyle.iconCopy}
              />
            </p>
          </>
        ),
        amount: (
          <>
            <p style={{ marginBottom: 0 }}>
              {withdrawal.amount}
              <CopyToClipboardIcon
                value={withdrawal?.amount}
                icon={TableIcons.copyIcon}
                style={withdrawStyle.iconCopy}
              />
            </p>
          </>
        ),
        account: withdrawal?.clientBank ? (
          <>
            <p style={withdrawStyle.accountDetailsText}>
              A/C Name: {withdrawal?.clientBank?.accountName}
              <CopyToClipboardIcon
                value={`Account Id: ${withdrawal?.accountId}\nUsername: ${withdrawal?.addedBy?.username}\nA/C Name: ${withdrawal?.clientBank?.accountName}\nA/C No: ${withdrawal?.clientBank?.accountNumber}\nIFSC Code: ${withdrawal?.clientBank?.ifscCode}\nAmount: ${withdrawal?.amount}`}
                icon={TableIcons.copyIcon}
                style={withdrawStyle.iconCopy}
              />
            </p>
            <p style={withdrawStyle.accountDetailsText}>
              A/C No: {withdrawal?.clientBank?.accountNumber}
            </p>
            <p style={withdrawStyle.accountDetailsText}>
              IFSC Code: {withdrawal?.clientBank?.ifscCode}
            </p>
          </>
        ) : (
          ""
        ),
        aproved_at: withdrawal?.approvedAt
          ? formatDateTime(withdrawal?.approvedAt)
          : "-",
        rejected_at: withdrawal?.rejectedAt
          ? formatDateTime(withdrawal?.rejectedAt)
          : "-",
        master: withdrawal.client.master,
      })),
      "created"
    ).map((item, index) => ({
      ...item,
      no: index + 1,
    }))
  );
};
