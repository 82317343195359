import React, { useEffect } from "react";
import { clientStyle } from "./style";
import SearchForm from "./helper/SearchForm";
import ClientList from "./helper/ClientList";
import useClientsHook from "../../../hooks/clients-hook";

const ClientListContainer = () => {
  const {
    clientsTableColumns,
    formattedSortedClientsData,
    loading,
    handleSearchSubmit,
    handleChange,
    handleReset,
    onHandleSelect,
    filters,
    dropdownOptions,
    formErrors,
    renderError,
    formChanged,
    domains,
    selectSourceRef,
    formSubmitState,
    selectDepositCountRef,
    formattedClientsData,
  } = useClientsHook();

  return (
    <>
      <div className="row">
        <div className="col-12 pb-2">
          <div className="card p-2" style={clientStyle.container}>
            <SearchForm
              handleSearchSubmit={handleSearchSubmit}
              onHandleSelect={onHandleSelect}
              filters={filters}
              dropdownOptions={dropdownOptions}
              handleChange={handleChange}
              formChanged={formChanged}
              formErrors={formErrors}
              renderError={renderError}
              handleReset={handleReset}
              domains={domains}
              selectSourceRef={selectSourceRef}
              formSubmitState={formSubmitState}
              selectDepositCountRef={selectDepositCountRef}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2">
          <div className="card table-responsive">
            <ClientList
              clientsTableColumns={clientsTableColumns}
              formattedClientsData={formattedClientsData}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientListContainer;
