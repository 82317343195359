import React from 'react';
import AddUserForm from './helper/AddUserForm';
import { addUserStyle } from './style';

const AddUsersContainer = () => {
    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card card-primary p-2' style={addUserStyle.container}>
                        <AddUserForm />
                    </div>
                </div>
            </div>
        </>

    );
};

export default AddUsersContainer;