// Importing Dependencies.
import React from "react";
import useLoginHook from "../../hooks/login-hook";
import { loginStyles } from "./style";
import Button from "../../components/atoms/Button";
import { loginConstants } from "./constants";
import { loginImage } from "../../assets/images";
import {
  buttonTypes,
  classes,
  inputTypes,
} from "../../constants/CommonConstants";
import CustomInput from "../../components/atoms/CustomInput";

// Declaring Login Function.
const Login = () => {
  // Declaring variables and functions from login hook.
  const {
    onChangeUserName,
    onChangePassword,
    handleLogin,
    credentials,
    loading,
    renderError,
    formErrors,
  } = useLoginHook();

  // Returning Login Page.
  return (
    <div style={loginStyles.container}>
      <div className="container">
        <div className="d-flex justify-content-center mb-2">
          <img
            className="img-fluid"
            src={loginImage}
            style={loginStyles.imgSize}
            alt="loginImage"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="card shadow rounded-0" style={loginStyles.cardSize}>
            <div className="card-body">
              <div className="text-center">
                <p className="text-secondary">{loginConstants.LoginTitle}</p>
              </div>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <CustomInput
                    type={inputTypes.text}
                    className={`${classes.inputClass} ${classes.shadowNone} ${
                      formErrors["username"] && classes.invalid
                    }`}
                    id="username"
                    placeholder={loginConstants.Username}
                    value={credentials.username}
                    onChange={onChangeUserName}
                    useInputGroupClass={false}
                    error={renderError("username")}
                  />
                </div>
                <div className="mb-3">
                  <CustomInput
                    type={inputTypes.password}
                    className={`${classes.inputClass} ${classes.shadowNone} ${
                      formErrors["password"] && classes.invalid
                    }`}
                    id="password"
                    placeholder={loginConstants.Password}
                    value={credentials?.password}
                    onChange={onChangePassword}
                    useInputGroupClass={false}
                    passwordToggleIconStyle={loginStyles.passwordToggleIcon}
                    error={renderError("password")}
                  />
                </div>
                <Button
                  type={buttonTypes.submit}
                  title={loginConstants.SignIn}
                  className={"btn btn-primary"}
                  isLoading={loading}
                  status={loginConstants.Status}
                  loaderStyle={loginStyles.loading}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Exporting Login Function.
export default Login;
