import React, { useEffect } from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { depositStyle } from "../style";
import { accountDepositHistoryTableColumns } from "../../utils/AccountUtils";
import BlankRecord from "../../../../components/organisms/CustomTable/BlankRecord";

const AccountWithdrawalHistoryList = (props) => {
  const {
    formattedAccountWithdrawalHistoryData,
    loading,
    openAccountWithdrawalHistory,
    accountWithdrawalFilteredData
  } = props;
  useEffect(() => {
    const item = localStorage.getItem("withdrawId");
    if (item) {
      openAccountWithdrawalHistory({ id: item });
    }
  }, []);
  return (
    <>{accountWithdrawalFilteredData === 0 || accountWithdrawalFilteredData === undefined ? <BlankRecord data={[]} columns={accountDepositHistoryTableColumns}
      style={depositStyle.table} /> : <CustomTable
      data={
        accountWithdrawalFilteredData.length > 0
          ? accountWithdrawalFilteredData
          : formattedAccountWithdrawalHistoryData
      }
      columns={accountDepositHistoryTableColumns}
      rows={25}
      style={depositStyle.table}
      loading={loading}
    />}
    </>

  );
};

export default AccountWithdrawalHistoryList;
