import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccount,
  getBankAccount,
  getBankAccountDepositHistoryById,
  getBankAccountWithdrawHistoryById,
  updateAccount,
  updateAccountStatus,
  setSearchCriteria,
  updateActiveAccountsData,
  updateDisableFilteredData,
} from "../redux/bankAccount/action";
import {
  convertToBase64,
  getAuthData,
  getValidateValue,
  sortByDateDescending,
  statusFilter,
} from "../utils/Common";
import {
  accountsConstants,
  maxAmountOptions,
} from "../containers/AccountsContainer/AccountListContainer/constants";
import { classes } from "../constants/CommonConstants";
import { accountsStyle } from "../containers/AccountsContainer/AccountListContainer/style";
import { useNavigate } from "react-router-dom";
import { URLs } from "../routes/URLs";
import ReusableIcon from "../components/atoms/ReusableIcon";
import Badge from "../components/atoms/Badge";
import { TableIcons } from "../assets/icons/Icons";
import { accountFormError } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { formError } from "./../constants/ErrorMessage";
import { _get } from "../services/HttpMethods";
import { endPoints } from "../services/EndPoints";
import {
  GET_BANK_ACCOUNT_BY_ID_FAIL,
  GET_BANK_ACCOUNT_BY_ID_SUCCESS,
} from "../redux/bankAccount/actionType";
import {
  accountDepositHistoryTableData,
  formatActiveAccountsData,
  formatDisabledAccountsData,
  formatHistoryData,
  formatWithdrawalHistoryData,
  statusLabelToValueMap,
} from "../containers/AccountsContainer/utils/AccountUtils";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";
import _ from "lodash";
import Swal from "sweetalert2";

export const useAccountsHook = (isAddAccount = true) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    bankAccounts,
    bankAccountById,
    bankAccountDepositHistoryById,
    bankAccountWithdrawalHistoryById,
  } = useSelector((state) => state.bankAccount);
  const [activeBanks, setActiveBanks] = useState([]);
  const [disabledBanks, setDisabledBanks] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedMaxDeposit, setSelectedMaxDeposit] = useState("");
  const [accountFilters, setAccountFilters] = useState({
    username: null,
    accountName: "",
    accountNumber: "",
    maxDepositAmount: null,
    status: null,
  });
  const [accountDepositFilters, setAccountDepositFilters] = useState({
    status: "",
    type: "",
    username: "",
    utrNumber: "",
  });
  const [accountWithdrawalFilters, setAccountWithdrawalFilters] = useState({
    status: "",
    type: "",
    username: "",
    utr_number: "",
  });
  const [accountDepositFilteredData, setAccountDepositFilteredData] = useState(
    []
  ); //rn using dummy data, need api data
  const [accountWithdrawalFilteredData, setAccountWithdrawalFilteredData] =
    useState([]); //rn using dummy data, need api data

  const resetStatusRef = useRef(null);
  const resetMaxDepositRef = useRef(null);
  const statusRef = useRef(null);
  const typeRef = useRef(null);

  const [formErrors, setFormErrors] = useState({
    account_name: "",
    purpose: "",
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    min_amount: "",
    max_amount: "",
    max_deposit_amount: null,
    status: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [qrCodeFile, setQrCodeFile] = useState(null);
  const [searchAccountFormValues, setSearchAccountFormValues] = useState({
    selectedUser: "",
    accountName: "",
    accountNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [account, setAccount] = useState({
    account_type: "",
    purpose: "",
    bank_name: "",
    account_name: "",
    account_number: "",
    ifsc_code: "",
    min_amount: "",
    max_amount: "",
    max_deposit_amount: null,
    status: null,
  });

  const [editAccount, setEditAccount] = useState({
    account_type: bankAccountById?.account_type,
    purpose: bankAccountById?.added_by?.for,
    bank_name: bankAccountById?.bank_name,
    account_name: bankAccountById?.account_name,
    account_number: bankAccountById?.account_number,
    ifsc_code: bankAccountById?.ifsc_code,
    min_amount: bankAccountById?.min_amount,
    max_amount: bankAccountById?.max_amount,
    max_deposit_amount: bankAccountById?.max_deposit_amount,
    qrCode: bankAccountById?.qrCode?.filename,
    status: bankAccountById?.status,
    preview: bankAccountById?.qrCode?.url,
  });

  const openPreview = () => {
    Swal.fire({
      imageUrl: bankAccountById?.qrCode?.url,
      imageHeight: 400,
      imageAlt: "Preview",
      showCloseButton: true,
      customClass: {
        confirmButton: "proof-class",
        closeButton: "proof-close-btn",
      },
    });
  };

  const validateForm = (obj) => {
    const errors = {};
    if (
      obj.account_type.length === 0 ||
      obj.account_type === "--Select Account type--"
    ) {
      errors.account_type = accountFormError.accountType;
    }
    if (obj?.purpose?.length === 0 || obj.purpose === "--Select Purpose--") {
      errors.purpose = accountFormError.purpose;
    }
    if (obj.bank_name.length === 0) {
      errors.bank_name = accountFormError.bankName;
    }
    if (obj?.account_name.length === 0) {
      errors.account_name = accountFormError.accountName;
    }
    if (obj?.account_number.length === 0) {
      errors.account_number = accountFormError.accountNumber;
    }
    if (obj?.ifsc_code.length === 0) {
      errors.ifsc_code = accountFormError.ifsc;
    }
    if (obj?.min_amount.length === 0) {
      errors.min_amount = accountFormError.minAmount;
    }
    if (obj?.max_amount.length === 0) {
      errors.max_amount = accountFormError.maxAmount;
    }
    if (
      obj?.max_deposit_amount === null ||
      obj?.max_deposit_amount === "--Select Amount Range--"
    ) {
      errors.max_deposit_amount = accountFormError.maxDeposit;
    }
    if (obj.status === null || obj?.status === "--Select Status--") {
      errors.status = accountFormError.status;
    }
    setFormErrors(errors);

    return getValidateValue(errors, accountFormError);
  };

  // Function to display errors
  const renderError = (field) => {
    return formErrors[field];
  };
  const { token } = getAuthData();
  const isMounted = useRef(true);

  const fetchBankAccounts = useCallback(async () => {
    setLoading(true);
    await dispatch(
      getBankAccount(token, {}, false, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch, token]);

  useEffect(() => {
    if (isMounted.current) {
      isAddAccount && fetchBankAccounts();
    }
    return () => {
      isMounted.current = false;
    };
  }, [fetchBankAccounts, isAddAccount]);

  useEffect(() => {
    setActiveBanks(bankAccounts?.filter(statusFilter([0, 1])));
    setDisabledBanks(bankAccounts?.filter(statusFilter([2])));
  }, [bankAccounts]);

  const getBankAccountById = async (id) => {
    try {
      const response = await _get(endPoints.bankAccount, id, token);
      setEditAccount({
        account_type: response.data?.account_type,
        purpose: response.data?.added_by?.for,
        bank_name: response.data?.bank_name,
        account_name: response.data?.account_name,
        account_number: response.data?.account_number,
        ifsc_code: response.data?.ifsc_code,
        min_amount: response.data?.min_amount,
        max_amount: response.data?.max_amount,
        max_deposit_amount: response.data?.max_deposit_amount,
        qrCode: response.data?.qrCode?.filename,
        status: response.data?.status,
        preview: response.data?.qrCode?.url,
      });
      dispatch({
        type: GET_BANK_ACCOUNT_BY_ID_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_BANK_ACCOUNT_BY_ID_FAIL,
        payload: error.message,
      });
    }
  };

  const openEditAccount = async (item) => {
    localStorage.setItem("editId", item?.id);
    await getBankAccountById(item?.id);
    navigate(`${URLs.accountsList}/edit`);
  };
  const openAccountDepositHistory = (item) => {
    setLoading(true);
    // This will be uncommented once api data is available for this module, rn there is no data for this
    if (isMounted.current) {
      dispatch(
        getBankAccountDepositHistoryById(item?.id, token, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    }
    localStorage.setItem("depositId", item?.id);
    navigate(`${URLs.accountsList}/deposit-history`);
    setLoading(false);
    return () => {
      isMounted.current = false;
    };
  };
  const openAccountWithdrawalHistory = (item) => {
    setLoading(true);
    // This will be uncommented once api data is available for this module, rn there is no data for this
    if (isMounted.current) {
      dispatch(
        getBankAccountWithdrawHistoryById(item?.id, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    }
    localStorage.setItem("withdrawId", item?.id);
    navigate(`${URLs.accountsList}/withdrawals-history`);
    setLoading(false);
    return () => {
      isMounted.current = false;
    };
  };

  const handleAddAccount = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileAddInput");
    const file = fileInput.files[0];
    if (validateForm(account)) {
      setButtonLoading(true);
      try {
        if (file) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64String_ = reader.result.split(",")[1];
            const accountVariable = {
              ...account,
              qrCode: {
                name: file.name,
                contentType: file.type,
                data: base64String_,
              },
            };
            setButtonLoading(true);
            await dispatch(
              addAccount(
                accountVariable,
                () => navigate(URLs.accountsList),
                token,
                () => {
                  dispatch({
                    type: LOGOUT_SUCCESS,
                    payload: [],
                  });
                  navigate("/");
                }
              )
            );
            setButtonLoading(false);
          };
          reader.readAsDataURL(file);
        } else {
          const accountVariable = {
            ...account,
            // },
          };
          setButtonLoading(true);
          await dispatch(
            addAccount(
              accountVariable,
              () => navigate(URLs.accountsList),
              token,
              () => {
                dispatch({
                  type: LOGOUT_SUCCESS,
                  payload: [],
                });
                navigate("/");
              }
            )
          );
          setButtonLoading(false);
        }
      } catch (error) {
        console.error("Error converting QR code to base64:", error);
      } finally {
        setButtonLoading(false);
      }
    } else {
      toast.error(formError.error);
    }
  };

  const handleEditAccount = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileEditInput");
    const file = fileInput.files[0];
    setFormSubmitted(true);
    if (
      getValidateValue(formErrors, accountFormError) &&
      validateForm(editAccount)
    ) {
      try {
        if (file) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64String_ = reader.result.split(",")[1];
            const accountId = bankAccountById.id;
            const updatedAccount = {
              ...editAccount,
              qrCode: {
                name: file.name,
                contentType: file.type,
                data: base64String_,
              },
            };
            setButtonLoading(true);
            await dispatch(
              updateAccount(
                accountId,
                updatedAccount,
                () => navigate(URLs.accountsList),
                token,
                () => {
                  dispatch({
                    type: LOGOUT_SUCCESS,
                    payload: [],
                  });
                  navigate("/");
                }
              ),
              token
            );
            setButtonLoading(false);
          };
          reader.readAsDataURL(file);
        } else {
          const accountId = bankAccountById.id;
          const updatedAccount = { ...editAccount };
          setButtonLoading(true);
          await dispatch(
            updateAccount(
              accountId,
              updatedAccount,
              () => navigate(URLs.accountsList),
              token,
              () => {
                dispatch({
                  type: LOGOUT_SUCCESS,
                  payload: [],
                });
                navigate("/");
              }
            ),
            token
          );
          setButtonLoading(false);
        }
      } catch (error) {
        console.error("Error converting QR code to base64:", error);
      } finally {
        setButtonLoading(false);
      }
    } else {
      toast.error(formError.error);
    }
  };
  const onHandleSelect = (property, value) => {
    setAccount({
      ...account,
      [property]: value,
    });
    setEditAccount({
      ...editAccount,
      [property]: value,
    });

    if (property === "account_type") {
      if (value !== "--Select Account type--") {
        setFormErrors({ ...formErrors, account_type: "" });
      } else {
        setFormErrors({
          ...formErrors,
          account_type: accountFormError.accountType,
        });
      }
    }
    if (property === "purpose") {
      if (value !== "--Select Purpose--") {
        setFormErrors({ ...formErrors, purpose: "" });
      } else {
        setFormErrors({
          ...formErrors,
          purpose: accountFormError.purpose,
        });
      }
    }

    if (property === "max_deposit_amount") {
      if (value !== "--Select Amount Range--") {
        setFormErrors({ ...formErrors, max_deposit_amount: "" });
      } else {
        setFormErrors({
          ...formErrors,
          max_deposit_amount: accountFormError.maxDeposit,
        });
      }
    }
    if (property === "status") {
      if (value !== "") {
        setFormErrors({ ...formErrors, status: "" });
      } else {
        setFormErrors({
          ...formErrors,
          status: accountFormError.status,
        });
      }
    }
  };

  const formErrorsCheck = (property, value) => {

    if (property === "bank_name") {
      const bankRegex = /^(?=.*[A-Za-z]{3})[A-Za-z]+(?:\s[A-Za-z]+)*$/;
      const bankName = value.value;
      if (!bankName.length || bankRegex.test(bankName)) {
        setFormErrors({ ...formErrors, bank_name: "" });
      } else {
        setFormErrors({
          ...formErrors,
          bank_name: accountFormError.bankName1,
        });
      }
    }

    if (property === "account_name") {
      const accnRegex = /^(?=.*[A-Za-z]{2})[A-Za-z]+(?:\s[A-Za-z]+)*$/;
      const account_name = value.value;
      if (!account_name.length || accnRegex.test(account_name)) {
        setFormErrors({ ...formErrors, account_name: "" });
      } else {
        setFormErrors({
          ...formErrors,
          account_name: accountFormError.bankName1,
        });
      }
    }

    if (property === "account_number") {
      const accRegex = /^\d{11,16}$/;
      const account_number = value.value;
      if (!account_number.length || accRegex.test(account_number)) {
        setFormErrors({ ...formErrors, account_number: "" });
      } else {
        setFormErrors({
          ...formErrors,
          account_number: accountFormError.accountNumber1,
        });
      }
    }

    if (property === "ifsc_code") {
      const ifscRegex = /^[A-Z]{4}[0-9]{7}$/;
      const ifsc_code = value.value;
      if (!ifsc_code.length || ifscRegex.test(ifsc_code)) {
        setFormErrors({ ...formErrors, ifsc_code: "" });
      } else {
        setFormErrors({
          ...formErrors,
          ifsc_code: accountFormError.ifsc1,
        });
      }
    }

    if (property === "min_amount") {
      const minRegex = /^(?!0\d{2})[0-9]{3,8}$/;
      const min_amount = value.value;
      if (
        !min_amount ||
        minRegex.test(min_amount) ||
        parseInt(min_amount) < parseInt(account.max_amount)
      ) {
        setFormErrors({ ...formErrors, min_amount: "" });
      }
      if (min_amount < 100) {
        setFormErrors({
          ...formErrors,
          min_amount: accountFormError.minAmount1,
        });
      }
      if (
        account.max_amount.length &&
        parseInt(account.max_amount) <= parseInt(min_amount)
      ) {
        setFormErrors({
          ...formErrors,
          min_amount: accountFormError.minAmountError,
        });
      }
      const isNumber = isNaN(min_amount) && min_amount.trim() !== "";
      if (isNumber) {
        setFormErrors({
          ...formErrors,
          min_amount: accountFormError.minAmountInvalid,
        });
      }

      // Check if min_amount is equal to max_amount
      if (
        account.max_amount &&
        parseInt(min_amount) === parseInt(account.max_amount)
      ) {
        setFormErrors({
          ...formErrors,
          min_amount: accountFormError.minMaxSame,
        });
      }
    }

    if (property === "max_amount") {
      const maxRegex = /^\d+$/;
      const max_amount = value.value;
      if (
        !max_amount.length ||
        maxRegex.test(max_amount) ||
        parseInt(max_amount) > parseInt(account.min_amount)
      ) {
        setFormErrors({ ...formErrors, max_amount: "" });
      }
      if (
        account.min_amount !== "" &&
        parseInt(max_amount) <= parseInt(account.min_amount)
      ) {
        setFormErrors({
          ...formErrors,
          max_amount: accountFormError.maxAmount1,
        });
      }
      if (max_amount.length && !maxRegex.test(max_amount)) {
        setFormErrors({
          ...formErrors,
          max_amount: accountFormError.maxAmountInvalid,
        });
      }
      if (
        account.min_amount !== "" &&
        parseInt(account.min_amount) < parseInt(account.max_amount)
      ) {
        setFormErrors({
          ...formErrors,
          min_amount: "",
        });
      }

      // Check if min_amount is equal to max_amount
      if (
        account.min_amount &&
        parseInt(max_amount) === parseInt(account.min_amount)
      ) {
        setFormErrors({
          ...formErrors,
          max_amount: accountFormError.minMaxSame,
        });
      }
    }
  };


  const onHandleInputChange = (property, e) => {
    const { type, files, value } = e.target;
    if (type === "file") {
      const file = files[0];
      // setEditAccount({
      //   ...editAccount,
      //   [property]: file.name, // or file to keep the file object
      // });
    } else {
      const newValue = property === "ifsc_code" ? value.toUpperCase() : value;
      setAccount({
        ...account,
        [property]: newValue,
      });
      setEditAccount({
        ...editAccount,
        [property]: value,
      });
    }

    formErrorsCheck(property, e.target);
  };

  const handleAccountStatusUpdate = async (item) => {
    setLoading(true);
    const status = item.status === 0 ? 1 : 0;
    await dispatch(
      updateAccountStatus(item.id, { status: status }, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  };
  const handleAccountDisbaledStatusUpdate = async (item) => {
    setLoading(true);
    let status = 2;
    if (item.status === 2) {
      status = 1;
    }
    await dispatch(
      updateAccountStatus(item.id, { status }, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  };

  const renderActions = (item) => (
    <>
      <ReusableIcon
        icon={TableIcons.editIcon}
        style={accountsStyle.icon}
        onClick={() => openEditAccount(item)}
        toolTip={accountsConstants.edit}
        className="btn-hover"
      />
      <Badge
        title={
          item.status === 0
            ? accountsConstants.active
            : accountsConstants.inactive
        }
        className={
          item.status === 0
            ? `${classes.badge} btn-hover`
            : `${classes.badge} inactive-hover`
        }
        style={{
          ...accountsStyle.badgeAction,
          ...(item.status === 0
            ? accountsStyle.badgeActionGreen
            : accountsStyle.badgeActionRed),
        }}
        onClick={() => handleAccountStatusUpdate(item)}
      />
      <Badge
        title={
          item.status === 2
            ? accountsConstants.active
            : accountsConstants.disable
        }
        className={`${classes.badge} warning-hover`}
        style={{
          ...accountsStyle.badgeAction,
          ...(item.status === 2
            ? accountsStyle.badgeActionGreen
            : item.status === 1
            ? accountsStyle.badgeYellowAction
            : accountsStyle.badgeYellowAction),
        }}
        onClick={() => handleAccountDisbaledStatusUpdate(item)}
      />
      <Badge
        title={accountsConstants.depositHistory}
        className={`${classes.badge} btn-hover`}
        style={{
          ...accountsStyle.badgeAction,
          ...accountsStyle.badgeActionGreen,
        }}
        onClick={() => openAccountDepositHistory(item)}
      />
      <Badge
        title={accountsConstants.withdrawHistory}
        className={`${classes.badge} btn-hover`}
        style={{
          ...accountsStyle.badgeAction,
          ...accountsStyle.badgeActionGreen,
        }}
        onClick={() => openAccountWithdrawalHistory(item)}
      />
    </>
  );
  const renderDisableActions = (item) => (
    <>
      <Badge
        title={
          item.status === 2
            ? accountsConstants.active
            : accountsConstants.disable
        }
        className={`${classes.badge} btn-hover`}
        style={{
          ...accountsStyle.badgeAction,
          ...(item.status === 2
            ? accountsStyle.badgeActionGreen
            : item.status === 1
            ? accountsStyle.badgeYellowAction
            : accountsStyle.badgeYellowAction),
        }}
        onClick={() => handleAccountDisbaledStatusUpdate(item)}
      />
      <Badge
        title={accountsConstants.depositHistory}
        className={`${classes.badge} btn-hover`}
        style={{
          ...accountsStyle.badgeAction,
          ...accountsStyle.badgeActionGreen,
        }}
        onClick={() => openAccountDepositHistory(item)}
      />
      <Badge
        title={accountsConstants.withdrawHistory}
        className={`${classes.badge} btn-hover`}
        style={{
          ...accountsStyle.badgeAction,
          ...accountsStyle.badgeActionGreen,
        }}
        onClick={() => openAccountWithdrawalHistory(item)}
      />
    </>
  );

  const formattedAccountDepositHistoryData = formatHistoryData(
    bankAccountDepositHistoryById, //rn api data not available
    // accountDepositHistoryTableData, //dummy data dont remove as of now
    accountsConstants,
    accountsStyle
  );

  const formattedAccountWithdrawalHistoryData = formatWithdrawalHistoryData(
    bankAccountWithdrawalHistoryById, //rn api data not available
    // accountDepositHistoryTableData,//dummy data
    accountsConstants,
    accountsStyle
  );

  //As we were not getting any index or serial no. from backend
  const formattedBankAccountsData =
    activeBanks?.length &&
    sortByDateDescending(
      formatActiveAccountsData(activeBanks, renderActions),
      "created"
    )?.map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  const disabledAccountsData =
    disabledBanks?.length &&
    sortByDateDescending(
      formatDisabledAccountsData(disabledBanks, renderDisableActions),
      "created"
    ).map((item, index) => ({
      ...item,
      no: index + 1,
    }));

  const resetTableData = () => {
    dispatch(setSearchCriteria({}));
    dispatch(updateActiveAccountsData(formattedBankAccountsData)); // Reset to full data
  };

  const handleResetForDisable = () => {
    dispatch(setSearchCriteria({}));
    dispatch(updateDisableFilteredData(disabledAccountsData));
  };

  const onHandleSearchInputChange = (name, value) => {
    setSearchAccountFormValues((prevValues) => {
      const newValues = { ...prevValues, [name]: value };

      // Check if all fields are empty
      const allFieldsEmpty = Object.values(newValues).every((val) => !val);

      if (allFieldsEmpty) {
        resetTableData();
      }

      return newValues;
    });
  };

  const onHandleSearchInputChangeDisable = (name, value) => {
    setSearchAccountFormValues((prevValues) => {
      const newValues = { ...prevValues, [name]: value };

      // Check if all fields are empty
      const allFieldsEmpty = Object.values(newValues).every((val) => !val);

      if (allFieldsEmpty) {
        handleResetForDisable();
      }

      return newValues;
    });
  };

  //Handle reset
  const handleReset = (e) => {
    e.preventDefault();
    const { username, accountName, accountNumber, maxDepositAmount, status } =
      accountFilters;
    if (
      (username === null || username === "") &&
      (accountName === null || accountName === "") &&
      (accountNumber === null || accountNumber === "") &&
      (maxDepositAmount === null || maxDepositAmount === "") &&
      (status === null || status === "")
    ) {
      return;
    }
    setAccountFilters({
      username: "",
      accountName: "",
      accountNumber: "",
      maxDepositAmount: null,
      status: null,
    });
    if (resetStatusRef.current) {
      resetStatusRef.current.value = ""; // Reset the select box value
    }
    if (resetMaxDepositRef.current) {
      resetMaxDepositRef.current.value = "Select Range"; // Reset the select box value
    }
    fetchBankAccounts({}, false);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    dispatch(setSearchCriteria(searchAccountFormValues));

    const filteredActiveAccounts = activeBanks?.filter((account) => {
      const username = account.username ? account.username.toLowerCase() : "";
      const accountName = account.account_name
        ? account.account_name.toLowerCase()
        : "";
      const accountNumber = account.account_number
        ? account.account_number.toLowerCase()
        : "";
      const formSelectedUser = searchAccountFormValues.selectedUser
        ? searchAccountFormValues.selectedUser.toLowerCase()
        : "";
      const formAccountName = searchAccountFormValues.accountName
        ? searchAccountFormValues.accountName.toLowerCase()
        : "";
      const formAccountNumber = searchAccountFormValues.accountNumber
        ? searchAccountFormValues.accountNumber.toLowerCase()
        : "";
      const formStatusValue = statusLabelToValueMap[account.status];

      return (
        (searchAccountFormValues.selectedUser
          ? username.includes(formSelectedUser)
          : true) &&
        (searchAccountFormValues.accountName
          ? accountName.includes(formAccountName)
          : true) &&
        (searchAccountFormValues.accountNumber
          ? accountNumber.includes(formAccountNumber)
          : true) &&
        (searchAccountFormValues.amountRange
          ? account.max_deposit_amount === searchAccountFormValues.amountRange
          : true) &&
        (searchAccountFormValues.status
          ? account.status === formStatusValue
          : true)
      );
    });

    const formattedBankAccountsData =
      filteredActiveAccounts.length &&
      formatActiveAccountsData(filteredActiveAccounts, renderActions);

    dispatch(updateActiveAccountsData(formattedBankAccountsData));
  };

  const handleSearchForDisable = (event) => {
    event.preventDefault();
    dispatch(setSearchCriteria(searchAccountFormValues));

    const filteredDisabledAccounts = disabledBanks?.filter((account) => {
      const username = account.username ? account.username.toLowerCase() : "";
      const accountName = account.account_name
        ? account.account_name.toLowerCase()
        : "";
      const accountNumber = account.account_number
        ? account.account_number.toLowerCase()
        : "";
      const formSelectedUser = searchAccountFormValues.selectedUser
        ? searchAccountFormValues.selectedUser.toLowerCase()
        : "";
      const formAccountName = searchAccountFormValues.accountName
        ? searchAccountFormValues.accountName.toLowerCase()
        : "";
      const formAccountNumber = searchAccountFormValues.accountNumber
        ? searchAccountFormValues.accountNumber.toLowerCase()
        : "";
      const formStatusValue =
        statusLabelToValueMap[searchAccountFormValues.status];

      return (
        (searchAccountFormValues.selectedUser
          ? username.includes(formSelectedUser)
          : true) &&
        (searchAccountFormValues.accountName
          ? accountName.includes(formAccountName)
          : true) &&
        (searchAccountFormValues.accountNumber
          ? accountNumber.includes(formAccountNumber)
          : true) &&
        (searchAccountFormValues.status
          ? account.status === formStatusValue
          : true)
      );
    });

    const formattedDisabledAccountsData =
      filteredDisabledAccounts.length &&
      formatDisabledAccountsData(
        filteredDisabledAccounts,
        renderDisableActions
      );

    dispatch(updateDisableFilteredData(formattedDisabledAccountsData));
  };

  const handleStatusFilterChange = (event) => {
    const selectedValue = event.value;
    setSelectedStatus(selectedValue);
    // Get the current state of the max deposit filter
    const currentMaxDeposit = selectedMaxDeposit.label;
    let filteredData = activeBanks;
    // Apply status filter
    if (selectedValue !== "") {
      filteredData = filteredData?.filter((account) => {
        return account.status === selectedValue;
      });
    }
    // Apply max deposit filter if it is set
    if (currentMaxDeposit !== "") {
      filteredData = filteredData?.filter((account) => {
        return account.max_deposit_amount === currentMaxDeposit;
      });
    }
    // Format and dispatch the filtered data
    const formattedData =
      filteredData.length &&
      formatActiveAccountsData(filteredData, renderActions);
    dispatch(updateActiveAccountsData(formattedData));
  };

  const handleMaxDepositFilterChange = (event) => {
    const selectedValue = event.value;
    const selectedLabel = event.label;
    setSelectedMaxDeposit({ value: selectedValue, label: selectedLabel });
    // Get the current state of the status filter
    const currentStatus = selectedStatus;
    let filteredData = activeBanks;
    // Apply max deposit filter
    if (selectedValue !== "") {
      filteredData = filteredData?.filter((account) => {
        return account.max_deposit_amount === event.label;
      });
    }
    // Apply status filter if it is set
    if (currentStatus !== "") {
      filteredData = filteredData?.filter((account) => {
        return account.status === currentStatus;
      });
    }
    // Format and dispatch the filtered data
    const formattedData =
      filteredData.length &&
      formatActiveAccountsData(filteredData, renderActions);
    dispatch(updateActiveAccountsData(formattedData));
  };

  const handleSearchAccounts = async (e) => {
    e.preventDefault();
    const { username, accountName, accountNumber, maxDepositAmount, status } =
      accountFilters;
    if (
      (username === null || username === "") &&
      (accountName === null || accountName === "") &&
      (accountNumber === null || accountNumber === "") &&
      (maxDepositAmount === null || maxDepositAmount === "") &&
      (status === null || status === "")
    ) {
      return;
    }
    await dispatch(
      getBankAccount(token, accountFilters, true, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
  };

  const handleAccountInputSearch = async (e) => {
    let obj = { ...accountFilters };
    const { name, value } = e.target;
    obj[name] = value;
    setAccountFilters(obj);
    if (e.target.value.length === 0) {
      await dispatch(
        getBankAccount(token, obj, true, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    }
  };

  const handleAccountSearchableSelect = async (value, prop) => {
    let obj = { ...accountFilters };
    obj[prop] = value;
    setAccountFilters(obj);
    if (value && value === "") {
      await dispatch(
        getBankAccount(token, obj, false, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    } else {
      handleSearchAccountList(obj, true);
    }
  };

  const handleSearchAccountList = useMemo(
    () =>
      _.debounce(async (filters, showToast = true) => {
        await dispatch(
          getBankAccount(token, filters, showToast, () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          })
        );
      }, 300),
    [dispatch, token, navigate]
  );

  const handleAccountFilterChange = async (e, prop) => {
    const obj = { ...accountFilters };

    if (prop === "maxDepositAmount") {
      switch (e.value) {
        case "":
          obj[prop] = "";
          break;
        case 0:
          obj[prop] = maxAmountOptions[1].label;
          break;
        case 1:
          obj[prop] = maxAmountOptions[2].label;
          break;
        case 2:
          obj[prop] = maxAmountOptions[3].label;
          break;
        default:
          break;
      }
    } else {
      obj[prop] = e.value;
    }

    setAccountFilters(obj);

    await dispatch(
      getBankAccount(token, obj, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
  };

  const commonFilterFunction = (filterObj, arr) => {
    let filterArr = arr.filter((item) => {
      let matchesAllFilters = true;
      for (let key in filterObj) {
        console.log("filterObj", filterObj, key);
        if (filterObj[key] !== "") {
          if (
            key === "username" &&
            item?.logged_in_user[key]?.includes(filterObj[key])
          ) {
            console.log("key", item[key]);
            matchesAllFilters = true;
          } else if (
            key === "utrNumber" &&
            item[key]?.includes(filterObj[key])
          ) {
            matchesAllFilters = true;
          } else if (item[key] !== filterObj[key]) {
            matchesAllFilters = false;
          }
        }
        if (!matchesAllFilters) {
          break;
        }
      }
      return matchesAllFilters;
    });
    if (!filterArr.length) {
      return null;
    }
    return filterArr;
  };
  const commonFilterWithdrawalFunction = (filterObj, arr) => {
    let filterArr = arr.filter((item) => {
      let matchesAllFilters = true;
      for (let key in filterObj) {
        console.log("filterObj", filterObj, key);
        if (filterObj[key] !== "") {
          if (
            key === "username" &&
            item?.added_by[key]?.includes(filterObj[key])
          ) {
            console.log("key", item[key]);
            matchesAllFilters = true;
          } else if (
            key === "utr_number" &&
            item[key]?.includes(filterObj[key])
          ) {
            matchesAllFilters = true;
          } else if (item[key] !== filterObj[key]) {
            matchesAllFilters = false;
          }
        }
        if (!matchesAllFilters) {
          break;
        }
      }
      return matchesAllFilters;
    });
    if (!filterArr.length) {
      return null;
    }
    return filterArr;
  };

  const handleAccountDepositFilterSelect = (name, value) => {
    if (value === "" || value === "Select type") {
      handleAccountDepositReset();
    } else {
      if (value === "Client") {
        value = value.toString().toLowerCase();
      }
      if (value === "Manual") {
        value = value.toString().toLowerCase();
      }
      setAccountDepositFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
      let obj = {
        ...accountDepositFilters,
        [name]: value,
      };
      setAccountDepositFilteredData(
        formatHistoryData(
          commonFilterFunction(obj, bankAccountDepositHistoryById)
        )
      );
    }
  };
  const handleAccountWithdrawalFilterSelect = (name, value) => {
    if (value === "" || value === "Select type") {
      handleAccountWithdrawalReset();
    } else {
      if (value === "Client") {
        value = value.toString().toLowerCase();
      }
      if (value === "Manual") {
        value = value.toString().toLowerCase();
      }
      setAccountWithdrawalFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
      let obj = {
        ...accountWithdrawalFilters,
        [name]: value,
      };
      setAccountWithdrawalFilteredData(
        formatWithdrawalHistoryData(
          commonFilterWithdrawalFunction(obj, bankAccountWithdrawalHistoryById)
        )
      );
    }
  };

  const handleAccountDepositFilterInput = (e, fieldName) => {
    setAccountDepositFilters({
      ...accountDepositFilters,
      [fieldName]: e.target.value,
    });
    if (e.target.value === "") {
      handleAccountDepositReset();
    }
  };

  const handleAccountWithdrawalFilterInput = (e, fieldName) => {
    setAccountWithdrawalFilters({
      ...accountWithdrawalFilters,
      [fieldName]: e.target.value,
    });
    if (e.target.value === "") {
      handleAccountWithdrawalReset();
    }
  };

  const handleAccountDepositSearch = (e) => {
    e.preventDefault();
    setAccountDepositFilteredData(
      formatHistoryData(
        commonFilterFunction(
          accountDepositFilters,
          bankAccountDepositHistoryById
        )
      )
    );
  };
  const handleAccountWithdrawalSearch = (e) => {
    e.preventDefault();
    setAccountWithdrawalFilteredData(
      formatWithdrawalHistoryData(
        commonFilterWithdrawalFunction(
          accountWithdrawalFilters,
          bankAccountWithdrawalHistoryById
        )
      )
    );
  };

  const handleAccountDepositReset = () => {
    setAccountDepositFilters({ username: "", utrNumber: "" });
    if (statusRef.current) {
      statusRef.current.value = ""; // Reset the select box value
    }
    if (typeRef.current) {
      typeRef.current.value = "Select type"; // Reset the select box value
    }
    setAccountDepositFilteredData([]); // Reset data to initial state
  };
  const handleAccountWithdrawalReset = () => {
    setAccountWithdrawalFilters({ username: "", utr_number: "" });
    if (statusRef.current) {
      statusRef.current.value = ""; // Reset the select box value
    }
    if (typeRef.current) {
      typeRef.current.value = "Select type"; // Reset the select box value
    }
    setAccountWithdrawalFilteredData([]); // Reset data to initial state
  };

  return {
    formattedBankAccountsData,
    loading,
    handleAddAccount,
    onHandleSelect,
    onHandleInputChange,
    account,
    disabledAccountsData,
    editAccount,
    handleEditAccount,
    formErrors,
    renderError,
    formSubmitted,
    formChanged,
    formattedAccountDepositHistoryData,
    formattedAccountWithdrawalHistoryData,
    openEditAccount,
    openAccountDepositHistory,
    openAccountWithdrawalHistory,
    bankAccounts,
    searchAccountFormValues,
    handleReset,
    buttonLoading,
    selectedStatus,
    selectedMaxDeposit,
    accountFilters,
    handleAccountInputSearch,
    handleSearchAccounts,
    handleAccountSearchableSelect,
    handleAccountFilterChange,
    resetStatusRef,
    resetMaxDepositRef,
    openPreview,
    accountDepositFilteredData,
    handleAccountDepositFilterSelect,
    accountDepositHistoryTableData,
    handleAccountDepositReset,
    statusRef,
    typeRef,
    handleAccountDepositSearch,
    handleAccountDepositFilterInput,
    accountDepositFilters,
    handleAccountWithdrawalFilterSelect,
    accountWithdrawalFilters,
    accountWithdrawalFilteredData,
    handleAccountWithdrawalReset,
    handleAccountWithdrawalFilterInput,
    handleAccountWithdrawalSearch,
  };
};

export default useAccountsHook;
