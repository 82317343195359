import React from 'react';
import SearchForm from './helper/SearchForm';
import InactiveUsersList from './helper/InactiveUsersList';
import useUsersHook from '../../../hooks/users-hook';

const InactiveUsersContainer = () => {
    const { onHandleSelect, handleReset, formattedInactiveUsersData, loading } = useUsersHook();
    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body pb-2">
                            <SearchForm
                                onHandleSelect={onHandleSelect}
                                handleReset={handleReset}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-2">
                    <div className="card table-responsive">
                        <InactiveUsersList
                            formattedInactiveUsersData={formattedInactiveUsersData}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </>

    );
};

export default InactiveUsersContainer;