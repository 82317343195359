import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../components/molecules/PageHeader";
import { container } from "./style";
import Logo from "../components/atoms/Logo/Logo";
import ReusableIcon from "../components/atoms/ReusableIcon";
import { SideBarIcons } from "../assets/icons/Icons";
import LogoutButton from "../components/atoms/LogoutButton";
import { Link, useNavigate } from "react-router-dom";
import { sidebarItems } from "./constant";
import "./stylee.css"; // Ensure this is imported
import CloseIcon from "@mui/icons-material/Close";
import { usePermissions } from "../permissionhook";
import { getPermission, getRole } from "../utils/Common";
import { useSelector } from "react-redux";

const Layout = ({ children, heading }) => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [openColapsedmenu, setOpenColapsedmenu] = useState(true);
  const [openSubId, setOpenSubId] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    setOpenColapsedmenu(!sidebarCollapsed);
    setOpenSubmenu(null);
  };

  const handleSubmenuClick = (id) => {
    setOpenSubmenu(openSubmenu === id ? null : id);
  };

  const handleIconClick = (id) => {
    setOpenSubId(openSubId === id ? null : id);
  };

  const handleWindowSizeChange = () => {
    window.innerWidth < 991 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    document.addEventListener("mousedown", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleOutsideClick = (event) => {
    console.log("sidebarRef", sidebarRef);
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarCollapsed(true);
    }
  };

  useEffect(() => {
    if (!sidebarCollapsed) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [sidebarCollapsed]);

  const handleClose = () => {
    setSidebarCollapsed(true);
  };

  return (
    <div className="px-0" style={container.col1}>
      <div className="d-flex flex-row">
        <div ref={isMobile ? sidebarRef : {}}>
          <div
            className={`px-0 leftside-menu menuitem-active ismobile ${
              sidebarCollapsed ? "collapsed" : ""
            }`}
          >
            <div className="close_icon_mobile" onClick={() => handleClose()}>
              <CloseIcon />
            </div>

            <Logo />
            {/* <!-- Sidebar --> */}
            <div
              className="h-100 show simplebar-scrollable-y sidebar-container"
              id="leftside-menu-container"
              data-simplebar="init"
            >
              {/* <!--- Sidemenu --> */}
              <ul className="side-nav">
                {sidebarItems
                  ?.filter((i) =>
                    getRole(role)
                      ? true
                      : getPermission(permissions, i?.name, "READPERMISSOIN")
                  )
                  ?.map((item, index) => (
                    <li
                      key={index}
                      className="side-nav-item"
                      onClick={() =>
                        item.collapse && handleSubmenuClick(item.id)
                      }
                    >
                      <Link
                        data-bs-toggle={item.collapse ? "collapse" : undefined}
                        to={item.collapse ? `#${item.id}` : item.link}
                        aria-expanded="false"
                        aria-controls={item.collapse ? item.id : undefined}
                        className={`side-nav-link${
                          item.active ? " menuitem-active" : ""
                        }${item.collapse ? " collapsed" : ""}`}
                      >
                        <ReusableIcon
                          icon={item.icon}
                          iconColor={item.iconColor}
                          className="icon-class"
                          onClick={
                            sidebarCollapsed
                              ? () => handleIconClick(item.id)
                              : () => {}
                          }
                          iconBackgroundColor={item.iconBackgroundColor}
                        />
                        {!sidebarCollapsed && <span>{item.label}</span>}
                        {item.hasArrow && (
                          <span className="menu-arrow">
                            <ReusableIcon
                              icon={
                                openSubmenu === item.id
                                  ? item.downArrow
                                  : item.leftArrow
                              }
                              iconColor={item.leftIconColor}
                              iconBackgroundColor={item.iconBackgroundColor}
                            />
                          </span>
                        )}
                      </Link>
                      {item.collapse && (
                        <div
                          className={`collapse${
                            openSubmenu == item.id ? " show" : ""
                          }`}
                          id={item.id}
                        >
                          <ul className="side-nav-second-level">
                            {item.submenu
                              ?.filter((i) =>
                                getRole(role)
                                  ? true
                                  : getPermission(
                                      permissions,
                                      i?.name,
                                      "READPERMISSOIN"
                                    )
                              )
                              ?.map((subitem, subindex) => (
                                <li
                                  key={subindex}
                                  onClick={() => navigate(subitem.link)}
                                >
                                  {subitem.link ? (
                                    <Link
                                      // to={subitem.link}
                                      className="second-level-link"
                                    >
                                      <ReusableIcon
                                        icon={subitem.icon}
                                        iconColor={item.iconColor}
                                        iconBackgroundColor={
                                          item.iconBackgroundColor
                                        }
                                      />
                                      {!sidebarCollapsed && (
                                        <span>{subitem.label}</span>
                                      )}
                                    </Link>
                                  ) : (
                                    <React.Fragment>
                                      <ReusableIcon
                                        icon={subitem.icon}
                                        iconColor={item.iconColor}
                                        iconBackgroundColor={
                                          item.iconBackgroundColor
                                        }
                                      />
                                      {!sidebarCollapsed && (
                                        <span>{subitem.label}</span>
                                      )}
                                    </React.Fragment>
                                  )}
                                </li>
                              ))}
                          </ul>
                          {sidebarCollapsed && openColapsedmenu && (
                            <div className="sub-menu-hover">
                              {
                                <ul className="side-nav-second-level-hover">
                                  {item.submenu.map((subitem, subindex) => (
                                    <li key={subindex}>
                                      {subitem.link ? (
                                        <Link
                                          to={subitem.link}
                                          className="second-level-link"
                                        >
                                          <ReusableIcon
                                            icon={subitem.icon}
                                            iconColor={item.iconColor}
                                            iconBackgroundColor={
                                              item.iconBackgroundColor
                                            }
                                          />

                                          <span>{subitem.label}</span>
                                        </Link>
                                      ) : (
                                        <React.Fragment>
                                          <ReusableIcon
                                            icon={subitem.icon}
                                            iconColor={item.iconColor}
                                            iconBackgroundColor={
                                              item.iconBackgroundColor
                                            }
                                          />

                                          <span>{subitem.label}</span>
                                        </React.Fragment>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              }
                            </div>
                          )}
                        </div>
                      )}
                    </li>
                  ))}
                <li className="mt-2">
                  <div className="sidebar-footer">
                    <LogoutButton />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`container1 px-0 ${
            sidebarCollapsed ? "container-collapsed" : "container-expanded"
          }`}
        >
          <nav className="navbar navbar-light" style={container.navBar}>
            <div className="container-fluid">
              <span
                className="navbar-brand mb-0 text-white"
                style={container.spanIcon}
                onClick={toggleSidebar}
              >
                {SideBarIcons.menuIcon}
              </span>
            </div>
          </nav>
          <div className="container-fluid">
            <PageHeader heading={heading} />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
