// Importing Dependencies.
import { URLs } from "./URLs";
import Login from "../pages/Login";
import { ComponentNames } from "../constants/ComponentNames";
import Dashboard from "../pages/Dashboard/Dashboard";
import GlobalSearch from "../pages/GlobalSearch";
import AddClient from "../pages/Clients/AddClient";
import ClientList from "../pages/Clients/ClientList";
import AddAccount from "../pages/Accounts/AddAccount";
import AccountList from "../pages/Accounts/AccountList";
import DisabledAccounts from "../pages/Accounts/DisabledAccounts";
import DepositList from "../pages/Deposit/DepositList";
import DepositHistory from "../pages/Deposit/DepositHistory";
import UTRForm from "./../pages/Deposit/UTRForm";
import UTRPendingList from "./../pages/Deposit/UTRPendingList";
import UTRRemovedList from "./../pages/Deposit/UTRRemovedList";
import CashSettlement from "./../pages/Deposit/CashSettlement";
import WithdrawList from "../pages/Withdraw/WithdrawList";
import WithdrawHistory from "../pages/Withdraw/WithdrawHistory";
import Manual from "../pages/Withdraw/Manual";
import WithdrawCashSettlement from "../pages/Withdraw/WithdrawCashSettlement";
import AddUsers from "../pages/ExtraUsers/AddUsers";
import ActiveUsers from "../pages/ExtraUsers/ActiveUsers";
import InactiveUsers from "../pages/ExtraUsers/InactiveUsers";
import AccountStatement from "../pages/AccountStatement";
import Statistics from "../pages/Statistics";
import ChangePassword from "../pages/ChangePassword";
import EditAccount from "./../pages/Accounts/EditAccount";
import AccountDepositHistory from "../pages/Accounts/AccountDepositHistory";
import AccountWithdrawalHistory from "../pages/Accounts/AccountWithdrawalHistory";
import EditUsers from "../pages/ExtraUsers/EditUsers/EditUsers";

// Exporting Routes as array of objects.
export const routes = [
  {
    id: 0,
    title: ComponentNames.Login,
    path: URLs.login,
    data: <Login />,
    isProtected: false,
    permission: true,
  },
  {
    id: 1,
    title: ComponentNames.Dashboard,
    path: URLs.dashboard,
    data: <Dashboard />,
    isProtected: true,
    permission: "DASHBOARD",
  },
  {
    id: 2,
    title: ComponentNames.GlobalSearch,
    path: URLs.globalSearch,
    data: <GlobalSearch />,
    isProtected: true,
    permission: false,
  },
  {
    id: 3,
    title: ComponentNames.Add,
    path: URLs.clientsAdd,
    data: <AddClient />,
    isProtected: true,
    permission: false,
  },
  {
    id: 4,
    title: ComponentNames.List,
    path: URLs.clientsList,
    data: <ClientList />,
    isProtected: true,
    permission: false,
  },
  {
    id: 5,
    title: ComponentNames.Add,
    path: URLs.accountsAdd,
    data: <AddAccount />,
    isProtected: true,
    permission: false,
  },
  {
    id: 6,
    title: ComponentNames.List,
    path: URLs.accountsList,
    data: <AccountList />,
    isProtected: true,
    permission: false,
  },
  {
    id: 7,
    title: ComponentNames.Disabled,
    path: URLs.accountsDisabled,
    data: <DisabledAccounts />,
    isProtected: true,
    permission: false,
  },
  {
    id: 8,
    title: ComponentNames.List,
    path: URLs.depositsList,
    data: <DepositList />,
    isProtected: true,
    permission: "DEPOSIT",
  },
  {
    id: 9,
    title: ComponentNames.History,
    path: URLs.depositsHistory,
    data: <DepositHistory />,
    isProtected: true,
    permission: "DEPOSITHISTORY",
  },
  {
    id: 10,
    title: ComponentNames.UtrForm,
    path: URLs.depositsUtrEntryAdd,
    data: <UTRForm />,
    isProtected: true,
    permission: "DEPOSITUTRFORM",
  },
  {
    id: 11,
    title: ComponentNames.UtrPendingList,
    path: URLs.depositsUtrPendingList,
    data: <UTRPendingList />,
    isProtected: true,
    permission: "DEPOSITPENDINGLIST",
  },
  {
    id: 12,
    title: ComponentNames.UtrRemovedList,
    path: URLs.depositsUtrRemovedList,
    data: <UTRRemovedList />,
    isProtected: true,
    permission: "DEPOSITREMOVEDLIST",
  },
  {
    id: 13,
    title: ComponentNames.CashSettlement,
    path: URLs.depositsCashSettlement,
    data: <CashSettlement />,
    isProtected: true,
    permission: "DEPOSITCASHSETTLEMENT",
  },
  {
    id: 14,
    title: ComponentNames.Withdraw,
    path: URLs.withdrawalList,
    data: <WithdrawList />,
    isProtected: true,
    permission: "WITHDRAW",
  },
  {
    id: 15,
    title: ComponentNames.WithdrawHistory,
    path: URLs.withdrawalHistory,
    data: <WithdrawHistory />,
    isProtected: true,
    permission: "WITHDRAWHISTORY",
  },
  {
    id: 16,
    title: ComponentNames.Manual,
    path: URLs.withdrawalManual,
    data: <Manual />,
    isProtected: true,
    permission: "WITHDRAWMANUA",
  },
  {
    id: 16,
    title: ComponentNames.Manual,
    path: URLs.withdrawalCashSettlement,
    data: <WithdrawCashSettlement />,
    isProtected: true,
    permission: "WITHDRAWCASHSETTLEMENT",
  },
  {
    id: 17,
    title: ComponentNames.ExtraUsers,
    path: URLs.usersAdd,
    data: <AddUsers />,
    isProtected: true,
    permission: false,
  },
  {
    id: 18,
    title: ComponentNames.Active,
    path: URLs.users,
    data: <ActiveUsers />,
    isProtected: true,
    permission: false,
  },
  {
    id: 19,
    title: ComponentNames.Inactive,
    path: URLs.usersInactive,
    data: <InactiveUsers />,
    isProtected: true,
    permission: false,
  },
  {
    id: 20,
    title: ComponentNames.AccountStatement,
    path: URLs.accountStatement,
    data: <AccountStatement />,
    isProtected: true,
    permission: false,
  },
  {
    id: 21,
    title: ComponentNames.Statistics,
    path: URLs.statistics,
    data: <Statistics />,
    isProtected: true,
    permission: false,
  },
  {
    id: 22,
    title: ComponentNames.ChangePassword,
    path: URLs.changePassword,
    data: <ChangePassword />,
    isProtected: true,
    permission: "CHANGEPASSWORD",
  },
  {
    id: 23,
    title: ComponentNames.Edit,
    path: `${URLs.accountsList}/edit`,
    data: <EditAccount />,
    isProtected: true,
    permission: false,
  },
  {
    id: 24,
    title: ComponentNames.AccountDeposit,
    path: `${URLs.accountsList}/deposit-history`,
    data: <AccountDepositHistory />,
    isProtected: true,
    permission: false,
  },
  {
    id: 25,
    title: ComponentNames.AccountWithdraw,
    path: `${URLs.accountsList}/withdrawals-history`,
    data: <AccountWithdrawalHistory />,
    isProtected: true,
    permission: "WITHDRAWHISTORY",
  },
  {
    id: 26,
    title: ComponentNames.EditUser,
    path: `${URLs.users}/edit`,
    data: <EditUsers />,
    isProtected: true,
    permission: false,
  },
];
