import React, { useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { tableStyle } from "./style";
import Pagination from "../../molecules/Pagination";
const CustomTable = (props) => {
  const {
    data,
    columns = [],
    rows,
    style,
    loading,
    loaderStyle,
    showTotal,
    totalData,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = rows;
  const totalRecords = data?.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  // const showPagination = totalRecords > 100; // Check if total records exceed 100
  //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
  const showPagination = totalRecords > 25; // Check if total records exceed 25
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const paginateData = () => {
    if (!Array.isArray(data)) {
      return [];
    }
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const loader = (
    <span
      className="spinner-border spinner-border-sm"
      aria-hidden="true"
      style={loaderStyle}
    ></span>
  );
  const sumOfTotals = (totalData) => {
    if (!Array.isArray(totalData) || totalData.length === 0) {
      return {};
    }
    return columns.reduce((acc, column) => {
      if (column.field && typeof totalData[0][column.field] === "number") {
        acc[column.field] = totalData.reduce((sum, row) => {
          const value = row[column.field];
          // if the value is a number and not undefined or NaN
          if (typeof value === "number" && !isNaN(value)) {
            return sum + value;
          } else {
            return sum;
          }
        }, 0);
      } else {
        acc[column.field] = "N/A";
      }
      return acc;
    }, {});
  };
  const columnTotals = useMemo(() => sumOfTotals(totalData), [data, columns]);
  return (
    <>
      <div className="card-body pb-2 table-class">
        <DataTable
          emptyMessage="No Record Found"
          size="small"
          value={paginateData()}
          rows={rows}
          stripedRows
          showGridlines
          tableStyle={{ ...tableStyle.row, ...style }}
          scrollable
          loading={loading && loader}
          rowClassName={(row) => {
            if (row?.cancelledAt?.length && row.status?.props?.title !== "Rejected") {
              return "cancelRowData";
            }
            return null;
          }}

        >
          {columns.length &&
            columns.map((column, index) => (
              <Column
                key={column.field}
                sortable
                field={column.field}
                header={column.header}
                style={{
                  width: column.width,
                  color: tableStyle.rowColor.color,
                  fontWeight: column?.fontWeight
                }}
                headerStyle={tableStyle.head}
                bodyStyle={{ backgroundColor: column.backgroundColor }}
                footer={
                  showTotal && column.field === columns[0].field ? (
                    <span style={tableStyle.footer}>Total : </span>
                  ) : showTotal &&
                    column.field !== "no" &&
                    column.field !== "account_id" &&
                    column.field !== "username" &&
                    columnTotals[column.field] !== "N/A" ? (
                    <span style={tableStyle.footer}>
                      {columnTotals[column.field]}
                    </span>
                  ) : null
                }
                footerStyle={{ backgroundColor: column.backgroundColor }}
              />
            ))}
        </DataTable>
        {showPagination && (
          <div className="card-footer pb-1">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default CustomTable;
