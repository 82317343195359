import React from "react";
import CustomLabel from "../../../components/atoms/CustomLabel";
import { globalSearchConstants } from "../constants";
import { globalSearchStyle } from "../style";
import CustomInput from "../../../components/atoms/CustomInput";
import { classes, inputTypes } from "../../../constants/CommonConstants";
import { depositConstants } from "../../DepositContainer/DepositListContainer/constants";
import SearchBox from "../../../components/atoms/SearchableSelectBox/SearchBox";

const DepositModal = ({
  formErrors,
  onHandleDepositInputChange,
  renderError,
  depositInput,
  accountOptions,
  showAmount = true,
  showNote = true,
}) => {
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <CustomLabel label={depositConstants.account} />
        <SearchBox
          options={accountOptions}
          placeholder={depositConstants.selectAccount}
          value={depositInput?.account}
          onChange={(value) => onHandleDepositInputChange("account", value[0])}
          className={`${formErrors["account"] && classes.invalid}`}
          error={renderError("account")}
        />
      </div>
      <div className="col-12 mb-3">
        <CustomLabel
          label={globalSearchConstants.utrNumber}
          additionalStyles={globalSearchStyle.modalLabel}
        />
        :
        <CustomInput
          placeholder={`Enter ${globalSearchConstants.utrNumber}`}
          type={inputTypes.text}
          value={depositInput?.utrNumber}
          className={`${classes.inputClass} ${
            formErrors["utrNumber"] && classes.invalid
          }`}
          onChange={(e) => onHandleDepositInputChange("utrNumber", e)}
          error={renderError("utrNumber")}
        />
      </div>
      {showAmount && (
        <div className="col-12 mb-3">
          <CustomLabel
            label={depositConstants.amount}
            additionalStyles={globalSearchStyle.modalLabel}
          />
          :
          <CustomInput
            placeholder={`Enter ${depositConstants.amount}`}
            type={inputTypes.text}
            value={depositInput?.amount}
            className={`${classes.inputClass} ${
              formErrors["amount"] && classes.invalid
            }`}
            onChange={(e) => onHandleDepositInputChange("amount", e)}
            error={renderError("amount")}
          />
        </div>
      )}

      {showNote && (
        <div className="col-12 mb-3">
          <CustomLabel
            label={depositConstants.note}
            additionalStyles={globalSearchStyle.modalLabel}
          />
          :
          <CustomInput
            type={inputTypes.text}
            value={depositInput?.note}
            className={`${classes.inputClass}`}
            onChange={(e) => onHandleDepositInputChange("note", e)}
          />
        </div>
      )}
    </div>
  );
};

export default DepositModal;
