import { toast } from "react-toastify";
import { logoutErrors, unexpectedError } from "../../constants/ErrorMessage";
import { responseCode } from "../../constants/response-code";
import { endPoints } from "../../services/EndPoints";
import { _get, _post } from "../../services/HttpMethods";
import {
  ADD_CLIENT_FAIL,
  ADD_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  GET_CLIENT_SUCCESS,
} from "./actionType";
import { clientMessage } from "../../constants/SuccessMessage";
import { constants } from "../../constants/CommonConstants";
import { removeAuthData } from "../../utils/Common";
import { LOGOUT_SUCCESS } from "../auth/actionType";

export const addClient = (
  credentials,
  onSuccess,
  token,
  logoutFunc,
  btnDisableFunc = null
) => {
  return async (dispatch) => {
    try {
      const response = await _post(
        endPoints.clients,
        credentials,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { status, msg, data } = response.data;
      if (status !== constants.status_200 && status !== constants.status_201) {
        throw new Error(msg);
      }
      dispatch({
        type: ADD_CLIENT_SUCCESS,
        payload: data,
      });
      toast.success(clientMessage.successClientAdded);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        if (btnDisableFunc) {
          btnDisableFunc();
        }
        dispatch({
          type: ADD_CLIENT_FAIL,
          payload: error.message,
        });
        toast.error(error.message);
      }
    }
  };
};

export const getClient = (token, logoutFunc) => {
  return async (dispatch) => {
    try {
      // GET request to fetch client data
      const response = await _get(
        endPoints.clients,
        null,
        token,
        null,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== constants.status_200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_CLIENT_FAIL,
          payload: error.message,
        });
      }
      // Dispatch fail action if there's an error fetching client data
    }
  };
};
