export const cardStyle = {
    img: {
        width: "104px",
        height: "40px",
    },
    heading: {
        textTransform: "uppercase",
        fontWeight: 900,
        fontSize: "14px",
        textAlign: "left",
        marginLeft:"5px"
    }
}