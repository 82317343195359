import React from 'react'
import { headerStyle } from './style'

const CardHeader = (props) => {
    const { cardHeaderTitle } = props
    return (
        <div className='card-header' style={headerStyle.container}>
            <h3 style={headerStyle.header} className='pt-2'>{cardHeaderTitle}</h3>
        </div>
    )
}

export default CardHeader