export const GET_BANK_ACCOUNT_SUCCESS = 'GET_BANK_ACCOUNT_SUCCESS';
export const GET_BANK_ACCOUNT_FAIL = 'GET_BANK_ACCOUNT_FAIL';
export const GET_BANK_ACCOUNT_BY_ID_SUCCESS = 'GET_BANK_ACCOUNT_BY_ID_SUCCESS';
export const GET_BANK_ACCOUNT_BY_ID_FAIL = 'GET_BANK_ACCOUNT_BY_ID_FAIL';
export const GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_SUCCESS = 'GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_SUCCESS';
export const GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_FAIL = 'GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_FAIL';
export const GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_SUCCESS = 'GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_SUCCESS';
export const GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_FAIL = 'GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_FAIL';
export const ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAIL = 'ADD_BANK_ACCOUNT_FAIL';
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS';
export const UPDATE_BANK_ACCOUNT_FAIL = 'UPDATE_BANK_ACCOUNT_FAIL';
export const UPDATE_BANK_ACCOUNT_STATUS_SUCCESS = 'UPDATE_BANK_ACCOUNT_STATUS_SUCCESS';
export const UPDATE_BANK_ACCOUNT_STATUS_FAIL = 'UPDATE_BANK_ACCOUNT_STATUS_FAIL';
export const UPDATE_DISABLED_FILTERED_DATA = 'UPDATE_DISABLED_FILTERED_DATA';
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const UPDATE_ACTIVE_ACCOUNTS_DATA = 'UPDATE_ACTIVE_ACCOUNTS_DATA';
export const GET_BANK_ACCOUNT_STATEMENT_SUCCESS = 'GET_BANK_ACCOUNT_STATEMENT_SUCCESS';
export const GET_BANK_ACCOUNT_STATEMENT_FAIL = 'GET_BANK_ACCOUNT_STATEMENT_FAIL';
export const GET_BANK_ACCOUNT_STATEMENT_BY_ID = "GET_BANK_ACCOUNT_STATEMENT_BY_ID"
