import { color } from "../../themes/Colors";

// Defining login page styling in an object.
export const loginStyles = {
	container: {
		backgroundColor: color._e9ecef,
		minHeight: '100vh',
		minWidth: '50vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	imgSize: {
		width: '360px',
		height: '63px'
	},
	cardSize: {
		width: '360px',
		height: 'auto'
	},
	loading: {
		marginRight: "5px"
	},
	passwordToggleIcon:{
		bottom:"10px"
	}
}