import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { depositConstants, statusOptions, typeOptions } from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { depositStyle } from "../style";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";

const RefreshForm = (props) => {
  const {
    bankAccounts,
    handleRefresh,
    handleDepositInput,
    depositFilters,
    handleDepositOnSelect,
    handleDepositSearchableSelect,
    resetStatusRef,
    resetTypeRef,
  } = props;
  const accountOptions = bankAccounts?.map((account) => ({
    value: account.id,
    label: account.account_name,
  }));

  return (
    <form>
      <div className="row m-0 p-2">
        <div className="col-md-2 p-1">
          <CustomLabel label={depositConstants.utrNumber} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.text}
            onChange={handleDepositInput}
            name="utrNumber"
            value={depositFilters.utrNumber || ""}
          />
        </div>
        <div className="col-md-2 p-1">
          <CustomLabel label={depositConstants.username} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.text}
            onChange={handleDepositInput}
            value={depositFilters.username || ""}
            name="username"
          />
        </div>
        <div className="col-md-2 p-1">
          <CustomLabel label={depositConstants.account} />
          <SearchBox
            options={accountOptions}
            placeholder={depositConstants.selectAccount}
            value={depositFilters.account || ""}
            defaultValue={depositFilters.account || ""}
            onChange={(value) =>
              handleDepositSearchableSelect(value[0]?.text, "account")
            }
          />
        </div>
        <div className="col-md-2 p-1">
          <CustomLabel label={depositConstants.status} />
          <CustomSelectBox
            options={statusOptions}
            className={classes.selectBox}
            onChange={(e) => handleDepositOnSelect(e, "status")}
            ref={resetStatusRef}
          />
        </div>
        <div className="col-md-2 p-1">
          <CustomLabel label={depositConstants.type} />
          <CustomSelectBox
            options={typeOptions}
            className={classes.selectBox}
            onChange={(e) => handleDepositOnSelect(e, "type")}
            property={"type"}
            ref={resetTypeRef}
          />
        </div>
        <div className="col-md-2 p-1 d-flex align-items-center mt-md-4">
          <Button
            title={depositConstants.refresh}
            style={depositStyle.searchBtn}
            className="py-1 btn-hover"
            onClick={handleRefresh}
          />
        </div>
      </div>
    </form>
  );
};

export default RefreshForm;
