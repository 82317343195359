import { color } from "../../../themes/Colors";

export const logoStyle = {
    logoContainer: {
        margin: 'auto'
    },
    img:{
        height:"18px"
    },
    color: {
        color: color._0e8d84,
        textTransform: 'uppercase'
    }
}