export const manualConstants = {
    manualWithdraw: "Manual Withdraw",
    client: "Client:",
    utrNumber: "UTR Number:",
    enterUtrNumber: "Enter UTR Number",
    account: "Account:",
    amount: "Amount:",
    enterAmount: "Enter Amount",
    add: "Add"
}

export const accountOptions = [
    { value: "1", label: "Demolalit(99exch.com)" },
    { value: "2", label: "Demolalit(laser247.com)" },
    { value: "3", label: "Demolalit(play247.win)" },
    { value: "4", label: "Demolalit(11xplay.com)" },
]

export const userOptions = [
    { value: "1", label: "user1" },
    { value: "2", label: "user2" },
    { value: "3", label: "user3" },
    { value: "4", label: "user4" },
]
