import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { URLs } from "../routes/URLs";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/auth/action";
import { clientsFormError } from "../constants/ErrorMessage";
import { getValidateValue } from "../utils/Common";

export const useLoginHook = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  const redirectToDashboard = useCallback(() => {
    navigate(URLs.dashboard);
  }, [navigate]);

  // Check authentication status when component gets mount
  useEffect(() => {
    // If user is already authenticated, redirect to dashboard
    if (isAuthenticated) {
      return redirectToDashboard();
    }
  }, [isAuthenticated, redirectToDashboard]);

  const validateForm = () => {
    const errors = {};
    if (credentials.username.length === 0) {
      errors.username = clientsFormError.username;
    }
    if (credentials.password.length === 0) {
      errors.password = clientsFormError.password;
    }
    setFormErrors(errors);
    return getValidateValue(errors, clientsFormError);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      await dispatch(login(credentials));
      setLoading(false);
      redirectToDashboard();
    }
  };

  // Event change of username
  const onChangeUserName = (e) => {
    const username = e.target.value;
    setCredentials({ ...credentials, username });
    if (!username.length) {
      setFormErrors({ ...formErrors, username: "" });
    }
  };

  // Event change of password
  const onChangePassword = (e) => {
    const password = e.target.value;
    setCredentials({ ...credentials, password });
    if (!password.length) {
      setFormErrors({ ...formErrors, password: "" });
    }
  };

  const renderError = (field) => {
    return formErrors[field];
  };

  return {
    handleLogin,
    onChangeUserName,
    onChangePassword,
    credentials,
    errorMessage,
    loading,
    renderError,
    formErrors,
  };
};

export default useLoginHook;
