import { color } from "../../../themes/Colors";

export const addAccountStyle = {
    container: { borderRadius: "1px" },
    label: {
        color: color._000,
        fontSize: "14px",
        fontWeight: 400
    },
    loading: {
        marginRight: "5px"
    }
}