import React, { useEffect, useState, useRef } from "react";
import "./search.css";

const SearchBox = ({
  options,
  defaultValue,
  className,
  ariaLabel,
  onChange,
  error,
  name,
  value,
  placeholder,
  formSubmitState,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    value || defaultValue || ""
  );
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(value || defaultValue || "");
  }, [value, defaultValue]);

  useEffect(() => {
    const handleClear = () => {
      setSelectedValue("");
      setSearchTerm("");
      onChange([]);
    };
    formSubmitState && handleClear();
  }, [formSubmitState, onChange]);

  const handleSelect = (option) => {
    setSelectedValue(option.label);
    setSearchTerm("");
    onChange([
      {
        id: option.value,
        text: option.label,
        ...(option.minAmount &&
          option.maxAmount && {
          minAmount: option.minAmount,
          maxAmount: option.maxAmount,
        }),
      },
    ]);
    setIsOpen(false);
  };

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div ref={containerRef} className={`selectBox-container ${className}`}>
        <div className={`selectBox ${isOpen ? "open" : ""}`}>
          <input
            type="text"
            value={selectedValue || value}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setSelectedValue(e.target.value);
            }}
            placeholder={placeholder}
            aria-label={ariaLabel}
            onFocus={() => setIsOpen(true)}
            className="selectBox-input"
          />
          <span className="selectBox-arrow" onClick={() => setIsOpen(!isOpen)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              viewBox="0 0 24 24"
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
        </div>
        {isOpen && (
          <ul className="selectBox-dropdown">
            {filteredOptions?.length > 0 ? (
              filteredOptions?.map((option) => (
                <li
                  key={option.value}
                  className={`selectBox-option ${option.value === selectedValue ? "selected" : ""
                    }`}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className="selectBox-no-options">No options found</li>
            )}
          </ul>
        )}
      </div>
      {error && (
        <div className="invalid-feedback fw-bolder selectBox-errorStyle">
          {error}
        </div>
      )}
    </>
  );
};

export default SearchBox;
