import React from "react";
import Button from "../../atoms/Button";
import { buttonTypes, classes } from "../../../constants/CommonConstants";
import { modalStyle } from "./style";
import { ModalIcon } from "../../../assets/icons/Icons";

const Modal = ({
  show,
  id,
  handleClose,
  modalTitle,
  children,
  modalFooterPrimaryTitle,
  handleSave,
  isLoading,
  modalFooterSecondaryTitle,
  dataBsDismiss,
  btnDisable,
}) => {
  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{
        display: show ? "block" : "none",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      tabIndex="-1"
      id={id}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header" style={modalStyle.header}>
            <h5 className="modal-title">{modalTitle}</h5>
            <button
              type={buttonTypes.button}
              style={modalStyle.btnClose}
              className={classes.btnClose}
              onClick={handleClose}
              data-bs-dismiss="modal"
            >
              {ModalIcon.closeIcon}
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer d-flex justify-content-between">
            <Button
              type={buttonTypes.button}
              className={classes.btnDanger}
              onClick={handleClose}
              title={modalFooterPrimaryTitle}
              dataBsDismiss="modal"
            />
            <Button
              type={buttonTypes.submit}
              className={classes.btn}
              style={modalStyle.modalFooterBtn}
              onClick={handleSave}
              title={modalFooterSecondaryTitle}
              isLoading={isLoading}
              loaderStyle={modalStyle.loading}
              disabled={btnDisable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
