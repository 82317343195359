import React from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { clientStyle } from "../style";
import { useSelector } from "react-redux";

const ClientList = (props) => {
  const { clientsTableColumns, formattedClientsData, loading } = props;
  const { filteredData } = useSelector((state) => state.filter);
  return (
    <CustomTable
      data={
        filteredData && filteredData.length > 0
          ? filteredData
          : !Array.isArray(filteredData)
          ? []
          : formattedClientsData
      }
      columns={clientsTableColumns}
      rows={25} //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
      style={clientStyle.table}
      loading={loading}
    />
  );
};

export default ClientList;
