// Importing Dependencies.
import React from "react";
import CardGroup from "../../components/organisms/CardGroups";
import OverviewCard from "../../components/molecules/OverviewCard";
import { dashboardConstants } from "./constant";
import useDashboardHook from "../../hooks/dashboard-hook";
import { getPermission, getRole } from "../../utils/Common";
import { usePermissions } from "../../permissionhook";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
// Defining Dashboard Container Function.
const DashboardContainer = () => {
  const permissions = usePermissions();
  const role = useSelector((state) => state.auth.role);
  const { dashboardCardGroups, dashboardData, loadingDashboard } =
    useDashboardHook();

  const InfoArr = [
    {
      numericFigure: `${dashboardData?.dashboard?.totalDeposits}`,
      title: `Total Deposits : ${dashboardData?.dashboard?.totalNoOfDeposits}`,
      cardBackColor: "rgba(24,160,182,255)",
      infoIconBackColor: "rgba(18,145,165,255)",
      name: "DEPOSIT",
      path: "/deposits",
      filter: {},
    },
    {
      numericFigure: `${dashboardData?.dashboard?.totalPendingDeposits}`,
      title: `Total Pending Deposits : ${dashboardData?.dashboard?.totalNoOfPendingDeposits}`,
      cardBackColor: "rgba(255,192,9,255)",
      infoIconBackColor: "rgba(228,176,5,255)",
      fontColor: "black",
      name: "DEPOSIT",
      numericColor: "black",
      titleColor: "black",
      path: "/deposits",
      filter: {
        status: 0,
      },
    },
    {
      numericFigure: `${dashboardData?.dashboard?.totalApprovedDeposits}`,
      title: `Total Approved Deposits : ${dashboardData?.dashboard?.totalNoOfApprovedDeposits}`,
      cardBackColor: "rgba(41,167,68,255)",
      infoIconBackColor: "rgba(35,150,62,255)",
      name: "DEPOSIT",
      path: "/deposits",
      filter: {
        status: 1,
      },
    },
    {
      numericFigure: `${dashboardData?.dashboard?.totalRejectedDeposits}`,
      title: `Total Rejected Deposits : ${dashboardData?.dashboard?.totalNoOfRejectedDeposits}`,
      cardBackColor: "rgba(220,51,70,255)",
      infoIconBackColor: "rgba(200,45,64,255)",
      name: "DEPOSIT",
      path: "/deposits",
      filter: {
        status: 2,
      },
    },
    {
      numericFigure: `${dashboardData?.dashboard?.totalWithdrawals}`,
      title: `Total Withdrawals : ${dashboardData?.dashboard?.totalNoOfWithdrawals}`,
      cardBackColor: "rgba(24,160,182,255)",
      infoIconBackColor: "rgba(18,145,165,255)",
      name: "WITHDRAW",
      path: "/withdrawals",
      filter: {},
    },
    {
      numericFigure: `${dashboardData?.dashboard?.totalApprovedWithdrawals}`,
      title: `Total Approved Withdrawals : ${dashboardData?.dashboard?.totalNoOfApprovedWithdrawals}`,
      cardBackColor: "rgba(41,167,68,255)",
      infoIconBackColor: "rgba(35,150,62,255)",
      name: "WITHDRAW",
      path: "/withdrawals",
      filter: {
        status: 1,
      },
    },
    {
      numericFigure: `${dashboardData?.dashboard?.totalRejectedWithdrawals}`,
      title: `Total Rejected Withdrawals : ${dashboardData?.dashboard?.totalNoOfRejectedWithdrawals}`,
      cardBackColor: "rgba(220,51,70,255)",
      infoIconBackColor: "rgba(200,45,64,255)",
      name: "WITHDRAW",
      path: "/withdrawals",
      filter: {
        status: 2,
      },
    },
  ];
  return (
    <>
      {dashboardCardGroups.map((group, index) => (
        <CardGroup
          key={index}
          colCount={group.colCount}
          cardData={group.cardData}
        />
      ))}
      {loadingDashboard ? (
        <ClipLoader
          cssOverride={{
            display: "block",
            margin: "0 auto",
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          <div className="row">
            <OverviewCard
              numericFigure={dashboardData?.dashboard?.totalFTD}
              title={`Total FTD :${dashboardData?.dashboard?.totalNoOfFTD}`}
              icon={dashboardConstants.icon}
              cardBackColor={"rgba(24,160,182,255)"}
              showMoreInfo={false}
            />
          </div>

          <div className="row">
            {InfoArr?.filter((i) =>
              getRole(role)
                ? true
                : getPermission(permissions, i?.name, "READPERMISSOIN")
            )?.map((item, indx) => {
              return (
                <OverviewCard
                  numericFigure={item.numericFigure}
                  title={item.title}
                  icon={dashboardConstants.icon}
                  infoIcon={dashboardConstants.infoIcon}
                  cardBackColor={item.cardBackColor}
                  infoIconBackColor={item.infoIconBackColor}
                  fontColor={item.fontColor}
                  numericColor={item.numericColor}
                  titleColor={item.titleColor}
                  navigatePath={item.path}
                  filter={item.filter}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

// Exporting Dashboard Container Function.
export default DashboardContainer;
