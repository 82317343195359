import { toast } from "react-toastify";
import { endPoints } from "../../services/EndPoints";
import { _get } from "../../services/HttpMethods";
import { GET_GLOBAL_FAIL, GET_GLOBAL_SUCCESS } from "./actionType";
import { removeAuthData } from "../../utils/Common";
import { logoutErrors } from "../../constants/ErrorMessage";
import { LOGOUT_SUCCESS } from "../auth/actionType";
import { constants } from "../../constants/CommonConstants";

export const getGlobalThirdParty = (logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _get(
        endPoints.thirdParties,
        null,
        null,
        null,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== 200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_GLOBAL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_GLOBAL_FAIL,
          payload: error.message,
        });
      }
    }
  };
};
