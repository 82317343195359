import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { depositConstants, statusOptions, typeOptions } from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { depositStyle } from "../style";

const SearchForm = (props) => {
    const {
        accountWithdrawalFilters,
        handleAccountWithdrawalReset,
        handleAccountWithdrawalFilterSelect,
        statusRef,
        typeRef,
        handleAccountWithdrawalFilterInput,
        handleAccountWithdrawalSearch,
    } = props;
    return (
        <form onSubmit={handleAccountWithdrawalSearch}>
            <div className="row m-0 pt-2">
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.selectStatus} />
                    <CustomSelectBox
                        options={statusOptions}
                        className={classes.selectBox}
                        onChange={(selectedOption) =>
                            handleAccountWithdrawalFilterSelect(
                                "status",
                                selectedOption.value
                            )
                        }
                        ref={statusRef}
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.type} />
                    <CustomSelectBox
                        options={typeOptions}
                        className={classes.selectBox}
                        onChange={(selectedOption) =>
                            handleAccountWithdrawalFilterSelect("type", selectedOption.label)
                        }
                        ref={typeRef}
                        property="type"
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.username} />
                    <CustomInput
                        className={classes.inputClass}
                        type={inputTypes.text}
                        value={accountWithdrawalFilters?.username}
                        name="username"
                        onChange={(e) => handleAccountWithdrawalFilterInput(e, "username")}
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel label={depositConstants.utrNumber} />
                    <CustomInput
                        className={classes.inputClass}
                        type={inputTypes.text} value={accountWithdrawalFilters?.utr_number}
                        onChange={(e) => handleAccountWithdrawalFilterInput(e, 'utr_number')} />
                </div>
                <div className="col-md-2">
                    <CustomLabel
                        label={depositConstants.search}
                        additionalStyles={depositStyle.btnLabel}
                    />
                    <Button
                        title={depositConstants.search}
                        style={depositStyle.searchBtn}
                        className="pb-1 pt-1"
                    />
                </div>
                <div className="col-md-2">
                    <CustomLabel
                        label={depositConstants.search}
                        additionalStyles={depositStyle.btnLabel}
                    />
                    <Button
                        title={depositConstants.reset}
                        style={depositStyle.searchBtn}
                        className="pb-1 pt-1"
                        onClick={handleAccountWithdrawalReset}
                    />
                </div>
            </div>
        </form>
    );
};

export default SearchForm;
