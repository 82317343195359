import React, { useState } from "react";
import { logoutBtnStyle } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/auth/action";
import { URLs } from "../../../routes/URLs";
import Button from "./../Button/Button";
import { LogOutIcon } from "../../../assets/icons/Icons";
import ReusableIcon from "../ReusableIcon";
import { color } from "../../../themes/Colors";
import { LOGOUT_SUCCESS } from "../../../redux/auth/actionType";

const LogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.userId);
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    await dispatch(
      logout(token, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate(URLs.login);
      })
    );
    setLoading(false);
  };
  return (
    <Button
      className="btn btn-default rounded-0 btn-hover logout-collapse"
      onClick={handleLogout}
      style={logoutBtnStyle.btn}
      title={"Logout"}
      btnIcon={
        <ReusableIcon
          icon={LogOutIcon.logoutIcon}
          iconColor={color._ffffff}
          style={{ marginRight: "5px" }}
        />
      }
      isLoading={loading}
      loaderStyle={logoutBtnStyle.loading}
    />
  );
};

export default LogoutButton;
