import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClient, getClient } from "../redux/client/action";
import { searchSubmit, updateFilteredData } from "../redux/filter/action";
import {
  formatDateTime,
  generatePassword,
  getAuthData,
  getValidateValue,
  sortByDateDescending,
} from "../utils/Common";
import { clientsConstants } from "../containers/ClientsContainer/ClientListContainer/constants";
import {
  classes,
} from "../constants/CommonConstants";
import Badge from "../components/atoms/Badge";
import { clientStyle } from "../containers/ClientsContainer/ClientListContainer/style";
import { useNavigate } from "react-router-dom";
import { URLs } from "../routes/URLs";
import {
  clientsFormError,
  dateError,
  formError,
} from "../constants/ErrorMessage";
import { masterOptions } from "../containers/ClientsContainer/AddClientContainer/constants";
import { toast } from "react-toastify";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";
import { getGlobalThirdParty } from "../redux/globalSearch/action";
import { infoMessage } from "../constants/SuccessMessage";

export const useClientsHook = (isAddClient = true) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    masterId: masterOptions[0].label, //by default sending this
    referralCode: "",
    username: "",
    password: generatePassword(),
  });
  const [formSubmitState, setFormSubmitState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnModalLoading, setBtnModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const { clients } = useSelector((state) => state.client);
  const [filters, setFilters] = useState({
    site: "",
    username: "",
    startDate: "",
    endDate: "",
    source: "",
    depositCount: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
    startDate: "",
    endDate: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [formattedClientData, setFormattedClientData] = useState({});
  const [errorCheckString, setErrorCheckString] = useState("");
  const isMountedThirdParties = useRef(true);
  const { globalThirdParties } = useSelector((state) => state.globalSearch);
  const selectSourceRef = useRef(null);
  const selectDepositCountRef = useRef(null);

  const domains = [
    { value: "100panel", label: "100panel" },
    ...globalThirdParties?.map((party) => ({
      value: `${party.site}`,
      label: `${party.site}`,
      id: party.site_id,
    })),
  ];

  useEffect(() => {
    clients?.length && setFormattedClientData(getFormattedData(clients));
  }, [clients]);

  const fetchGlobalThirdParties = useCallback(async () => {
    setLoading(true);
    await dispatch(
      getGlobalThirdParty(() => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (isMountedThirdParties.current) {
      fetchGlobalThirdParties();
    }

    return () => {
      isMountedThirdParties.current = false;
    };
  }, [fetchGlobalThirdParties]);

  const validateDates = (name, value) => {
    let error = "";
    let today = new Date();
    const startDate =
      name === "startDate"
        ? new Date(value)
        : filters.startDate
          ? new Date(filters.startDate)
          : "";
    const endDate =
      name === "endDate"
        ? new Date(value)
        : filters.endDate
          ? new Date(filters.endDate)
          : "";
    if (
      startDate &&
      endDate &&
      startDate !== "" &&
      endDate !== "" &&
      errorCheckString === ""
    ) {
      if (startDate > endDate) {
        error = name === "startDate" ? dateError.startDate : dateError.endDate;
      }
    } else if (
      endDate !== "" &&
      endDate > today &&
      errorCheckString !== dateError.endCannotGreat
    ) {
      error = dateError.endCannotGreat;
    } else if (
      startDate !== "" &&
      startDate > today &&
      errorCheckString !== dateError.startCannotGreat
    ) {
      error = dateError.startCannotGreat;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newVal = value.split(" ")[0];
    setFilters((prevData) => ({
      ...prevData,
      [name]: newVal,
    }));
    if (name === "username") {
      if (value.length >= 3) {
        const filteredClients = clients.filter((client) => {
          return client.username.includes(value);
        });

        setDropdownOptions(
          filteredClients.map(
            (client) =>
              `${client.username} ${client.site ? `(${client.site})` : ""}`
          )
        );
      }
    } else {
      const dateError = validateDates(name, value);
      setFormChanged(true);
      setErrorCheckString(dateError);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: dateError,
      }));
      if (dateError === "") {
        let obj = {
          ...filters,
          [name]: value,
        };
        dispatch(
          updateFilteredData(commonFilterFunction(obj, formattedClientData))
        );
      }
      setDropdownOptions([]);
    }
  };

  const getFormattedData = (arr) => {
    return sortByDateDescending(
      arr.map((client) => ({
        ...client,
        created: formatDateTime(client?.created),
        active: client?.active ? (
          <Badge
            title={clientsConstants.active}
            className={`${classes.badge} btn-hover`}
            style={{ ...clientStyle.badge, ...clientStyle.badgeGreen }}
          />
        ) : (
          <Badge
            title={clientsConstants.inactive}
            className={classes.badge}
            style={{ ...clientStyle.badge, ...clientStyle.badgeRed }}
          />
        ),
        action: renderActions(client),
      })),
      "created"
    ).map((client, index) => ({
      ...client,
      no: index + 1,
    }));
  };

  const handleReset = () => {
    setFilters({
      site: "",
      username: "",
      startDate: "",
      endDate: "",
      source: "",
      depositCount: "",
    });
    setFormErrors({
      username: "",
      password: "",
      startDate: "",
      endDate: "",
    });
    setErrorCheckString("");
    if (selectSourceRef.current) {
      selectSourceRef.current.value = "";
    }

    if (selectDepositCountRef.current) {
      selectDepositCountRef.current.value = "";
    }
    setDropdownOptions([]);
    dispatch(updateFilteredData(formattedClientData));
  };

  const validateForm = () => {
    const errors = {};
    if (credentials.username.length === 0) {
      errors.username = clientsFormError.username;
    }
    setFormErrors(errors);
    return getValidateValue(errors, clientsFormError);
  };

  const renderError = (field) => {
    return formErrors[field];
  };

  const convertingTheCreatedDate = (dateString) => {
    let components = dateString.split(/[/: ]/);

    // Extract components
    let day = components[0];
    let month = components[1] - 1; // Months are 0-based in JavaScript (0 = January, 1 = February, etc.)
    let year = components[2];
    return new Date(year, month, day);
  };

  const convertTheStartDate = (date) => {
    let previousDayDate = date;
    previousDayDate.setDate(previousDayDate.getDate() - 1);
    return previousDayDate;
  };

  const commonFilterFunction = (filterObj, filterArr) => {
    filterArr = filterArr.filter((item) => {
      let matchesAllFilters = true;
      for (let key in filterObj) {
        if (filterObj[key] !== "") {
          if (
            (key === "startDate" && filterObj["startDate"]) ||
            (key === "endDate" && filterObj["endDate"])
          ) {
            let startDate = convertTheStartDate(new Date(filterObj?.startDate));
            let endDate = new Date(filterObj?.endDate);
            let compareDate = convertingTheCreatedDate(item?.created);
            if (filterObj.startDate && filterObj.endDate) {
              matchesAllFilters =
                compareDate >= startDate && compareDate <= endDate;
            } else if (filterObj.startDate) {
              matchesAllFilters = compareDate >= startDate;
            } else {
              matchesAllFilters = compareDate <= endDate;
            }
          } else if (key === "depositCount" && filterObj["depositCount"]) {
            if (parseInt(filterObj[key]) >= 5) {
              matchesAllFilters = parseInt(item[key]) >= 5;
            } else {
              if (
                filterObj[key].toString() &&
                item[key].toString() !== filterObj[key].toString()
              ) {
                matchesAllFilters = false;
              }
            }
          } else if (key === "username" && item[key].includes(filterObj[key])) {
            matchesAllFilters = true;
          } else if (filterObj[key] && item[key] !== filterObj[key]) {
            matchesAllFilters = false;
          }
        }
        if (!matchesAllFilters) {
          break;
        }
      }
      return matchesAllFilters;
    });
    if (!filterArr.length) {
      return null;
    }
    return filterArr;
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setDropdownOptions([]);
    dispatch(
      updateFilteredData(commonFilterFunction(filters, formattedClientData))
    );
    dispatch(searchSubmit());
  };

  const { token } = getAuthData();
  const isMounted = useRef(true);

  const fetchClients = useCallback(async () => {
    setLoading(true);
    await dispatch(
      getClient(token, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch, token]);

  useEffect(() => {
    if (isMounted.current) {
      isAddClient && fetchClients();
    }
    return () => {
      isMounted.current = false;
    };
  }, [fetchClients, isAddClient]);

  const handleCloseModal = () => {
    setBtnDisable(false);
    setShowModal(false);
  };

  const handleCopyModal = () => {
    const clientCredentials = {
      ...credentials,
      site: filters.site || "100panel",
    };
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(
        () => {
          toast.info(infoMessage.copied, { autoClose: 1000 });
        },
        (err) => {
          console.error("Failed to copy text to clipboard", err);
        }
      );
    };

    // Copy username and password to the clipboard
    copyToClipboard(`Username: ${clientCredentials.username}\nPassword: ${clientCredentials.password}`);
    setBtnLoading(false);
    setBtnModalLoading(true)
    dispatch(
      addClient(
        clientCredentials,
        () => navigate(URLs.clientsList),
        token,
        () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        }
      )
    );
    setBtnModalLoading(true)
  }

  const handleAddClient = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (getValidateValue(formErrors, clientsFormError) && validateForm()) {
      setBtnLoading(true);
      setShowModal(true);
    } else {
      toast.error(formError.error);
    }
  };

  const onHandleSelect = (property, value, seperator = "addClient") => {
    setFilters({
      ...filters,
      [property]: property === "source" ? value?.toLowerCase() : value,
    });
    setDropdownOptions([]);
    if (seperator === "searchClient") {
      //to seperate add and search option select
      let obj = {
        ...filters,
        [property]: property === "source" ? value?.toLowerCase() : value,
      };
      dispatch(
        updateFilteredData(commonFilterFunction(obj, formattedClientData))
      );
    }
  };

  const onHandleInputChange = (property, e) => {
    setCredentials({
      ...credentials,
      [property]: e.target.value,
    });
    if (property === "username") {
      const bankRegex = /^[a-zA-Z]{2,}[0-9]*$/;
      const username = e.target.value;
      if (!username.length || bankRegex.test(username)) {
        setFormErrors({ ...formErrors, username: "" });
      } else {
        setFormErrors({
          ...formErrors,
          username: clientsFormError.usernameCondition,
        });
      }
    }
  };

  const clientsTableColumns = [
    { field: "no", header: "no", width: "1%" },
    { field: "clientId", header: "id", width: "6%" },
    { field: "username", header: "username", width: "7%" },
    { field: "master", header: "master", width: "7%" },
    { field: "phone", header: "mobile", width: "7%" },
    { field: "site", header: "site", width: "7%" },
    { field: "depositCount", header: "total deposits", width: "10%" },
    { field: "source", header: "source", width: "8%" },
    { field: "active", header: "status", width: "5%" },
    { field: "created", header: "created on", width: "20%" },
    { field: "action", header: "action", width: "22%" },
  ];

  const renderActions = (item) => (
    <>
      <Badge
        title={clientsConstants.acStatement}
        className={`${classes.badgeRounded} global_badge_hover`}
        style={{ ...clientStyle.badge, ...clientStyle.badgeRed }}
        onClick={() => {
          navigate("/account-statement", { state: item });
        }}
      />
      <Badge
        title={clientsConstants.depositHistory}
        className={`${classes.badge} btn-hover`}
        style={{
          ...clientStyle.badgeAction,
          ...clientStyle.badgeActionGreen,
        }}
        onClick={() => {
          navigate("/deposits/history", { state: item });
        }}
      />
      <Badge
        title={clientsConstants.withdrawHistory}
        className={`${classes.badge} warning-hover`}
        style={{
          ...clientStyle.badgeAction,
          ...clientStyle.badgeYellow,
        }}
        onClick={() => {
          navigate("/withdrawals/history", { state: item });
        }}
      />
    </>
  );

  //As we were not getting any index or serial no. from backend
  const formattedClientsData = clients?.length && getFormattedData(clients);

  // const formattedSortedClientsData = sortByCreated(formattedClientsData);

  return {
    clientsTableColumns,
    handleAddClient,
    loading,
    formSubmitted,
    formChanged,
    formErrors,
    credentials,
    onHandleInputChange,
    formattedClientsData,
    onHandleSelect,
    renderError,
    filters,
    handleChange,
    handleSearchSubmit,
    dropdownOptions,
    handleReset,
    btnLoading,
    domains,
    selectSourceRef,
    formSubmitState,
    selectDepositCountRef,
    showModal,
    btnDisable,
    handleCloseModal,
    handleCopyModal,
    btnModalLoading
  };
};

export default useClientsHook;
