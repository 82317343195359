export const depositConstants = {
    utrNumber: "UTR Number:",
    username: "Username:",
    account: "Account:",
    status: "Status:",
    active: "Active",
    inactive: "Inactive",
    type: "Type:",
    amount: "Amount",
    submit: "Add Deposit",
    refresh: "Refresh",
    user: "User",
    note: "Note",
    selectUser: "Select user",
    selectAccount: "Select account",
    uploadImg: "Upload Image",
    utrEntry: "UTR Entry"
}

export const statusOptions = [
    { value: "", label: "Select status" },
    { value: 0, label: "Pending" },
    { value: 1, label: "Approved" },
    { value: 2, label: "Rejected" },
];

export const typeOptions = [
    { value: "", label: "Select type" },
    { value: 1, label: "Manual" },
    { value: 2, label: "Client" },
    { value: 3, label: "UTR Entry" },
];

