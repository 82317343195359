// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';
import AccountDepositHistoryContainer from '../../../containers/AccountsContainer/AccountDepositHistoryContainer';

// Declaring Funtion
const AccountDepositHistory = () => {

    return (
        <>
            <PageHelmet tabTitle={tabTitles.depositListing} />
            <Layout heading={layoutHeading.deposit}>
                <AccountDepositHistoryContainer />
            </Layout>
        </>
    );
};

export default AccountDepositHistory;
