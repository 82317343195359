// Importing Dependencies.
import React from 'react'
import DashboardContainer from '../../containers/DashboardContainer'
import Layout from '../../layout/Layout'
import { layoutHeading, tabTitles } from '../../constants/CommonConstants'
import PageHelmet from '../../components/atoms/PageHelmet'

// Defining Dashboard Functions.
const Dashboard = () => {
  // Returning Dashboard Container.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.dashboard} />
      <Layout heading={layoutHeading.dashboard}>
        <DashboardContainer />
      </Layout>
    </>
  )
}

// Exporting Dashboard Function.
export default Dashboard