import { color } from "../../../themes/Colors";

export const accountsStyle = {
    container: { borderRadius: "1px", position: "relative" },
    searchBtn: {
        width: "100%",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: "none",
        color: color._ffffff,
        fontWeight: 400,
    },
    statusActive: {
        backgroundColor: color._28a745
    },
    statusInActive: {
        backgroundColor: color._dc3545
    },
    badge: {
        marginRight: "5px",
        padding: "6px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 400,
        cursor: "pointer",
        borderRadius: "2px",
    },
    badgeAction: {
        marginRight: "5px",
        padding: "7px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 400,
        cursor: "pointer",
        borderRadius: "3px"
    },
    badgeActionGreen: {
        backgroundColor: color._0e8d84,
    },
    badgeActionRed: {
        backgroundColor: color._f44336,
    },
    badgeRed: {
        backgroundColor: color._f44336,
        padding: "3px",
        // textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "12px",
        borderRadius: "3px"
    },
    badgeGreen: {
        backgroundColor: color._28a745,
        padding: "3px",
        // textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "12px",
        borderRadius: "3px"
    },
    badgeYellow: {
        backgroundColor: color._ffc107,
        color: color._000,
        fontWeight: 600,
        fontSize: "12px",
        padding: "3px",
        borderRadius: "3px"
    },
    badgeYellowAction: {
        backgroundColor: color._ffc107,
        color: color._000,
    },
    badgeDisabledStatus: {
        fontWeight: 600,
        borderRadius: "3px"
    },
    icon: {
        color: color._ffffff,
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        marginRight: "5px",
        padding: "5px"
    },
    iconCopy: {
        color: color._ffffff,
        backgroundColor: color._0e8d84,
        borderRadius: "50px",
        width: '20px',
        height: '20px',
    },
    span: {
        marginRight: "5px",
    },
    table: { minWidth: "100rem" },
};