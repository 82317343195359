import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { utrConstants } from "../constants";
import CardHeader from "../../../../components/molecules/CardHeader";
import CustomInput from "../../../../components/atoms/CustomInput/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import { utrStyle } from "../style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import { useDepositHook } from "./../../../../hooks/deposit-hook";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";
import { useSelector } from "react-redux";
import { getPermission, getRole } from "../../../../utils/Common";
import { usePermissions } from "../../../../permissionhook";
const AddUTRForm = () => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();

  const CREATEPERMISSION = getPermission(
    permissions,
    "DEPOSITUTRFORM",
    "WRITEPERMISSION",
    getRole(role)
  );
  const {
    utr,
    formErrors,
    renderError,
    handleAddUtr,
    onHandleInputChange,
    handleDepositInputChange,
    bankAccounts,
    setDepositAccountId,
    btnLoading,
  } = useDepositHook(true, true, false, false);

  const accountOptions = bankAccounts?.map((account) => ({
    value: account?.id,
    label: account?.account_name,
  }));
  return (
    <form onSubmit={handleAddUtr}>
      <CardHeader cardHeaderTitle={utrConstants.utrEntry} />
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <CustomLabel
                label={utrConstants.utrNumber}
                additionalStyles={utrStyle.label}
              />
              <CustomInput
                type={inputTypes.text}
                placeholder={utrConstants.enterUtrNumber}
                className={`${classes.inputClass} ${
                  formErrors["utrNumber"] && classes.invalid
                }`}
                value={utr.utrNumber}
                onChange={(e) =>
                  handleDepositInputChange("utrNumber", e, "utrForm")
                }
                error={renderError("utrNumber")}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={utrConstants.account}
                additionalStyles={utrStyle.label}
              />
              <SearchBox
                options={accountOptions}
                placeholder={utrConstants.selectAccount}
                onChange={(value) => {
                  onHandleInputChange(
                    "account",
                    value[0]?.text,
                    null,
                    "utrForm"
                  );
                  setDepositAccountId(value[0]?.id);
                }}
                className={`${formErrors["account"] && classes.invalid}`}
                error={renderError("account")}
              />
            </div>
            <div className="col-md-6 mt-1">
              <CustomLabel
                label={utrConstants.amount}
                additionalStyles={utrStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                placeholder={utrConstants.enterAmount}
                className={`${classes.inputClass} ${
                  formErrors["amount"] && classes.invalid
                }`}
                value={utr.amount}
                onChange={(e) =>
                  handleDepositInputChange("amount", e, "utrForm")
                }
                error={renderError("amount")}
              />
            </div>
            <div className="col-md-6 mt-1">
              <CustomLabel
                label={utrConstants.note}
                additionalStyles={utrStyle.label}
              />
              <CustomInput
                className={classes.inputClass}
                type={inputTypes.text}
                value={utr?.note}
                onChange={(e) => handleDepositInputChange("note", e, "utrForm")}
              />
            </div>
          </div>
        </div>
      </div>
      <CardFooter
        title={utrConstants.add}
        className={`${classes.btn} btn-hover`}
        isLoading={btnLoading}
        CREATEPERMISSION={CREATEPERMISSION}
      />
    </form>
  );
};

export default AddUTRForm;
