import { color } from "../../../themes/Colors";

export const footer = {
  div: { border: "none" },
  btn: {
    backgroundColor: color._0e8d84,
    color: color._ffffff,
    cursor: "pointer",
  },
  btnDisabled: {
    backgroundColor: color._0e8d84,
    color: color._ffffff,
    cursor: "not-allowed",
    PointerEvent: "auto",
  },
};
