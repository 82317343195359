// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import UtrFormContainer from './../../../containers/DepositContainer/UtrFormContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const UTRForm = () => {

  // Returning UTRForm Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.utrEntry} />
      <Layout heading={layoutHeading.utrEntry}>
        <UtrFormContainer />
      </Layout>
    </>
  );
};

// Exporting UTRForm Function.
export default UTRForm;
