import { color } from "../../../themes/Colors";

export const headerStyle = {
    container: {
        backgroundColor: color._0e8d84,
    },
    header: {
        color: color._ffffff,
        fontSize: "14px",
        fontWeight: 400
    },
}
