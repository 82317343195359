import { color } from "../../../themes/Colors";

export const balanceInfoContainer = {
    container: {
        maxWidth: "32%",
        width: "32%",
        border: `1px solid ${color._ffffff}`,
        textAlign: "center",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderRadius: "2px",
        padding: "2px 0px"
    },
    label: {
        fontWeight: "500",
        fontSize: "9px",
        textTransform: "uppercase",
    },
    value: {
        fontWeight: "400",
        fontSize: "13px",
    },
}