import { useCallback, useEffect, useRef, useState } from "react";
import { GlobalSearchIcons } from "../assets/icons/Icons";
import {
  globalSearchConstants,
  globalSearchTableClasses,
} from "../containers/GlobalSearchContainer/constants";
import { globalSearchStyle } from "../containers/GlobalSearchContainer/style";
import { getGlobalThirdParty } from "../redux/globalSearch/action";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData, getValidateValue } from "../utils/Common";
import Badge from "../components/atoms/Badge";
import { addClient, getClient } from "../redux/client/action";
import {
  generateGlobalCardsData,
  getFormattedData,
} from "../containers/GlobalSearchContainer/utils/GlobalSearchUtils";
import {
  clientsFormError,
  formError,
  utrFormError,
} from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";
import { updateGlobalSearchData } from "../redux/filter/action";
import {
  GLOBAL_SITE_DETAILS,
  GLOBAL_SITE_USER_DETAILS,
} from "../redux/globalSearch/actionType";
import { getBankAccount } from "../redux/bankAccount/action";
import { addDeposit } from "../redux/deposit/action";
import { manualWithdrawal } from "../redux/withdrawal/action";
import { passwordRegexComp, utrRegexComp } from "../constants/CommonConstants";
const useGlobalSearchHook = (isClients = true) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { globalThirdParties } = useSelector((state) => state.globalSearch);
  const { bankAccounts } = useSelector((state) => state.bankAccount);
  const { clients } = useSelector((state) => state.client);
  const { token } = getAuthData();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
    account: "",
    utrNumber: "",
    amount: "",
  });
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [domainValue, setDomainValue] = useState();
  const [clientDetailObj, setClientDetailObj] = useState({});
  const [formattedClientData, setFormattedClientData] = useState({});
  const [searchUsername, setSearchUsername] = useState("");
  const [modalCheck, setModalCheck] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [depositInput, setDepositInput] = useState({
    account: "",
    utrNumber: "",
    amount: "",
    note: "",
  });
  console.log(userDetails, "userDetails");
  const [accountId, setAccountId] = useState();
  const [loaderKey, setLoaderKey] = useState("");
  const [individualLoader, setIndividualLoader] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [resetBtnDisable, setResetBtnDisable] = useState(true);
  const isMountedClients = useRef(true);
  const isMountedThirdParties = useRef(true);
  const inputRef = useRef(null);
  const fetchGlobalThirdParties = useCallback(async () => {
    await dispatch(
      getGlobalThirdParty(() => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setIndividualLoader(false);
  }, [dispatch]);

  useEffect(() => {
    clients?.length &&
      setFormattedClientData(getFormattedData(clients, renderActions));
  }, [clients]);

  const fetchClients = useCallback(async () => {
    setLoading(true);
    await dispatch(
      getClient(token, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
    setLoading(false);
  }, [dispatch, token]);

  const fetchBankAccounts = useCallback(async () => {
    await dispatch(getBankAccount(token));
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(updateGlobalSearchData([]));
    if (isMountedClients.current) {
      isClients && fetchClients();
    }
    if (isMountedThirdParties.current) {
      fetchGlobalThirdParties();
    }
    return () => {
      isMountedClients.current = false;
      isMountedThirdParties.current = false;
    };
  }, [fetchClients, fetchGlobalThirdParties, isClients]);

  const handleAddClientClick = async (clientDetails) => {
    await dispatch({
      type: GLOBAL_SITE_DETAILS,
      payload: clientDetails,
    });
    setModalCheck("AddClient");
    setClientDetailObj(clientDetails);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setBtnDisable(false);
    setDepositInput({
      account: "",
      utrNumber: "",
      amount: "",
      note: "",
    });
    setFormErrors({
      username: "",
      password: "",
      account: "",
      utrNumber: "",
      amount: "",
    });
    setShowModal(false);
  };
  const renderError = (field) => {
    return formErrors[field];
  };

  const validateForm = (
    usernameCheck = true,
    passwordCheck = true,
    accountCheck = true,
    amountCheck = true,
    utrCheck = true
  ) => {
    const errors = {};
    if (usernameCheck && credentials.username.length === 0) {
      errors.username = clientsFormError.username;
    }
    if (passwordCheck && credentials.password.length === 0) {
      errors.password = clientsFormError.password;
    }
    if (accountCheck && depositInput.account === "") {
      errors.account = utrFormError.account;
    }
    if (amountCheck && depositInput.amount.length === 0) {
      errors.amount = utrFormError.amount;
    }
    if (
      amountCheck &&
      depositInput.amount.length &&
      isNaN(depositInput.amount)
    ) {
      errors.amount = utrFormError.validAmount;
    }
    if (utrCheck && depositInput.utrNumber.length === 0) {
      errors.utrNumber = utrFormError.utrNumber;
    }
    setFormErrors(errors);
    return getValidateValue(errors, {
      ...clientsFormError,
      ...utrFormError,

    });
  };

  const handleAddClient = async (e) => {
    e.preventDefault();
    if (
      getValidateValue(formErrors, { ...clientsFormError, ...utrFormError }) &&
      validateForm(true, true, false, false, false)
    ) {
      setBtnLoading(true);
      const clientCredentials = {
        ...credentials,
        masterId: "Demolalit(99exch.com)",
        site: clientDetailObj.site,
      };
      await dispatch(
        addClient(
          clientCredentials,
          () => {
            setCredentials({
              username: "",
              password: "",
            });
            handleCloseModal();
          },
          token,
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          },
          () => setBtnDisable(true)
        )
      );
      handleCloseModal();
      setBtnLoading(false);
      fetchClients();
    } else {
      setBtnDisable(true);
      toast.error(formError.error); // Corrected to clientsFormError
    }
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    if (
      getValidateValue(formErrors, {
        ...clientsFormError,
        ...utrFormError,
      }) &&
      validateForm(false, false)
    ) {
      setBtnLoading(true);
      const withdrawObj = {
        ...depositInput,
        clientId: userDetails.clientId,
        site: userDetails.site,
        siteId: "1",
        type: "manual",
        username: userDetails.username,
        accountId,
      };
      await dispatch(
        manualWithdrawal(
          withdrawObj,
          () => {
            setDepositInput({
              account: "",
              utrNumber: "",
              amount: "",
              note: "",
            });
            fetchClients();
            handleCloseModal();
          },
          token,
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          },
          () => setBtnDisable(true)
        )
      );
      setBtnLoading(false);
    } else {
      setBtnDisable(true);
      toast.error(formError.error); // Corrected to clientsFormError
    }
  };

  const handleDeposit = async (e) => {
    e.preventDefault();
    if (
      getValidateValue(formErrors, {
        ...clientsFormError,
        ...utrFormError,

      }) &&
      validateForm(false, false)
    ) {
      setBtnLoading(true);
      const depositObj = {
        ...depositInput,
        clientId: userDetails.clientId,
        site: userDetails.site,
        type: "manual",
        accountId,
      };
      await dispatch(
        addDeposit(
          depositObj,
          () => {
            setDepositInput({
              account: "",
              utrNumber: "",
              amount: "",
              note: "",
            });
            fetchClients();
            handleCloseModal();
          },
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          },
          token,
          () => setBtnDisable(true)
        )
      );
      setBtnLoading(false);
    } else {
      setBtnDisable(true);
      toast.error(formError.error); // Corrected to clientsFormError
    }
  };

  const onHandleInputChange = (property, e) => {
    setBtnDisable(false);
    setCredentials({
      ...credentials,
      [property]: e.target.value,
    });
    if (property === "username") {
      const bankRegex = /^[a-zA-Z]{2,}[0-9]*$/;
      const username = e.target.value;
      if (!username.length || bankRegex.test(username)) {
        setFormErrors({ ...formErrors, username: "" });
      } else {
        setFormErrors({
          ...formErrors,
          username: clientsFormError.usernameCondition,
        });
      }
    }
    if (property === "password") {
      const password = e.target.value;
      if (!password.length || passwordRegexComp.test(password)) {
        setFormErrors({ ...formErrors, password: "" });
      } else {
        setFormErrors({
          ...formErrors,
          password: clientsFormError.passwordCondition,
        });
      }
    }
  };

  const onHandleDepositInputChange = (property, e) => {
    setBtnDisable(false);
    setDepositInput({
      ...depositInput,
      [property]: property === "account" ? e.text : e.target.value,
    });
    if (property === "account") {
      setAccountId(e.id);
      if (e.text.length) {
        setFormErrors({
          ...formErrors,
          account: "",
        });
      } else {
        setFormErrors({
          ...formErrors,
          account: utrFormError.account,
        });
      }
    } else if (property === "utrNumber") {
      const utrRegex = utrRegexComp;
      const utr = e.target.value;
      if (!utr.length || utrRegex.test(utr)) {
        setFormErrors({ ...formErrors, utrNumber: "" });
      } else {
        setFormErrors({
          ...formErrors,
          utrNumber: utrFormError.invalidUtr,
        });
      }
    } else if (property === "amount") {
      const amountRegex = /^\d+(\.\d{1,2})?$/;
      const amount = e.target.value;
      if (
        amount.length > 0 &&
        (parseInt(amount) < 100 || parseInt(amount) > 10000000)
      ) {
        if (parseInt(amount) < 100) {
          setFormErrors({
            ...formErrors,
            amount: utrFormError.amountGreaterThan100,
          });
        } else if (parseInt(amount) > 10000000) {
          setFormErrors({
            ...formErrors,
            amount: utrFormError.amountLessThan1Cr,
          });
        }
      } else if (
        !amount.length ||
        amountRegex.test(amount) ||
        parseInt(amount) >= 100 ||
        parseInt(amount) <= 10000000
      ) {
        setFormErrors({ ...formErrors, amount: "" });
      } else {
        setFormErrors({
          ...formErrors,
          amount: utrFormError.validAmount,
        });
      }
    }
  };

  const handleDomainChange = (val) => {
    setClientDetailObj({ site: val[0].text });
    setDomainValue(val[0].text);
    setResetBtnDisable(false);
    dispatch(
      updateGlobalSearchData(
        commonFilterFunction(
          { site: val[0].text, username: searchUsername },
          formattedClientData
        )
      )
    );
  };

  const commonFilterFunction = (filterObj, filterArr) => {
    filterArr = filterArr.filter((item) => {
      let matchesAllFilters = true;
      for (let key in filterObj) {
        if (filterObj[key] !== "") {
          if (key === "username" && item[key].includes(filterObj[key])) {
            matchesAllFilters = true;
          } else if (filterObj[key] && item[key] !== filterObj[key]) {
            matchesAllFilters = false;
          }
        }
        if (!matchesAllFilters) {
          break;
        }
      }
      return matchesAllFilters;
    });
    if (!filterArr.length) {
      return null;
    }
    return filterArr;
  };
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      updateGlobalSearchData(
        commonFilterFunction(
          { site: clientDetailObj.site, username: searchUsername },
          formattedClientData
        )
      )
    );
  };

  const handleUserName = (e) => {
    setResetBtnDisable(false);
    setSearchUsername(e.target.value);
  };

  const handleSearchClient = (clientDetails) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setClientDetailObj(clientDetails);
    setDomainValue(clientDetails.site);
    dispatch(
      updateGlobalSearchData(
        commonFilterFunction(
          { site: clientDetails.site, username: searchUsername },
          formattedClientData
        )
      )
    );
  };

  const cardButtons = (clientDetails) => [
    {
      title: globalSearchConstants.addClient,
      icon: GlobalSearchIcons.personAddIcon,
      style: globalSearchStyle.btn,
      onClick: () => handleAddClientClick(clientDetails),
      className: "mt-1 global-card-btn",
      iconStyle: globalSearchStyle.icon,
    },
    {
      title: globalSearchConstants.search,
      icon: GlobalSearchIcons.searchIcon,
      style: globalSearchStyle.btnSearch,
      onClick: () => handleSearchClient(clientDetails),
      className: "mt-2 mb-3 global-card-btn",
    },
  ];

  const handleDepositActionClick = async (item) => {
    !bankAccounts.length && fetchBankAccounts();
    await dispatch({
      type: GLOBAL_SITE_USER_DETAILS,
      payload: item,
    });
    setModalCheck("Deposit");
    setUserDetails(item);
    setShowModal(true);
  };
  const handleWithdrawActionClick = async (item) => {
    !bankAccounts.length && fetchBankAccounts();
    await dispatch({
      type: GLOBAL_SITE_USER_DETAILS,
      payload: item,
    });
    setModalCheck("Withdraw");
    setUserDetails(item);
    setShowModal(true);
  };
  const handleAcStatementActionClick = (item) => {
    setUserDetails(item);
    navigate("/account-statement", { state: item });
  };
  const renderActions = (item) => (
    <>
      <Badge
        title={globalSearchConstants.deposit}
        className={globalSearchTableClasses.badge}
        style={{ ...globalSearchStyle.badge, ...globalSearchStyle.badgeGreen }}
        onClick={() => handleDepositActionClick(item)}
      />
      <Badge
        title={globalSearchConstants.withdraw}
        className={globalSearchTableClasses.badge}
        style={{
          ...globalSearchStyle.badge,
          ...globalSearchStyle.badgeRedSecondary,
        }}
        onClick={() => handleWithdrawActionClick(item)}
      />
      <Badge
        title={globalSearchConstants.acStatement}
        className={globalSearchTableClasses.badge}
        style={{ ...globalSearchStyle.badge, ...globalSearchStyle.badgeRed }}
        onClick={() => handleAcStatementActionClick(item)}
      />
    </>
  );
  const formattedGlobalData = getFormattedData(clients, renderActions);
  const globalCardsData = generateGlobalCardsData(
    globalThirdParties,
    cardButtons
  );

  const domains = [
    { value: "100panel", label: "100panel" },
    ...globalThirdParties?.map((party) => ({
      value: `${party.site_id}`,
      label: `${party.site}`,
      id: party.site_id,
    })),
  ];

  const handleReset = (e) => {
    e.preventDefault();
    setSearchUsername("");
    setDomainValue("");
    setClientDetailObj({});
    fetchClients();
    dispatch(updateGlobalSearchData(formattedClientData));
    setResetBtnDisable(true);
  };

  const onClickRefresh = (key) => {
    setIndividualLoader(true);
    setLoaderKey(key);
    fetchGlobalThirdParties();
  };

  return {
    globalCardsData,
    showModal,
    handleAddClientClick,
    handleCloseModal,
    formattedGlobalData,
    loading,
    onHandleInputChange,
    renderError,
    credentials,
    formErrors,
    handleAddClient,
    btnLoading,
    domains,
    domainValue,
    handleDomainChange,
    inputRef,
    handleClick,
    handleUserName,
    handleReset,
    searchUsername,
    modalCheck,
    userDetails,
    clientDetailObj,
    onHandleDepositInputChange,
    depositInput,
    handleDeposit,
    handleWithdraw,
    onClickRefresh,
    loaderKey,
    individualLoader,
    btnDisable,
    resetBtnDisable,
  };
};
export default useGlobalSearchHook;
