import { formatDateTime, sortByDateDescending } from "../../../utils/Common";

export const accountStatementTableColumns = [
  { field: "no", header: "no", width: "5%" },
  { field: "date", header: "date", width: "15%" },
  { field: "eventType", header: "event type", width: "10%" },
  { field: "amount", header: "amount", width: "5%" },
  { field: "total", header: "total", width: "10%" },
  { field: "balance", header: "balance", width: "10%" },
  { field: "type", header: "type", width: "10%" },
  { field: "debitCredit", header: "d/c", width: "5%" },
  { field: "desc", header: "desc", width: "30%" },
];

function formatCurrency(amount, isCredit) {
  if (amount === 0) {
    return "0";
  }

  const sign =
    isCredit && isCredit.toLowerCase() === "Credit".toLowerCase() ? "" : "-";

  const formattedAmount = Math.abs(amount).toFixed(2);

  return `${sign}${formattedAmount}`;
}

export const getTypeValue = (val) => {
    switch(val){
      case 0 : return "Deposit";
      case 1 : return "Withdraw";
      case 2 : return "Profit & Loss";
       case 3 : return "Chips";
       case 4 : return "Free Chip";
       default : return "-"
    }
}
export const getFormattedData = (arr, location = false) => {
  let newArr = sortByDateDescending(
    arr.map((account) => ({
      ...account,
      date: formatDateTime(account?.created.toString()),
      amount: formatCurrency(account.amount, account?.debitCredit),
      total: formatCurrency(account.total, account?.debitCredit),
      type: getTypeValue(account.transactionType)
    })),
    "date"
  ).map((account, index) => ({
    ...account,
    no: index + 1,
  }));
  if (location) {
    let balance = 0;
    for (let i = newArr.length - 1; i >= 0; i--) {
      balance += parseInt(newArr[i].amount);
      newArr[i] = {
        ...newArr[i],
        balance: balance,
      };
    }
  }

  return newArr;
};
