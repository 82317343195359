import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { manualConstants } from "../constants";
import CardHeader from "../../../../components/molecules/CardHeader";
import CustomInput from "../../../../components/atoms/CustomInput/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import { manualStyle } from "../style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import useWithdrawHook from "../../../../hooks/withdraw-hook";
import { useSelector } from "react-redux";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";
import { usePermissions } from "../../../../permissionhook";
import { getPermission, getRole } from "../../../../utils/Common";

const ManualWithdrawForm = () => {
  const role = useSelector((state) => state.auth.role);
  const permissions = usePermissions();
  const CREATEPERMISSION = getPermission(
    permissions,
    "WITHDRAWLIST",
    "WRITEPERMISSION",
    getRole(role)
  );
  const UPDATEPERMISSION = getPermission(
    permissions,
    "WITHDRAWLIST",
    "UPDATEPERMISSION",
    getRole(role)
  );
  const {
    formErrors,
    renderError,
    onHandleInputChange,
    onHandleSelect,
    withdrawManual,
    handleAddWithdrawalManual,
    setWithdrawUserName,
    setWithdrawAccountName,
    btnLoading,
  } = useWithdrawHook(false, true, true, false, UPDATEPERMISSION);
  const { clients } = useSelector((state) => state.client);
  const { bankAccounts } = useSelector((state) => state.bankAccount);
  const userOptions = clients?.map((client) => ({
    value: client.clientId,
    label: client.username,
  }));
  const accountOptions = bankAccounts?.map((account) => ({
    value: account.id,
    label: account.account_name,
  }));
  return (
    <form onSubmit={handleAddWithdrawalManual}>
      <CardHeader cardHeaderTitle={manualConstants.manualWithdraw} />
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <CustomLabel
                label={manualConstants.client}
                additionalStyles={manualStyle.label}
              />
              <SearchBox
                options={userOptions}
                placeholder={"Select Client"}
                className={`${formErrors["client"] && classes.invalid}`}
                onChange={(value) => {
                  setWithdrawUserName(value[0]?.text);
                  onHandleSelect("client", value[0]);
                }}
                error={renderError("client")}
              />
            </div>
            <div className="col-md-6">
              <CustomLabel
                label={manualConstants.account}
                additionalStyles={manualStyle.label}
              />
              <SearchBox
                options={accountOptions}
                className={`${formErrors["account"] && classes.invalid}`}
                placeholder={"Select Account"}
                onChange={(value) => {
                  setWithdrawAccountName(value[0]?.text);
                  onHandleSelect("account", value[0]?.id);
                }}
                error={renderError("account")}
              />
            </div>
            <div className="col-md-6 mt-2">
              <CustomLabel
                label={manualConstants.utrNumber}
                additionalStyles={manualStyle.label}
              />
              <CustomInput
                type={inputTypes.text}
                className={`${classes.inputClass} ${
                  formErrors["utr_number"] && classes.invalid
                }`}
                placeholder={manualConstants.enterUtrNumber}
                value={withdrawManual?.utr_number}
                onChange={(e) => onHandleInputChange("utr_number", e)}
                error={renderError("utr_number")}
              />
            </div>

            <div className="col-md-6 mt-2">
              <CustomLabel
                label={manualConstants.amount}
                additionalStyles={manualStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                className={`${classes.inputClass} ${
                  formErrors["amount"] && classes.invalid
                }`}
                placeholder={manualConstants.enterAmount}
                value={withdrawManual?.amount}
                onChange={(e) => onHandleInputChange("amount", e)}
                error={renderError("amount")}
              />
            </div>
          </div>
        </div>
      </div>
      <CardFooter
        title={manualConstants.add}
        className={`${classes.btn} btn-hover`}
        isLoading={btnLoading}
        CREATEPERMISSION={CREATEPERMISSION}
      />
    </form>
  );
};

export default ManualWithdrawForm;
