import { color } from "../../../themes/Colors";
import { statsStyle } from "../style";

export const statsTableColumns = [
    { field: 'no', header: 'no', width: '2%' },
    { field: 'account_id', header: 'id', width: '6%' },
    { field: 'username', header: 'username', width: '7%' },
    { field: 'totalClients', header: 'total clients', width: '8%', backgroundColor: color._lightSkyBlue },
    { field: 'totalApprovedDeposits', header: 'total approved deposit', width: '9%', backgroundColor: color._lightGreen },
    { field: 'totalNoOfApprovedDeposits', header: 'number approved deposit', width: '10%', backgroundColor: color._lightYellow },
    { field: 'totalRejectedDeposits', header: 'total rejected deposit', width: '10%', backgroundColor: color._indianRed },
    { field: 'totalNoOfRejectedDeposits', header: 'number rejected deposit', width: '10%', backgroundColor: color._lightYellow },
    { field: 'totalApprovedWithdrawals', header: 'total approved withdraw', width: '10%', backgroundColor: color._lightGreen },
    { field: 'totalNoOfApprovedWithdrawals', header: 'number approved withdraw', width: '10%', backgroundColor: color._lightYellow },
    { field: 'totalRejectedWithdrawals', header: 'total rejected withdraw', width: '10%', backgroundColor: color._indianRed },
    { field: 'totalNoOfRejectedWithdrawals', header: 'number rejected withdraw', width: '10%', backgroundColor: color._lightYellow },
];

export const getFormattedStats = (arr) => {
    return arr.map((stats, index) => ({
        ...stats,
        no: index + 1,
    }));
};