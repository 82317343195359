import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { depositConstants, statusOptions, typeOptions } from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { depositStyle } from "../style";
import useGlobalSearchHook from "../../../../hooks/global-search-hook";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";

const SearchForm = (props) => {
  const {
    formErrors,
    renderError,
    formChanged,
    handleDepositHistorySelectChange,
    historyFilters,
    handleDepositHistoryInput,
    handleDepositHistoryRefresh,
    formSubmitState,
    selectStatusRef,
    selectTypeRef,
    refreshBtnDisable
  } = props;
  const { domains } = useGlobalSearchHook(false);
  return (
    <form>
      <div className="row m-0 p-2">
        <div className="col-md-3">
          <CustomLabel label={depositConstants.domain} />
          <SearchBox
            options={domains}
            placeholder={depositConstants.selectDomain}
            defaultValue={historyFilters.site || ""}
            value={historyFilters.site || ""}
            onChange={(value) => handleDepositHistorySelectChange(value[0]?.text, "site")}
          // formSubmitState={formSubmitState}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={depositConstants.status} />
          <CustomSelectBox
            options={statusOptions}
            ref={selectStatusRef}
            // value={historyFilters.status || ""}
            className={classes.selectBox}
            onChange={(e) => handleDepositHistorySelectChange(e, "status")}
          // onChange={(e) => {
          //   if (e.value !== "") handleDepositHistorySelectChange(e, "status");
          // }}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={depositConstants.type} />
          <CustomSelectBox
            options={typeOptions}
            ref={selectTypeRef}
            className={classes.selectBox}
            onChange={(e) => handleDepositHistorySelectChange(e, "type")}
            // onChange={(e) => {
            //   if (e.value !== "") handleDepositHistorySelectChange(e, "type");
            // }}
            property={"type"}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={depositConstants.username} />
          <CustomInput
            className={classes.inputClass}
            value={historyFilters?.username}
            type={inputTypes.text}
            onChange={(e) => handleDepositHistoryInput(e, "username")}
          />
        </div>
        <div className="col-md-3 mt-1">
          <CustomLabel label={depositConstants.utrNumber} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.numeric}
            value={historyFilters?.utrNumber}
            onChange={(e) => handleDepositHistoryInput(e, "utrNumber")}
          />
        </div>
        <div className="col-md-3 mt-1">
          <CustomLabel label={depositConstants.startDate} />
          <CustomInput
            className={`${classes.inputClass} ${formChanged && formErrors["startDate"] && classes.invalid
              }`}
            type={inputTypes.date}
            name="startDate"
            value={historyFilters?.startDate}
            onChange={(e) => handleDepositHistorySelectChange(e, "startDate")}
            error={renderError("startDate")}
          />
        </div>
        <div className="col-md-3 mt-1">
          <CustomLabel label={depositConstants.endDate} />
          <CustomInput
            className={`${classes.inputClass} ${formChanged && formErrors["endDate"] && classes.invalid
              }`}
            type={inputTypes.date}
            name="endDate"
            value={historyFilters?.endDate}
            onChange={(e) => handleDepositHistorySelectChange(e, "endDate")}
            error={renderError("endDate")}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center mt-md-4 mt-2">
          <Button
            title={depositConstants.refresh}
            style={depositStyle.searchBtn}
            className="py-1 btn-hover"
            onClick={handleDepositHistoryRefresh}
            disabled={refreshBtnDisable}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
