import { toast } from "react-toastify";
import { responseCode } from "../../constants/response-code";
import { endPoints } from "../../services/EndPoints";
import {
  _crossPlatformGet,
  _crossXp_put,
  _get,
  _getDeposit,
  _post,
  _postCrossPlatToken,
  _put,
} from "../../services/HttpMethods";
import {
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_FAIL,
  ADD_DEPOSIT_FAIL,
  UPDATE_DEPOSIT_STATUS_SUCCESS,
  UPDATE_DEPOSIT_STATUS_FAIL,
  GET_DEPOSIT_HISTORY_SUCCESS,
  GET_DEPOSIT_HISTORY_FAIL,
  UPDATE_FILTERED_DEPOSIT_DATA,
} from "./actionType";
import { logoutErrors } from "../../constants/ErrorMessage";
import { LOGOUT_SUCCESS } from "../auth/actionType";
import { removeAuthData } from "../../utils/Common";
import { constants } from "../../constants/CommonConstants";

export const addDeposit = (
  deposit,
  success,
  logoutFunc,
  token,
  btnDisableFunc = null
) => {
  return async (dispatch) => {
    try {
      const response = await _postCrossPlatToken(
        endPoints.deposits,
        deposit,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { status, msg } = response.data;
      if (status !== constants.status_200 && status !== constants.status_201) {
        throw new Error(msg);
      }
      toast.success(msg);
      if (success) success();
    } catch (error) {
      if (error.message === "Unauthorized") {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        if (btnDisableFunc) {
          btnDisableFunc();
        }
        dispatch({
          type: ADD_DEPOSIT_FAIL,
          payload: error.message,
        });
        toast.error(error.message);
      }
    }
  };
};

export const getDeposit = (
  token = null,
  obj = null,
  showToast = true,
  logoutFunc
) => {
  return async (dispatch) => {
    try {
      const response = await _getDeposit(
        endPoints.deposits,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      }
      if (response.status === 200) {
        dispatch({
          type: GET_DEPOSIT_SUCCESS,
          payload: response.data || [],
        });
      } else if (response.status === 404) {
        dispatch({
          type: GET_DEPOSIT_SUCCESS,
          payload: [], // Set deposits to an empty array on 404
        });
        if (showToast) {
          // toast.info(response.msg); // Show toast only if showToast is true
        }
      } else {
        throw new Error(response.msg);
      }
    } catch (error) {
      if (error.message === "Unauthorized") {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_DEPOSIT_FAIL,
          payload: error.message,
        });
      }
    }
  };
};

export const updateDepositStatus = (
  payload,
  token,
  logoutFunc,
  isClientParams = true
) => {
  return async (dispatch, getState) => {
    try {
      const response = await _crossXp_put(
        endPoints.despoitUpdateAction,
        payload,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.data.status !== responseCode.OK) {
        throw new Error(response.data.msg);
      }
      toast.success(response.data.msg);
      const { deposits, depositHistory } = getState().deposit;
      const depositChanged = deposits.deposits.some(
        (deposit) =>
          deposit.id === payload.id && deposit.status !== payload.status
      );
      const depositHistoryChanged = depositHistory.some(
        (deposit) =>
          deposit.id === payload.id && deposit.status !== payload.status
      );

      let filterData = isClientParams
        ? { status: 0, type: "client" }
        : { status: 0 };

      if (depositChanged) {
        dispatch(getDeposit(token, filterData));
      }

      if (depositHistoryChanged) {
        dispatch(getDepositHistory(token));
      }
    } catch (error) {
      if (error.message === "Unauthorized") {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: UPDATE_DEPOSIT_STATUS_FAIL,
          payload: error.message,
        });
      }
    }
  };
};

export const getDepositHistory = (token, obj, showToast = true, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _crossPlatformGet(
        endPoints.depositHistory,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status === 200) {
        dispatch({
          type: GET_DEPOSIT_HISTORY_SUCCESS,
          payload: response.data,
        });
      } else if (response.status === 404) {
        dispatch({
          type: GET_DEPOSIT_HISTORY_SUCCESS,
          payload: [], // Set deposits to an empty array on 404
        });
        if (showToast) {
          // toast.info(response.msg); // Show toast only if showToast is true
        }
      } else {
        throw new Error(response.msg);
      }
    } catch (error) {
      if (error.message === "Unauthorized") {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_DEPOSIT_HISTORY_FAIL,
          payload: error.message,
        });
      }
    }
  };
};
