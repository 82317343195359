import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import {
  clientsConstants,
  depositCountOptions,
  sourceOptions,
} from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { clientStyle } from "../style";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";

const SearchForm = (props) => {
  const {
    handleSearchSubmit,
    onHandleSelect,
    handleReset,
    filters,
    dropdownOptions,
    renderError,
    formErrors,
    formChanged,
    handleChange,
    domains,
    formSubmitState,
    selectSourceRef,
    selectDepositCountRef,
  } = props;
  return (
    <form onSubmit={handleSearchSubmit}>
      <div className="row m-0 pt-1 pb-1">
        <div className="col-md-3">
          <CustomLabel label={clientsConstants.domain} />
          <SearchBox
            options={domains}
            placeholder={clientsConstants.selectDomain}
            name="site"
            onChange={(value) =>
              onHandleSelect("site", value[0]?.text, "searchClient")
            }
            // formSubmitState={formSubmitState}
            value={filters.site}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={clientsConstants.username} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.text}
            name="username"
            value={filters.username}
            onChange={handleChange}
            dropdownOptions={dropdownOptions}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={clientsConstants.startDate} />
          <CustomInput
            className={`${classes.inputClass} ${
              formChanged && formErrors["startDate"] && classes.invalid
            }`}
            type={inputTypes.date}
            name="startDate"
            value={filters.startDate}
            onChange={handleChange}
            error={renderError("startDate")}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={clientsConstants.endDate} />
          <CustomInput
            className={`${classes.inputClass} ${
              formChanged && formErrors["endDate"] && classes.invalid
            }`}
            type={inputTypes.date}
            name="endDate"
            value={filters.endDate}
            onChange={handleChange}
            error={renderError("endDate")}
          />
        </div>
        <div className="col-md-3 mt-1">
          <CustomLabel label={clientsConstants.source} />
          <CustomSelectBox
            options={sourceOptions}
            defaultValue=""
            className={classes.selectBox}
            name="source"
            onChange={(value) =>
              onHandleSelect("source", value.value, "searchClient")
            }
            ref={selectSourceRef}
          />
        </div>
        <div className="col-md-3 mt-1">
          <CustomLabel label={clientsConstants.depositCount} />
          <CustomSelectBox
            options={depositCountOptions}
            defaultValue=""
            className={classes.selectBox}
            name="depositCount"
            onChange={(value) =>
              onHandleSelect("depositCount", value.value, "searchClient")
            }
            ref={selectDepositCountRef}
          />
        </div>
        <div className="col-md-3 d-flex align-items-end my-2 my-md-0 mt-1">
          <Button
            title={clientsConstants.search}
            style={clientStyle.searchBtn}
            className="pb-1 pt-1 btn-hover"
          />
        </div>
        <div className="col-md-3 d-flex align-items-end mt-1">
          <Button
            title={clientsConstants.reset}
            type={inputTypes.button}
            style={clientStyle.searchBtn}
            className="pb-1 pt-1 btn-hover"
            onClick={handleReset}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
