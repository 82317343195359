import React from "react";
import Button from "../../atoms/Button/Button";
import { cardStyles } from "./style";

const CustomCards = ({ icon, cardTitle, btnClassName, btnTitle, type, btnStyle, onClick }) => {
    return (
        <div className="card mb-3">
            <div className="card-body d-flex align-items-start text-nowrap overflow-hidden text-truncate">
                <div className="me-3">{icon}</div>
                <div style={cardStyles.cardItem}>
                    <div>{cardTitle}</div>
                    <Button title={btnTitle} className={btnClassName} type={type} style={btnStyle} onClick={onClick} />
                </div>
            </div>
        </div>
    );
};

export default CustomCards;
