export const withdrawConstants = {
  domain: "Domain:",
  status: "Status:",
  type: "Type:",
  username: "Username:",
  utrNumber: "UTR Number:",
  startDate: "Start Date:",
  endDate: "End Date:",
  search: "Search",
  reset: "Reset",
  selectDomain: "Select Domain",
};

export const statusOptions = [
  { value: "", label: "Select status" },
  { value: 0, label: "Pending" },
  { value: 1, label: "Approved" },
  { value: 2, label: "Rejected" },
];

export const typeOptions = [
  { value: "", label: "Select type" },
  { value: "manual", label: "Manual" },
  { value: "client", label: "Client" },
  { value: "UTR Entry", label: "UTR Entry" },
];
