export const clientsConstants = {
  domain: "Domain:",
  source: "Source:",
  username: "Username:",
  startDate: "Start Date:",
  endDate: "End Date:",
  depositCount: "Deposit Count:",
  search: "Search",
  reset: "Reset",
  active: "Active",
  inactive: "InActive",
  acStatement: "A/C Statement",
  depositHistory: "Deposit history",
  withdrawHistory: "Withdraw history",
  selectDomain: "Select Domain",
};

export const sourceOptions = [
  { value: "", label: "Select source" },
  { value: "Signup", label: "Signup" },
  { value: "Branch", label: "Branch" },
  { value: "Manual", label: "Manual" },
  { value: "Wallet", label: "Wallet" },
];

export const depositCountOptions = [
  { value: "", label: "Select deposit count" },
  { value: "0", label: 0 },
  { value: "1", label: 1 },
  { value: "2", label: 2 },
  { value: "3", label: 3 },
  { value: "4", label: 4 },
  { value: "5", label: "More than 5" },
];
