import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDeposit,
  getDeposit,
  getDepositHistory,
  updateDepositStatus,
} from "../redux/deposit/action";
import {
  getAuthData,
  getValidateValue,
  removeSpaces,
  statusFilter,
} from "../utils/Common";
import { depositConstants } from "../containers/DepositContainer/DepositCashSettlementContainer/constants";
import { depositStyle } from "../containers/DepositContainer/DepositListContainer/style";
import { dateError, formError, utrFormError } from "../constants/ErrorMessage";
import { toast } from "react-toastify";
import { getClient } from "../redux/client/action";
import { getBankAccount } from "../redux/bankAccount/action";
import ReusableIcon from "../components/atoms/ReusableIcon";
import { TableIcons } from "../assets/icons/Icons";
import { formatDepositListData } from "../containers/DepositContainer/utils/DepositUtils";
import Swal from "sweetalert2";
import _ from "lodash";
import { classes, utrRegexComp } from "../constants/CommonConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGOUT_SUCCESS } from "../redux/auth/actionType";
import Badge from "../components/atoms/Badge";
import { GET_DEPOSIT_LIST_ITEM_DATA } from "../redux/deposit/actionType";

export const useDepositHook = (
  isClients = true,
  isAccounts = true,
  isDeposit = true,
  isDepositHistory = false,
  UPDATEPERMISSION = true,
  DELETEPERMISSION = true
) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deposits, depositAdded, depositHistory, depositUpdated } =
    useSelector((state) => state.deposit);
  const { clients } = useSelector((state) => state.client);
  const { bankAccounts: _bankAccounts } = useSelector(
    (state) => state.bankAccount
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const { token } = getAuthData();
  const [pendingDeposits, setPendingDeposits] = useState([]);
  const [allDeposits, setAllDeposits] = useState([]);
  const [removedDeposits, setRemovedDeposits] = useState([]);
  const [base64String, setBase64String] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [depositFilters, setDepositFilters] = useState({
    siteId: null,
    status: null,
    type: null,
    username: "",
    utrNumber: "",
    startDate: null,
    endDate: null,
    account: null,
  });
  const [historyFilters, setHistoryFilters] = useState({
    site: null,
    startDate: "",
    endDate: "",
    ...(location?.state && { clientId: location?.state?.clientId }),
  });
  const [refreshBtnDisable, setRefreshBtnDisable] = useState(
    location?.state ? false : true
  );
  const [btnDisable, setBtnDisable] = useState();
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [dateFieldError, setDateFieldError] = useState(false);
  const [formSubmitState, setFormSubmitState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalItemData, setModalItemData] = useState({});
  const [modalCheck, setModalCheck] = useState("Approve");
  const [modalFormErrors, setModalFormErrors] = useState({
    reason: "",
    otherReason: "",
  });
  const [rejectInput, setRejectInput] = useState({
    file: "",
    reason: {},
    otherReason: "",
  });
  const isMountedClients = useRef(true);
  const isMountedAccounts = useRef(true);
  const isMountedDeposits = useRef(true);
  const isMountedDepositHistory = useRef(true);
  const fileInputRef = useRef(null);
  const selectStatusRef = useRef(null);
  const selectTypeRef = useRef(null);
  const resetStatusRef = useRef(null);
  const resetTypeRef = useRef(null);
  const selectBoxRef = useRef(null);

  const fetchBankAccounts = useCallback(async () => {
    setLoading(true);
    await dispatch(getBankAccount(token));
    setLoading(false);
  }, [dispatch, token]);

  const fetchDeposits = useCallback(
    async (obj = null, showToast = true) => {
      setLoading(true);
      await dispatch(
        getDeposit(token, obj, showToast, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
      setLoading(false);
    },
    [dispatch, token]
  );

  const fetchDepositHistory = useCallback(
    async (obj = null, showToast = true) => {
      setLoading(true);
      await dispatch(
        getDepositHistory(token, obj, showToast, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
      setLoading(false);
    },
    [dispatch, token]
  );

  useEffect(() => {
    // if (isMountedClients.current) {
    //   isClients && fetchClients();
    // }
    if (isMountedAccounts.current) {
      if (location.pathname === "/deposits/utr-entry") {
        isAccounts && fetchBankAccounts();
      }
    }
    if (isMountedDeposits.current) {
      if (location.pathname === "/deposits/utr-entry/list") {
        isDeposit && fetchDeposits({ status: 0 }, true);
      } else if (location.pathname === "/deposits/utr-entry/removed-list") {
        isDeposit && fetchDeposits({}, true);
      } else {
        if (location.state?.dashboardCheck) {
          let newFilter = location.state;
          delete newFilter["dashboardCheck"];
          setDepositFilters({ ...depositFilters, ...newFilter });
          isDeposit && fetchDeposits(newFilter, true);

          if (Object.values(newFilter).length && resetStatusRef.current) {
            resetStatusRef.current.value = newFilter.status; // Reset the select box value
          }
        } else {
          setDepositFilters({ ...depositFilters, status: 0, type: "client" });
          isDeposit && fetchDeposits({ status: 0, type: "client" }, true);

          if (resetStatusRef.current) {
            resetStatusRef.current.value = 0; // Reset the select box value
          }
          if (resetTypeRef.current) {
            resetTypeRef.current.value = "Client"; // Reset the select box value
          }
        }
      }
    }
    if (isMountedDepositHistory.current) {
      isDepositHistory &&
        fetchDepositHistory(
          location?.state ? { clientId: location?.state?.clientId } : {}
        );
    }

    return () => {
      isMountedClients.current = false;
      isMountedAccounts.current = false;
      isMountedDeposits.current = false;
      isMountedDepositHistory.current = false;
    };
  }, [
    fetchBankAccounts,
    fetchDeposits,
    fetchDepositHistory,
    isClients,
    isAccounts,
    isDeposit,
    isDepositHistory,
  ]);

  const bankAccounts = deposits?.bankAccounts ?? _bankAccounts;

  useEffect(() => {
    setAllDeposits(
      deposits.deposits &&
        deposits.deposits
          .filter((item) => !item.deleted)
          .filter(statusFilter([0, 1, 2]))
    );
    setPendingDeposits(
      deposits.deposits &&
        deposits.deposits
          .filter((item) => !item.deleted)
          .filter(statusFilter([0]))
    );
    setRemovedDeposits(
      deposits.deposits &&
        deposits.deposits
          .filter((item) => !item.deleted)
          .filter(statusFilter([2]))
    );
  }, [deposits.deposits]);

  const [loading, setLoading] = useState(false);
  const [depositDetails, setDepositDetails] = useState({
    utrNumber: "",
    account: "",
    amount: "",
    // user: "",
    note: "",
  });
  const [depositAccountId, setDepositAccountId] = useState();

  const [utr, setUtr] = useState({
    utrNumber: "",
    account: null,
    amount: "",
    // user: null,
    note: "",
  });
  const [depositCashSettlement, setDepositCashSettlement] = useState({
    account: null,
    amount: "",
  });
  const [formErrors, setFormErrors] = useState({
    utr_number: "",
    utrNumber: "",
    account: null,
    amount: "",
    user: null,
    startDate: "",
    endDate: "",
    qrCode: "",
  });

  const [searchInput, setSearchInput] = useState({
    startDate: "",
    endDate: "",
  });
  const [formChanged, setFormChanged] = useState(false);
  // Function to display errors
  const renderError = (field) => {
    return formErrors[field];
  };

  const validateForm = (
    obj,
    utrCheck = true,
    accountCheck = true
    // userCheck = true,
    // qrCodeCheck = true
  ) => {
    const errors = {};
    if (utrCheck && obj.utrNumber.length === 0) {
      errors.utrNumber = utrFormError.utrNumber;
    }
    if (accountCheck && (obj.account === null || obj.account === "")) {
      errors.account = utrFormError.account;
    }
    if (obj.amount.length === 0) {
      errors.amount = utrFormError.amount;
    }
    if (obj.amount.length && isNaN(obj.amount)) {
      errors.amount = utrFormError.validAmount;
    }
    setFormErrors(errors);
    return getValidateValue(errors, utrFormError);
  };

  const handleAddUtr = async (e) => {
    e.preventDefault();
    // First, validate the form without the qrCodeCheck
    const formIsValid = validateForm(utr, true, true);

    if (formIsValid) {
      setBtnLoading(true);
      await dispatch(
        addDeposit(
          {
            ...utr,
            clientId: "0",
            account: utr.account,
            accountId: depositAccountId,
            // clientId: user,
            type: depositConstants.utrEntry,
            site_id: "0",
            site: "100panel",
          },
          () => navigate("/deposits/utr-entry/list"),
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          },
          token
        )
      );
      setBtnLoading(false);
    } else {
      toast.error(utrFormError.error); // Assuming utrFormError.error contains the error message
    }
  };

  const handleAddDepositCashSettlement = async (e) => {
    e.preventDefault();
    // Validate form first
    if (
      getValidateValue(formErrors, utrFormError) &&
      validateForm(depositCashSettlement, false, true, false)
    ) {
      setLoading(true);
      //Getting depositCashSettlement values will use them
      console.log("depositCashSettlement", depositCashSettlement);
      setLoading(false);
    } else {
      toast.error(formError.error);
    }
  };

  const handleDepositSubmit = async (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileInput");
    const file = fileInput.files[0]; // Get the selected file
    if (
      getValidateValue(formErrors, utrFormError) &&
      validateForm(depositDetails)
    ) {
      // const { user, ...detailsWithoutUser } = depositDetails;
      try {
        if (file) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64String_ = reader.result.split(",")[1];
            setBase64String(base64String_);
            const clientCredentials = {
              ...depositDetails,
              // clientId: userId,
              clientId: "0",
              type: depositConstants.utrEntry,
              account: depositDetails.account,
              accountId: depositAccountId,
              site_id: "0",
              site: "100panel",
              qrCode: {
                name: file.name,
                contentType: file.type,
                data: base64String_,
              },
            };
            setBtnLoading(true);
            await dispatch(
              addDeposit(
                clientCredentials,
                () => {
                  navigate("/deposits/utr-entry/list");
                  if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Clear the file input after successful dispatch
                  }
                },

                () => {
                  dispatch({
                    type: LOGOUT_SUCCESS,
                    payload: [],
                  });
                  navigate("/");
                },
                token
              )
            );
            setBtnLoading(false);
            setDepositDetails({
              utrNumber: "",
              amount: "",
              note: "",
              user: "",
              account: "",
            });
          };
          reader.readAsDataURL(file);
        } else {
          const clientCredentials = {
            ...depositDetails,
            // clientId: userId,
            clientId: "0",
            account: depositDetails.account,
            accountId: depositAccountId,
            type: depositConstants.utrEntry,
            site_id: "0",
            site: "100panel",
          };
          setBtnLoading(true);
          await dispatch(
            addDeposit(
              clientCredentials,
              () => navigate("/deposits/utr-entry/list"),
              () => {
                dispatch({
                  type: LOGOUT_SUCCESS,
                  payload: [],
                });
                navigate("/");
              },
              token
            )
          );
          setBtnLoading(false);
          setDepositDetails({
            utrNumber: "",
            amount: "",
            note: "",
            user: "",
            account: "",
          });
        }
      } catch (error) {
        console.error("Error converting file to base64:", error);
        // Handle error appropriately
      }
    } else {
      toast.error(formError.error);
    }
  };

  const handleDepositInputChange = (property, e, defaultState = "deposit") => {
    if (defaultState === "deposit") {
      setDepositDetails({ ...depositDetails, [property]: e.target.value });
    } else if (defaultState === "utrForm") {
      setUtr({ ...utr, [property]: e.target.value });
    } else {
      setDepositCashSettlement({
        ...depositCashSettlement,
        [property]: e.target.value,
      });
    }
    if (property === "qrCode" && e.target.value !== "") {
      setFormErrors({ ...formErrors, qrCode: "" });
      return; // Exit early if qrCode and image uploaded
    }

    if (property === "utrNumber") {
      const utrRegex = utrRegexComp;
      const utr = e.target.value;
      if (!utr.length || utrRegex.test(utr)) {
        setFormErrors({ ...formErrors, utrNumber: "" });
      } else {
        setFormErrors({
          ...formErrors,
          utrNumber: utrFormError.invalidUtr,
        });
      }
    } else if (property === "amount") {
      const amountRegex = /^\d+(\.\d{1,2})?$/;
      const amount = e.target.value;

      if (!amount.length || amountRegex.test(amount)) {
        if (parseFloat(amount) < 100) {
          setFormErrors({
            ...formErrors,
            amount: utrFormError.minAmount100,
          });
        } else {
          setFormErrors({ ...formErrors, amount: "" });
        }
      } else {
        setFormErrors({
          ...formErrors,
          amount: utrFormError.validAmount,
        });
      }
    }
  };
  const [userId, setUserId] = useState();
  const onHandleInputChange = (
    property,
    value,
    e,
    defaultState = "deposit"
  ) => {
    if (defaultState === "deposit") {
      setUserId(value.id);
      setDepositDetails({
        ...depositDetails,
        [property]: property === "user" ? value.text : value,
      });
    } else if (defaultState === "utrForm") {
      setUtr({
        ...utr,
        [property]: value,
      });
    } else {
      setDepositCashSettlement({
        ...depositCashSettlement,
        [property]: value,
      });
    }
    if (property === "account") {
      if (value !== "Select Account Options") {
        setFormErrors({ ...formErrors, account: "" });
      }
    }
    if (property === "user") {
      if (e !== "Select user" || e !== "Select user") {
        setFormErrors({ ...formErrors, user: "" });
      }
    }
  };

  const validateDates = (name, value) => {
    let error = "";
    let today = new Date();
    let startDate = new Date(historyFilters.startDate || "");
    let endDate = new Date(historyFilters.endDate || "");

    if (name === "startDate") {
      startDate = new Date(value);
    } else if (name === "endDate") {
      endDate = new Date(value);
    }

    if (startDate && endDate && startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        error = name === "startDate" ? dateError.startDate : dateError.endDate;
      } else {
        setFormErrors({
          ...formErrors,
          startDate: "",
          endDate: "",
        }); // Clear error if dates are valid
      }
    }

    // Check end date against today
    if (endDate !== "" && endDate > today) {
      error = dateError.endCannotGreat;
    }

    // Check start date against today
    if (startDate !== "" && startDate > today) {
      error = dateError.startCannotGreat;
    }

    return error;
  };

  const handleSearchFormInputChange = (e) => {
    const { name, value } = e.target;
    const dateError = validateDates(name, value);

    setSearchInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormChanged(true);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: dateError,
    }));
  };

  const openProof = (item) => {
    Swal.fire({
      imageUrl: item.imageUrl,
      imageHeight: 500,
      imageAlt: "A tall image",
      showCloseButton: true,
      customClass: {
        confirmButton: "proof-class",
        closeButton: "proof-close-btn",
      },
    });
  };

  const handleDepositApproved = async (e) => {
    e.preventDefault();
    setBtnDisable(true);
    const status = modalItemData.status === 0 ? 1 : 0;
    await dispatch(
      updateDepositStatus(
        { id: modalItemData.id, status: status },
        token,
        () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        }
      )
    );
    setDepositFilters({
      utrNumber: "",
      amount: "",
      note: "",
      account: "",
    });

    if (resetStatusRef.current) {
      resetStatusRef.current.value = 0; // Reset the select box value
    }
    if (resetTypeRef.current) {
      resetTypeRef.current.value = "Select type"; // Reset the select box value
    }
    //For clearing deposit history form on action update
    setHistoryFilters({
      site: null,
      username: "",
      utrNumber: "",
      startDate: "",
      endDate: "",
    });
    if (selectStatusRef.current) {
      selectStatusRef.current.value = ""; // Reset the select box value
    }
    if (selectTypeRef.current) {
      selectTypeRef.current.value = "Select type"; // Reset the select box value
    }
    setShowModal(false);
    setBtnDisable(false);
  };

  const validateModalForm = (obj) => {
    const errors = {};
    if (!obj.reason.value || obj.reason?.value === "") {
      errors.reason = utrFormError.reason;
    }
    if (obj.reason?.value === "Other" && obj.otherReason === "") {
      errors.otherReason = utrFormError.reason;
    }
    // Set formErrors state with the errors
    setModalFormErrors(errors);
    return getValidateValue(errors, utrFormError);
  };

  const handleDepositReject = async (e) => {
    e.preventDefault();
    setBtnDisable(true);
    if (
      getValidateValue(modalFormErrors, utrFormError) &&
      validateModalForm(rejectInput)
    ) {
      let status = 2;
      if (modalItemData.status === 0 || modalItemData.status === 1) {
        status = 2;
      }
      await dispatch(
        updateDepositStatus(
          {
            id: modalItemData.id,
            status: status,
            reason:
              rejectInput.reason?.value === "Other"
                ? rejectInput.otherReason
                : rejectInput.reason?.value,
          },
          token,
          () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          }
        )
      );
      setDepositFilters({
        utrNumber: "",
        amount: "",
        note: "",
        // user: "",
        account: "",
      });
      if (resetStatusRef.current) {
        resetStatusRef.current.value = 0; // Reset the select box value
      }
      if (resetTypeRef.current) {
        resetTypeRef.current.value = "Select type"; // Reset the select box value
      }
      //For clearing deposit history form on action update
      setHistoryFilters({
        site: null,
        username: "",
        utrNumber: "",
        startDate: "",
        endDate: "",
      });
      if (selectStatusRef.current) {
        selectStatusRef.current.value = ""; // Reset the select box value
      }
      if (selectTypeRef.current) {
        selectTypeRef.current.value = "Select type"; // Reset the select box value
      }
      handleCloseModal();
    } else {
      setBtnDisable(true);
      toast.error(formError.error);
    }
  };

  const handleDelete = async (item) => {
    setBtnLoading(true);
    await dispatch(
      updateDepositStatus(
        { id: item.id, deleted: true },
        token,
        () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        },
        false
      )
    );
    setBtnLoading(false);
  };

  const renderDelete = (item) => {
    return (
      <Badge
        title={depositConstants.delete}
        className={classes.badge}
        style={{
          ...(DELETEPERMISSION
            ? depositStyle.badge
            : depositStyle.Disabledbadge),
          ...depositStyle.badgeRed,
        }}
        onClick={DELETEPERMISSION ? () => handleDelete(item) : () => {}}
      />
    );
  };

  const openApproveModal = async (item) => {
    await dispatch({
      type: GET_DEPOSIT_LIST_ITEM_DATA,
      payload: item,
    });
    setModalItemData(item);
    setModalCheck("Approve");
    setShowModal(true);
  };

  const openRejectModal = async (item) => {
    await dispatch({
      type: GET_DEPOSIT_LIST_ITEM_DATA,
      payload: item,
    });
    setModalItemData(item);
    setModalCheck("Reject");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalFormErrors({
      reason: "",
      otherReason: "",
    });
    setRejectInput({
      reason: {},
      otherReason: "",
    });
    if (selectBoxRef.current) {
      selectBoxRef.current.value = "Select Reason";
    }
    setShowModal(false);
    setBtnDisable(false);
  };

  const renderActions = (item) => {
    if (
      item.status === 0 &&
      item.type.toLowerCase() !== "UTR Entry".trim().toLowerCase()
    ) {
      return (
        <>
          <Badge
            title={depositConstants.approve}
            className={classes.badge}
            style={{
              ...(UPDATEPERMISSION
                ? depositStyle.badge
                : depositStyle.Disabledbadge),
              ...depositStyle.badgeGreen,
            }}
            onClick={UPDATEPERMISSION ? () => openApproveModal(item) : () => {}}
          />
          <Badge
            title={depositConstants.reject}
            className={classes.badge}
            style={{
              ...(UPDATEPERMISSION
                ? depositStyle.badge
                : depositStyle.Disabledbadge),
              ...depositStyle.badgeRed,
            }}
            onClick={UPDATEPERMISSION ? () => openRejectModal(item) : () => {}}
          />
         
          {item.imageUrl && (
            <Badge
              title={depositConstants.proof}
              className={classes.badge}
              style={{ ...depositStyle.badge, ...depositStyle.badgeBlue }}
              onClick={() => openProof(item)}
            />
          )}
        </>
      );
    } else if (item.status === 1 && item?.imageUrl) {
      return (
        <>
          <Badge
            title={depositConstants.proof}
            className={classes.badge}
            style={{ ...depositStyle.badge, ...depositStyle.badgeBlue }}
            onClick={() => openProof(item)}
          />
          
        </>
      );
    } else {
      return null;
    }
  };

  const formattedDepositData =
    formatDepositListData(allDeposits, "date", renderActions) ?? [];
  const formattedPendingDepositData =
    formatDepositListData(pendingDeposits, "date", renderDelete) ?? [];
  const formattedRemovedDepositData =
    formatDepositListData(removedDeposits, "date", renderActions) ?? [];
  const formattedDepositHistoryData =
    formatDepositListData(depositHistory, "date", renderActions) ?? [];

  const handleRefresh = (e) => {
    e.preventDefault();
    setDepositFilters({
      utrNumber: "",
      amount: "",
      note: "",
      // user: "",
      account: "",
    });
    if (resetStatusRef.current) {
      resetStatusRef.current.value = 0; // Reset the select box value
    }
    if (resetTypeRef.current) {
      resetTypeRef.current.value = "Client"; // Reset the select box value
    }
    setRefreshBtnDisable(true);
    setSearchDisabled(true);
    if (location.pathname === "/deposits/utr-entry/removed-list") {
      fetchDeposits({}, false);
    } else {
      fetchDeposits({ status: 0, type: "client" }, false);
    }
  };

  const handleDepositHistorySelectChange = (e, property) => {
    setRefreshBtnDisable(false);
    try {
      let obj = { ...historyFilters };
      obj[property] =
        property === "startDate" || property === "endDate"
          ? e.target.value
          : property === "site"
          ? e
          : property === "type"
          ? e.value === 3
            ? "UTR Entry"
            : e.value === ""
            ? ""
            : e.label.toLowerCase() || ""
          : e.value;

      setHistoryFilters(obj); // setting the filters value

      let error = "";
      if (property === "startDate" || property === "endDate") {
        error = validateDates(property, e.target.value);
        setFormChanged(true);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [property]: error,
        }));
        fetchDepositHistory(obj);
        return;
      }
      fetchDepositHistory(obj);
    } catch (err) {
      toast.error(err.message);
    }
  };

  

  const handleDepositInput = async (e) => {
    let obj = { ...depositFilters };
    let { name, value } = e.target;
    value = removeSpaces(value);
    obj[name] = value;
    setDepositFilters(obj);

    if (value.length === 0) {
      fetchDeposits({ status: 0, type: "client" }, false);
    } else {
      handleSearchDepositList(obj, true); // Pass true to show toast
    }
  };

  const handleDepositOnSelect = async (e, prop) => {
    let obj = { ...depositFilters };

    if (prop === "type") {
      if (e.value === 3) {
        obj[prop] = "UTR Entry";
      } else {
        obj[prop] = e.value === 0 ? "" : e.value ? e.label.toLowerCase() : "";
      }
    } else {
      obj[prop] = e.value;
    }

    setDepositFilters(obj);

    if (e.value === "") {
      handleSearchDepositList(obj, true);
    } else if (e.value && e.value.length === 0) {
      await dispatch(
        getDeposit(token, obj, false, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    } else {
      handleSearchDepositList(obj, true);
    }
  };

  const handleDepositSearchableSelect = async (value, prop) => {
    let obj = { ...depositFilters };
    obj[prop] = value;
    setDepositFilters(obj);
    if (value && value === "") {
      await dispatch(
        getDeposit(token, obj, false, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    } else {
      handleSearchDepositList(obj, true);
    }
  };

  const handleSearchDepositList = useMemo(
    () =>
      _.debounce(async (filters, showToast = true) => {
        await dispatch(
          getDeposit(token, filters, showToast, () => {
            dispatch({
              type: LOGOUT_SUCCESS,
              payload: [],
            });
            navigate("/");
          })
        );
      }, 300),
    [dispatch, token, navigate]
  );

  const handleDepositHistoryInput = async (e, property) => {
    setRefreshBtnDisable(false);
    let obj = { ...historyFilters };
    obj[property] = e.target.value;
    setHistoryFilters(obj);
    if (e.target.value === 0) {
      fetchDepositHistory(obj, false);
    } else {
      handleDepositSearch(obj);
    }
  };

  const handleUtrRemovedInput = async (e) => {
    setSearchDisabled(false);
    setRefreshBtnDisable(false);
    let obj = { ...depositFilters };
    const { name, value } = e.target;
    obj[name] = value;
    setDepositFilters(obj);
    if (e.target.value.length === 0) {
      await dispatch(
        getDeposit(token, obj, true, () => {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: [],
          });
          navigate("/");
        })
      );
    }
  };

  const handleUtrRemoveSearch = async (e) => {
    e.preventDefault();
    setSearchDisabled(true);
    await dispatch(
      getDeposit(token, depositFilters, true, () => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: [],
        });
        navigate("/");
      })
    );
  };

  const handleDepositSearch = useMemo(
    () =>
      _.debounce(async (obj) => {
        fetchDepositHistory(obj, true);
      }, 300),
    [dispatch]
  );

  const handleDepositHistoryRefresh = (e) => {
    e.preventDefault();
    setRefreshBtnDisable(true);
    // setFormSubmitState(true);
    setHistoryFilters({
      site: null,
      username: "",
      utrNumber: "",
      startDate: "",
      endDate: "",
    });
    if (selectStatusRef.current) {
      selectStatusRef.current.value = ""; // Reset the select box value
    }
    if (selectTypeRef.current) {
      selectTypeRef.current.value = "Select type"; // Reset the select box value
    }
    setFormErrors({
      ...formErrors,
      startDate: "",
      endDate: "",
    });
    fetchDepositHistory({}, false);
  };

  const renderModalError = (field) => {
    return modalFormErrors[field];
  };

  const onHandleWithRejectChange = (property, e) => {
    setBtnDisable(false);
    setRejectInput({
      ...rejectInput,
      [property]: property === "reason" ? e : e.target.value,
    });
    if (property === "reason") {
      if (e.value !== "" || e.label === "Select Reason")
        setModalFormErrors({ ...modalFormErrors, reason: "" });
    } else if (property === "otherReason") {
      if (e.target.value)
        setModalFormErrors({ ...modalFormErrors, otherReason: "" });
    }
  };

  return {
    formattedDepositData,
    loading,
    utr,
    formErrors,
    formChanged,
    renderError,
    handleAddUtr,
    onHandleInputChange,
    depositCashSettlement,
    handleAddDepositCashSettlement,
    searchInput,
    handleSearchFormInputChange,
    clients,
    bankAccounts,
    depositDetails,
    handleDepositSubmit,
    handleDepositInputChange,
    btnLoading,
    formattedPendingDepositData,
    formattedRemovedDepositData,
    formattedDepositHistoryData,
    handleRefresh,
    handleDepositInput,
    depositFilters,
    handleDepositOnSelect,
    handleDepositHistorySelectChange,
    historyFilters,
    handleDepositHistoryInput,
    handleDepositHistoryRefresh,
    formSubmitState,
    handleDepositSearchableSelect,
    handleUtrRemoveSearch,
    handleUtrRemovedInput,
    fileInputRef,
    selectStatusRef,
    selectTypeRef,
    resetStatusRef,
    resetTypeRef,
    setDepositAccountId,
    refreshBtnDisable,
    searchDisabled,
    showModal,
    handleCloseModal,
    modalCheck,
    handleDepositApproved,
    handleDepositReject,
    modalFormErrors,
    renderModalError,
    rejectInput,
    onHandleWithRejectChange,
    btnDisable,
    selectBoxRef,
  };
};

export default useDepositHook;
