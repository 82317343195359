export const logoutErrors = {
  logoutFail: "Logout failed. Please try again later.",
  unexpextedServer: "An unexpected error occurred during logout.",
  sessionExpire: "Session Expired",
};

export const unexpectedError = {
  unexpextedServer: "An unexpected error occurred.",
};

export const accountFormError = {
  accountType: "Account type is required",
  purpose: "Purpose is required",
  bankName: "Bank name is required",
  bankName1:
    "Invalid name. Only alphabets and single spaces between words are allowed.",
  accountName: "Account name is required",
  accountNumber: "Account number is required",
  accountNumber1:
    "Invalid account number. It should contain 11-16 digits with no spaces",
  accountNumberOnly: "Account number should be numbers only",
  accountNumberMaxLength: "Account number can have only 12 digits",
  ifsc: "IFSC code is required",
  ifsc1:
    "Invalid IFSC code.", //asked by client side team to change this message on 2 august morning over gmeet call
  // ifsc1:
  //   "Invalid IFSC code. It should contain only contain alphanumeric characters in uppercase.", /earlier was this message
  minAmount: "Minimum amount is required",
  minAmount1: "Minimum amount should be 100.",
  minAmountInvalid: "Minimum amount should be in numbers only.",
  minAmountError: "Minimum amount must be less than maximum account",
  maxAmount: "Maximum amount is required",
  maxAmount1: "Maximum amount must be greater than Minimum amount.",
  maxAmountInvalid: "Maximum amount should be in numbers only.",
  maxAmountError: "The max amount must be greater than",
  maxDeposit: "Maximum deposit amount is required",
  qr: "QR is required",
  status: "Status is required",
  minMaxSame: "Minimum and maximum amount cannot be the same",
};

export const clientsFormError = {
  oldPassword: "Old Password is required",
  username: "Username is required",
  usernameCondition:
    "Username must be alphanumeric and have more than 5 characters",
  password: "Password is required",
  passwordCondition:
    "Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character and length should be more than or equal to 8.",
  confirmPassword: "Confirm Password does not match with the password",
};

export const dateError = {
  startDate: "Start date cannot be later than end date.",
  endDate: "End date cannot be earlier than start date.",
  endCannotGreat: "End date cannot be greater than today's date",
  startCannotGreat: "Start date cannot be greater than today's date",
};
export const utrFormError = {
  utrNumber: "UTR Number is required",
  utrNumberCondition:
    "Invalid UTR Number. Please enter utr number of 8 digits.",
  account: "Account is required",
  amount: "Amount is required",
  amountCondition:
    "Invalid Amount. It should be minimum 3-digits with no zero on first place.",
  client: "Client is required",
  reason: "Reason is required",
  user: "User is required",
  validAmount: "Amount should be in numbers only.",
  minInvalidAmount: "Amount is less than minimum value - ",
  maxInvalidAmount: "Amount is more than maximum value - ",
  amountGreaterThan100: "Amount must be greater than 100",
  amountLessThan1Cr: "Amount must be less than 1cr",
  invalidUtr: "Invalid UTR Number format. Must be 8-20 digits with no spaces",
  qrCode: "Image is required",
  minAmount100: "Minimum amount should be at least 100"
};

export const userFormError = {
  name: "Name is required",
  nameCondition:
    "Invalid name. Only alphabets and single spaces between words are allowed.",
  username: "Username is required",
  usernameCondition:
    "Username must be alphanumeric and have more than 5 characters",
  password: "Password is required",
  passwordCondition:
    "Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character and length should be more than or equal to 8.",
  // passwordCondition:
  //   "Invalid password. Please use alphanumeric format and string with minimum 8 characters.",
  role: "Role is required",
  status: "Status is required",
};

export const formError = {
  error: "Please check the form errors",
};
