import { color } from "../../themes/Colors";

export const statsStyle = {
    searchBtn: {
        width: "100%",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: 'none',
        color: color._ffffff,
        fontWeight: 400,
        height: "29px"
    },
    table: { width: "auto" },
    clientSpan: {
        color: color._darkBlue
    },
    totalRejectedDeposit: {
        color: color._ffffff
    },
    numberRejectedWithdraw: {
        color: "black"
    },
    totalApprovedDeposit: {
        color: color._darkSlateGrey
    },
};