import { color } from "../../../themes/Colors";

export const withdrawStyle = {
    container: { borderRadius: "1px", position: "relative" },
    refreshBtn: {
        width: "100%",
        backgroundColor: color._0e8d84,
        borderRadius: "2px",
        textTransform: "uppercase",
        border: "none",
        color: color._ffffff,
        fontWeight: 400,
        height: "29px"
    },
    table: { minWidth: "auto" },
    badge: {
        marginRight: "5px",
        padding: "7px",
        fontSize: "8px",
        textTransform: "uppercase",
        fontWeight: 600,
        cursor: "pointer"
    },
    badgeRed: {
        backgroundColor: color._f44336,
        padding: "5px",
        fontWeight: 600,
        fontSize: "10px",
        borderRadius: "10px",
    },
    badgeStatusRed: {
        backgroundColor: color._f44336,
        padding: "3px",
        fontWeight: 600,
        fontSize: "12px",
        borderRadius: "3px",
    },
    badgeGreen: {
        backgroundColor: color._28a745,
        padding: "5px",
        fontWeight: 600,
        fontSize: "10px",
        borderRadius: "10px",
    },
    badgeStatusGreen: {
        backgroundColor: color._28a745,
        padding: "3px",
        fontWeight: 600,
        fontSize: "12px",
        borderRadius: "3px",
    },
    badgeYellow: {
        backgroundColor: color._ffc107,
        color: color._000,
        fontWeight: 600,
        fontSize: "12px",
        padding: "3px",
        borderRadius: "3px"
    },
    iconApproved: {
        color: color._ffffff,
        backgroundColor: color._28a745,
        borderRadius: "2px",
        marginRight: "5px",
        marginLeft: "10px",
        padding: "2px",
    },
    iconRejected: {
        color: color._ffffff,
        backgroundColor: color._f44336,
        borderRadius: "2px",
        padding: "2px",
    },
    iconProof: {
        color: color._ffffff,
        backgroundColor: color._0089ca,
        borderRadius: "15px",
        paddingLeft: "7px",
        paddingRight: "7px",
        paddingTop: "1px",
        paddingBottom: "1px",
    },
    iconCopy: {
        color: color._ffffff,
        backgroundColor: color._0e8d84,
        borderRadius: "50px",
        width: '20px',
        height: '20px',
        marginLeft: "3px",
    },
    accountDetailsText: { marginBottom: "2px" }
};
