export const usersConstants = {
    addUser: "Add User",
    name: "Name:",
    enterName: "Enter Name",
    username: "Username:",
    enterUsername: "Enter Username",
    password: "Password:",
    enterPassword: "Enter Password",
    role: "Role:",
    noOfLogins: "No of logins:",
    status: "Status:",
    add: "Add",
    editUser:"Edit User",
    update:"Update"
}

export const roleOptions = [
    { value: "1", label: "--Select role--" },
    { value: "2", label: "Deposit Manager" },
    { value: "3", label: "Withdraw Manager" },
];

export const loginOptions = [
    { value: "1", label: 1 },
    { value: "2", label: 2 },
    { value: "3", label: 3 },
    { value: "4", label: 4 },
    { value: "5", label: 5 },
    { value: "6", label: 15 },
    { value: "7", label: 20 },
];

export const statusOptions = [
    { value: "", label: "--Select status--" },
    { value: 1, label: "Active" },
    { value: 0, label: "InActive" },
];