import React from 'react'
import Navigation from './routes/Navigation';

// App Function.
const App = () => {
  return (
   <Navigation/>
  );
};

// Exporting App Function.
export default App;

