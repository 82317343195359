import React, { useState, useEffect } from 'react';
import { inputBox } from './style';

const CustomFileInput = ({ id, className, useInputGroupClass = true, initialFilename, onChange, placeholder = "No file chosen", ...props }) => {
    const [filename, setFilename] = useState(initialFilename || "");

    useEffect(() => {
        if (initialFilename) {
            setFilename(initialFilename);
        }
    }, [initialFilename]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFilename(file.name);
            onChange(e);
        }
    };

    const inputGroupClass = useInputGroupClass
        ? "input-group input-group-sm"
        : "";

    return (
        <div className={`${inputGroupClass}`} style={inputBox.inputGroup}>
            <input
                type="file"
                id={id}
                onChange={handleFileChange}
                {...props}
                className={className}
                style={{ display: 'none' }} // Hide the actual file input
            />
            <input
                type="text"
                className={className}
                value={filename}
                placeholder={placeholder}
                readOnly
                onClick={() => document.getElementById(id).click()}
                style={{ cursor: 'pointer' }}
            />
        </div>
    );
};

export default CustomFileInput;

