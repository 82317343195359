import React from "react";
import CustomLabel from "../../../components/atoms/CustomLabel";
import Button from "../../../components/atoms/Button";
import { statsStyle } from "../style";
import {
  buttonTypes,
  classes,
  inputTypes,
} from "../../../constants/CommonConstants";
import { statisticsContants } from "../constant";
import CustomInput from "../../../components/atoms/CustomInput";

const SearchForm = (props) => {
  const {
    formErrors,
    renderError,
    formInputChange,
    searchInput,
    handleSearchFormInputChange,
    handleReset,
    handleSearch,
    btnDisable,
    resetDisable,
  } = props;

  return (
    <form>
      <div className="row">
        <div className="col-md-3">
          <CustomLabel label={statisticsContants.startDate} />
          <CustomInput
            className={`${classes.inputClass} ${
              formInputChange && formErrors["startDate"] && classes.invalid
            }`}
            type={inputTypes.date}
            name="startDate"
            value={searchInput?.startDate}
            onChange={handleSearchFormInputChange}
            error={renderError("startDate")}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={statisticsContants.endDate} />
          <CustomInput
            className={`${classes.inputClass} ${
              formInputChange && formErrors["endDate"] && classes.invalid
            }`}
            type={inputTypes.date}
            name="endDate"
            value={searchInput?.endDate}
            onChange={handleSearchFormInputChange}
            error={renderError("endDate")}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center mt-md-4 mt-2">
          <Button
            title={statisticsContants.search}
            style={statsStyle.searchBtn}
            className="btn-hover"
            onClick={handleSearch}
            disabled={btnDisable}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center mt-md-4 mt-2">
          <Button
            title={statisticsContants.reset}
            style={statsStyle.searchBtn}
            className="btn-hover"
            type={buttonTypes.button}
            onClick={handleReset}
            disabled={resetDisable}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
