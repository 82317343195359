import React from "react";
import CustomTable from "../../../components/organisms/CustomTable/CustomTable";
import { statsStyle } from "../style";
import { statsTableColumns } from "../Utils/StatsUtils";
const Statistics = (props) => {
  const { formattedSiteUserStatsData, siteUserStats, loading } = props;
  return (
    <CustomTable
      data={formattedSiteUserStatsData}
      columns={statsTableColumns}
      rows={25}
      style={statsStyle.table}
      showTotal={true}
      totalData={siteUserStats}
      loading={loading}
    />
  );
};
export default Statistics;
