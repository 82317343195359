import { getAuthData, removeAuthData, setAuthData } from "../../utils/Common";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} from "./actionType";

const { token, userId, permission, role } = getAuthData();

const initialState = {
  isAuthenticated: !!token,
  token: token,
  errorMessage: null,
  userId: userId,
  userName: null,
  permission: permission,
  role: role,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      setAuthData({
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
        permission: action.payload.permission,
        role: action.payload.role,
      });
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
        errorMessage: null,
        permission: action.payload.permission,
        role: action.payload.role,
      };
    case LOGOUT_SUCCESS:
      removeAuthData();
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        userId: null,
        errorMessage: null,
        permission: [],
        role: null,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
