export const addClientConstant = {
    addClients: "Add Clients",
    master: "Master:",
    referralCode: "Referral code:",
    enterReferralCode: "Enter Referral Code",
    username: "Username:",
    enterUsername: "Enter Username",
    password: "Password:",
    enterPassword: "Enter Password",
    add: "Add",
    close: "Close",
    copyText: "Copy",
    clickToCopy: "Click below button to copy"
}

export const masterOptions = [
    { value: "1", label: "Demolalit(99exch.com)" },
    { value: "2", label: "Demolalit(laser247.com)" },
    { value: "3", label: "Demolalit(play247.win)" },
    { value: "4", label: "Demolalit(11xplay.com)" },
];