export const usersContants = {
    role: "Role:",
    search: "Search",
    reset: "Reset",
    edit: "Edit",
    btnEdit: "EDIT",
    rp: "RESET PASSWORD",
    loading: "Loading...",
    ResetPassword: "Reset Password",
    copyPassword: "Copy Password",
    newPassword: "New Password : ",
    active: "Active",
    inActive: "Inactive",
    PasswordCopied: "Password has been Copied!",
    close: "Close",
    clickToCopy: "Click copy password to copy",
    resettingPass: "Resetting Password..."

}

export const roleOptions = [
    { value: "", label: "Select role" },
    { value: "0", label: "Deposit Manager" },
    { value: "1", label: "Withdraw Manager" },
];
