import {
  SEARCH_SUBMIT,
  SEARCH_COMPLETE,
  UPDATE_FILTERED_DATA,
  UPDATE_GLOBAL_SEARCH_DATA,
} from "./actionTypes";

export const searchSubmit = () => ({
  type: SEARCH_SUBMIT,
});

export const searchComplete = () => ({
  type: SEARCH_COMPLETE,
});

export const updateFilteredData = (filteredData) => ({
  type: UPDATE_FILTERED_DATA,
  payload: filteredData,
});

export const updateGlobalSearchData = (filteredData) => ({
  type: UPDATE_GLOBAL_SEARCH_DATA,
  payload: filteredData,
});
