import { SideBarIcons } from "../assets/icons/Icons";
import { ComponentNames } from "../constants/ComponentNames";
import { URLs } from "../routes/URLs";
import { color } from "../themes/Colors";

export const sidebarItems = [
  {
    icon: SideBarIcons.dashboardIcon,
    iconColor: color._67748E,
    label: ComponentNames.Dashboard,
    // collapse: true,
    // id: 'sidebarDashboards',
    iconBackgroundColor: color._ffffff,
    // active: true,
    submenu: [],
    link: URLs.dashboard,
    name: "DASHBOARD",
  },
  {
    icon: SideBarIcons.searchIcon,
    iconColor: color._67748E,
    label: ComponentNames.GlobalSearch,
    iconBackgroundColor: color._ffffff,
    submenu: [],
    link: URLs.globalSearch,
    name: "SITESEARCH",
  },
  {
    icon: SideBarIcons.clientsIcon,
    iconColor: color._67748E,
    label: ComponentNames.Clients,
    collapse: true,
    id: "sidebarClients",
    hasArrow: true,
    leftArrow: SideBarIcons.leftArrow,
    downArrow: SideBarIcons.downArrow,
    leftIconColor: color._0e8d84,
    iconBackgroundColor: color._ffffff,
    name: "CLIENTS",
    submenu: [
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.Add,
        link: URLs.clientsAdd,
        name: "ADDCLIENTS",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.List,
        link: URLs.clientsList,
        name: "LISTCLIENTS",
      },
    ],
  },
  {
    icon: SideBarIcons.accountsIcon,
    iconColor: color._67748E,
    label: ComponentNames.Accounts,
    collapse: true,
    id: "sidebarEmail",
    hasArrow: true,
    leftArrow: SideBarIcons.leftArrow,
    downArrow: SideBarIcons.downArrow,
    leftIconColor: color._0e8d84,
    iconBackgroundColor: color._ffffff,
    name: "ACCOUNTS",
    submenu: [
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.Add,
        link: URLs.accountsAdd,
        name: "ADDACCOUNTS",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.List,
        link: URLs.accountsList,
        name: "LISTACCOUNTS",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.Disabled,
        link: URLs.accountsDisabled,
        name: "DISABLEDACCOUNTS",
      },
    ],
  },
  {
    icon: SideBarIcons.depositIcon,
    iconColor: color._67748E,
    label: ComponentNames.Deposit,
    collapse: true,
    id: "deposits",
    hasArrow: true,
    leftArrow: SideBarIcons.leftArrow,
    downArrow: SideBarIcons.downArrow,
    leftIconColor: color._0e8d84,
    iconBackgroundColor: color._ffffff,
    name: "DEPOSIT",
    submenu: [
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.List,
        link: URLs.depositsList,
        name: "DEPOSITLIST",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.History,
        link: URLs.depositsHistory,
        name: "DEPOSITHISTORY",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.UtrForm,
        link: URLs.depositsUtrEntryAdd,
        name: "DEPOSITUTRFORM",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.UtrPendingList,
        link: URLs.depositsUtrPendingList,
        name: "DEPOSITPENDINGLIST",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.UtrRemovedList,
        link: URLs.depositsUtrRemovedList,
        name: "DEPOSITREMOVEDLIST",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.CashSettlement,
        link: URLs.depositsCashSettlement,
        name: "DEPOSITCASHSETTLEMENT",
      },
    ],
  },
  {
    icon: SideBarIcons.withdrawIcon,
    iconColor: color._67748E,
    label: ComponentNames.Withdraw,
    collapse: true,
    id: "withdrawals",
    hasArrow: true,
    leftArrow: SideBarIcons.leftArrow,
    downArrow: SideBarIcons.downArrow,
    leftIconColor: color._0e8d84,
    iconBackgroundColor: color._ffffff,
    name: "WITHDRAW",
    submenu: [
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.List,
        link: URLs.withdrawalList,
        name: "WITHDRAWLIST",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.History,
        link: URLs.withdrawalHistory,
        name: "WITHDRAWHISTORY",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.Manual,
        link: URLs.withdrawalManual,
        name: "WITHDRAWMANUAL",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.CashSettlement,
        link: URLs.withdrawalCashSettlement,
        name: "WITHDRAWCASHSETTLEMENT",
      },
    ],
  },
  {
    icon: SideBarIcons.usersIcon,
    iconColor: color._67748E,
    label: ComponentNames.ExtraUsers,
    collapse: true,
    id: "extraUsers",
    hasArrow: true,
    leftArrow: SideBarIcons.leftArrow,
    downArrow: SideBarIcons.downArrow,
    leftIconColor: color._0e8d84,
    iconBackgroundColor: color._ffffff,
    name: "USERS",
    submenu: [
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.Add,
        link: URLs.usersAdd,
        name: "ADDUSERS",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.Active,
        link: URLs.users,
        name: "ACTIVEUSERS",
      },
      {
        icon: SideBarIcons.submenuIcon,
        label: ComponentNames.Inactive,
        link: URLs.usersInactive,
        name: "INACTIVEUSERS",
      },
    ],
  },
  {
    icon: SideBarIcons.statementIcon,
    iconColor: color._67748E,
    iconBackgroundColor: color._ffffff,
    label: ComponentNames.AccountStatement,
    link: URLs.accountStatement,
    submenu: [],
    name: "ACCOUNTSTATEMENT",
  },
  {
    icon: SideBarIcons.statsIcon,
    iconColor: color._67748E,
    iconBackgroundColor: color._ffffff,
    label: ComponentNames.Statistics,
    link: URLs.statistics,
    submenu: [],
    name: "STATISTICS",
  },
  {
    icon: SideBarIcons.changepasswordIcon,
    iconColor: color._67748E,
    iconBackgroundColor: color._ffffff,
    label: ComponentNames.ChangePassword,
    link: URLs.changePassword,
    submenu: [],
    name: "CHANGEPASSWORD",
  },
];
