export const depositConstants = {
  cashSettlement: "Cash Settlement",
  account: "Account:",
  amount: "Amount:",
  enterAmount: "Enter Amount",
  add: "Add",
  active: "Active",
  inactive: "Inactive",
  pending: "Pending",
  approved: "Approved",
  approve: "Approve",
  rejected: "Rejected",
  reject: "Reject",
  proof: "Proof",
  selectAccount: "Select Account",
  utrEntry: "UTR Entry",
  delete: "Delete",
};
