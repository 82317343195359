import React from "react";
import CustomImageCard from "../../atoms/CustomImageCard";
import BalanceInfoCard from "../../atoms/BalanceInfoCard";
import Button from "../../atoms/Button";
import ReusableIcon from "../../atoms/ReusableIcon/ReusableIcon";
import { GlobalSearchIcons } from "../../../assets/icons/Icons";
import { globalSearchCardStyle } from "./style";
import ClipLoader from "react-spinners/ClipLoader";

const GlobalSearchCards = ({
  cardStyle,
  imageSrc,
  alt,
  labelText,
  balanceInfo,
  cardButtons,
  onClickRefresh,
  altKey,
  loaderKey,
  individualLoader,
}) => {
  return (
    <div style={globalSearchCardStyle.parentDiv}>
      { altKey === loaderKey && individualLoader && (
        <div style={globalSearchCardStyle.childDiv}>
          <div>
            <ClipLoader
              cssOverride={{
                display: "block",
                margin: "0 auto",
              }}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      )}
      <div className="card p-1 pt-0 rounded-0" style={cardStyle}>
        <div className="d-flex flex-column">
          <CustomImageCard
            imageSrc={imageSrc}
            alt={alt}
            labelText={labelText}
          />
        </div>
        <div className="d-flex justify-column justify-content-around mb-1">
          {balanceInfo.length &&
            balanceInfo.map((info, index) => (
              <BalanceInfoCard
                key={index + info.label}
                label={info.label}
                value={info.value}
              />
            ))}
        </div>
        <div className="d-flex flex-column">
          {Array.isArray(cardButtons) &&
            cardButtons.map((button, index) => (
              <Button
                key={index + button.title}
                title={button.title}
                style={button.style}
                className={button.className}
                onClick={button.onClick}
                dataBsToggle={button.dataBsToggle}
                dataBsTarget={button.dataBsTarget}
                dataBsDismiss={button.dataBsDismiss}
                btnIcon={
                  <ReusableIcon
                    style={button.iconStyle}
                    icon={button.icon}
                    iconColor={globalSearchCardStyle.iconColor.color}
                  />
                }
              />
            ))}
        </div>
        <ReusableIcon
          style={globalSearchCardStyle.btnRefresh}
          icon={GlobalSearchIcons.cachedIcon}
          onClick={() => onClickRefresh(altKey)}
          className="position-absolute end-0 top-0 mb-2 icon_hover"
        />
      </div>
    </div>
  );
};

export default GlobalSearchCards;
