import axios from "axios";
import { removeAuthData } from "../utils/Common";
import { toast } from "react-toastify";
import { logoutErrors } from "../constants/ErrorMessage";

const headers = {
  "Content-Type": "application/json",
};

// Common function for making GET requests
export const _get = async (
  endpoint,
  id = null,
  token = null,
  data = null,
  logoutFunc = null,
  idUrlChange = false
) => {
  try {
    // Adjust the URL based on whether id is provided
    const url = idUrlChange
      ? `${process.env.REACT_APP_URL_DEV}/${idUrlChange}/${endpoint}`
      : id
      ? `${process.env.REACT_APP_URL_DEV}/${endpoint}/${id}`
      : `${process.env.REACT_APP_URL_DEV}/${endpoint}`;
    const response =
      token && data
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer ${token}` },
            params: data,
          })
        : token
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer ${token}` },
          })
        : await axios.get(url);
    return response.data;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
  }
};

export const _crossPlatformGet = async (
  endpoint,
  id = null,
  token = null,
  data = null,
  logoutFunc = null
) => {
  try {
    // Adjust the URL based on whether id is provided
    const url = id
      ? `${process.env.REACT_APP_URL_DEV}/${endpoint}/${id}`
      : `${process.env.REACT_APP_URL_DEV}/${endpoint}`;
    const response =
      token && data
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer xpToken ${token}` },
            params: data,
          })
        : token
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer xpToken ${token}` },
          })
        : await axios.get(url);
    return response.data;
  } catch (error) {
    // console.log(error.response.status,"errorHere2")
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
  }
};

export const _postNew = async (
  endpoint,
  data,
  token = null,
  resetHeaders = false,
  logoutFunc = null
) => {
  try {
    const config = {
      headers: { ...headers }, // Create a copy of the headers
    };

    if (resetHeaders) {
      config.headers = { "Content-Type": "application/json" };
    }

    if (token) {
      config.headers["authorizationToken"] = `Bearer ${token}`;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_USER_PERMISSION_URL}/${endpoint}`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw new Error(error);
  }
};
// function for making GET requests Deposit
export const _getDeposit = async (
  endpoint,
  id = null,
  token = null,
  data = null,
  logoutFunc = null
) => {
  try {
    // Adjust the URL based on whether id is provided
    const url = id
      ? `${process.env.REACT_APP_URL_DEV}/${endpoint}/${id}`
      : `${process.env.REACT_APP_URL_DEV}/${endpoint}`;
    const response =
      token && data
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer xpToken ${token}` },
            params: data,
          })
        : token
        ? await axios.get(url, {
            headers: { authorizationToken: `Bearer xpToken ${token}` },
          })
        : await axios.get(url);
    return response.data;
  } catch (error) {
    // console.log(error.response.status,"errorHere2")
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
  }
};

// Common function for making POST requests
export const _post = async (
  endpoint,
  data,
  token = null,
  resetHeaders = false,
  logoutFunc = null
) => {
  try {
    const config = {
      headers: { ...headers }, // Create a copy of the headers
    };

    if (resetHeaders) {
      config.headers = { "Content-Type": "application/json" };
    }

    if (token) {
      config.headers["authorizationToken"] = `Bearer ${token}`;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_URL_DEV}/${endpoint}`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw new Error(error);
  }
};
// Common function for making POST requests
export const _postCrossPlatToken = async (
  endpoint,
  data,
  token = null,
  resetHeaders = false,
  logoutFunc = null
) => {
  try {
    const config = {
      headers: { ...headers }, // Create a copy of the headers
    };

    if (resetHeaders) {
      config.headers = { "Content-Type": "application/json" };
    }

    if (token) {
      config.headers["authorizationToken"] = `Bearer xpToken ${token}`;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_URL_DEV}/${endpoint}`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw new Error(error);
  }
};

// Common function for making POST requests with an id parameter
export const _postWithId = async (endpoint, id, data, logoutFunc = null) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_DEV}/${endpoint}/${id}`,
      data,
      {
        headers: headers,
      }
    );
    return response;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw error;
  }
};

// Common function for making UPDATE (PUT) requests
export const _update = async (
  endpoint,
  id,
  data,
  token = null,
  logoutFunc = null
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_URL_DEV}/${endpoint}/${id}`,
      data,
      {
        headers: {
          ...headers,
          ...(token && {
            authorizationToken: `Bearer ${token}`,
          }),
        },
      }
    );
    return response.data;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw new Error(error.response.data.message);
  }
};

// Common function for making DELETE requests
export const _delete = async (endpoint, logoutFunc = null) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_URL_DEV}/${endpoint}`
    );
    return response.data;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw new Error(error.response.data.message);
  }
};

// Common function for making PUT requests
export const _put = async (
  endpoint,
  data,
  token = null,
  resetHeaders = false,
  logoutFunc = null
) => {
  try {
    const config = {
      headers: { ...headers }, // Create a copy of the headers
    };

    if (resetHeaders) {
      config.headers = { "Content-Type": "application/json" };
    }

    if (token) {
      config.headers["authorizationToken"] = `Bearer ${token}`;
    }

    const response = await axios.put(
      `${process.env.REACT_APP_URL_DEV}/${endpoint}`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw new Error(error);
  }
};

export const _crossXp_put = async (
  endpoint,
  data,
  token = null,
  resetHeaders = false,
  logoutFunc = null
) => {
  try {
    const config = {
      headers: { ...headers }, // Create a copy of the headers
    };

    if (resetHeaders) {
      config.headers = { "Content-Type": "application/json" };
    }

    if (token) {
      config.headers["authorizationToken"] = `Bearer xpToken ${token}`;
    }

    const response = await axios.put(
      `${process.env.REACT_APP_URL_DEV}/${endpoint}`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (logoutFunc) {
      removeAuthData();
      toast.error(error.message);
      logoutFunc();
    }
    // throw new Error(error);
  }
};
