// Importing dependencies.
import React from 'react';
import Layout from '../../layout/Layout';
import StatisticsContainer from '../../containers/StatisticsContainer';
import { layoutHeading, tabTitles } from '../../constants/CommonConstants';
import PageHelmet from '../../components/atoms/PageHelmet';

// Declaring Funtion
const Statistics = () => {

  // Returning Statistics Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.statistics} />
      <Layout heading={layoutHeading.stats}>
        <StatisticsContainer />
      </Layout>
    </>
  );
};

// Exporting Statistics Function.
export default Statistics;
