import { color } from "../../../themes/Colors";

export const listStyle = {
    list: {
        fontSize: "13px",
        textDecoration: "none",
        color: color._000,
        textTransform: "capitalize"
    },
    listItem: {
        fontSize: "13px",
        textDecoration: "none",
        color: color._68758e,
        textTransform: "capitalize"
    },
};