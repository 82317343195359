export const inputBox = {
    errorStyle: {
        fontSize: "12px"
    },
    inputGroup: {
        position: "relative",
    },
    passwordToggleIcon: {
        position: "absolute",
        right: "10px",
        bottom: "7px",
        cursor: "pointer",
        zIndex: "5"
    },
}