import React from "react";
import RefreshForm from "./helper/RefreshForm";
import { withdrawStyle } from "./style";
import WithdrawList from "./helper/WithdrawList";
import useWithdrawHook from "../../../hooks/withdraw-hook";
import Modal from "../../../components/molecules/Modal/Modal";
import { useSelector } from "react-redux";
import { globalSearchConstants } from "../../GlobalSearchContainer/constants";
import DepositModal from "../../GlobalSearchContainer/utils/DepositModal";
import RejectWithModal from "./helper/RejectWithModal";
import { usePermissions } from "../../../permissionhook";
import { getPermission, getRole } from "../../../utils/Common";

const WithdrawListContainer = () => {
  const role = useSelector((state) => state.auth.role);
  const permissions = usePermissions();
  const UPDATEPERMISSION = getPermission(
    permissions,
    "WITHDRAWLIST",
    "UPDATEPERMISSION",
    getRole(role)
  );
  const {
    handleRefresh,
    handleWithdrawFilterChange,
    withdrawListFilters,
    handleWithdrawInput,
    handleSearchWithdrawList,
    formattedWithdrawalsData,
    loading,
    showModal,
    handleCloseModal,
    handleApproveWithdrawal,
    onHandleWithdrawModalChange,
    modalFormErrors,
    renderError,
    withdrawApproveInput,
    btnDisable,
    btnLoading,
    modalCheck,
    withdrawRejectInput,
    onHandleWithRejectChange,
    handleRejectWithdrawal,
    renderModalError,
    uploadRejectFile,
    selectBoxRef,
    statusRef,
    typeRef,
    btnDisabled,
    resetDisabled,
  } = useWithdrawHook(true, false, false, false, UPDATEPERMISSION);

  const { withdrawListData } = useSelector((state) => state.withdrawal);
  const { bankAccounts } = useSelector((state) => state.bankAccount);
  const accountOptions = bankAccounts?.map((account) => ({
    value: account.id,
    label: account.account_name,
    minAmount: account.min_amount,
    maxAmount: account.max_amount,
  }));
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card p-2" style={withdrawStyle.container}>
            <RefreshForm
              handleRefresh={handleRefresh}
              handleWithdrawFilterChange={handleWithdrawFilterChange}
              withdrawListFilters={withdrawListFilters}
              handleWithdrawInput={handleWithdrawInput}
              handleSearchWithdrawList={handleSearchWithdrawList}
              statusRef={statusRef}
              typeRef={typeRef}
              btnDisabled={btnDisabled}
              resetDisabled={resetDisabled}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2 mt-2">
          <div className="card table-responsive">
            <WithdrawList
              formattedWithdrawalsData={formattedWithdrawalsData}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        handleClose={handleCloseModal}
        isLoading={btnLoading}
        btnDisable={btnDisable}
        modalTitle={`${withdrawListData?.addedBy?.username} (${withdrawListData.amount})`}
        modalFooterPrimaryTitle={globalSearchConstants.close}
        modalFooterSecondaryTitle={globalSearchConstants.update}
        handleSave={
          modalCheck === "Approve"
            ? handleApproveWithdrawal
            : handleRejectWithdrawal
        }
      >
        {modalCheck === "Approve" ? (
          <DepositModal
            formErrors={modalFormErrors}
            onHandleDepositInputChange={onHandleWithdrawModalChange}
            renderError={renderModalError}
            depositInput={withdrawApproveInput}
            accountOptions={accountOptions}
            showAmount={false}
            showNote={false}
          />
        ) : (
          <RejectWithModal
            modalFormErrors={modalFormErrors}
            renderError={renderModalError}
            withdrawRejectInput={withdrawRejectInput}
            onHandleWithRejectChange={onHandleWithRejectChange}
            uploadRejectFile={uploadRejectFile}
            selectBoxRef={selectBoxRef}
          />
        )}
      </Modal>
    </>
  );
};

export default WithdrawListContainer;
