// Importing dependencies.
import React from 'react';
import LoginContainer from '../../containers/LoginContainer';
import PageHelmet from '../../components/atoms/PageHelmet';
import { ComponentNames } from '../../constants/ComponentNames';

// Declaring Funtion
const Login = () => {

  // Returning LoginContainer Component from function.
  return (
    <>
      <PageHelmet tabTitle={ComponentNames.Login} />
      <LoginContainer />
    </>
  );
};

// Exporting Login Function.
export default Login;
