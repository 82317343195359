import { color } from "../../../themes/Colors";
import { fontFamily } from "../../../themes/font";

export const tableStyle = {
    icon: {
        color: color._ffffff,
        backgroundColor: color._0e8d84,
    },
    row: {
        fontSize: "13px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontFamily: fontFamily.fontFamily,
    },
    head: {
        fontSize: "13px",
        textTransform: "uppercase",
        color: color._66748e,
        fontWeight: 600
    },
    rowColor:{
        color:color._000
    },
    footer:{fontWeight:"normal"}
}