import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import {
  withdrawConstants,
  domainOptions,
  statusOptions,
  typeOptions,
} from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { withdrawStyle } from "../style";
import useGlobalSearchHook from "../../../../hooks/global-search-hook";
import SearchBox from "../../../../components/atoms/SearchableSelectBox/SearchBox";

const SearchForm = (props) => {
  const {
    formErrors,
    renderError,
    handleInputChange,
    handleSelectChange,
    handleFilterReset,
    handleSearch,
    historyFilters,
    statusRef,
    typeRef,
    userNameRef,
    utrRef,
    endDateRef,
    startDateRef,
    btnDisabled,
    resetDisabled
  } = props;
  const { domains } = useGlobalSearchHook(false);

  return (
    <form>
      <div className="row m-0 p-2">
        <div className="col-md-3">
          <CustomLabel label={withdrawConstants.domain} />
          <SearchBox
            options={domains}
            defaultValue=""
            value={historyFilters?.site}
            onChange={(value) => handleSelectChange(value[0]?.text, "site")}
            placeholder={withdrawConstants.selectDomain}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={withdrawConstants.status} />
          <CustomSelectBox
            options={statusOptions}
            defaultValue=""
            className={classes.selectBox}
            onChange={(e) => handleSelectChange(e, "status")}
            ref={statusRef}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={withdrawConstants.type} />
          <CustomSelectBox
            options={typeOptions}
            defaultValue=""
            className={classes.selectBox}
            onChange={(e) => handleSelectChange(e, "type")}
            ref={typeRef}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={withdrawConstants.username} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.text}
            onChange={(e) => handleInputChange(e, "username")}
            inputRef={userNameRef}
          />
        </div>
        <div className="col-md-3 mt-1">
          <CustomLabel label={withdrawConstants.utrNumber} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.numeric}
            onChange={(e) => handleInputChange(e, "utrNumber")}
            inputRef={utrRef}
          />
        </div>
        <div className="col-md-2 mt-1">
          <CustomLabel label={withdrawConstants.startDate} />
          <CustomInput
            className={`${classes.inputClass} ${formErrors["startDate"] && classes.invalid
              }`}
            type={inputTypes.date}
            name="startDate"
            value={historyFilters.start_date}
            onChange={(e) => handleSelectChange(e, "startDate")}
            error={renderError("startDate")}
            inputRef={startDateRef}
          />
        </div>
        <div className="col-md-2 mt-1">
          <CustomLabel label={withdrawConstants.endDate} />
          <CustomInput
            className={`${classes.inputClass} ${formErrors["endDate"] && classes.invalid
              }`}
            type={inputTypes.date}
            name="endDate"
            value={historyFilters.end_date}
            onChange={(e) => handleSelectChange(e, "endDate")}
            error={renderError("endDate")}
            inputRef={endDateRef}
          />
        </div>
        <div className="col-md-2 p-1">
          <CustomLabel
            label={withdrawConstants.search}
            additionalStyles={withdrawStyle.btnLabel}
          />
          <Button
            title={withdrawConstants.search}
            style={withdrawStyle.searchBtn}
            className="btn-hover"
            onClick={handleSearch}
            disabled={btnDisabled}
          />
        </div>
        <div className="col-md-2 p-1">
          <CustomLabel
            label={withdrawConstants.search}
            additionalStyles={withdrawStyle.btnLabel}
          />
          <Button
            title={withdrawConstants.reset}
            style={withdrawStyle.searchBtn}
            className="btn-hover"
            onClick={handleFilterReset}
            disabled={resetDisabled}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
