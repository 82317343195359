import React from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import { statusOptions, typeOptions, withdrawConstants } from "../constants";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import CustomInput from "../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import Button from "../../../../components/atoms/Button";
import { withdrawStyle } from "../style";

const RefreshForm = (props) => {
  const {
    handleRefresh,
    handleWithdrawFilterChange,
    withdrawListFilters,
    handleWithdrawInput,
    handleSearchWithdrawList,
    statusRef,
    typeRef,
    btnDisabled,
    resetDisabled,
  } = props;
  return (
    <form>
      <div className="row m-0 p-2">
        <div className="col-md-3">
          <CustomLabel label={withdrawConstants.username} />
          <CustomInput
            className={classes.inputClass}
            type={inputTypes.text}
            value={withdrawListFilters.username || ""}
            onChange={handleWithdrawInput}
          />
        </div>
        <div className="col-md-3">
          <CustomLabel label={withdrawConstants.status} />
          <CustomSelectBox
            options={statusOptions}
            defaultValue=""
            className={classes.selectBox}
            onChange={(e) => handleWithdrawFilterChange(e, "status")}
            ref={statusRef}
          />
        </div>
        <div className="col-md-2">
          <CustomLabel label={withdrawConstants.type} />
          <CustomSelectBox
            options={typeOptions}
            className={classes.selectBox}
            value={withdrawListFilters.type}
            onChange={(e) => handleWithdrawFilterChange(e, "type")}
            ref={typeRef}
          />
        </div>
        <div className="col-md-2 d-flex align-items-center mt-md-4 mt-2">
          <Button
            title={withdrawConstants.search}
            style={withdrawStyle.refreshBtn}
            className="btn-hover"
            onClick={handleSearchWithdrawList}
            disabled={btnDisabled}
          />
        </div>
        <div className="col-md-2 d-flex align-items-center mt-md-4 mt-2">
          <Button
            title={withdrawConstants.refresh}
            style={withdrawStyle.refreshBtn}
            className="btn-hover"
            onClick={handleRefresh}
            disabled={resetDisabled}
          />
        </div>
      </div>
    </form>
  );
};

export default RefreshForm;
