import React from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { depositStyle } from "../style";
import { depositListTableColumns } from "../../utils/DepositUtils";
import BlankRecord from "../../../../components/organisms/CustomTable/BlankRecord";

const DespositList = (props) => {
  const { formattedDepositData, loading } = props;

  return (
    <>
      {formattedDepositData.length === 0 ? (
        <BlankRecord
          data={[]}
          columns={depositListTableColumns}
          style={depositStyle.table}
        />
      ) : (
        <CustomTable
          data={formattedDepositData}
          columns={depositListTableColumns}
          rows={25} //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
          style={depositStyle.table}
          loading={loading}
        />
      )}
    </>
  );
};

export default DespositList;
