import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { tableStyle } from "./style";

const BlankRecord = (props) => {
    const { columns = [], style } = props;
    const paginateData = () => {
        return [];
    };

    return (
        <div className="card-body pb-2">
            <DataTable
                size="small"
                value={paginateData()}
                rows={0}
                stripedRows
                showGridlines
                tableStyle={{ ...tableStyle.row, ...style }}
                scrollable
            >
                {columns.length &&
                    columns.map((column) => (
                        <Column
                            key={column.field}
                            sortable
                            field={column.field}
                            header={column.header}
                            style={{ width: column.width, color: tableStyle.rowColor.color }}
                            headerStyle={tableStyle.head}
                        />
                    ))}
            </DataTable>
        </div>
    );
};

export default BlankRecord;
