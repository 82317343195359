// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';
import AccountWithdrawalHistoryContainer from '../../../containers/AccountsContainer/AccountWithdrawalHistoryContainer';

// Declaring Funtion
const AccountWithdrawalHistory = () => {

    return (
        <>
            <PageHelmet tabTitle={tabTitles.withdrawListing} />
            <Layout heading={layoutHeading.withdraw}>
                <AccountWithdrawalHistoryContainer />
            </Layout>
        </>
    );
};

export default AccountWithdrawalHistory;
