import React, { forwardRef } from "react";
import { selectBox } from "./style";

const CustomSelectBox = forwardRef((props, ref) => {
  const {
    options,
    defaultValue,
    className,
    ariaLabel,
    onChange,
    property,
    error,
    name,
    value,
    disabled,
    defaultOption
  } = props;

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = options[selectedIndex];
    onChange(selectedOption);
  };
  return (
    <>
      <select
        ref={ref}
        defaultValue={defaultValue}
        className={className}
        name={name}
        value={value}
        aria-label={ariaLabel}
        onChange={handleSelectChange}
        disabled={disabled}
      >
        {defaultOption && <option value="">{defaultOption}</option>}
        {options.map((option) => (
          <option
            key={option.value}
            value={property ? option.label : option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <div
          className="invalid-feedback fw-bolder"
          style={selectBox.errorStyle}
        >
          {error}
        </div>
      )}
    </>
  );
});

export default CustomSelectBox;

