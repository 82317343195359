// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import InactiveUsersContainer from './../../../containers/ExtraUsersContainer/InactiveUsersContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const InactiveUsers = () => {

  // Returning InactiveUsers Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.userListing} />
      <Layout heading={layoutHeading.users}>
        <InactiveUsersContainer />
      </Layout>
    </>
  );
};

// Exporting InactiveUsers Function.
export default InactiveUsers;
