import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";

const PermissionsContext = createContext({});

export const PermissionsProvider = ({ children }) => {
  // Replace with actual logic to fetch or manage permissions
  const permissions = useSelector((state) => state.auth.permission);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
