import { toast } from "react-toastify";
import Badge from "../components/atoms/Badge";
import ReusableIcon from "../components/atoms/ReusableIcon";
import { infoMessage } from "../constants/SuccessMessage";
import React from "react";

// Common function to set authData in local storage
export const setAuthData = ({ token, userId, userName, permission, role }) => {
  Object.entries({ token, userId, userName, permission, role }).forEach(
    ([key, value]) => {
      if (key === "permission") {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, value);
      }
    }
  );
};

// Common function to get authData in local storage
export const getAuthData = () => {
  return ["token", "userId", "userName", "permission", "role"].reduce(
    (authData, key) => {
      if (key === "permission") {
        console.log("calueeee", key);
        try {
          const data = localStorage.getItem(key);
          authData[key] = data && data !== "undefined" ? JSON.parse(data) : {};
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Handle the error as needed, e.g., set parsedValue to a default value
        }
      } else {
        authData[key] = localStorage.getItem(key);
      }
      return authData;
    },
    {}
  );
};

// Common function to remove authData from local storage
export const removeAuthData = () => {
  ["token", "userId", "userName"].forEach((key) =>
    localStorage.removeItem(key)
  );
};

export const parseDate = (dateString) => {
  if (dateString) {
    const [fullDate, time, period] = dateString?.split(" ");
    const [day, month, year] = fullDate?.split("/");
    const [hour, minute, second] = time?.split(":");
    let hourNum = parseInt(hour);
    if (period === "PM") {
      hourNum += 12; // Convert 12-hour time to 24-hour time
    }
    return `${year}-${month}-${day}T${hourNum}:${minute}:${second}`;
  }
};

//Common Function to format date & time without comma
export const formatDateTime = (timestamp) => {
  let date, unixTimestampSec, milliseconds;
  if (timestamp && timestamp.length === 16) {
    //timestamp in microsec
    date = new Date(parseInt(timestamp));
    unixTimestampSec = date / 1000000; //converting to sec
    milliseconds = unixTimestampSec * 1000; //converting to milliseconds
  } else if (timestamp && timestamp.length === 10) {
    //timestamp in seconds
    date = new Date(parseInt(timestamp));
    milliseconds = date * 1000;
  } else {
    milliseconds = parseInt(timestamp); //timestamp in milliseconds
  }
  const newDate = new Date(milliseconds); //formating new date
  const year = newDate.getFullYear();
  // Get date components
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const day = newDate.getDate().toString().padStart(2, "0");

  // Get time components
  const hour = newDate.getHours().toString().padStart(2, "0");
  const minute = newDate.getMinutes().toString().padStart(2, "0");
  const second = newDate.getSeconds().toString().padStart(2, "0");
  const period = newDate.getHours() >= 12 ? "PM" : "AM";
  const formattedDate = `${day}/${month}/${year} ${hour}:${minute}:${second} ${period}`;
  return formattedDate;
};

//Common Function to sort last updated date in descending order
export const sortByDateDescending = (array, key) => {
  return array.slice().sort((a, b) => {
    const dateA = parseDate(a[key]);
    const dateB = parseDate(b[key]);
    return dateB?.localeCompare(dateA);
  });
};

//Common getStatus function
export const getStatus = (
  status,
  titles,
  withdrawConstants,
  classes,
  withdrawStyle
) => {
  let title, badgeStyle;

  switch (status) {
    case 0:
      title = titles.pending;
      badgeStyle = { ...withdrawStyle.badge, ...withdrawStyle.badgeRed };
      break;
    case 1:
      title = titles.approved;
      badgeStyle = { ...withdrawStyle.badge, ...withdrawStyle.badgeGreen };
      break;
    default:
      title = titles.rejected;
      badgeStyle = { ...withdrawStyle.badge, ...withdrawStyle.badgeYellow };
      break;
  }

  return <Badge title={title} className={classes.badge} style={badgeStyle} />;
};

export const CopyToClipboardIcon = ({ value, icon, style }) => {
  const handleCopy = () => {
    const textValue =
      typeof value === "string"
        ? value
        : React.isValidElement(value)
        ? value.props.children.join("\n")
        : "";

    navigator.clipboard
      .writeText(textValue)
      .then(() => {
        toast.info(infoMessage.copied, { autoClose: 1000 });
        console.log("Copied to clipboard:", textValue);
      })
      .catch((error) => {
        console.error("Unable to copy:", error);
      });
  };

  return (
    <ReusableIcon
      icon={icon}
      style={style}
      onClick={handleCopy}
      className="btn-hover"
    />
  );
};

//For conversion base 64
export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error("Parameter is not of type 'Blob'"));
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;

      reader.readAsDataURL(file);
      console.log("reader.readAsDataURL(file)", reader.readAsDataURL(file));
    }
  });
};

//Common function to format Amount
export const formatNumber = (number) => {
  // Ensure the number is parsed correctly and always has two decimal places
  return parseFloat(number).toFixed(2);
};

//Common function to convert local date time
export const formatToLocaleString = (date, options) => {
  const defaultOptions = {
    timeZone: "Asia/Kolkata",
    hour12: true,
  };

  const mergedOptions = { ...defaultOptions, ...options };

  return date && new Date(date).toLocaleString(mergedOptions).split(",");
};

export const getFormattedUserData = (userData) => {
  let formattedArr = [];
  for (let i = 0; i < userData.length; i++) {
    let newObj = {};
    if (userData[i].role) {
      if (userData[i].role === "1") {
        newObj = { ...userData[i], role: "Deposit Manager" };
      } else {
        newObj = { ...userData[i], role: "Withdraw Manager" };
      }
      formattedArr.push(newObj);
    } else {
      formattedArr.push(userData[i]);
    }
  }
  return formattedArr;
};

//Common function for status filter
export const statusFilter = (statuses) => {
  return (account) => statuses.includes(account.status);
};
//Common function for User status filter
export const statusUserFilter = (statuses) => {
  const statusNumbers = statuses.map(Number); // Convert statuses to numbers
  return (user) => statusNumbers.includes(Number(user.active)); // Convert user status to number
};

export const getValidateValue = (formObj, errors) => {
  let errorsArr = Object.values(errors);
  for (let value of Object.values(formObj)) {
    if (errorsArr.includes(value)) {
      return false;
    }
  }
  return true;
};

export function generatePassword() {
  var length = 8;
  var charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var password = "";
  var hasLowercase = false;
  var hasUppercase = false;
  var hasDigit = false;

  var firstCharIndex = Math.floor(Math.random() * 26);
  password += charset[firstCharIndex];
  hasUppercase = true;

  for (var i = 1; i < length; i++) {
    var randomChar = charset.charAt(Math.floor(Math.random() * charset.length));
    password += randomChar;

    if ("abcdefghijklmnopqrstuvwxyz".includes(randomChar)) {
      hasLowercase = true;
    } else if ("ABCDEFGHIJKLMNOPQRSTUVWXYZ".includes(randomChar)) {
      hasUppercase = true;
    } else if ("0123456789".includes(randomChar)) {
      hasDigit = true;
    }
  }

  while (!hasLowercase || !hasUppercase || !hasDigit) {
    password = password.substr(0, 1);

    for (var i = 1; i < length; i++) {
      var randomChar = charset.charAt(
        Math.floor(Math.random() * charset.length)
      );
      password += randomChar;

      if ("abcdefghijklmnopqrstuvwxyz".includes(randomChar)) {
        hasLowercase = true;
      } else if ("ABCDEFGHIJKLMNOPQRSTUVWXYZ".includes(randomChar)) {
        hasUppercase = true;
      } else if ("0123456789".includes(randomChar)) {
        hasDigit = true;
      }
    }
  }

  return password;
}

//To remove extra spaces
export const removeSpaces = (value) => {
  return value.replace(/\s+/g, "");
};
export const getPermission = (permissions, category, permission, role) => {
  if ((permissions && Object.keys(permissions)?.length === 0) || role) {
    return true;
  }
  // Check if the category exists in the permissions object
  if (permissions[category]) {
    // Check if the permission exists in the specified category
    if (permissions[category].hasOwnProperty(permission)) {
      return permissions[category][permission];
    } else {
      console.warn(
        `Permission '${permission}' does not exist in category '${category}'`
      );
      return false;
    }
  } else {
    console.warn(`Category '${category}' does not exist`);
    return false;
  }
};

export const getRole = (role) => {
  const roles = ["9"]; //will add roles in future
  return roles?.includes(role);
};
