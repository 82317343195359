import React from "react";
import CustomLabel from "../../../components/atoms/CustomLabel";
import CardHeader from "../../../components/molecules/CardHeader";
import CustomInput from "../../../components/atoms/CustomInput/CustomInput";
import { inputTypes, classes } from "../../../constants/CommonConstants";
import { changePasswordStyle } from "../style";
import CardFooter from "../../../components/molecules/CardFooter/CardFooter";
import { changePasswordConstants } from "../constant";
import useChangePasswordHook from "../../../hooks/change-password-hook";
import { useSelector } from "react-redux";
import { usePermissions } from "../../../permissionhook";
import { getPermission, getRole } from "../../../utils/Common";

const ChangePasswordForm = () => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();
  const UPDATEPERMISSION = getPermission(
    permissions,
    "CHANGEPASSWORD",
    "UPDATEPERMISSION",
    getRole(role)
  );
  const {
    changePassword,
    formErrors,
    handleUpdatePassword,
    renderError,
    onHandleInputChange,
    btnLoading,
  } = useChangePasswordHook();
  return (
    <form onSubmit={handleUpdatePassword}>
      <CardHeader cardHeaderTitle={changePasswordConstants.changePassword} />
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <CustomLabel
                label={changePasswordConstants.oldPassword}
                additionalStyles={changePasswordStyle.label}
              />
              <CustomInput
                type={inputTypes.password}
                className={`${classes.inputClass} ${
                  formErrors["oldPassword"]?.length && classes.invalid
                }`}
                placeholder={changePasswordConstants.oldPassword}
                value={changePassword?.oldPassword}
                onChange={(e) => onHandleInputChange("oldPassword", e)}
                error={renderError("oldPassword")}
                passwordToggleIconStyle={changePasswordStyle.eyeIcon}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-2">
              <CustomLabel
                label={changePasswordConstants.password}
                additionalStyles={changePasswordStyle.label}
              />
              <CustomInput
                type={inputTypes.password}
                className={`${classes.inputClass} ${
                  formErrors["password"]?.length && classes.invalid
                }`}
                placeholder={changePasswordConstants.enterPassword}
                value={changePassword?.password}
                onChange={(e) => onHandleInputChange("password", e)}
                error={renderError("password")}
                passwordToggleIconStyle={changePasswordStyle.eyeIcon}
              />
            </div>
            <div className="col-md-6 mt-2">
              <CustomLabel
                label={changePasswordConstants.confirmPassword}
                additionalStyles={changePasswordStyle.label}
              />
              <CustomInput
                type={inputTypes.password}
                className={`${classes.inputClass} ${
                  formErrors["confirm_password"]?.length && classes.invalid
                }`}
                placeholder={changePasswordConstants.reEnterPassword}
                value={changePassword?.confirm_password}
                onChange={(e) => onHandleInputChange("confirm_password", e)}
                error={renderError("confirm_password")}
                passwordToggleIconStyle={changePasswordStyle.eyeIcon}
              />
            </div>
          </div>
        </div>
      </div>
      <CardFooter
        title={changePasswordConstants.update}
        className={classes.btn}
        isLoading={btnLoading}
        loaderStyle={changePasswordStyle.loading}
        CREATEPERMISSION={UPDATEPERMISSION}
      />
    </form>
  );
};

export default ChangePasswordForm;
