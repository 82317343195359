// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import ActiveUsersContainer from './../../../containers/ExtraUsersContainer/ActiveUsersContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const ActiveUsers = () => {

  // Returning ActiveUsers Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.userListing} />
      <Layout heading={layoutHeading.users}>
        <ActiveUsersContainer />
      </Layout>
    </>
  );
};

// Exporting ActiveUsers Function.
export default ActiveUsers;
