import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes } from "./Routes";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import { URLs } from "./URLs";
import { useSelector } from "react-redux";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import { usePermissions } from "../permissionhook";
import { getRole } from "../utils/Common";

const Navigation = () => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();

  const getRouteNavigation = (permissions, singlePermission) => {
    if (singlePermission === true) {
      return true;
    }
    if (singlePermission === false) return false;
    if (Object.keys(permissions).includes(singlePermission)) {
      return true;
    }
    return false;
  };
  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={
              route.isProtected ? (
                <PrivateRoute
                  isAuthenticated={
                    (permissions &&
                      getRouteNavigation(permissions, route.permission)) ||
                    getRole(role)
                  }
                >
                  {route.data}
                </PrivateRoute>
              ) : (
                route.data
              )
            }
          />
        ))}
        <Route path={URLs.login} element={<Login />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
