import { color } from "../../../themes/Colors";

export const accountsStyle = {
  container: { borderRadius: "1px", position: "relative" },
  searchBtn: {
    width: "100%",
    height: "30px",
    backgroundColor: color._0e8d84,
    borderRadius: "2px",
    textTransform: "uppercase",
    border: "none",
    color: color._ffffff,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
