import React from 'react';
import AddClientForm from './helper/AddClientForm';
import { addClientStyle } from './style';

const AddClientContainer = () => {
    return (
        <>
            <div className='row'>
                <div className='col-md-9'>
                    <div className='card card-primary p-2' style={addClientStyle.container}>
                        <AddClientForm />
                    </div>
                </div>
            </div>
        </>

    );
};

export default AddClientContainer;