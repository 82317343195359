export const withdrawConstants = {
  username: "Username:",
  status: "Status:",
  type: "Type:",
  refresh: "Refresh",
  pending: "Pending",
  approved: "Approved",
  approve: "Approve",
  rejected: "Rejected",
  reject: "Reject",
  proof: "Proof",
  search: "Search",
};

export const statusOptions = [
  { value: "", label: "Select status" },
  { value: 0, label: "Pending" },
  { value: 1, label: "Approved" },
  { value: 2, label: "Rejected" },
];

export const typeOptions = [
  { value: "", label: "Select type" },
  { value: "manual", label: "Manual" },
  { value: "client", label: "Client" },
  { value: "UTR Entry", label: "UTR Entry" },
];

export const reasonOptions = [
  { value: "", label: "Select Reason" },
  { value: "Invalid Account Detail", label: "Invalid Account Detail" },
  {
    value: "Payment is not being processed",
    label: "Payment is not being processed",
  },
  { value: "Other", label: "Other" },
];

export const depositReasonOptions = [
  { value: "", label: "Select Reason" },
  { value: "Payment not recieved", label: "Payment not recieved" },
  {
    value: "Utr and amount not matched",
    label: "Utr and amount not matched",
  },
  {
    value: "Utr already used",
    label: "Utr already used",
  },
  { value: "Other", label: "Other" },
];
