import {
  GET_GLOBAL_FAIL,
  GET_GLOBAL_SUCCESS,
  GLOBAL_SITE_DETAILS,
  GLOBAL_SITE_USER_DETAILS,
} from "./actionType";

const initialState = {
  globalThirdParties: [],
  globalSiteObjDetails: {},
  globalSiteUserDetails: {},
};

const globalSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GLOBAL_SUCCESS:
      return {
        ...state,
        globalThirdParties: action.payload,
        errorMessage: null,
      };
    case GET_GLOBAL_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GLOBAL_SITE_DETAILS:
      return {
        ...state,
        globalSiteObjDetails: action.payload,
      };
    case GLOBAL_SITE_USER_DETAILS:
      return {
        ...state,
        globalSiteUserDetails: action.payload,
      };
    default:
      return state;
  }
};

export default globalSearchReducer;
