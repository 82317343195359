import React from "react";
import { accountsStyle } from "./style";
import SearchForm from "./helper/SearchForm";
import AccountList from "./helper/AccountList";
import useAccountsHook from "../../../hooks/accounts-hook";

const AccountListContainer = () => {
    const {
        bankAccounts,
        // handleSearch,
        searchAccountFormValues,
        // onHandleSearchInputChange,
        handleReset,
        // handleStatusFilterChange,
        selectedStatus,
        selectedMaxDeposit,
        // handleMaxDepositFilterChange,
        formattedBankAccountsData,
        loading,
        accountFilters,
        handleAccountSearchableSelect,
        handleSearchAccounts,
        handleAccountInputSearch,
        handleAccountFilterChange,
        resetStatusRef,
        resetMaxDepositRef
    } = useAccountsHook();
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2" style={accountsStyle.container}>
                        <SearchForm
                            bankAccounts={bankAccounts}
                            // handleSearch={handleSearch}
                            searchAccountFormValues={searchAccountFormValues}
                            // onHandleSearchInputChange={onHandleSearchInputChange}
                            handleReset={handleReset}
                            // handleStatusFilterChange={handleStatusFilterChange}
                            selectedStatus={selectedStatus}
                            selectedMaxDeposit={selectedMaxDeposit}
                            // handleMaxDepositFilterChange={handleMaxDepositFilterChange}
                            accountFilters={accountFilters}
                            handleAccountInputSearch={handleAccountInputSearch}
                            handleSearchAccounts={handleSearchAccounts}
                            handleAccountSearchableSelect={handleAccountSearchableSelect}
                            handleAccountFilterChange={handleAccountFilterChange}
                            resetStatusRef={resetStatusRef}
                            resetMaxDepositRef={resetMaxDepositRef}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pb-4 mt-2">
                    <div className="card table-responsive">
                        <AccountList
                            formattedBankAccountsData={formattedBankAccountsData}
                            loading={loading} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountListContainer;
