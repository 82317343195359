import React, { useEffect } from "react";
import CustomLabel from "../../../../components/atoms/CustomLabel";
import CustomSelectBox from "../../../../components/atoms/CustomSelectBox";
import {
  accountTypeOptions,
  addAccountConstant,
  depositOptions,
  statusOptions,
  withdrawlOptions,
} from "../../AddAccountContainer/constants";
import CardHeader from "../../../../components/molecules/CardHeader";
import CustomInput from "./../../../../components/atoms/CustomInput";
import { inputTypes, classes } from "../../../../constants/CommonConstants";
import { addAccountStyle } from "../../AddAccountContainer/style";
import CardFooter from "../../../../components/molecules/CardFooter/CardFooter";
import { useAccountsHook } from "./../../../../hooks/accounts-hook";
import ReusableIcon from "../../../../components/atoms/ReusableIcon";
import { TableIcons } from "../../../../assets/icons/Icons";
import { color } from "../../../../themes/Colors";
import CustomFileInput from "../../../../components/atoms/CustomInput/CustomFileInput";

const EditAccountForm = () => {
  const {
    handleEditAccount,
    onHandleSelect,
    onHandleInputChange,
    editAccount,
    buttonLoading,
    formErrors,
    renderError,
    openEditAccount,
    openPreview,
  } = useAccountsHook(false);
  useEffect(() => {
    if (!editAccount.account_type && localStorage.getItem("editId")) {
      openEditAccount({ id: localStorage.getItem("editId") });
    }
  }, []);
  return (
    <form onSubmit={handleEditAccount}>
      <CardHeader cardHeaderTitle={addAccountConstant.editAccount} />
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-md-4 mb-3">
              <CustomLabel
                label={addAccountConstant.accountType}
                additionalStyles={addAccountStyle.label}
              />
              <CustomSelectBox
                options={accountTypeOptions}
                defaultValue={editAccount.account_type}
                className={`${classes.selectBox} ${formErrors["account_type"] && classes.invalid
                  }`}
                onChange={(value) =>
                  onHandleSelect("account_type", value.label)
                }
                property="account_type"
                error={renderError("account_type")}
              />
            </div>
            <div className="col-md-4">
              <CustomLabel
                label={addAccountConstant.onlyForWithdrawal}
                additionalStyles={addAccountStyle.label}
              />
              <CustomSelectBox
                options={withdrawlOptions}
                defaultValue={editAccount.purpose}
                className={classes.selectBox}
                onChange={(value) =>
                  onHandleSelect("purpose", value.label)
                }
                property="purpose"
                error={renderError("purpose")}
              />
            </div>
            <div className="col-md-4 mb-3">
              <CustomLabel
                label={addAccountConstant.bankName}
                additionalStyles={addAccountStyle.label}
              />
              <CustomInput
                type={inputTypes.text}
                className={`${classes.inputClass} ${formErrors["bank_name"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.enterBank}
                value={editAccount.bank_name}
                onChange={(e) => onHandleInputChange("bank_name", e)}
                error={renderError("bank_name")}
              />
            </div>
            <div className="col-md-4">
              <CustomLabel
                label={addAccountConstant.name}
                additionalStyles={addAccountStyle.label}
              />
              <CustomInput
                type={inputTypes.text}
                className={`${classes.inputClass} ${formErrors["account_name"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.enterName}
                value={editAccount.account_name}
                onChange={(e) => onHandleInputChange("account_name", e)}
                error={renderError("account_name")}
              />
            </div>
            <div className="col-md-4 mb-3">
              <CustomLabel
                label={addAccountConstant.accountNumber}
                additionalStyles={addAccountStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                className={`${classes.inputClass} ${formErrors["account_number"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.enterAccountNumber}
                value={editAccount.account_number}
                onChange={(e) => onHandleInputChange("account_number", e)}
                error={renderError("account_number")}
              />
            </div>
            <div className="col-md-4">
              <CustomLabel
                label={addAccountConstant.ifsc}
                additionalStyles={addAccountStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                className={`${classes.inputClass} ${formErrors["ifsc_code"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.enterIfsc}
                value={editAccount.ifsc_code}
                onChange={(e) => onHandleInputChange("ifsc_code", e)}
                error={renderError("ifsc_code")}
              />
            </div>
            <div className="col-md-4 mb-3">
              <CustomLabel
                label={addAccountConstant.minimumAmount}
                additionalStyles={addAccountStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                className={`${classes.inputClass} ${formErrors["min_amount"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.enterMinimum}
                value={editAccount.min_amount}
                onChange={(e) => onHandleInputChange("min_amount", e)}
                error={renderError("min_amount")}
              />
            </div>
            <div className="col-md-4">
              <CustomLabel
                label={addAccountConstant.maximumAmount}
                additionalStyles={addAccountStyle.label}
              />
              <CustomInput
                type={inputTypes.numeric}
                className={`${classes.inputClass} ${formErrors["max_amount"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.enterMaximum}
                value={editAccount.max_amount}
                onChange={(e) => onHandleInputChange("max_amount", e)}
                error={renderError("max_amount")}
              />
            </div>
            <div className="col-md-4 mb-3">
              <CustomLabel
                label={addAccountConstant.maximumDeposit}
                additionalStyles={addAccountStyle.label}
              />
              <CustomSelectBox
                options={depositOptions}
                defaultValue={editAccount.max_deposit_amount}
                className={`${classes.selectBox} ${formErrors["max_deposit_amount"] && classes.invalid
                  }`}
                onChange={(value) =>
                  onHandleSelect("max_deposit_amount", value.label)
                }
                property={"max_deposit_amount"}
                error={renderError("max_deposit_amount")}
              />
            </div>
            <div className="col-md-4">
              <CustomLabel
                label={addAccountConstant.qrCode}
                additionalStyles={addAccountStyle.label}
              />
              {!editAccount.qrCode ? <CustomInput
                type={inputTypes.file}
                className={`${classes.selectBox} ${formErrors["qrCode"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.noFile}
                onChange={(e) => onHandleInputChange("qrCode", e)}
                accept={inputTypes.acceptType}
                // error={renderError("qr_code")}
                id="fileEditInput"
              /> : <CustomFileInput
                type={inputTypes.file}
                className={`${classes.selectBox} ${formErrors["qrCode"] && classes.invalid
                  }`}
                placeholder={addAccountConstant.noFile}
                onChange={(e) => onHandleInputChange("qrCode", e)}
                accept={inputTypes.acceptType}
                initialFilename={editAccount.qrCode}
                // error={renderError("qr_code")}
                id="fileEditInput"
              />}

            </div>
            <div className="col-md-4">
              <CustomLabel
                label={addAccountConstant.status}
                additionalStyles={addAccountStyle.label}
              />
              <CustomSelectBox
                options={statusOptions}
                defaultValue={editAccount.status}
                className={`${classes.selectBox} ${formErrors["status"] && classes.invalid
                  }`}
                onChange={(value) => onHandleSelect("status", value.value)}
                error={renderError("status")}
              />
            </div>
            {editAccount?.preview && <div className="col-md-4">
              <CustomLabel
                label={addAccountConstant.preview}
                additionalStyles={addAccountStyle.label}
              />
              <div>
                <ReusableIcon
                  icon={TableIcons.visibleIcon}
                  style={{
                    color: color._ffffff,
                    backgroundColor: color._0089ca,
                    borderRadius: "15px",
                    paddingLeft: "7px",
                    paddingRight: "7px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                  onClick={() => openPreview()}
                  iconText={"Preview"}
                  toolTip={"Preview"}
                />
              </div>
            </div>}
          </div>
        </div>
      </div>
      <CardFooter
        title={addAccountConstant.update}
        className={classes.btn}
        isLoading={buttonLoading}
        loaderStyle={addAccountStyle.loading}
      />
    </form>
  );
};

export default EditAccountForm;
