import React from 'react';
import { manualStyle } from './style';
import ManualWithdrawForm from './helper/ManualWithdrawForm';

const WithdrawManualContainer = () => {
    return (
        <>
            <div className='row'>
                <div className='col-md-9'>
                    <div className='card card-primary p-2' style={manualStyle.container}>
                        <ManualWithdrawForm />
                    </div>
                </div>
            </div>
        </>

    );
};

export default WithdrawManualContainer;