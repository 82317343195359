import { color } from "../../../themes/Colors";

const logoutBtnStyle = {
	btn: {
		color: color._ffffff,
		backgroundColor: color._0e8d84,
		width: '100%',
		padding: "8px"
	},
	loading: {
		marginRight: "5px",
	}
}

export { logoutBtnStyle };