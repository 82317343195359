import React from 'react'
import CustomTable from '../../../components/organisms/CustomTable/CustomTable'
import { accountStatementStyle } from '../style'
import { accountStatementTableColumns } from '../utils/AccountStatementUtils';

const AccountStatementList = (props) => {
    const { formattedAccountStatementData, loading } = props
    return (
        <CustomTable
            data={formattedAccountStatementData}
            columns={accountStatementTableColumns}
            rows={25}  //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
            loading={loading}
            style={accountStatementStyle.table} />
    )
}

export default AccountStatementList