// Setting components names in an object.
export const ComponentNames = {
    Login: "Login",
    Home: "Home",
    ForgotPassword: "Forgot Password",
    Dashboard: "Dashboard",
    GlobalSearch: "Global Search",
    Clients: "Clients",
    Accounts: "Accounts",
    Add: "Add",
    Edit: "Edit",
    List: "List",
    Disabled: "Disabled",
    Deposit: "Deposit",
    AccountDeposit: "Account Deposit History",
    AccountWithdraw: "Account Withdraw History",
    History: "History",
    UtrForm: "UTR Form",
    UtrPendingList: "UTR Pending List",
    UtrRemovedList: "UTR Removed List",
    CashSettlement: "Cash Settlement",
    Withdraw: "Withdraw",
    WithdrawHistory: "Withdraw History",
    Manual: "Manual",
    ExtraUsers: "Extra Users",
    Active: "Active",
    Inactive: "Inactive",
    AccountStatement: "A/C Statement",
    Statistics: "Stats",
    ChangePassword: "Change Password",
    EditUser:"Edit User"
}