import React from "react";
import WithdrawCashSettlementForm from "./helper/WithdrawCashSettlementForm";
import { withdrawStyle } from "./style";
import useWithdrawHook from "../../../hooks/withdraw-hook";
import { useSelector } from "react-redux";
import { usePermissions } from "../../../permissionhook";
import { getPermission, getRole } from "../../../utils/Common";

const WithdrawCashSettlementContainer = () => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();

  const CREATEPERMISSION = getPermission(
    permissions,
    "WITHDRAWLIST",
    "WRITEPERMISSION",
    getRole(role)
  );
  const UPDATEPERMISSION = getPermission(
    permissions,
    "WITHDRAWLIST",
    "UPDATEPERMISSION",
    getRole(role)
  );
  const {
    withdrawalCashSettlement,
    formErrors,
    renderError,
    handleAddWithdrawalCashSettlement,
    onHandleInputChange,
    bankAccounts,
    onHandleSelect,
  } = useWithdrawHook(false, false, true, false, UPDATEPERMISSION);
  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div
            className="card card-primary p-2"
            style={withdrawStyle.container}
          >
            <WithdrawCashSettlementForm
              withdrawalCashSettlement={withdrawalCashSettlement}
              formErrors={formErrors}
              renderError={renderError}
              handleAddWithdrawalCashSettlement={
                handleAddWithdrawalCashSettlement
              }
              onHandleInputChange={onHandleInputChange}
              onHandleSelect={onHandleSelect}
              bankAccounts={bankAccounts}
              CREATEPERMISSION={CREATEPERMISSION}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawCashSettlementContainer;
