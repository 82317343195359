import React from "react";
import Layout from "../../../layout/Layout";
import ClientListContainer from "../../../containers/ClientsContainer/ClientListContainer";
import { layoutHeading, tabTitles } from "../../../constants/CommonConstants";
import PageHelmet from "../../../components/atoms/PageHelmet";

// Declaring Funtion
const ClientList = () => {
  // Returning ClientList Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.clientsListing} />
      <Layout heading={layoutHeading.clients}>
        <ClientListContainer />
      </Layout>
    </>
  );
};

// Exporting ClientList Function.
export default ClientList;
