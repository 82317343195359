import React from 'react'
import CustomTable from '../../../../components/organisms/CustomTable/CustomTable'
import { depositStyle } from '../style'
import { depositHistoryTableColumns } from '../../utils/DepositUtils'

const DepositHistoryList = (props) => {
    const { formattedDepositHistoryData, loading } = props
    return (
        <CustomTable
            data={formattedDepositHistoryData}
            columns={depositHistoryTableColumns}
            rows={25}  //AVINASH & ABIR ASKED TO SHOW 25 RECORDS EARLIER IT WAS 100 WHICH WAS SUGGESTED BY HEMANT SIR
            style={depositStyle.table}
            loading={loading}
        />
    )
}

export default DepositHistoryList