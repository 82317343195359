import React from "react";
import Button from "../../atoms/Button";
import { footer } from "./style";

const CardFooter = (props) => {
  const {
    title,
    type,
    className,
    isLoading,
    status,
    loaderStyle,
    CREATEPERMISSION = true,
  } = props;
  return (
    <div className="card-footer" style={footer.div}>
      <Button
        title={title}
        type={type}
        className={className}
        style={CREATEPERMISSION ? footer.btn : footer.btnDisabled}
        isLoading={isLoading}
        status={status}
        loaderStyle={loaderStyle}
        disabled={!CREATEPERMISSION}
      />
    </div>
  );
};

export default CardFooter;
