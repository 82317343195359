import React from "react";
import { depositStyle } from "./style";
import DepositForm from "./helper/DepositForm";
import RefreshForm from "./helper/RefreshForm";
import DespositList from "./helper/DespositList";
import useDepositHook from "../../../hooks/deposit-hook";
import { useSelector } from "react-redux";
import { getPermission, getRole } from "../../../utils/Common";
import { usePermissions } from "../../../permissionhook";
import Modal from "../../../components/molecules/Modal/Modal";
import { globalSearchConstants } from "../../GlobalSearchContainer/constants";
import RejectWithModal from "../../WithdrawContainer/WithdrawListContainer/helper/RejectWithModal";
import { depositReasonOptions } from "../../WithdrawContainer/WithdrawListContainer/constants";

const DepositListContainer = () => {
  const role = useSelector((state) => state.auth.role);

  const permissions = usePermissions();

  const CREATEPERMISSION = getPermission(
    permissions,
    "DEPOSITLIST",
    "WRITEPERMISSION",
    getRole(role)
  );
  const UPDATEPERMISSION = getPermission(
    permissions,
    "DEPOSITLIST",
    "UPDATEPERMISSION",
    getRole(role)
  );

  const {
    clients,
    bankAccounts,
    depositDetails,
    onHandleInputChange,
    handleDepositInputChange,
    handleDepositSubmit,
    formErrors,
    renderError,
    btnLoading,
    formattedDepositData,
    loading,
    handleStatusFilterChange,
    filterStatus,
    filterAccount,
    handleRefresh,
    handleDepositInput,
    depositFilters,
    handleDepositOnSelect,
    formSubmitState,
    handleDepositSearchableSelect,
    fileInputRef,
    resetStatusRef,
    resetTypeRef,
    setDepositAccountId,
    showModal,
    handleCloseModal,
    btnDisable,
    modalCheck,
    handleDepositApproved,
    handleDepositReject,
    modalFormErrors,
    renderModalError,
    rejectInput,
    onHandleWithRejectChange,
    selectBoxRef,
  } = useDepositHook(true, true, true, false, UPDATEPERMISSION);
  const { depositListItemData } = useSelector((state) => state.deposit);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card p-2" style={depositStyle.container}>
            <DepositForm
              clients={clients}
              bankAccounts={bankAccounts}
              depositDetails={depositDetails}
              onHandleInputChange={onHandleInputChange}
              handleDepositInputChange={handleDepositInputChange}
              handleDepositSubmit={handleDepositSubmit}
              formErrors={formErrors}
              renderError={renderError}
              btnLoading={btnLoading}
              // formSubmitState={formSubmitState}
              fileInputRef={fileInputRef}
              setDepositAccountId={setDepositAccountId}
              CREATEPERMISSION={CREATEPERMISSION}
              role={role}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2">
          <div className="card p-2" style={depositStyle.container}>
            <RefreshForm
              bankAccounts={bankAccounts}
              depositFilters={depositFilters}
              handleDepositInput={handleDepositInput}
              handleStatusFilterChange={handleStatusFilterChange}
              filterStatus={filterStatus}
              filterAccount={filterAccount}
              handleRefresh={handleRefresh}
              handleDepositOnSelect={handleDepositOnSelect}
              handleDepositSearchableSelect={handleDepositSearchableSelect}
              resetStatusRef={resetStatusRef}
              resetTypeRef={resetTypeRef}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pb-2 mt-2">
          <div className="card table-responsive">
            <DespositList
              formattedDepositData={formattedDepositData}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        handleClose={handleCloseModal}
        isLoading={btnLoading}
        btnDisable={btnDisable}
        modalTitle={
          `${depositListItemData?.client?.username} (${depositListItemData.amount})`
        }
        modalFooterPrimaryTitle={globalSearchConstants.cancel}
        modalFooterSecondaryTitle={
          modalCheck === "Approve"
            ? globalSearchConstants.yes
            : globalSearchConstants.save
        }
        handleSave={
          modalCheck === "Approve" ? handleDepositApproved : handleDepositReject
        }
      >
        {modalCheck === "Approve" ? (
          <b>Do you want to Approve?</b>
        ) : (
          <RejectWithModal
            modalFormErrors={modalFormErrors}
            renderError={renderModalError}
            withdrawRejectInput={rejectInput}
            onHandleWithRejectChange={onHandleWithRejectChange}
            selectBoxRef={selectBoxRef}
            showProof={false}
            modalSelectOptions={depositReasonOptions}
          />
        )}
      </Modal>
    </>
  );
};

export default DepositListContainer;
