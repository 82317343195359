// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import AddAccountContainer from '../../../containers/AccountsContainer/AddAccountContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const AddAccount = () => {

  // Returning AddAccount Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.addAccount} />
      <Layout heading={layoutHeading.account}>
        <AddAccountContainer />
      </Layout>
    </>
  );
};

// Exporting AddAccount Function.
export default AddAccount;
