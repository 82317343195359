import { toast } from "react-toastify";
import { endPoints } from "../../services/EndPoints";
import {
  _crossPlatformGet,
  _get,
  _post,
  _postWithId,
  _update,
} from "../../services/HttpMethods";
import {
  GET_BANK_ACCOUNT_SUCCESS,
  GET_BANK_ACCOUNT_FAIL,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAIL,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_FAIL,
  UPDATE_BANK_ACCOUNT_STATUS_SUCCESS,
  UPDATE_BANK_ACCOUNT_STATUS_FAIL,
  GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_SUCCESS,
  GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_FAIL,
  GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_SUCCESS,
  GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_FAIL,
  UPDATE_ACTIVE_ACCOUNTS_DATA,
  UPDATE_DISABLED_FILTERED_DATA,
  SET_SEARCH_CRITERIA,
  GET_BANK_ACCOUNT_STATEMENT_SUCCESS,
  GET_BANK_ACCOUNT_STATEMENT_FAIL,
  GET_BANK_ACCOUNT_STATEMENT_BY_ID,
} from "./actionType";
import { accountMessage } from "../../constants/SuccessMessage";
import { unexpectedError } from "../../constants/ErrorMessage";
import { removeAuthData } from "../../utils/Common";
import { logoutErrors } from "../../constants/ErrorMessage";
import { LOGOUT_SUCCESS } from "../auth/actionType";
import { constants } from "../../constants/CommonConstants";

export const addAccount = (account, onSuccess, token, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _post(
        endPoints.bankAccount,
        account,
        token,
        false,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { status, data } = response.data;
      if (status !== constants.status_200) {
        throw new Error(response.data.msg);
      }
      dispatch({
        type: ADD_BANK_ACCOUNT_SUCCESS,
        payload: data,
      });
      toast.success(accountMessage.successAccountAdded);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        dispatch({
          type: ADD_BANK_ACCOUNT_FAIL,
          payload: error.message,
        });
        toast.error(error.message);
      }
    }
  };
};

export const updateAccount = (
  id,
  updatedData,
  onSuccess,
  token,
  logoutFunc
) => {
  return async (dispatch) => {
    try {
      const response = await _update(
        endPoints.bankAccount,
        id,
        updatedData,
        token,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { status, msg } = response;
      if (status !== constants.status_200) {
        throw new Error(msg);
      }
      dispatch({
        type: UPDATE_BANK_ACCOUNT_SUCCESS,
        payload: msg,
      });
      toast.success(accountMessage.successAccountUpdated);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        dispatch({
          type: UPDATE_BANK_ACCOUNT_FAIL,
          payload: error.message,
        });
        toast.error(error.message);
      }
    }
  };
};

export const updateAccountStatus = (id, status, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _postWithId(
        endPoints.bankAccountUpdateStatus,
        id,
        status,
        logoutFunc
      );
      if (!response) {
        return;
      }
      const { data, msg } = response.data;
      if (response.data.status !== constants.status_200) {
        throw new Error(msg);
      }
      dispatch({
        type: UPDATE_BANK_ACCOUNT_STATUS_SUCCESS,
        payload: data,
      });
      toast.success(accountMessage.successAccountUpdated);
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        dispatch({
          type: UPDATE_BANK_ACCOUNT_STATUS_FAIL,
          payload: error.message,
        });
        toast.error(error.message);
      }
    }
  };
};

export const getBankAccount = (token, obj, showToast = true, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _crossPlatformGet(
        endPoints.bankAccount,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      }
      if (response.status === 200) {
        dispatch({
          type: GET_BANK_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      } else if (response.status === 404) {
        dispatch({
          type: GET_BANK_ACCOUNT_SUCCESS,
          payload: [], // Set deposits to an empty array on 404
        });
        if (showToast) {
          // toast.info(response.msg); // Show toast only if showToast is true
        }
      } else {
        throw new Error(response.msg);
      }
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_BANK_ACCOUNT_FAIL,
          payload: error.message,
        });
      }
    }
  };
};

export const getBankAccountDepositHistoryById = (id, token, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _get(
        endPoints.bankAccountDepositHistory,
        id,
        token,
        null,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== constants.status_200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_BANK_ACCOUNT_DEPOSIT_HISTORY_BY_ID_FAIL,
          payload: error.message,
        });
      }
    }
  };
};
export const getBankAccountWithdrawHistoryById = (id, logoutFunc) => {
  return async (dispatch) => {
    try {
      const response = await _get(
        endPoints.bankAccountWithdrawalHistory,
        id,
        null,
        null,
        logoutFunc
      );
      if (!response) {
        return;
      } else if (response.status !== constants.status_200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_BANK_ACCOUNT_WITHDRAW_HISTORY_BY_ID_FAIL,
          payload: error.message,
        });
      }
    }
  };
};

export const updateActiveAccountsData = (filteredActiveData) => ({
  type: UPDATE_ACTIVE_ACCOUNTS_DATA,
  payload: filteredActiveData,
});

export const updateDisableFilteredData = (filteredDisabledData) => ({
  type: UPDATE_DISABLED_FILTERED_DATA,
  payload: filteredDisabledData,
});

export const setSearchCriteria = (criteria) => ({
  type: SET_SEARCH_CRITERIA,
  payload: criteria,
});

const getFormattedResponse = (data) => {
  for (let i = 0; i < data?.length; i++) {
    data[i].total = data[i].amount;
    data[i].eventType = "-";
  }
  return data;
};

export const getBankAccountStatement = (token, logoutFunc, data = null) => {
  return async (dispatch) => {
    try {
      const response = await _get(
        endPoints.accountStatement,
        null,
        token,
        data,
        logoutFunc,
        data && "clients"
      );
      if (!response) {
        return;
      } else if (response.status !== constants.status_200) {
        throw new Error(response.msg);
      }
      dispatch({
        type: GET_BANK_ACCOUNT_STATEMENT_SUCCESS,
        payload: getFormattedResponse(response.data),
      });
    } catch (error) {
      if (error.message === constants.Unauthorized) {
        removeAuthData();
        toast.info(logoutErrors.sessionExpire);
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: logoutErrors.sessionExpire,
        });
      } else {
        toast.error(error.message);
        dispatch({
          type: GET_BANK_ACCOUNT_STATEMENT_SUCCESS,
          payload: [],
        });
      }
    }
  };
};

export const getBankAccountStatementById = (obj, token, logoutFunc) => {
  return async (dispatch) => {
    try {
      // if (!obj.siteId) {
      //   throw new Error("Site Id is Undefined");
      // }
      const response = await _get(
        endPoints.accountStatement,
        null,
        token,
        obj,
        logoutFunc
      );
      if (!response) {
        return;
      }
      dispatch({
        type: GET_BANK_ACCOUNT_STATEMENT_BY_ID,
        payload: getFormattedResponse(response.data),
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
};
