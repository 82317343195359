// Importing dependencies.
import React from 'react';
import Layout from '../../../layout/Layout';
import AddUsersContainer from '../../../containers/ExtraUsersContainer/AddUsersContainer';
import { layoutHeading, tabTitles } from '../../../constants/CommonConstants';
import PageHelmet from '../../../components/atoms/PageHelmet';

// Declaring Funtion
const AddUsers = () => {

  // Returning AddUsers Component from function.
  return (
    <>
      <PageHelmet tabTitle={tabTitles.addUsers} />
      <Layout heading={layoutHeading.users}>
        <AddUsersContainer />
      </Layout>
    </>
  );
};

// Exporting AddUsers Function.
export default AddUsers;
