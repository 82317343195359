import React from "react";
import CustomLabel from "../../../components/atoms/CustomLabel";
import { globalSearchConstants } from "../constants";
import CustomInput from "../../../components/atoms/CustomInput";
import Button from "../../../components/atoms/Button";
import { globalSearchStyle } from "../style";
import { classes } from "../../../constants/CommonConstants";
import SearchBox from "../../../components/atoms/SearchableSelectBox/SearchBox";

const SearchForm = (props) => {
  const {
    domains,
    domainValue,
    handleDomainChange,
    inputRef,
    handleClick,
    handleUserName,
    handleReset,
    searchUsername,
    resetBtnDisable,
  } = props;
  return (
    <form>
      <div className="row">
        <div className="col-md-3">
          <CustomLabel label={globalSearchConstants.domain} />
          <SearchBox
            options={domains}
            value={domainValue}
            placeholder={globalSearchConstants.selectDomain}
            onChange={(e) => handleDomainChange(e)}
          />
        </div>
        <div className="col-md-3 mb-md-3 mb-2">
          <CustomLabel label={globalSearchConstants.username} />
          <CustomInput
            placeholder={globalSearchConstants.usernamePlaceholder}
            className={classes.inputClass}
            inputRef={inputRef}
            onChange={handleUserName}
            value={searchUsername}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center mt-md-2 mt-0">
          <Button
            title={globalSearchConstants.search}
            style={globalSearchStyle.searchBtn}
            className="pb-1 mb-2 mb-md-0 btn-hover"
            onClick={handleClick}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center mt-md-2 mt-0">
          <Button
            title={globalSearchConstants.reset}
            style={globalSearchStyle.searchBtn}
            className="pb-1 mb-2 mb-md-0 btn-hover"
            onClick={handleReset}
            disabled={resetBtnDisable}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
