import React, { useEffect } from "react";
import CustomTable from "../../../../components/organisms/CustomTable/CustomTable";
import { depositStyle } from "../style";
import { accountDepositHistoryTableColumns } from "../../utils/AccountUtils";
import BlankRecord from "../../../../components/organisms/CustomTable/BlankRecord";

const AccountDespositHistoryList = (props) => {
  const {
    formattedAccountDepositHistoryData,
    loading,
    openAccountDepositHistory,
    accountDepositFilteredData,
  } = props;

  useEffect(() => {
    const item = localStorage.getItem("depositId");
    if (item) {
      openAccountDepositHistory({ id: item });
    }
  }, []);

  return (
    <> {accountDepositFilteredData === 0 || accountDepositFilteredData === undefined ? <BlankRecord data={[]} columns={accountDepositHistoryTableColumns}
      style={depositStyle.table} /> : <CustomTable
      data={
        accountDepositFilteredData.length > 0
          ? accountDepositFilteredData
          : formattedAccountDepositHistoryData
      }
      columns={accountDepositHistoryTableColumns}
      rows={25} 
      style={depositStyle.table}
      loading={loading}
    />}
    </>
  );
};

export default AccountDespositHistoryList;
