export const clientMessage = {
  successClientAdded: "Client created successfully!",
};
export const accountMessage = {
  successAccountAdded: "Account created successfully!",
  successAccountUpdated: "Account updated successfully!",
};
export const depositMessage = {
  successDepositAdded: "Deposit added successfully!",
};
export const infoMessage = {
  copied: "Copied",
};

export const withdrawlMessage = {
  successManualWithdrawalAdded: "Withdrawal Added Successfully!",
};

export const userMessage = {
  successUserAdded: "User Added Successfully!",
};

export const passwordChanged = {
  PasswordChanged: "Password Changed Successfully!",
};
